import {
  RETRIEVE_STOCK_QUERY,
  RETRIEVE_STOCK_QUERY_DETAIL
} from "../StockQuery/actiontype";

const INITIAL_STATE = {
  currentIntegration: [],
  peddlerStockDetail: [],
  isLoading: false,
};

const peddlerStockQueryReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
 
  switch (type) {
    case  RETRIEVE_STOCK_QUERY:
      return {
        ...state,
        currentIntegration: payload,
        isLoading: true,
      };
    case  RETRIEVE_STOCK_QUERY_DETAIL:
      return {
        ...state,
        peddlerStockDetail: payload,
      };
      
      default:
        return state;
  }
};
export default peddlerStockQueryReducer;