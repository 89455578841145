import {
    RETRIEVE_DECLINED_ORDER,
    DELETE_DECLINED_ORDER
} from "../DeclinedOrder/actiontype";

import { toast } from 'react-toastify';
import api from "../../../api";



export const getDeclinedOrder= (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  try {
    const res = await api.get(`/order/declinedOrder`);
   
    
    dispatch({
      type: RETRIEVE_DECLINED_ORDER,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_DECLINED_ORDER,
      payload: [],
    });
  }
};

export const deleteDeclinedOrder = (id) => async (dispatch) => {
  try {
    const res = await api.get('/order/deleteOrder/' + id);
   
    if (res.status === 200) {
      toast.success(res.data.message);
      dispatch({
        type: DELETE_DECLINED_ORDER,
        payload: res.data.data.info,
      });
      
    }
  } catch (error) {
    
    toast.error('Failed to delete');
    dispatch({
      type: DELETE_DECLINED_ORDER,
      payload: [],
    });
  }
};

