import {
    RETRIEVE_QUOTATION_DETAIL
} from "../ViewQuotation/actiontype";
import api from "../../../api";

export const getQuotationDetail= (id) => async (dispatch) => {
  
  try {
    const res = await api.get(`/quotation/viewDetailQuotation/` + id);
   
    dispatch({
      type: RETRIEVE_QUOTATION_DETAIL,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_QUOTATION_DETAIL,
      payload: [],
    });
  }
};



