import { TryOutlined } from "@mui/icons-material";
import {
    CREATE_PAID_INCENTIVE,
    RETRIEVE_PAID_INCENTIVE,
    UPDATE_PAID_INCENTIVE,
    DELETE_PAID_INCENTIVE
} from "../PaidIncentive/actiontype";

const INITIAL_STATE = {
  currentIntegration: [],
  isLoading: false,
};

const paidIncentiveReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case CREATE_PAID_INCENTIVE:
      return {
        ...state
      };
    case RETRIEVE_PAID_INCENTIVE:
      return {
        ...state,
        currentIntegration: payload,
        isLoading: TryOutlined,
      };
    case UPDATE_PAID_INCENTIVE:
      return state.map((paidIncentive) => {
        if (paidIncentive.id === payload.id) {
          return {
            ...paidIncentive,
            ...payload,
          };
        } else {
          return paidIncentive;
        }
      });
    case DELETE_PAID_INCENTIVE:
      return state.filter(({ id }) => id !== payload.id);
    default:
      return state;
  }
};
export default paidIncentiveReducer;