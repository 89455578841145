import {
    RETRIEVE_PAYMENT
} from "../PaymentDetail/actiontype";

import api from "../../../api";

let usertype = JSON.parse(localStorage.getItem('usertype'));
let userid = JSON.parse(localStorage.getItem('userid'));


export const getPaymentDetail= (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  let data = {
    usertype : usertype,
    userid : userid
  };

  try {
    const res = await api.post(`/payment/paymentList`, data);
   
    dispatch({
      type: RETRIEVE_PAYMENT,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_PAYMENT,
      payload: [],
    });
  }
};



