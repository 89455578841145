import React from 'react'
import {useState, useEffect} from 'react'
import {Button, Modal } from 'react-bootstrap';
import KErrorMessage from "../../components/ErrorMessage/KErrorMessage";
import { Formik, Form } from "formik";
import * as yup from "yup";
import Select from 'react-select';
import {getUnapprovedOrder,getBranch} from '../../redux/Order/UnapprovedOrder/action';
import { useNavigate } from 'react-router-dom';
import { connect } from "react-redux";

const validationSchema = yup.object({
    branch: yup.string().required("Branch Name is Required"),
  });

//export default function CreateOrder() {

const CreateOrder = ({ getUnapprovedOrder,currentIntegration,getBranch,branches }) =>  {  useEffect(() => {
  getUnapprovedOrder();
  getBranch();
}, []);

const navigate = useNavigate();
const [formData, setFormData] = useState({
  branch1: "",
});

const [show, setShow] = useState(false);
const modalAddClose = () => setShow(false);
const modalAddShow = () => setShow(true);

const [anchorEl, setAnchorEl] = React.useState(null);
const open = Boolean(anchorEl);
const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
};
const { branch1 } = formData;
  
const onChange = (e) =>
{
  setFormData({ branch1: e.value });
}
const handleClose = () => {
    setAnchorEl(null);
};

const onSubmit = async (e) => {
  e.preventDefault();
  const branch_id = branch1;
  navigate("/create-order/"+branch_id);
};

const [branch, branchList] = useState(null);

useEffect(() => {
  if(branches)
  {
    branchList(branches); 
  }
}, [branches])

// const branch = [
//     { value: '1', label: 'INDESIGN#QT_1002' },
//     { value: '2', label: 'INDESIGN#QT_1003' },
//     { value: '3', label: 'INDESIGN#QT_10024' }
//   ]

  return (
    <>
      <button type="button" className="btn btn-md btn-primary d-none d-sm-block" onClick={modalAddShow}><i className="fa-solid fa-plus"></i> Create</button>
      <button type="button" className="btn btn-md btn-primary d-block d-sm-none roundedcircle ml-3"  onClick={modalAddShow}><i className="fa-solid fa-plus"></i></button>
     {/* Create Order Modal Start */}
     <Modal 
          size="md"
          centered
          show={show} 
          onHide={modalAddClose}>
            <Formik
                validationSchema={validationSchema}
                initialValues={{
                branch: ""
                }}
                onSubmit={(values) => {
                  //console.log(values);
                }}
            >
                 <Form onSubmit={(e) => onSubmit(e)}>
                <Modal.Header closeButton>
                    <Modal.Title>Select Branch</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label>Branch</label>
                    <Select options={branch} name="branch1"  onChange={(e) => onChange(e)}/>
                    <KErrorMessage name="branch" />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={modalAddClose}>Close</Button>
                    <Button variant="primary" type="submit">Submit</Button>
                </Modal.Footer>
                </Form>
            </Formik>
        </Modal>
        {/* Create Order Modal End */}
       
    </>
  )
}

const mapStateToProps = (state) => {
  
  return {
     currentIntegration: state.unapprovedOrder.currentIntegration,
     branches: state.unapprovedOrder.branches,
   }
 }

 export default connect(mapStateToProps,{getUnapprovedOrder,getBranch})(CreateOrder);
