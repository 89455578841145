import {
  RETRIEVE_STOCK_RETURN_SUMMARY,
} from "../../BranchDashboard/StockReturn/actiontype";

const INITIAL_STATE = {
  stockReturnSummary: [],
  isLoading: false,
};

const branchStockReturnReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
 
  switch (type) {
    case  RETRIEVE_STOCK_RETURN_SUMMARY:
      return {
        ...state,
        stockReturnSummary: payload,
        isLoading: true,
      };
      default:
        return state;
  }
};
export default branchStockReturnReducer;