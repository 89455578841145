import React from 'react'
import {useState, useEffect} from 'react'
import { Row, Col, Container, Badge, Dropdown } from 'react-bootstrap';
import MaterialTable from 'material-table';
import CheckIcon from '@mui/icons-material/Check';
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import SearchBar from '../../../components/SearchBar/SearchBar';
import Loader from "../../../components/Loader/Loader";
import { connect } from "react-redux";
import {getReceivedOrder} from '../../../redux/Order/ReceivedOrder/action';
import { useNavigate } from 'react-router-dom';

const ReceivedOrder = ({ getReceivedOrder,currentIntegration,isLoading }) =>  {  useEffect(() => {
  getReceivedOrder();
}, []);

const navigate = useNavigate();
const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (isLoading) {
        setLoading(true);
    }
  });

const [tableData, setTableData] = useState([]);
  useEffect(() => {
    if(currentIntegration)
    {
      setTableData(currentIntegration); 
    }
  }, [currentIntegration])

const columns = [
  { title: "Order No", field: "orderid" },
  { title: "CP", field: "cp" },
  { title: "Name", field: "name" },
  { title: "Mobile", field: "mobile" },
  { title: "Total Qty", field: "qty"},
  { title: "Amount", field: "amount"},
];

const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', type: ''})
const convertToOrder = async (e) => {
  console.log(e);
  navigate('/convert-cp-order/'+e);
}

const [visible, setVisible] = useState(false);
const handleVisibility = () => {
    setVisible(prev => !prev);
};

  return (
    <>
        <Container fluid className="main-content">
            <Row>
              <Col>
                  <h3 className="main-title">Received Order</h3>
              </Col>
              <Col>
                  <div style={{float: "right"}} className="d-flex">
                    <div className="d-flex justify-content-between d-flex d-sm-none">
                      <div className="magnifierContainer">
                        <button type="button" className="btn btn-md btn-warning text-white roundedcircle mr-3" onClick={handleVisibility}><i className="fa-solid fa-search"></i></button>
                      </div>
                    </div>
                  </div>
              </Col> 
              <Col lg={"12"}>
                  <div className='d-block d-sm-none mt-2'>
                    <div className={visible ? "open" : "close"}>
                      <SearchBar/>
                    </div>
                  </div>
              </Col>
            </Row>
            <Row className="d-none d-sm-block mt-2">
              <Col lg={"12"} className="mt-3">
                <div style={{display: 'table', tableLayout:'fixed', width:'100%'}}>
                <MaterialTable columns={columns} data={tableData}
                    actions={[
                      {
                        icon: () => <CheckIcon color={'success'} />,
                        tooltip: 'Convert to Order',
                        onClick: (event, rowData) => convertToOrder(rowData.id)
                      }
                    ]}
                    options={{
                    sorting: true, search: true,
                    paging: true, pageSizeOptions: [10, 25, 50, 100], pageSize: 10,
                    exportButton: true,
                    exportAllData: true, exportFileName: "Received Order", actionsColumnIndex: -1,
                    }}
                    title="Received Order"
                />
                </div>
              </Col>
          </Row>
          {loading ? <div>
          <Row className="d-block d-sm-none mt-2">
            {/* {currentIntegration.filter(filterOrders).map((order, index) => ( */}
            <Col xs={"12"}>
                <div className="card mb-2">
                    <div className="card-header d-flex justify-content-between pb-0">
                        <h6>order  <Badge bg="danger" text="light" className="ml-2">5</Badge></h6>
                        <Dropdown>
                        <Dropdown.Toggle variant="light" id="dropdown-basic">
                            <i className="ri-more-2-line"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {/* <Dropdown.Item onClick={() => approveOrder(order.id)}><CheckIcon color={'success'} /> Approve</Dropdown.Item> */}
                        </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="card-body pt-1">
                        <p className="card-text mb-0"><span className="quotation-info">Customer Detail:</span> </p>
                        <p className="card-text mb-0"><span className="quotation-info">Net Total Amount:</span> </p>
                        <p className="card-text mb-0"><span className="quotation-info">Total Discount:</span> </p>
                        <p className="card-text mb-0"><span className="quotation-info">Grand Total:</span> </p>
                        <p className="card-text mb-0"><span className="quotation-info">Advance Paid:</span> </p>
                        <p className="card-text mb-0"><span className="quotation-info">Order Date:</span> </p>
                    </div>
                </div>
            </Col>
            {/* ))} */}
          </Row>
          </div> : <Loader/>}
        </Container>
        <ConfirmDialog confirmDialog={confirmDialog} setConfirmDialog={setConfirmDialog} />
    </>
  )
}

const mapStateToProps = (state) => {
//console.log(state);
  return {
     currentIntegration: state.receivedOrder.currentIntegration,
     isLoading: state.receivedOrder.isLoading,
   }
 }

 export default connect(mapStateToProps,{getReceivedOrder})(ReceivedOrder);
