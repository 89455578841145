import { combineReducers } from 'redux';
import  brand  from './Product/Brand/reducer';
import  polish  from './Product/Polish/reducer';
import unit from './Product/Unit/reducer';
import category from './Product/Category/reducer';
import colourCode from './Product/ColourCode/reducer';
import leatherCategory from './Product/LeatherCategory/reducer';
import leatherType from './Product/LeatherType/reducer';
import leatherColour from './Product/LeatherColour/reducer';
import importedProducts from './Product/ImportedProduct/reducer';
import assignProduct from './Product/AssignProduct/reducer';
import customers from './Users/Customers/reducer';
import users from './Users/Users/reducer';
import vendors from './Vendor/Vendor/reducer';
import branchs from './Setting/Branch/reducer';
import userTypes from './Setting/UserType/reducer';
import fabric from './Product/Fabric/reducer';
import unapprovedOrder from './Order/UnapprovedOrder/reducer';
import approvedOrder from './Order/ApprovedOrder/reducer';
import deliveryDueOrder from './Order/DeliveryDueOrder/reducer';
import expectedDeliveryDate from './Order/ExpectedDeliveryDate/reducer';
import outForDelivery from './Order/OutForDelivery/reducer';
import deliveredOrder from './Order/DeliveredOrder/reducer';
import declinedOrder from './Order/DeclinedOrder/reducer';
import orderDetail from './Order/ViewOrder/reducer';
import orders from './Order/Orders/reducer';
import editOrders from './Order/EditOrder/reducer';
import paymentDetail from './Order/PaymentDetail/reducer';
import vendorIncentive from './Vendor/VendorIncentive/reducer';
import vendorPaidIncentive from './Vendor/VendorPaidIncentive/reducer';
import pendingQuotation from './Quotation/PendingQuotation/reducer';
import approvedQuotation from './Quotation/ApprovedQuotation/reducer';
import declinedQuotation from './Quotation/DeclinedQuotation/reducer';
import quotationDetail from './Quotation/ViewQuotation/reducer';
import editQuotationReducer from './Quotation/EditQuotation/reducer';
// import orders from './Order/Orders/reducer';
import pendingIncentive from './Incentive/PendingIncentive/reducer';
import paidIncentive from './Incentive/PaidIncentive/reducer';
import branchWiseReport from './Report/BranchWise/reducer';
import executiveWiseReport from './Report/ExecutiveWise/reducer';
import consolidatedReport from './Report/ConsolidatedReport/reducer';
import productWiseReport from './Report/ProductWise/reducer';
import stockReport from './Report/StockReport/reducer';
import columnerReport from './Report/ColumnerReport/reducer';
//Chat
import chat from './Chat/reducer';
// Accounts section 
import receivePayment from './Payment//ReceivePayment/reducer';
import approvePayment from './Payment//ApprovePayment/reducer';
import dueIncentive from './IncentiveAccounts/DueIncentive/reducer';
import accountsPaidIncentive from './IncentiveAccounts/PaidIncentive/reducer';
// Book section 
import viewOrder from './BookOrder/ViewOrder/reducer';
import bookedOrder from './BookOrder/BookedOrder/reducer';
// Design section 
import pendingDesign from './Design/PendingDesign/reducer';
import completedDesign from './Design/CompletedDesign/reducer';
// Dispatch section 
import pendingDispatch from './Dispatch/PendingDispatch/reducer';
import completeDispatch from './Dispatch/CompleteDispatch/reducer';
import pendingToDispatch from './Dispatch/PendingToDispatch/reducer';
import dispatchOrderDetail from './Dispatch/DispatchOrderView/reducer';
// Production Section
import productionOrder from './Production/ProductionOrder/reducer';
import productionUnderProcessing from './Production/ProductionUnderProcessing/reducer';
import productionOrderCompleted from './Production/ProductionOrderCompleted/reducer';
// SAP Section
import confirmBooking from './Sap/ConfirmBooking/reducer';
import sapBookedOrder from './Sap/BookedOrder/reducer';
import stockOutOrder from './Sap/StockOutOrder/reducer';
// Login
import login from './Authentication/reducer';
// create Order
import orderReducer from './Order/CreateOrder/reducer';
import quotationReducer from './Quotation/CreateQuotation/reducer';
import quotationToOrderReducer from './Quotation/QuotationToOrder/reducer';
// Dashboard
import dashboard from './Dashboard/reducer';
import dashboardsales from './DashboardSales/reducer';
import dashboardaccounts from './DashboardAccounts/reducer';

//CP
import cpAllotedOrder from './CP/AllotedOrder/reducer';
import cpPlaceOrder from './CP/PlaceOrder/reducer';
import cpProduct from './CP/Product/reducer';
import cpOrder from './CP/ViewOrder/reducer';
import CPorderDetail from './CP/DetailViewOrder/reducer';

//peddler
import peddlerStockQuery from './Peddler/StockQuery/reducer';

//Branch
import branchStockQuery from './Branch/StockQuery/reducer';
import branchCurrentStock from './Branch/CurrentStock/reducer';
import branchStockIn from './Branch/BranchDashboard/StockIn/reducer';
import branchStockOut from './Branch/BranchDashboard/StockOut/reducer';
import branchStockReturn from './Branch/BranchDashboard/StockReturn/reducer';
import stockIn from './Branch/StockIn/reducer';
import stockOut from './Branch/StockOut/reducer';
import branchEntries from './Branch/BranchEntries/reducer';

export default combineReducers({
    brand,
    fabric,
    unit,
    category,
    colourCode,
    leatherCategory,
    leatherType,
    leatherColour,
    importedProducts,
    assignProduct,
    customers,
    users,
    vendors,
    branchs,
    userTypes,
    unapprovedOrder,
    approvedOrder,
    deliveryDueOrder,
    expectedDeliveryDate,
    outForDelivery,
    deliveredOrder,
    declinedOrder,
    paymentDetail,
    vendorIncentive,
    vendorPaidIncentive,
    pendingQuotation,
    approvedQuotation,
    declinedQuotation,
    pendingIncentive,
    paidIncentive,
    branchWiseReport,
    executiveWiseReport,
    consolidatedReport,
    productWiseReport,
    stockReport,
    columnerReport,
    receivePayment,
    approvePayment,
    dueIncentive,
    accountsPaidIncentive,
    viewOrder,
    editOrders,
    bookedOrder,
    pendingDesign,
    completedDesign,
    pendingDispatch,
    completeDispatch,
    pendingToDispatch,
    dispatchOrderDetail,
    productionOrder,
    productionUnderProcessing,
    productionOrderCompleted,
    confirmBooking,
    sapBookedOrder,
    stockOutOrder,
    chat,
    login,
    polish,
    orderReducer,
    quotationReducer,
    quotationToOrderReducer,
    dashboard,
    dashboardsales,
    dashboardaccounts,
    orderDetail,
    orders,
    quotationDetail,
    cpAllotedOrder,
    cpPlaceOrder,
    peddlerStockQuery,
    branchStockQuery,
    branchCurrentStock,
    cpProduct,
    cpOrder,
    editQuotationReducer,
    branchStockIn,
    branchStockOut,
    branchStockReturn,
    stockIn,
    stockOut,
    branchEntries,
    CPorderDetail
});