import * as React from "react";
import {useState, useEffect} from 'react'
import { Row, Col, Container, Badge, Dropdown} from 'react-bootstrap';
import MaterialTable from 'material-table';
import Zoom from 'react-medium-image-zoom'
import { connect } from "react-redux";
import {getCurrentStock} from '../../redux/Branch/CurrentStock/action';
import Loader from '../../components/Loader/Loader';
import SearchBar from "../../components/SearchBar/SearchBar";

  const BranchCurrentStock = ({ getCurrentStock,currentIntegration,isLoading }) =>  {  useEffect(() => {
    getCurrentStock();
  }, []);
  
  const [loading, setLoading] = useState(false);
  useEffect(() => {
      if (isLoading) {
          setLoading(true);
      }
    });

const [visible, setVisible] = useState(false);
const handleVisibility = () => {
    setVisible(prev => !prev);
};

const [tableData, setTableData] = useState([]);
useEffect(() => {
  if(currentIntegration)
  {
    setTableData(currentIntegration); 
  }
}, [currentIntegration])

const columns = [
{ title: 'Product Image', field: 'image', render: rowData =><Zoom zoomMargin={20}><img src={rowData.image} style={{width: 80}}/></Zoom> },
{ title: "Model No. ", field: "model" },
{ title: "Qty", field: "stock_qty" },
{ title: "Product Category", field: "category" },
{ title: "Leather Category", field: "leather_category" },
{ title: "Leather Type", field: "leather_type" },
{ title: "Leather Colour", field: "leather_colour" },
{ title: "Colour Code", field: "colour_code" },
]

// Search for Card view start
const [searchValue, setSearchValue] = useState("");

const filterStock = ({ model }) => {
  return model.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
};
// Search for Card view end

  return (
    <>
        <Container fluid className="main-content">
          <Row>
              <Col>
                  <h3 className="main-title">Current Stock</h3>
              </Col> 
              <Col>
                  <div style={{float: "right"}} className="d-flex">
                    <div className="d-flex justify-content-between d-flex d-sm-none">
                      <div className="magnifierContainer">
                        <button type="button" className="btn btn-md btn-warning text-white roundedcircle mr-3" onClick={handleVisibility}><i className="fa-solid fa-search"></i></button>
                      </div>
                    </div>
                  </div>
              </Col>
              <Col lg={"12"}>
                <div className='d-block d-sm-none mt-2'>
                  <div className={visible ? "open" : "close"}>
                      <SearchBar onSearch={setSearchValue} value={searchValue} />
                  </div>
                </div>
              </Col>
            </Row>
            {loading ? <div>
            <Row className="d-none d-sm-block mt-2">
              <Col lg={"12"} className="mt-3">
                <div style={{display: 'table', tableLayout:'fixed', width:'100%'}}>
                <MaterialTable columns={columns} data={tableData}
                    options={{
                    sorting: true, search: true,
                    paging: true, pageSizeOptions: [10, 25, 50, 100], pageSize: 10,
                    exportButton: true,
                    exportAllData: true, exportFileName: "Current Stock", actionsColumnIndex: -1,
                    // headerStyle: { background: "#f44336",color:"#fff"}
                    }}
                    title="Current Stock"
                />
                </div>
              </Col>
            </Row>
            <Row className="d-block d-sm-none mt-2">
            {currentIntegration.filter(filterStock).map((stock, index) => (
            <Col xs={"12"} key={index}>
                <div className="card mb-2">
                    <div className="card-header d-flex justify-content-between pb-0">
                        <h6>{stock.model}  <Badge bg="danger" text="light" className="ml-2">{stock.stock_qty}</Badge></h6>
                        {/* <Dropdown>
                        <Dropdown.Toggle variant="light" id="dropdown-basic">
                            <i className="ri-more-2-line"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1"><i className="ri-edit-box-line text-primary"></i> Edit</Dropdown.Item>
                            <Dropdown.Item href="#/action-2"><i className="far fa-trash-alt text-danger"></i> Delete</Dropdown.Item>
                        </Dropdown.Menu>
                        </Dropdown> */}
                    </div>
                    <div className="card-body pt-1">
                        <p className="card-text mb-0"><span className="quotation-info">Category:</span> {stock.model}</p>
                        <p className="card-text mb-0"><span className="quotation-info">Leather Type:</span> {stock.leather_type}</p>
                        <p className="card-text mb-0"><span className="quotation-info">Leather Category:</span> {stock.leather_category}</p>
                        <p className="card-text mb-0"><span className="quotation-info">Leather Colour:</span> {stock.leather_colour}</p>
                        <p className="card-text mb-0"><span className="quotation-info">Colour Code:</span> {stock.colour_code}</p>
                    </div>
                </div>
            </Col>
             ))}
        </Row>
        </div> : <Loader/>}
        </Container>
    </>

  )
}

const mapStateToProps = (state) => {
  
    return {
       currentIntegration: state.branchCurrentStock.currentIntegration,
       isLoading: state.branchCurrentStock.isLoading,
     }
   }
  
   export default connect(mapStateToProps,{getCurrentStock})(BranchCurrentStock);