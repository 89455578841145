import {
    ORDER_LIST,
    LATEST_ORDER_ID,
    CONVERSATION,
    NO_OF_MEMBER,
    ORDER_LIST_NAME,
    UPDATE_DATE,
    MEMBER_LIST,
    PAYMENT_DETAIL,
    REMAINING_AMOUNT,
    REMAINING_MEMBER_LIST,
    ADD_PAYMENT,
    EXIT_GROUP,
    SET_USERTYPE,
    SET_DISPATCH_ORDER,
    EXPORT_CHAT
  } from "../Chat/actiontype";
  
  const INITIAL_STATE = {
    order_list: [],
    latest_orderid :0,
    conversation : '',
    no_of_member:0,
    order_name : '',
    update_date: '',
    member_list: [],
    payment_list:[],
    usertype:[],
    remaining_amount: 0,
    remaining_member_list : [],
    isLoading: false,
    isLoadingConversation: false,
    isDispatch: false,
    export_chat: []
  };
  
  const chatReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
    switch (type) {
        case ORDER_LIST:
            return {
            ...state,
            order_list: payload,
            isLoading: true,
        };
        case LATEST_ORDER_ID:
            return {
            ...state,
            latest_orderid: payload,
        };
        case CONVERSATION:
            return {
            ...state,
            conversation: payload,
            isLoadingConversation: true,
        };
        case NO_OF_MEMBER:
            return {
            ...state,
            no_of_member: payload,
        };
        case ORDER_LIST_NAME:
            return {
            ...state,
            order_name: payload,
        };
        case UPDATE_DATE:
            return {
            ...state,
            update_date: payload,
        };
        case MEMBER_LIST:
            return {
            ...state,
            member_list: payload,
        };
        case PAYMENT_DETAIL:
            return {
            ...state,
            payment_list: payload,
        };
        case REMAINING_AMOUNT:
            return {
            ...state,
            remaining_amount: payload,
        };
        case REMAINING_MEMBER_LIST:
            return {
            ...state,
            remaining_member_list: payload,
        };
        case ADD_PAYMENT:
        return {
            ...state,
        };
        case EXIT_GROUP:
            return {
              ...state,
              //currentIntegration: state.currentIntegration.filter((item) => parseInt(item.id) !== parseInt(payload)),
            };
        case SET_USERTYPE:
            return {
                ...state,
                usertype: payload,
            };
        case SET_DISPATCH_ORDER:
            return {
                ...state,
                isDispatch: true,
            };
        case EXPORT_CHAT:
            return {
            ...state,
            export_chat: payload,
        };
    
        default:
          return state;
    }
  };
  export default chatReducer;