import {
    RETRIEVED_ORDERS
} from "../ReceivedOrder/actiontype";
import api from "../../../api";

export const getReceivedOrder = (data) => async (dispatch) => {
  
  try {
    const res = await api.get(`/order/receivedOrder`);
   
    dispatch({
      type: RETRIEVED_ORDERS,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVED_ORDERS,
      payload: [],
    });
  }
};

