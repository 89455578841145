import {
    RETRIEVE_PRODUCTION_ORDER_COMPLETED
} from "../ProductionOrderCompleted/actiontype";

import { toast } from 'react-toastify';
import api from "../../../api";
let usertype = JSON.parse(localStorage.getItem('usertype'));
let userid = JSON.parse(localStorage.getItem('userid'));

export const getProductionOrderCompleted= (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  let data = {
    usertype : usertype,
    userid : userid
  };


  try {
    const res = await api.post(`/productionSection/productionCompleted`, data);
   
    
    dispatch({
      type: RETRIEVE_PRODUCTION_ORDER_COMPLETED,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_PRODUCTION_ORDER_COMPLETED,
      payload: [],
    });
  }
};



