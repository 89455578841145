import {
    SET_ORDERNAME,
    SET_QUOTATION,
    SET_CORDINATOR,
    SET_REFFERED,
    SET_PRODUCT,
    SET_LOCATION,
    PRODUCT_NAME,
    GET_IMPORTED_READY,
    GET_IMPORTED_ORDER,
    GET_TOTAL_AMOUNT,
    ORDER_CREATED,
    GROSS_TOTAL,
    BALANCE_AMT,
    EDIT_READY_PRODUCT,
    EDIT_PRODUCT_NAME,
    READY_NET_AMOUNT,
    ORDER_NET_AMOUNT
} from "../CreateOrder/actionType";

const INITIAL_STATE = {
  orderName :'',
  currentIntegration: [],
  quotations:[],
  cordinator:[],
  reffered:[],
  products:[],
  locations:[],
  product_name :'',
  imported_ready: [],
  imported_order: [],
  total_amount:'',
  isOrderCreated: false,
  set_cordinator:'',
  set_reffered:'',
  gross_total:0,
  balance: 0,
  edit_ready_product: [],
  edit_ready_netamount:0,
  edit_order_product: [],
  edit_order_netamount:0,
  edit_product_name:"",
  edit_product_name_ready:"",
  edit_product_name_order:"",
  ready_net_amount:0,
  order_net_amount:0,
};

const orderReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
 
  switch (type) {
    case SET_ORDERNAME:
      return {
        ...state,
        orderName: payload,
      };
    case SET_QUOTATION:
      return {
        ...state,
        quotations: payload,
      };
    case SET_CORDINATOR:
      return {
        ...state,
        cordinator: payload,
      };
    case SET_REFFERED:
      return {
        ...state,
        reffered: payload,
      };
    case SET_PRODUCT:
      return {
        ...state,
        products: payload,
      };
    case SET_LOCATION:
      return {
        ...state,
        locations: payload,
      };
    case PRODUCT_NAME:
      return {
        ...state,
        product_name: payload,
      };
    case READY_NET_AMOUNT:
      return {
        ...state,
        ready_net_amount: payload,
      };
    case ORDER_NET_AMOUNT:
      return {
        ...state,
        order_net_amount: payload,
      };
    case GET_IMPORTED_READY:
      return {
        ...state,
        imported_ready: payload,
      };
    case GET_IMPORTED_ORDER:
      return {
        ...state,
        imported_order: payload,
      };
    case GET_TOTAL_AMOUNT:
      return {
        ...state,
        total_amount: payload,
      };
    case GROSS_TOTAL:
      return {
        ...state,
        gross_total: payload,
      };
    case BALANCE_AMT:
      return {
        ...state,
        balance: payload,
      };
    case ORDER_CREATED:
      return {
        ...state,
        isOrderCreated: true,
      };
    case EDIT_READY_PRODUCT:
      return {
        ...state,
        edit_ready_product: payload,
      };
    case EDIT_PRODUCT_NAME:
      return {
        ...state,
        edit_product_name: payload,
      };
    default:
      return state;
  }
};

export default orderReducer;