import {
    RETRIEVE_PENDING_DESIGN,
    DESIGN_MARKAS_COMPLETE
} from "../PendingDesign/actiontype";

import { toast } from 'react-toastify';
import api from "../../../api";
let usertype = JSON.parse(localStorage.getItem('usertype'));
let userid = JSON.parse(localStorage.getItem('userid'));

export const getPendingDesign= (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  let data = {
    usertype : usertype,
    userid : userid
  };

  try {
    const res = await api.post(`/designSection/pendingDesign`, data);
   
    
    dispatch({
      type: RETRIEVE_PENDING_DESIGN,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_PENDING_DESIGN,
      payload: [],
    });
  }
};


export const designMarkAsComplete= (id) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  let data = {
    id: id,
    userid : userid
  };

  try {
    const res = await api.post(`/bookSection/DesignMarkAsComplete`, data);
    if (res.status === 200) {
      toast.success(res.data.message);
    dispatch({
      type: DESIGN_MARKAS_COMPLETE,
      payload: res.data.data,
    });
  }
  } catch (err) {
    
    dispatch({
      type: DESIGN_MARKAS_COMPLETE,
      payload: [],
    });
  }
};

