import React from 'react';
import {Routes,Route} from "react-router-dom";
import Footer from './components/Footer/Footer';
import NavbarMenu from './components/Navbar/NavbarMenu';
import Dashboard from './pages/Dashboard/Dashboard'
import Brand from './pages/Product/Brand/Brand';
import Polish from './pages/Product/Polish/Polish';
import Fabric from './pages/Product/Fabric/Fabric';
import Unit from './pages/Product/Unit/Unit';
import Category from './pages/Product/Category/Category';
import ColourCode from './pages/Product/ColourCode/ColourCode';
import LeatherCategory from './pages/Product/LeatherCategory/LeatherCategory';
import LeatherType from './pages/Product/LeatherType/LeatherType';
import LeatherColour from './pages/Product/LeatherColour/LeatherColour';
import ImportedProduct from './pages/Product/ImportedProduct/ImportedProduct';
import Users from './pages/Users/Users/Users';
import Customers from './pages/Users/Customers/Customers';
import Vendor from './pages/Vendor/Vendor/Vendor';
import VendorIncentive from './pages/Vendor/Incentive/VendorIncentive';
import VendorPaidIncentive from './pages/Vendor/Incentive/VendorPaidIncentive';
import UnapprovedOrder from './pages/Order/UnapprovedOrder/UnapprovedOrder';
import ApprovedOrder from './pages/Order/ApprovedOrder/ApprovedOrder';
import DueList from './pages/Order/DeliveryDueList/DueList';
import ExpectedDeliveryDate from './pages/Order/ExpectedDeliveryDate/ExpectedDeliveryDate';
import OutForDelivery from './pages/Order/OutForDelivery/OutForDelivery';
import DeliveredOrder from './pages/Order/DeliveredOrder/DeliveredOrder';
import DeclinedOrder from './pages/Order/DeclinedOrder/DeclinedOrder';
import PaymentDetail from './pages/Order/OrderPaymentDetail/PaymentDetail';
import CreateOrder from './pages/Order/CreateOrder/CreateOrder';
import PaidIncentive from './pages/IncentiveSales/Incentive/PaidIncentive';
import Incentive from './pages/IncentiveSales/Incentive/Incentive';
import Login from './pages/Authenticaion/Login';
import UserType from './pages/Setting/UserType/UserType';
import Branch from './pages/Setting/Branch/Branch';
import Quotation from './pages/Quotation/Quotation/Quotation';
import ApprovedQuotation from './pages/Quotation/ApprovedQuotation/ApprovedQuotation';
import DeclinedQuotation from './pages/Quotation/DeclinedQuotation/DeclinedQuotation';
import CreateQuotation from './pages/Quotation/CreateQuotation/CreateQuotation';
import Branchwise from './pages/Report/Branchwise';
import Productwise from './pages/Report/Productwise';
import Executivewise from './pages/Report/Executivewise';
import ConsolidatedReport from './pages/Report/ConsolidatedReport';
import { Slide, ToastContainer, Zoom } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import StockReport from './pages/Report/StockReport';
import ColumnerReport from './pages/Report/ColumnerReport';
import Order from './pages/Order/Orders/Order';
import MakeToOrder from './pages/Order/MakeToOrder/MakeToOrder';
// Account  Section start
import ReceivePayment from './pages/Payment/ReceivePayment/ReceivePayment';
import ApprovePayment from './pages/Payment/ApprovePayment/ApprovePayment';
import DueIncentive from './pages/Incentive/DueIncentive/DueIncentive';
import AccountPaidIncentive from './pages/Incentive/PaidIncentive/PaidIncentive';
// Book Section Start
import ViewOrder from './pages/BookOrder/ViewOrder/ViewOrder';
import BookedOrder from './pages/BookOrder/BookedOrder/BookedOrder';
import BookOrderView from './pages/BookOrder/BookOrderView/BookOrderView';
import BookOrderPrint from './pages/BookOrder/BookOrderPrint/BookOrderPrint';
// Design Section Start
import PendingDesign from './pages/Design/PendingDesign/PendingDesign';
import CompletedDesign from './pages/Design/CompletedDesign/CompletedDesign';
// Dispatch Section Start
import PendingDispatch from './pages/Dispatch/PendingDispatch/PendingDispatch';
import CompleteDispatch from './pages/Dispatch/CompleteDispatch/CompleteDispatch';
import PendingToDispatch from './pages/Dispatch/PendingTo Dispatch/PendingToDispatch';
import DispatchOrderPrint from './pages/Dispatch/DispatchOrderPrint/DispatchOrderPrint';

// Production Section Start
import ProductionOrder from './pages/Production/ProductionOrder/ProductionOrder';
import ProductionUnderProcessing from './pages/Production/ProductionUnderProcessing/ProductionUnderProcessing';
import ProductionOrderCompleted from './pages/Production/ProductionCompleted/ProductionCompleted';
// SAP Section Start
import ConfirmBooking from './pages/Sap/ConfirmBooking/ConfirmBooking';
import SapBookedOrder from './pages/Sap/BookedOrder/BookedOrder';
import StockOutOrder from './pages/Sap/StockOutOrder/StockOutOrder';
import { useLocation } from 'react-router-dom';
import AssignProduct from './pages/Product/AssignProduct/AssignProduct';
import StockQuery from './pages/Peddler/StockQuery/StockQuery';
import BranchStockQuery from './pages/Branch/StockQuery';
import BranchCurrentStock from './pages/Branch/CurrentStock';
import BranchDashboard from './pages/Branch/BranchDashboard';
import BranchEntries from './pages/Branch/BranchEntries';
import StockIn from './pages/Branch/StockIn';
import StockOut from './pages/Branch/StockOut';
import Product from './pages/CP/Product';
import CpViewOrder from './pages/CP/ViewOrder';
import CpCreateOrder from './pages/CP/CreateOrder';
import Test from './pages/test/test';
import EditOrder from './pages/Order/EditOrder/EditOrder';
import ApproveOrder from './pages/Order/ApproveOrder/ApproveOrder';
import EditQuotation from './pages/Quotation/EditQuotation/EditQuotation';
import QuotationToOrder from './pages/Order/QuotationToOrder/QuotationToOrder';
import QuotationToOrderEdit from './pages/Order/QuotationToOrderEdit/QuotationToOrderEdit';
import DetailViewQuotation from './pages/Quotation/ViewQuotation/ViewQuotation';
import OrderList from './pages/CP/OrderList';
import DetailViewOrder from './pages/Order/ViewOrder/ViewOrder';
import ChangePassword from './pages/Authenticaion/ChangePassword';
// Chat
import Chat from './pages/Chat/Chat';
import ExportChat from './pages/Chat/ExportChat';

import DashboardAccounts from './pages/Dashboard/DashboardAccounts';
import DashboardSale from './pages/Dashboard/DashboardSale';
import ReceivedOrder from './pages/Order/ReceivedOrder/ReceivedOrder';
import DetailViewCpOrder from './pages/CP/DetailViewOrder';



  function App() {

    const { pathname } = useLocation();
    return (
      <>
      {
       pathname !=='/' ?<NavbarMenu/>:null
     }
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/dashboard' exact element={<Dashboard />} />
        <Route path='/dashboard-accounts' exact element={<DashboardAccounts />} />
        <Route path='/dashboard-sales' exact element={<DashboardSale />} />
        <Route path='/brand' element={<Brand />}  />
        <Route path='/polish' element={<Polish/>} />
        <Route path='/fabric' element={<Fabric/>} />
        <Route path='/unit' element={<Unit/>} />
        <Route path='/category' element={<Category/>} />
        <Route path='/colourcode' element={<ColourCode/>} />
        <Route path='/leathercategory' element={<LeatherCategory/>} />
        <Route path='/leathertype' element={<LeatherType/>} />
        <Route path='/leathercolour' element={<LeatherColour/>} />
        <Route path='/product-imported' element={<ImportedProduct/>} />
        <Route path='/assign-product' element={<AssignProduct/>} />
        <Route path='/users' element={<Users/>} />
        <Route path='/customers' element={<Customers/>} />
        <Route path='/vendor' element={<Vendor/>} />
        <Route path='/vendor-incentive' element={<VendorIncentive/>} />
        <Route path='/vendor-paid-incentive' element={<VendorPaidIncentive/>} />
        <Route path='/usertype' element={<UserType/>} />
        <Route path='/branch' element={<Branch/>} />
        <Route path='/incentive' element={<Incentive/>} />
        <Route path='/paid-incentive' element={<PaidIncentive/>} />
        <Route path='/quotation' element={<Quotation/>} />
        <Route path='/approved-quotation' element={<ApprovedQuotation/>} />
        <Route path='/declined-quotation' element={<DeclinedQuotation/>} />
        <Route path='/create-quotation/:id' element={<CreateQuotation/>} />
        <Route path='/edit-quotation/:id' element={<EditQuotation/>} />
        <Route path='/order' element={<UnapprovedOrder/>} />
        <Route path='/delivery-due-order' element={<DueList/>} />
        <Route path='/approved-order' element={<ApprovedOrder/>} />
        <Route path='/create-order/:id' element={<CreateOrder/>} />
        <Route path='/edit-order/:id' element={<EditOrder/>} />
        <Route path='/approve-order/:id' element={<ApproveOrder/>} />
        <Route path='/received-order' element={<ReceivedOrder/>} />
        <Route path='/detail-View-order/:id' element={<DetailViewOrder/>} />
        <Route path='/expected-delivery-date' element={<ExpectedDeliveryDate/>} />
        <Route path='/out-for-delivery' element={<OutForDelivery/>} />
        <Route path='/delivered-order' element={<DeliveredOrder/>} />
        <Route path='/declined-order' element={<DeclinedOrder/>} />
        <Route path='/make-imported' element={<Order/>} />
        <Route path='/make-order' element={<MakeToOrder/>} />
        <Route path='/quotation-to-order/:id' element={<QuotationToOrder/>} />
        <Route path='/edit-quotation-to-order' element={<QuotationToOrderEdit/>} />
        <Route path='/view-quotation/:id' element={<DetailViewQuotation/>} />
        <Route path='/payment' element={<PaymentDetail/>} />
        <Route path='/branch-wise-report' element={<Branchwise/>} />
        <Route path='/product-wise-report' element={<Productwise/>} />
        <Route path='/executive-wise-report' element={<Executivewise/>} />
        <Route path='/consolidated-report' element={<ConsolidatedReport/>} />
        <Route path='/stock-report' element={<StockReport/>} />
        <Route path='/columner-report' element={<ColumnerReport/>} />
        {/* Account Section */}
        <Route path='/receive-payment' element={<ReceivePayment/>} />
        <Route path='/approved-payment' element={<ApprovePayment/>} />
        <Route path='/due-incentive' element={<DueIncentive/>} />
        <Route path='/account-paid-incentive' element={<AccountPaidIncentive/>} />
        {/* Book Section */}
        <Route path='/view-order' element={<ViewOrder/>} />
        <Route path='/booked-order' element={<BookedOrder/>} />
        <Route path='/view-order-other/:id' element={<BookOrderView/>} />
        <Route path='/book-order-print/:id' element={<BookOrderPrint/>} />
        {/* Design Section */}
        <Route path='/view-pending-design' element={<PendingDesign/>} />
        <Route path='/completed-design' element={<CompletedDesign/>}/>
        {/* Dispatch Section */}
        <Route path='/dispatch-order' element={<PendingDispatch/>}/>
        <Route path='/dispatched-order' element={<CompleteDispatch/>}/>
        <Route path='/pending-to-dispatch/:serial_no' element={<PendingToDispatch/>}/>
        <Route path='/dispatch-order-print/:id' element={<DispatchOrderPrint/>}/>

        {/* Production Section */}
        <Route path='/production-view' element={<ProductionOrder/>}/>
        <Route path='/production-under-processing' element={<ProductionUnderProcessing/>}/>
        <Route path='/production-completed' element={<ProductionOrderCompleted/>}/>
        {/* SAP Section */}
        <Route path='/sap-booking' element={<ConfirmBooking/>}/>
        <Route path='/sap-booked' element={<SapBookedOrder/>}/>
        <Route path='/sap-stockout' element={<StockOutOrder/>}/>
        {/* Peddler Section */}
        <Route path='/peddler-stock-query' element={<StockQuery/>}/>
        {/* Branch Section */}
        <Route path='/branch-dashboard' element={<BranchDashboard/>}/>
        <Route path='/stock-query' element={<BranchStockQuery/>}/>
        <Route path='/current-stock' element={<BranchCurrentStock/>}/>
        <Route path='/branch-entries' element={<BranchEntries/>}/>
        <Route path='/stock-in' element={<StockIn/>}/>
        <Route path='/stock-out' element={<StockOut/>}/>
        {/* CP Section */}
        <Route path='/create-cp-order' element={<CpCreateOrder/>}/>
        <Route path='/view-cp-order' element={<CpViewOrder/>}/>
        <Route path='/cp-product' element={<Product/>}/>
        <Route path='/order-list' element={<OrderList/>}/>
        <Route path='/detail-view-cporder/:id' element={<DetailViewCpOrder/>}/>
        {/* Password */}
        <Route path='/change-password' element={<ChangePassword/>}/>
        {/* Chat */}
        <Route path='/export-chat/:id' element={<ExportChat/>}/>
        <Route path='/prfm' element={<Chat/>}/>
        <Route path='/test' element={<Test/>}/>
      </Routes>
      <ToastContainer theme={'colored'} transition={Slide} autoClose={2000} />
      {
      pathname !=='/' ?<Footer/>:null
      }
    </>
  )
}
export default App