import {
    CREATE_LEATHERCOLOUR,
    RETRIEVE_LEATHERCOLOUR,
    DELETE_LEATHERCOLOUR,
    UPDATE_LEATHERCOLOUR
} from "../LeatherColour/actiontype";

import { toast } from 'react-toastify';
import api from "../../../api";

export const leatherColour = (registerData) => async (dispatch) => {
  const body = JSON.stringify(registerData);
  try {
    const res = await api.post('/leatherColour/addLeatherColour', body);
   
    if (res.status === 200) {
      toast.success('Leather Colour Added Successfully');
        dispatch({
            type: CREATE_LEATHERCOLOUR,
            payload: res.data.data.info[0],
          });
        }
      } catch (err) {
    
    toast.warn('Invalid inputs! Leather Colour Not Added');
    // dispatch({
    //   type: REGISTER_FAIL,
    // });
  }
};

export const getLeatherColour = (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  try {
    const res = await api.get(`/leatherColour/leatherColour`);
   
    dispatch({
      type: RETRIEVE_LEATHERCOLOUR,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_LEATHERCOLOUR,
      payload: [],
    });
  }
};

//export const updateLeatherColour
export const updateLeatherColour = (appId, formData) => async (dispatch) => {
  formData.id = appId;
  try {
    const res = await api.put('/leatherColour/updateLeatherColour', formData);
   
    if (res.status === 200) {
      dispatch({
        type: UPDATE_LEATHERCOLOUR,
       payload: res.data.data,
      });
      toast.success(res.data.message);
    }
  } catch (error) {
    
    toast.error('Failed to update data!');
    // dispatch({
    //   type: APP_DATA_ERROR,
    //   payload: 'ERROR' + uuidv1(),
    // });
  }
};


export const deleteLeatherColour = (id) => async (dispatch) => {
  try {
    const res = await api.delete('/leatherColour/deleteLeatherColour/' + id);
   
    if (res.status === 200) {
      dispatch({
        type: DELETE_LEATHERCOLOUR,
        payload: res.data.data.info,
      });
      toast.success(res.data.message);
    }
  } catch (error) {
    
    toast.error('Failed to delete');
    // dispatch({
    //   type: DELETE_LEATHERCOLOUR,
    //   payload: [],
    // });
  }
};




