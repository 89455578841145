import * as React from "react";
import {useState, useEffect} from 'react'
import { Row, Col, Container, Button, Modal, Dropdown, Badge } from 'react-bootstrap';
import MaterialTable from 'material-table';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import { connect } from "react-redux";
import {getPendingQuotation, deleteQuotation, declineQuotation,approveQuotation} from '../../../redux/Quotation/PendingQuotation/action';
import CreateQuotation from "../../../components/BranchList/CreateQuotation";
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import Loader from "../../../components/Loader/Loader";
import { useNavigate } from 'react-router-dom';
import { Formik, Form, Field } from "formik";
import * as yup from "yup";

const validationSchema = yup.object({
  reason: yup.string().required("Reason is Required"),
});

const Quotation = ({ getPendingQuotation,currentIntegration, deleteQuotation, declineQuotation,approveQuotation,isLoading }) =>  {  useEffect(() => {
  getPendingQuotation();
}, []);


const [visible, setVisible] = useState(false);
const handleVisibility = () => {
    setVisible(prev => !prev);
};

const [loading, setLoading] = useState(false);
useEffect(() => {
    if (isLoading) {
        setLoading(true);
    }
  });

const [show, setShow] = useState(false);
const modalClose = () => setShow(false);
const modalShow = () => setShow(true);

const [tableData, setTableData] = useState([]);
    useEffect(() => {
    if(currentIntegration)
    {
        setTableData(currentIntegration); 
    }
    }, [currentIntegration])

const columns = [
{ title: " Quotation No.", field: "quotationNo" },
{ title: "Created By", field: "createdBy" },
{ title: "Customer Name", field: "customerName" },
{ title: "Customer Mobile", field: "CustomerMobile" },
{ title: "No. Of Items", field: "qty" },
{ title: "Date Of Quotation", field: "quotationDate" },
]

const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', type: '' })

const navigate = useNavigate();

const quotationView = async (e) => {
    navigate('/view-quotation/'+e);
}

const quotationEdit = async (e) => {
    navigate('/edit-quotation/'+e);
}

const [formData, setFormData] = useState({
  reason: "",
  id: ""
});

const { reason, id } = formData;

const onChange = (e) =>
{
  setFormData({ ...formData, [e.target.name]: e.target.value });
  
}
const quotationDecline = async (e) => {
  setFormData({ ...formData, 'id': e });
  setShow({
      isOpen: true,
      })
  
}

const onSubmit = async (e) => {
  e.preventDefault();
  const data = {
   id: formData.id,
   reason: formData.reason,
 };
 
 declineQuotation(data); // action name
};

const quotationApprove = async (e) => {
  
  setConfirmDialog({
    isOpen: true,
    title: 'Are you sure to approve this quotation?',
    type: true,
    onConfirm: () => { 
      approveQuotation(e);
      setConfirmDialog({ ...confirmDialog, isOpen: false })
     }
})
}

const quotationDelete = async (e) => {
  
  setConfirmDialog({
    isOpen: true,
    title: 'Are you sure to delete this Quotation?',
    type: false,
    onConfirm: () => { 
      deleteQuotation(e);
      setConfirmDialog({ ...confirmDialog, isOpen: false })
     }
})
}
    return (
      <>
        <Container fluid className="main-content">
            <Row>
              <Col>
                  <h3 className="main-title">Quotation</h3>
              </Col> 
              <Col>
                  <div style={{float: "right"}} className="d-flex">
                    <div className="d-flex justify-content-between d-flex d-sm-none">
                      <div className="magnifierContainer">
                        <button type="button" className="btn btn-md btn-warning text-white roundedcircle mr-3" onClick={handleVisibility}><i className="fa-solid fa-search"></i></button>
                      </div>
                    </div>
                    <CreateQuotation/>
                  </div>
              </Col>
              <Col lg={"12"}>
                  <div className='d-block d-sm-none mt-2'>
                    <div className={visible ? "open" : "close"}>
                        <input placeholder="Search..." type="text" className="form-control is-open mr-3 mb-0 search-input"/>
                    </div>
                  </div>
              </Col>
            </Row>
            {loading ? <div>
            <Row className="d-none d-sm-block mt-4">
              <Col lg={"12"}>
                <div style={{display: 'table', tableLayout:'fixed', width:'100%'}}>
                <MaterialTable columns={columns} data={tableData}
                    actions={[
                      {
                        icon: () => <VisibilityIcon color={'primary'} />,
                        tooltip: 'View',
                        onClick: (event, rowData) => quotationView(rowData.id)
                      },
                      {
                        icon: () => <EditIcon color={'secondary'} />,
                        tooltip: 'Edit',
                        onClick: (event, rowData) => quotationEdit(rowData.id)
                      },
                      {
                        icon: () => <CheckIcon color={'success'} />,
                        tooltip: 'Approve',
                        onClick: (event, rowData) => quotationApprove(rowData.id)
                      },
                      {
                        icon: () => <CloseIcon color={'warning'} />,
                        tooltip: 'Decline',
                        onClick: (event, rowData) => quotationDecline(rowData.id)
                      },
                      {
                        icon: () => <DeleteIcon color={'error'} />,
                        tooltip: 'Delete',
                        onClick: (event, rowData) => quotationDelete(rowData.id)
                      }
                    ]}
                    options={{
                    sorting: true, search: true,
                    paging: true, pageSizeOptions: [10, 25, 50, 100], pageSize: 10,
                    exportButton: true,
                    exportAllData: true, exportFileName: "Quotation", actionsColumnIndex: -1,
                    // headerStyle: { background: "#f44336",color:"#fff"}
                    }}
                    title="Quotation"
                />
                </div>
              </Col>
            </Row>
            <Row className="d-block d-sm-none">
              {currentIntegration.map((quotation, index) => ( 
              <Col xs={"12"} key={index}>
                <div className="card mb-2">
                  <div className="card-header d-flex justify-content-between pb-0">
                      <h6>{quotation.quotationNo}	 <Badge bg="danger" text="light" className="ml-2">{quotation.qty}</Badge></h6>
                    <Dropdown>
                      <Dropdown.Toggle variant="light" id="dropdown-basic">
                        <i className="ri-more-2-line"></i>
                      </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => quotationView(quotation.id)}><VisibilityIcon color={'primary'} /> View</Dropdown.Item>
                            <Dropdown.Item onClick={() => quotationEdit(quotation.id)}><EditIcon color={'secondary'} /> Edit</Dropdown.Item>
                            <Dropdown.Item onClick={() => quotationApprove(quotation.id)}><CheckIcon color={'success'} /> Approve</Dropdown.Item>
                            <Dropdown.Item onClick={() => quotationDecline(quotation.id)}><CloseIcon color={'warning'} /> Decline</Dropdown.Item>
                            <Dropdown.Item onClick={() => quotationDelete(quotation.id)}><DeleteIcon color={'error'} /> Delete</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="card-body pt-1">
                    <p className="card-text mb-0"><span className="quotation-info">Customer Name:</span> {quotation.customerName}</p>
                    <p className="card-text mb-0"><span className="quotation-info">Customer Mobile:</span> {quotation.CustomerMobile}</p>
                    <p className="card-text mb-0"><span className="quotation-info">Quotation Date:</span> {quotation.quotationDate}</p>
                    <p className="card-text mb-0"><span className="quotation-info">Created By:</span> {quotation.createdBy}</p>
                  </div>
                </div>
              </Col>
              ))}
            </Row>
            </div> : <Loader/>}
          </Container>
          <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
          />
       {/* Add Quotation Modal Start */}
       <Modal 
            size="md"
            centered
            show={show} 
            onHide={modalClose}>
                <Formik
                    validationSchema={validationSchema}
                    initialValues={{
                    reason: "",
                    }}
                >
                {({values, setFieldValue}) => (
                    <Form onSubmit={(e) => onSubmit(e)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Decline Quotation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <label>Decline Reason</label>
                        <Field name="reason" as="textarea" className="form-control" value={reason} onChange={(e) => onChange(e)} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={modalClose}>Close</Button>
                        <Button variant="primary" type="submit">Submit</Button>
                    </Modal.Footer>
                    </Form>
                )}
                </Formik>
            </Modal>
        {/* Add Quotation Modal End */}
        
    </>

  )
}

const mapStateToProps = (state) => {

  return {
     currentIntegration: state.pendingQuotation.currentIntegration,
     isLoading: state.pendingQuotation.isLoading,
   }
 }

 export default connect(mapStateToProps,{getPendingQuotation,deleteQuotation,declineQuotation,approveQuotation})(Quotation);