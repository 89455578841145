import {
    CREATE_USERTYPES,
    RETRIEVE_USERTYPES,
    DELETE_USERTYPES,
    UPDATE_USERTYPES
} from "../UserType/actiontype";

import { toast } from 'react-toastify';
import api from "../../../api";

export const userType = (registerData) => async (dispatch) => {
  const body = JSON.stringify(registerData);
  try {
    const res = await api.post('/setting/addUserType', body);
   
    if (res.status === 200) {
      toast.success('User Type Added Successfully');
        dispatch({
            type: CREATE_USERTYPES,
            payload: res.data.data.info[0],
          });
        }
      } catch (err) {
    
    toast.warn('Invalid inputs! User Type Not Added');
    // dispatch({
    //   type: REGISTER_FAIL,
    // });
  }
};

export const getUserType= (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  try {
    const res = await api.get(`/setting/usertype`);
   
    
    dispatch({
      type: RETRIEVE_USERTYPES,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_USERTYPES,
      payload: [],
    });
  }
};

//export const updateUserType
export const updateUserType = (appId, formData) => async (dispatch) => {
  formData.id = appId;
  try {
    const res = await api.put('/setting/updateUsertype', formData);
   
    if (res.status === 200) {
      dispatch({
        type: UPDATE_USERTYPES,
        payload: res.data.data.info,
      });
      toast.success(res.data.message);
    }
  } catch (error) {
    //
    toast.error('Failed to update data!');
    // dispatch({
    //   type: APP_DATA_ERROR,
    //   payload: 'ERROR' + uuidv1(),
    // });
  }
};


export const deleteUserType = (id) => async (dispatch) => {
  try {
    const res = await api.delete('/setting/deleteUsertype/' + id);
   
    if (res.status === 200) {
      dispatch({
        type: DELETE_USERTYPES,
        payload: res.data.data.info,
      });
      toast.success(res.data.message);
    }
  } catch (error) {
    
    toast.error('Failed to delete');
    // dispatch({
    //   type: DELETE_USERTYPES,
    //   payload: [],
    // });
  }
};
