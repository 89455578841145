import {
  RETRIEVE_UNAPPROVED_ORDERS,
  GET_BRANCHES,
  DELETE_UNAPPROVED_ORDERS,
  DECLINE_UNAPPROVED_ORDERS
} from "../UnapprovedOrder/actiontype";

import { toast } from 'react-toastify';
import api from "../../../api";

let usertype = JSON.parse(localStorage.getItem('usertype'));
let userid = JSON.parse(localStorage.getItem('userid'));

export const getUnapprovedOrder= (data) => async (dispatch) => {
  let data = {
    usertype : usertype,
    userid : userid
  };
  
  try {
    const res = await api.post('/order/unapprovedOrder',data);
    //
    //
    dispatch({
      type: RETRIEVE_UNAPPROVED_ORDERS,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_UNAPPROVED_ORDERS,
      payload: [],
    });
  }
};

export const getBranch = (data) => async (dispatch)=> {
  try {
    const res = await api.get('/common/getBranch');
   
    dispatch({
      type: GET_BRANCHES,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_BRANCHES,
      payload: [],
    });
  }
}

export const declineOrder = (data) => async (dispatch) => {
  //const body = JSON.stringify(data);
  
  try {
    let body = {
      id: data.id,
     reason : data.reason
   };
    const res = await api.post('/order/declineOrder', body);
    
    if (res.status === 200) {
      toast.success(res.data.message);
        dispatch({
            type: DECLINE_UNAPPROVED_ORDERS,
            payload: res.data.data.info,
          });
         }
      } catch (err) {
    
    toast.warn('Invalid inputs! Order Not Decline');
    // dispatch({
    //   type: REGISTER_FAIL,
    // });
  }
};


export const deleteOrder = (id) => async (dispatch) => {
  try {
    const res = await api.get('/order/deleteOrder/' + id);
   
    if (res.status === 200) {
      toast.success(res.data.message);
      dispatch({
        type:  DELETE_UNAPPROVED_ORDERS,
        payload: res.data.data.info,
      });
      
    }
  } catch (error) {
    
    toast.error('Failed to delete');
    dispatch({
      type:  DELETE_UNAPPROVED_ORDERS,
      payload: [],
    });
  }
};
