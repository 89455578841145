import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Card } from 'react-bootstrap';
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import KErrorMessage from "../../../components/ErrorMessage/KErrorMessage";
import MaterialTable from 'material-table';
import { connect } from "react-redux";
import { category, getCategory, updateCategory, deleteCategory } from '../../../redux/Product/Category/action';
import Loader from "../../../components/Loader/Loader";

const validationSchema = yup.object({
  name: yup.string().required("Category Name is Required!")
});

const Category = ({ category,getCategory,currentIntegration, updateCategory, deleteCategory, isLoading, isUpdate }) =>  { useEffect(() => {
  getCategory();
}, []);

const [loading, setLoading] = useState(false);
useEffect(() => {
    if (isLoading) {
        setLoading(true);
    }
  });

  //update row
  const updateRow = async (e) => {
   // 
    const data = {
      name:e.name
    }
    
    updateCategory(e.id,data);
  }
  
  const deleteRow = (e) => {
    //
    deleteCategory(e);
  };

  const [tableData, setTableData] = useState([]);
    useEffect(() => {
      if(currentIntegration)
      {
        setTableData(currentIntegration); 
      }
    }, [currentIntegration])
   
  const columns = [
    { title: "Category", field: "name" },
  ]

  useEffect(() => {
    if(isUpdate)
    {
      window.location.reload(false);
    }
  }, [isUpdate])

  return (
    <>
      <Container fluid className="main-content">
        <Row>
        <Col lg={"6"} className="mb-3">
            <Card>
              <Card.Body>
                <Card.Title>Add Category</Card.Title>
                <Formik
                  validationSchema={validationSchema}
                  initialValues={{
                    name: ""
                  }}
                  onSubmit={(values) => {
                    category(values);
                  }}
                >
                {({ values }) => (
                  <Form>
                    <label>Category Name</label>
                    <Field name="name" type="text" className="form-control" />
                    <KErrorMessage name="name" />
                    <div className="mt-3 float-right">
                      <button className="btn btn-primary btn-md" type="submit">Submit</button>
                    </div>
                  </Form>
                )}
                </Formik>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={"6"}>
            <div style={{display: 'table', tableLayout:'fixed', width:'100%'}}>
              {loading ? 
            <MaterialTable columns={columns} data={tableData}
              editable={{

                onRowUpdate: (newRow, oldRow) => new Promise((resolve, reject) => {
                  updateRow(newRow);
                  setTimeout(() => resolve(), 500)
                }),
                onRowDelete: (selectedRow) => new Promise((resolve, reject) => {
                  deleteRow(selectedRow.id)
                  setTimeout(() => resolve(), 1000)
                })
              }}

              options={{
                sorting: true, search: true,
                paging: true, pageSizeOptions: [10, 25, 50, 100], pageSize: 10,
                exportButton: true,
                exportAllData: true, exportFileName: "Category", actionsColumnIndex: -1,
                // headerStyle: { background: "#f44336",color:"#fff"}
              }}
              title="Category"
            />
            : <Loader/>}
            </div>
          </Col>
        </Row>
      </Container>

    </>
  )
}

const mapStateToProps = (state) => {
  return {
     currentIntegration: state.category.currentIntegration,
     isLoading: state.category.isLoading,
     isUpdate: state.category.isUpdate,
   }
 }

 export default connect(mapStateToProps,{category,getCategory, updateCategory, deleteCategory})(Category);