import {
    RETRIEVE_DASHBOARD,
    RECEIVED_PAYMENT,
    TOP_INCENTIVE
   
} from "../DashboardAccounts/actiontype";

import api from "../../api";



export const getDashboardAccounts= (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
 
  try {
    const res = await api.get(`/dashboard/cardViewAccount`);
    dispatch({
      type: RETRIEVE_DASHBOARD,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: RETRIEVE_DASHBOARD,
      payload: [],
    });
  }
};

export const getReceivedPayment= (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
 
  try {
    const res = await api.get(`/dashboard/receivedPayment`);
    dispatch({
      type: RECEIVED_PAYMENT,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: RECEIVED_PAYMENT,
      payload: [],
    });
  }
};

export const getTopIncentive= (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
 
  try {
    const res = await api.get(`/dashboard/topIncentive`);
    dispatch({
      type: TOP_INCENTIVE,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: TOP_INCENTIVE,
      payload: [],
    });
  }
};

