import React, {useEffect} from 'react'
import { Row, Col, Container, Breadcrumb, Card, Badge } from 'react-bootstrap';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Zoom from 'react-medium-image-zoom'
import { connect } from "react-redux";
import {getCpOrderDetail} from '../../redux/CP/DetailViewOrder/action';


const DetailViewCpOrder = ({ getCpOrderDetail,currentIntegration,orderDetail }) =>  {  useEffect(() => {
    const order_id = window.location.pathname.split("/").pop();
    getCpOrderDetail(order_id);
}, []);

  return (
    <>
        <Container fluid className="main-content">
            <Row>
                <Col>
                    <h3 className="main-title mb-0">Order - {currentIntegration.orderno}</h3>
                    <Breadcrumb>
                        <Breadcrumb.Item>Order</Breadcrumb.Item>
                        <Breadcrumb.Item active>View Order</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <Row className='mt-3'>
                                <Col lg={12}>
                                    <div className='d-flex justify-content-between'>
                                        <h6 className="card-title mb-3 ml-3">{currentIntegration.orderno}</h6>
                                        <div className="ml-auto" style={{float: "right"}}>
                                            <p className="mb-0"><span style={{fontWeight: "500"}}>DOB :</span> {currentIntegration.date}</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col>
                                    <div className='line'></div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <div className='d-lg-flex d-sm-block justify-content-between' >
                                        <div className='mb-3'>
                                            <p className='mb-0'><strong>Name:</strong> {currentIntegration.name}</p>
                                            <p className='mb-0'><strong>Mobile:</strong> {currentIntegration.mobile}</p>
                                            <p className='mb-0'><strong>Address:</strong> {currentIntegration.address}</p>
                                            <p className='mb-0'><strong>Email:</strong> {currentIntegration.email}</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col>
                                    <div className='line mt-0'></div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <h6 className='text-danger mb-4'>Order Detail</h6>
                                </Col>
                                <Col>
                                    <Table striped bordered hover>
                                        <Thead>
                                            <Tr>
                                                <Th className='custom-table-header'>#</Th>
                                                <Th className='custom-table-header'>IMAGE</Th>
                                                <Th className='custom-table-header'>MODEL NO</Th>
                                                <Th className='custom-table-header'>DESCRIPTION</Th>
                                                <Th className='custom-table-header'>LEATHER TYPE</Th>
                                                <Th className='custom-table-header'>LEATHER CATEGORY</Th>
                                                <Th className='custom-table-header'>LEATHER COLOUR</Th>
                                                <Th className='custom-table-header'>SPECIFICATION</Th>
                                                <Th className='custom-table-header'>RDP</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                        {orderDetail && orderDetail.map((order, index) => (
                                            <Tr key={index}>
                                                <Td className='custom-table'>{index+1}</Td>
                                                <Td className='custom-table'><Zoom zoomMargin={40}><img src={process.env.REACT_APP_IMAGE_URL+'/'+order.image} alt={order.image} style={{width: 50, height:50}}/></Zoom></Td>
                                                <Td className='custom-table'>M1234</Td>
                                                <Td className='custom-table'>
                                                    <ul class="description-list">
                                                        <li>Product Name: {order.name}</li>
                                                        <li>Product Category: {order.category}</li>
                                                        <li>Color Code: {order.colour_code}</li>
                                                        <li>Qty: {order.qty}</li>
                                                    </ul>
                                                </Td>
                                                <Td className='custom-table'>{order.leather_type}</Td>
                                                <Td className='custom-table'>{order.leather_category}</Td>
                                                <Td className='custom-table'>{order.leather_colour}</Td>
                                                <Td className='custom-table'>{order.spec}</Td>
                                                <Td className='custom-table'>{order.rdp}</Td>
                                            </Tr>
                                           ))}
                                        </Tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col lg={{ span: 3, offset: 9 }} sm={12}>
                                    <div class="table-responsive">
                                        <table class="table table-invoice text-right" >
                                            <tbody>
                                                <tr>
                                                    <td class="tx-right tx-11" style={{borderBottomWidth: "0px"}}><strong>Total</strong></td>
                                                    <td class="tx-right tx-12" colspan="2" style={{borderBottomWidth: "0px"}}><strong>₹ {currentIntegration.amount}</strong></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    </>
  )
}

const mapStateToProps = (state) => {
    console.log(state);
    return {
       currentIntegration: state.CPorderDetail.currentIntegration,
       orderDetail: state.CPorderDetail.currentIntegration.products,
     }
   }
  
   export default connect(mapStateToProps,{getCpOrderDetail})(DetailViewCpOrder);