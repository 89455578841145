import {
    RETRIEVE_RECEIVE_PAYMENT,
    APPROVE_RECEIVE_PAYMENT,
    DECLINE_RECEIVE_PAYMENT,
    VIEWDETAIL_RECEIVE_PAYMENT
} from "../ReceivePayment/actiontype";

import { toast } from 'react-toastify';
import api from "../../../api";

let userid = JSON.parse(localStorage.getItem('userid'));
let id = JSON.parse(localStorage.getItem('id'));

export const getReceivePayment= (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  try {
    const res = await api.get(`/accountSection/receivedPayment`);
    dispatch({
      type: RETRIEVE_RECEIVE_PAYMENT,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_RECEIVE_PAYMENT,
      payload: [],
    });
  }
};

export const approvePayment= (id) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  let data = {
    id : id,
    userid : userid
  };
 
  try {
    const res = await api.post(`/accountSection/approvePayment`,data);
    
    if (res.status === 200) {
      toast.success(res.data.message);
      dispatch({
        type:  APPROVE_RECEIVE_PAYMENT,
        payload: res.data.data,
      });
     
    }
    
  } catch (err) {
    
    dispatch({
      type:  APPROVE_RECEIVE_PAYMENT,
      payload: [],
    });
  }
};

export const declinePayment= (id) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  let data = {
    id : id,
    userid : userid
  };
  try {
    const res = await api.post(`/accountSection/declinePayment`,data);
   
    if (res.status === 200) {
      toast.success(res.data.message);
      dispatch({
        type:  DECLINE_RECEIVE_PAYMENT,
        payload: res.data.data,
      });
    }
   
  } catch (err) {
    
    dispatch({
      type:  DECLINE_RECEIVE_PAYMENT,
      payload: [],
    });
  }
};


export const getPaymentDetail= (id) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  let data = {
    id : id,
    userid : userid
  };
  try {
    const res = await api.get(`/accountSection/viewPaymentDetail/`+id);
      dispatch({
        type: VIEWDETAIL_RECEIVE_PAYMENT,
        payload: res.data.data,
      });
   
  } catch (err) {
    
    dispatch({
      type: VIEWDETAIL_RECEIVE_PAYMENT,
      payload: [],
    });
  }
};



