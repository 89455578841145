import React from 'react'
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, makeStyles, IconButton } from '@material-ui/core'
import NotListedLocationIcon from '@material-ui/icons/NotListedLocation';
import { Button } from 'react-bootstrap';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const useStyles = makeStyles(theme => ({
    dialog: {
        padding: theme.spacing(2),
        position: 'absolute',
        top: theme.spacing(18)
    },
    dialogTitle: {
        textAlign: 'center'
    },
    dialogContent: {
        textAlign: 'center'
    },
    dialogAction: {
        justifyContent: 'center'
    },
    titleIcon: {
        // backgroundColor: '#d3001c',
        color: 'white',
        '&:hover': {
            backgroundColor: '#ff445d',
            cursor: 'default'
        },
        '& .MuiSvgIcon-root': {
            fontSize: '4rem !important',
        }
    },
    successBg: {
        backgroundColor: 'green',
        color: 'white',
        '&:hover': {
            backgroundColor: 'green',
            cursor: 'default'
        },
        '& .MuiSvgIcon-root': {
            fontSize: '4rem !important',
        }
    },
    errorBg: {
        backgroundColor: '#d3001c',
        color: 'white',
        '&:hover': {
            backgroundColor: '#d3001c',
            cursor: 'default'
        },
        '& .MuiSvgIcon-root': {
            fontSize: '4rem !important',
        }
    }
}))

export default function ConfirmDialog(props) {
    const { confirmDialog, setConfirmDialog } = props;
    const classes = useStyles()
    return (
        <Dialog open={confirmDialog.isOpen} classes={{ paper: classes.dialog }}>
            <DialogTitle className={classes.dialogTitle}>
            { confirmDialog.type ?
                <IconButton disableRipple className={ classes.successBg}>
                    <CheckIcon />
                </IconButton> :  
                <IconButton disableRipple className={ classes.errorBg}>
                  <CloseIcon />  
                </IconButton>
            }
            </DialogTitle>
            <DialogContent className={classes.dialogContent}>
                <Typography variant="h6">
                    {confirmDialog.title}
                </Typography>
                <Typography variant="subtitle2">
                    {confirmDialog.subTitle}
                </Typography>
            </DialogContent>
            <DialogActions className={classes.dialogAction}>
                <Button variant="secondary" className='text-white'
                    onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: false })} >No</Button>
                <Button variant="primary"
                    onClick={confirmDialog.onConfirm}> Yes </Button>
            </DialogActions>
        </Dialog>
    )
}
