import React from "react";
import { Row, Col } from "react-bootstrap";

function Search(props) {
  return (
    <>
      <Row>
        <Col>
            <input
            type="text"
            placeholder="Search..."
            onChange={(e) => props.onSearch(e.target.value)}
            value={props.value}
            className="form-control mr-3 mb-0 search-input"
            />
        </Col>
    </Row>
    </>
  );
}

export default Search;
