import {
    CREATE_BRANDS,
    RETRIEVE_BRANDS,
    UPDATE_BRANDS,
    DELETE_BRANDS,
} from "../Brand/actiontype";

import { toast } from 'react-toastify';
import api from "../../../api";

export const getBrand = (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  try {
    const res = await api.get(`/brand/brand`);
    dispatch({
      type: RETRIEVE_BRANDS,
      payload: res.data.brands,
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_BRANDS,
      payload: [],
    });
  }
};

export const brand = (registerData) => async (dispatch) => {
  const body = JSON.stringify(registerData);
  try {
    const res = await api.post('/brand/addBrand', body);
    if (res.status === 200) {
      toast.success('Brand Added Successfully');
        dispatch({
            type: CREATE_BRANDS,
            payload: res.data.data.info[0],
          });
        }
      } catch (err) {
    
    toast.warn('Invalid inputs! Brand Not Added');
    // dispatch({
    //   type: REGISTER_FAIL,
    // });
  }
};



//export const updateBrand
export const updateBrand = (appId, formData) => async (dispatch) => {
  formData.id = appId;
  try {
    const res = await api.put('/brand/updateBrand', formData);
    if (res.status === 200) {
      dispatch({
        type: UPDATE_BRANDS,
        payload: res.data.data.info[0],
      });
      toast.success(res.data.message);
    }
  } catch (error) {
    
    toast.error('Failed to update data!');
    // dispatch({
    //   type: APP_DATA_ERROR,
    //   payload: 'ERROR' + uuidv1(),
    // });
  }
};


export const deleteBrand = (id) => async (dispatch) => {
  try {
    const res = await api.delete('/brand/deleteBrand/' + id);
    if (res.status === 200) {
      dispatch({
        type: DELETE_BRANDS,
        payload: res.data.data.info,
      });
      toast.error('User deleted successfully!');
    }
  } catch (error) {
    
    toast.error('Failed to delete user');
    dispatch({
      type: DELETE_BRANDS,
      payload: [],
    });
  }
};



