import {
    CREATE_BRANCHS,
    RETRIEVE_BRANCHS,
    UPDATE_BRANCHS,
    DELETE_BRANCHS,
    RETRIEVE_SINGLE_BRANCHS
} from "../Branch/actiontype";

import { toast } from 'react-toastify';
import api from "../../../api";

export const branch = (registerData) => async (dispatch) => {
  const body = JSON.stringify(registerData);
  try {
    const res = await api.post('/setting/addBranch', body);
   
    if (res.status === 200) {
      toast.success('Branch Added Successfully');
        dispatch({
            type: CREATE_BRANCHS,
            payload: res.data.data.info[0],
          });
        }
      } catch (err) {
    
    toast.warn('Invalid inputs! Branch Not Added');
    // dispatch({
    //   type: REGISTER_FAIL,
    // });
  }
};

export const getBranch= (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  try {
    const res = await api.get(`/setting/branch`);
   
    
    dispatch({
      type: RETRIEVE_BRANCHS,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_BRANCHS,
      payload: [],
    });
  }
};


export const getSingleBranch = (id) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  try {
    const res = await api.get(`/setting/getBranch/` + id);
   
    dispatch({
      type: RETRIEVE_SINGLE_BRANCHS,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_SINGLE_BRANCHS,
      payload: [],
    });
  }
};

export const updateBranch = (registerData) => async (dispatch) => {
  const body = JSON.stringify(registerData);
  try {
    const res = await api.post('/setting/updateBranch', body);
   
    if (res.status === 200) {
      toast.success('Branch Updated Successfully');
        dispatch({
            type: UPDATE_BRANCHS,
            payload: res.data.data.info[0],
          });
        }
      } catch (err) {
    
    toast.warn('Invalid inputs! Branch Not Updated');
    // dispatch({
    //   type: REGISTER_FAIL,
    // });
  }
};


export const deleteBranch = (id) => async (dispatch) => {
  try {
    const res = await api.delete('/setting/deleteBranch/' + id);
   
    if (res.status === 200) {
      toast.success(res.data.message);
      dispatch({
        type:  DELETE_BRANCHS,
        payload: res.data.data.info,
      });
      
    }
  } catch (error) {
    
    toast.error('Failed to delete');
    dispatch({
      type:  DELETE_BRANCHS,
      payload: [],
    });
  }
};