import {
    QUOTATION_DETAIL,
    UPDATE_QUOTATION,
    GET_IMPORTED_READY,
    GET_IMPORTED_ORDER,
    GET_TOTAL_AMOUNT,
    GET_QUOTATION_NAME,
    READY_NET_AMOUNT,
    ORDER_NET_AMOUNT,
    EDIT_READY_PRODUCT,
    EDIT_NET_AMOUNT,
    EDIT_ORDER_PRODUCT,
    EDIT_ORDER_NET_AMOUNT,
    BRANCH_DETAIL,
    SUB_TOTAL_AMOUNT,
    TOTAL_AMOUNT,
    FITTING_AMOUNT,
    PACKING_AMOUNT,
    GRAND_TOTAL,
    GROSS_TOTAL,
    BALANCE_AMT,
} from "./actionType";

const INITIAL_STATE = {
    quotations:[],
    imported_ready: [],
    imported_order: [],
    total_amount:'',
    quotation_name : '',
    ready_net_amount:0,
    order_net_amount:0,
    edit_ready_product: [],
    edit_ready_netamount:0,
    edit_order_product: [],
    edit_order_netamount:0,
    isUpdated:false,
    branch_detail: [],
    sub_total_amount:0,
    total_amount:0,
    fitting_amount:0,
    packing_amount:0,
    grand_total:0
  };
  
  const editQuotationReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
   
    switch (type) {
      case QUOTATION_DETAIL:
        return {
          ...state,
          quotations: payload,
        };
      case GET_IMPORTED_READY:
        return {
          ...state,
          imported_ready: payload,
        };
      case GET_IMPORTED_ORDER:
        return {
          ...state,
          imported_order: payload,
        };
      case GET_TOTAL_AMOUNT:
        return {
          ...state,
          total_amount: payload,
        };
      case GET_QUOTATION_NAME:
        return {
          ...state,
          quotation_name: payload,
        };
      case READY_NET_AMOUNT:
        return {
          ...state,
          ready_net_amount: payload,
        };
      case ORDER_NET_AMOUNT:
        return {
          ...state,
          order_net_amount: payload,
        };
      case EDIT_READY_PRODUCT:
      return {
        ...state,
        edit_ready_product: payload,
      };
    case EDIT_NET_AMOUNT:
      return {
        ...state,
        edit_ready_netamount: payload,
      };
    case EDIT_ORDER_PRODUCT:
      return {
        ...state,
        edit_order_product: payload,
      };
    case EDIT_ORDER_NET_AMOUNT:
      return {
        ...state,
        edit_order_netamount: payload,
      };
    case UPDATE_QUOTATION:
      return {
        ...state,
        isUpdated: true,
      };
    case BRANCH_DETAIL:
      return {
        ...state,
        branch_detail: payload,
      };
    case SUB_TOTAL_AMOUNT:
      return {
        ...state,
        sub_total_amount: payload,
      };
    case TOTAL_AMOUNT:
      return {
        ...state,
        total_amount: payload,
      };
    case FITTING_AMOUNT:
      return {
        ...state,
        fitting_amount: payload,
      };
    case PACKING_AMOUNT:
      return {
        ...state,
        packing_amount: payload,
      };
    case GRAND_TOTAL:
      return {
        ...state,
        grand_total: payload,
      };
      default:
        return state;
    }
  };
  
  
  export default editQuotationReducer;