import * as React from "react";
import {useState, useEffect} from 'react'
import { Row, Col, Container, Badge, Dropdown, Modal, Button} from 'react-bootstrap';
import MaterialTable from 'material-table';
import EditIcon from '@mui/icons-material/Edit';
import DescriptionIcon from '@mui/icons-material/Description';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Formik, Form, Field } from "formik";
import { connect } from "react-redux";
import {getDeliveryDueOrder,getbilling,addBilling} from '../../../redux/Order/DeliveryDueOrder/action';
import CreateOrder from "../../../components/BranchList/CreateOrder";
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import Loader from "../../../components/Loader/Loader";

const DeliveryDueOrder = ({ getDeliveryDueOrder,currentIntegration,getbilling,billing,addBilling,isLoading }) =>  {  useEffect(() => {
  getDeliveryDueOrder();
}, []);
  

const paymentModeList = [
  { value: 'cash', label: 'Cash' },
  { value: 'card', label: 'Card' }
];

const [visible, setVisible] = useState(false);
const handleVisibility = () => {
    setVisible(prev => !prev);
};

const [loading, setLoading] = useState(false);
useEffect(() => {
    if (isLoading) {
        setLoading(true);
    }
  });

const [show, setShow] = useState(false);
const modalClose = () => setShow(false);
const modalShow = () => setShow(true);

const [tableData, setTableData] = useState([]);
  useEffect(() => {
    if(currentIntegration)
    {
      setTableData(currentIntegration); 
    }
  }, [currentIntegration])

const columns = [
{ title: "Order No. | Created By", field: "orderNo" },
// { title: "Created By ", field: "createdBy" },
{ title: "Customer Detail", field: "customerDetail" },
// { title: "Customer Mobile", field: "customerMobile" },
{ title: "Qty", field: "qty" },
{ title: "Net Total Amount", field: "totalAmount" },
{ title: "Total Discount", field: "totalDiscount" },
{ title: "Grand Total", field: "grandTotal" },
{ title: "Advance Paid", field: "advancePaid" },
{ title: "Total Paid", field: "totalPaid" },
{ title: "Total Due", field: "totalDue" },
{ title: "Date Of Order", field: "orderDate" },
{ title: "Expected Date Of Order", field: "expectedDate" },

]

const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', type: ''})
const navigate = useNavigate();

const editOrder = async (e) => {
  navigate('/edit-order/'+e);
}
const viewOrder = async (e) => {
  navigate('/detail-View-order/'+e);
}
// const addBill = async (e) => {
//   // setFormData({ ...formData, 'id': e });
//   setShow({
//       isOpen: true,
//       })
//   getbilling(e);
//   
// }

const [formData, setFormData] = useState({
  id: "",
  billing_name: "",
  billing_address: "",
  gst_no: "",
  payment_mode: "",
  vehicle_no: "",
});

const { id, billing_name, billing_address, gst_no, payment_mode, vehicle_no } = formData;

const onChange = (e) =>
{
  setFormData({ ...formData, [e.target.name]: e.target.value });
  
}
const onSelectChangePaymentMode = (e) =>
{
  setFormData({ ...formData, 'payment_mode': e.value });
}
const addBill = async (e) => {
  setFormData({ ...formData, 'id': e });
  setShow({
      isOpen: true,
      })
      getbilling(e);
  
}

const onSubmit = async (e) => {
  e.preventDefault();
  const data = {
   id: formData.id,
   billing_name: formData.billing_name,
   billing_address: formData.billing_address,
   gst_no: formData.gst_no,
   payment_mode: formData.payment_mode,
   vehicle_no: formData.vehicle_no,
 };
 addBilling(data); // action name
}



  return (
    <>
        <Container fluid className="main-content">
          <Row>
              <Col>
                  <h3 className="main-title">Delivery Due Order</h3>
              </Col> 
              <Col>
                  <div style={{float: "right"}} className="d-flex">
                    <div className="d-flex justify-content-between d-flex d-sm-none">
                      <div className="magnifierContainer">
                        <button type="button" className="btn btn-md btn-warning text-white roundedcircle mr-3" onClick={handleVisibility}><i className="fa-solid fa-search"></i></button>
                      </div>
                    </div>
                    <CreateOrder/>
                  </div>
              </Col>
              <Col lg={"12"}>
                  <div className='d-block d-sm-none mt-2'>
                    <div className={visible ? "open" : "close"}>
                        <input placeholder="Search..." type="text" className="form-control is-open mr-3 mb-0 search-input"/>
                    </div>
                  </div>
              </Col>
          </Row>
          {loading ? <div>
          <Row className="d-none d-sm-block mt-2">
            <Col lg={"12"} className="mt-3">
              <div style={{display: 'table', tableLayout:'fixed', width:'100%'}}>
              <MaterialTable columns={columns} data={tableData}
                 actions={[
                  {
                    icon: () => <VisibilityIcon color={'primary'} />,
                    tooltip: 'View',
                    onClick: (event, rowData) => viewOrder(rowData.id)
                  },
                  
                  {
                    icon: () => <EditIcon color={'secondary'} />,
                    tooltip: 'Edit',
                    onClick: (event, rowData) => editOrder(rowData.id)
                  },
                  
                  {
                    icon: () => <DescriptionIcon color={'warning'} />,
                    tooltip: 'Billing',
                    onClick: (event, rowData) => addBill(rowData.id)
                  }
                ]}
                  options={{
                  sorting: true, search: true,
                  paging: true, pageSizeOptions: [10, 25, 50, 100], pageSize: 10,
                  exportButton: true,
                  exportAllData: true, exportFileName: "Delivery Due Order", actionsColumnIndex: -1,
                  // headerStyle: { background: "#f44336",color:"#fff"}
                  }}
                  title="Delivery Due Order"
              />
              </div>
            </Col>
          </Row>
          <Row className="d-block d-sm-none mt-2">
            {currentIntegration.map((order, index) => (
            <Col xs={"12"} key={index}>
                <div className="card mb-2">
                    <div className="card-header d-flex justify-content-between pb-0">
                        <h6>{order.orderNo}	 <Badge bg="danger" text="light" className="ml-2">{order.qty}</Badge></h6>
                        <Dropdown>
                        <Dropdown.Toggle variant="light" id="dropdown-basic">
                            <i className="ri-more-2-line"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => viewOrder(order.id)}><VisibilityIcon color={'primary'} /> View</Dropdown.Item>
                            <Dropdown.Item onClick={() => editOrder(order.id)}><EditIcon color={'secondary'} /> Edit</Dropdown.Item>
                            <Dropdown.Item onClick={() => addBill(order.id)}><DescriptionIcon color={'warning'} /> Billing</Dropdown.Item>
                        </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="card-body pt-1">
                        <p className="card-text mb-0"><span className="quotation-info">Customer Detail:</span> {order.customerDetail}</p>
                        <p className="card-text mb-0"><span className="quotation-info">Net Total Amount:</span> {order.totalAmount}</p>
                        <p className="card-text mb-0"><span className="quotation-info">Total Discount:</span> {order.totalDiscount}</p>
                        <p className="card-text mb-0"><span className="quotation-info">Grand Total:</span> {order.grandTotal}</p>
                        <p className="card-text mb-0"><span className="quotation-info">Advance Paid:</span> {order.advancePaid}</p>
                        <p className="card-text mb-0"><span className="quotation-info">Total Paid:</span> {order.totalPaid}</p>
                        <p className="card-text mb-0"><span className="quotation-info">Total Due:</span> {order.totalDue}</p>
                        <p className="card-text mb-0"><span className="quotation-info">Order Date:</span> {order.orderDate}</p>
                        <p className="card-text mb-0"><span className="quotation-info">Delivery Date:</span> {order.expectedDate}</p>
                    </div>
                </div>
            </Col>
            ))}
          </Row>
          </div> : <Loader/>}
        </Container>
        {/* Add Billing  Modal Start */}
        <Modal 
            size="md"
            centered
            show={show} 
            onHide={modalClose}>
                <Formik
                    initialValues={{
                    billing_name: "",
                    billing_address: "",
                    gst_no: "",
                    payment_mode: "",
                    vehicle_no: "",
                    }}
                >
                {({values, setFieldValue}) => (
                    <Form onSubmit={(e) => onSubmit(e)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Billing Detail</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {/* <Field name="id" type="text" className="form-control" value={billing.id}/> */}
                        <label>Billing Name</label>
                        <Field name="billing_name" type="text" className="form-control" value={billing_name} onChange={(e) => onChange(e)}/>
                        <label>Billing Address</label>
                        <Field name="billing_address" type="text" className="form-control" value={billing_address} onChange={(e) => onChange(e)}/>
                        <label>GST No.</label>
                        <Field name="gst_no" type="text" className="form-control" value={gst_no} onChange={(e) => onChange(e)}/>
                        <label>Mode Of Payment</label>
                        <Select options={paymentModeList} name="payment_mode" onChange={(e) => onSelectChangePaymentMode(e)} className='mb-2' />
                        <label>Vehicle Detail</label>
                        <Field name="vehicle_no" as="textarea" className="form-control" value={vehicle_no} onChange={(e) => onChange(e)} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={modalClose}>Close</Button>
                        <Button variant="primary" type="submit">Submit</Button>
                    </Modal.Footer>
                    </Form>
                )}
                </Formik>
            </Modal>
            {/*Add Billing Modal End */}
    </>
  )
}

const mapStateToProps = (state) => {

  return {
     currentIntegration: state.deliveryDueOrder.currentIntegration,
     isLoading: state.deliveryDueOrder.isLoading,
     billing: state.deliveryDueOrder.billing[0],
   }
 }

 export default connect(mapStateToProps,{getDeliveryDueOrder,getbilling,addBilling})(DeliveryDueOrder);