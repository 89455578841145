import * as React from "react";
import { useState, useEffect, useRef } from 'react'
import { Row, Col, Container, Dropdown, Badge, Modal, Table } from 'react-bootstrap';
import { useReactToPrint } from "react-to-print";
import MaterialTable from 'material-table';
import CheckIcon from '@mui/icons-material/Check';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import { connect } from "react-redux";
import {getReceivePayment, approvePayment, declinePayment, getPaymentDetail} from '../../../redux/Payment/ReceivePayment/action';
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import SearchBar from "../../../components/SearchBar/SearchBar";

const ReceivePayment = ({ getReceivePayment,currentIntegration, approvePayment, declinePayment, getPaymentDetail, paymentDetail, list }) =>  {  useEffect(() => {
    getReceivePayment();
    
}, []);

const [visible, setVisible] = useState(false);
const handleVisibility = () => {
    setVisible(prev => !prev);
};

const [show, setShow] = useState(false);
const modalClose = () => setShow(false);
const modalShow = () => setShow(true);

const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', type: ''})

const [tableData, setTableData] = useState([]);
useEffect(() => {
if(currentIntegration)
{
    setTableData(currentIntegration); 
}
}, [currentIntegration])

const columns = [
    { title: "Order No.", field: "orderNo" },
    { title: "Customer Name", field: "customerName" },
    { title: "Total Order", field: "totalOrder" },
    { title: "Due Amount", field: "dueAmount" },
    { title: "Payment", field: "payment" },
    { title: "Mode", field: "mode" },
    { title: "Sold By", field: "soldBy" },
    { title: "Order Date", field: "date" },
]

const paymentApproved = async (e) => {
    
    setConfirmDialog({
    isOpen: true,
    title: 'Are You Sure To Approve This Payment?',
    type: true,
    onConfirm: () => { 
        approvePayment(e);
        setConfirmDialog({ ...confirmDialog, isOpen: false })
    }
    })
}

const paymentdeclined = async (e) => {
    
    setConfirmDialog({
    isOpen: true,
    title: 'Are You Sure To Delete This Payment?',
    type: false,
    onConfirm: () => { 
        declinePayment(e);
        setConfirmDialog({ ...confirmDialog, isOpen: false })
    }
    })
}
const viewDetail = async (e) => {
    
    setShow({
    isOpen: true,

    })
    getPaymentDetail(e);
}

// Search for Card view start
const [searchValue, setSearchValue] = useState("");

const filterPayment = ({ orderNo }) => {
    return orderNo.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
};
// Search for Card view end


const componentRef = useRef();
const handlePrint = useReactToPrint({
  content: () => componentRef.current,
});


    return (
        <>
            <Container fluid className="main-content">
                <Row>
                    <Col className='d-flex justify-content-between mb-2'>
                        <h3 className="main-title">Receive Payment</h3>
                        <div className="d-flex justify-content-between d-block d-sm-none">
                            <div className="magnifierContainer">
                                <button type="button" className="btn btn-md btn-warning text-white roundedcircle mr-3" onClick={handleVisibility}><i className="fa-solid fa-search"></i></button>
                            </div>
                        </div>
                    </Col>
                    <Col lg={"12"} className='mb-2 d-block d-sm-none'>
                        <div className={visible ? "open" : "close"}>
                            <SearchBar onSearch={setSearchValue} value={searchValue} />
                        </div>
                    </Col>
                </Row>
                <Row className="d-none d-sm-block mt-2">
                    <Col lg={"12"}>
                        <div style={{ display: 'table', tableLayout: 'fixed', width: '100%' }}>
                            <MaterialTable columns={columns} data={tableData}
                                actions={[
                                    {
                                      icon: () => <VisibilityIcon color={'primary'} />,
                                      tooltip: 'View',
                                      onClick: (event, rowData) => viewDetail(rowData.serial_no)
                                    },
                                    {
                                      icon: () => <CheckIcon color={'success'} />,
                                      tooltip: 'Approve Payment',
                                      onClick: (event, rowData) => paymentApproved(rowData.id)
                                    },
                                    {
                                      icon: () => <CloseIcon color={'error'} />,
                                      tooltip: 'Decline Payment',
                                      onClick: (event, rowData) => paymentdeclined(rowData.id)
                                    }
                                ]}
                                options={{
                                    sorting: true, search: true,
                                    paging: true, pageSizeOptions: [10, 25, 50, 100], pageSize: 10,
                                    exportButton: true,
                                    exportAllData: true, exportFileName: "Receive Payment", actionsColumnIndex: -1,
                                    // headerStyle: { background: "#f44336",color:"#fff"}
                                }}
                                title="Receive Payment"
                            />
                        </div>
                    </Col>
                </Row>
                <Row className="d-block d-sm-none">
                    {currentIntegration.filter(filterPayment).map((payment, index) => ( 
                    <Col xs={"12"} key={index}>
                        <div className="card mb-2">
                            <div className="card-header d-flex justify-content-between pb-0">
                                <h6>{payment.orderNo}</h6>
                                <Dropdown>
                                <Dropdown.Toggle variant="light" id="dropdown-basic">
                                    <i className="ri-more-2-line"></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => viewDetail(payment.serial_no)}><VisibilityIcon color={'primary'} /> View</Dropdown.Item>
                                    <Dropdown.Item onClick={() => paymentApproved(payment.id)}><CheckIcon color={'success'} /> Approve</Dropdown.Item>
                                    <Dropdown.Item onClick={() => paymentdeclined(payment.id)}><CloseIcon color={'error'} /> Decline</Dropdown.Item>
                                </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className="card-body pt-1">
                                <p className="card-text mb-0"><span className="quotation-info">Customer Name:</span> {payment.customerName}</p>
                                <p className="card-text mb-0"><span className="quotation-info">Total Order:</span> {payment.totalOrder}</p>
                                <p className="card-text mb-0"><span className="quotation-info">Due Amount:</span> {payment.dueAmount}</p>
                                <p className="card-text mb-0"><span className="quotation-info">Payment:</span> {payment.payment}</p>
                                <p className="card-text mb-0"><span className="quotation-info">Mode:</span> {payment.mode}</p>
                                <p className="card-text mb-0"><span className="quotation-info">Sold By:</span> {payment.soldBy}</p>
                                <p className="card-text mb-0"><span className="quotation-info">Order Date:</span> {payment.date}</p>
                            </div>
                        </div>
                    </Col>
                    ))}
                </Row>
            </Container>
            <Modal 
                size="md"
                centered
                show={show} 
                onHide={modalClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title>View Payment</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <div ref={componentRef} style={{padding: "25px"}}>
                    <Table striped bordered hover>
                        <tbody>
                            <tr>
                                <td><strong>Order No:</strong></td>
                                <td>{paymentDetail.orderNo}</td>
                            </tr>
                            <tr>
                                <td><strong>No Of Item:</strong></td>
                                <td>{paymentDetail.qty}</td>
                            </tr>
                            <tr>
                                <td><strong>Total Amount :</strong></td>
                                <td>{paymentDetail.gross_total}</td>
                            </tr>
                            <tr>
                                <td><strong>Advance Paid :</strong></td>
                                <td>{paymentDetail.advance}</td>
                            </tr>
                            <tr>
                                <td><strong>Customer Name :</strong></td>
                                <td>{paymentDetail.name}</td>
                            </tr>
                            <tr>
                                <td><strong>Customer Mobile :</strong></td>
                                <td>{paymentDetail.mobile_no}</td>
                            </tr>
                        </tbody>
                    </Table>
                    <h6>Payment Detail:</h6>
                    <Timeline>
                    {list && list.map((duepayment, index) => ( 
                        <TimelineItem >
                            <TimelineSeparator>
                            <TimelineDot />
                            <TimelineConnector />
                            </TimelineSeparator>
                            <TimelineContent>{duepayment.payment}</TimelineContent>
                        </TimelineItem>
                    ))}
                    </Timeline>
                </div>
                    <button type="button" onClick={handlePrint} className="btn btn-md btn-warning text-white" style={{float: "right"}}><i class="ri-printer-line"></i> Print</button>
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button variant="secondary" onClick={modalClose}>Close</Button>
                    <Button variant="primary" type="submit">Submit</Button>
                </Modal.Footer> */}
            </Modal>
            <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
            />
        </>

    )
}

const mapStateToProps = (state) => {

  return {
     currentIntegration: state.receivePayment.currentIntegration,
     paymentDetail: state.receivePayment.paymentDetail,
     list: state.receivePayment.paymentDetail.list,
     
   }
 }

 export default connect(mapStateToProps,{getReceivePayment, approvePayment, declinePayment, getPaymentDetail})(ReceivePayment);