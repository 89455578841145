import {
    RETRIEVE_SAP_BOOKED
} from "../BookedOrder/actiontype";

import { toast } from 'react-toastify';
import api from "../../../api";

export const getSapBookedOrder= (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  try {
    const res = await api.get(`/sapSection/bookedOrder`);
   
    
    dispatch({
      type: RETRIEVE_SAP_BOOKED,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_SAP_BOOKED,
      payload: [],
    });
  }
};



