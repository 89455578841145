import * as React from "react";
import {useState, useEffect} from 'react'
import { Row, Col, Container, Badge, Card, Button} from 'react-bootstrap';
import MaterialTable from 'material-table';
import { connect } from "react-redux";
import {getCpProduct} from '../../redux/CP/Product/action';
import Loader from '../../components/Loader/Loader';
import Zoom from 'react-medium-image-zoom'
import SearchBar from "../../components/SearchBar/SearchBar";

const Product = ({ getCpProduct,currentIntegration,isLoading }) =>  {  useEffect(() => {
  getCpProduct();
}, []);

const [loading, setLoading] = useState(false);
useEffect(() => {
    if (isLoading) {
        setLoading(true);
    }
  });

const [visible, setVisible] = useState(false);
const handleVisibility = () => {
    setVisible(prev => !prev);
};

const [tableData, setTableData] = useState([]);
    useEffect(() => {
      if(currentIntegration)
      {
        setTableData(currentIntegration); 
      }
    }, [currentIntegration])

const columns = [
{ title: 'Product Image', field: 'image', render: rowData => <img src={process.env.REACT_APP_IMAGE_URL+'/'+rowData.image} alt={rowData.image} style={{width: 80}}/> },
{ title: "Model No. ", field: "model" },
{ title: "Specification", field: "spec" },
{ title: "Qty", field: "qty" },
{ title: "Product Category", field: "cateory" },
{ title: "Leather Category", field: "leather_category" },
{ title: "Leather Type", field: "leather_type" },
{ title: "Colour Code", field: "colour_code" },
{ title: "RDP", field: "rdp" },

]
    
// Search for Card view start
const [searchValue, setSearchValue] = useState("");

const filterProduct = ({ model }) => {
  return model.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
};
// Search for Card view end

  return (
    <>
        <Container fluid className="main-content">
          <Row>
              <Col>
                  <h3 className="main-title">Channel Partner Product</h3>
              </Col> 
              <Col>
                  <div style={{float: "right"}} className="d-flex">
                    <div className="d-flex justify-content-between d-flex d-sm-none">
                      <div className="magnifierContainer">
                        <button type="button" className="btn btn-md btn-warning text-white roundedcircle mr-3" onClick={handleVisibility}><i className="fa-solid fa-search"></i></button>
                      </div>
                    </div>
                  </div>
              </Col>
              <Col lg={"12"}>
                <div className='d-block d-sm-none mt-2'>
                  <div className={visible ? "open" : "close"}>
                      <SearchBar onSearch={setSearchValue} value={searchValue}/>
                  </div>
                </div>
              </Col>
            </Row>
            {loading ? <div>
            <Row className="d-none d-sm-block mt-2">
              <Col lg={"12"} className="mt-3">
                <div style={{display: 'table', tableLayout:'fixed', width:'100%'}}>
                <MaterialTable columns={columns} data={tableData}
                    options={{
                    sorting: true, search: true,
                    paging: true, pageSizeOptions: [10, 25, 50, 100], pageSize: 10,
                    exportButton: true,
                    exportAllData: true, exportFileName: "Channel Partner Product", actionsColumnIndex: -1,
                    // headerStyle: { background: "#f44336",color:"#fff"}
                    }}
                    title="Channel Partner Product"
                />
                </div>
              </Col>
            </Row>
            <Row className="d-block d-sm-none mt-2">
              {currentIntegration.filter(filterProduct).map((cpOrder, index) => (
                <Col sm={12} key={index} className="mb-3">
                    <Card>
                    <Zoom><Card.Img variant="top" src={process.env.REACT_APP_IMAGE_URL+'/'+cpOrder.image} alt={cpOrder.image} /></Zoom>
                    <Card.Body>
                        <Card.Title className='d-flex justify-content-between'>
                            <h5 className='mb-0'>{cpOrder.model} <span><Badge bg="info">{cpOrder.qty}</Badge></span></h5>   
                        </Card.Title>
                        <p className="card-text mb-0"><span className="quotation-info">Specification:</span>{cpOrder.spec}</p>
                        <p className="card-text mb-0"><span className="quotation-info">Product Category:</span>{cpOrder.cateory}</p>
                        <p className="card-text mb-0"><span className="quotation-info">Leather Category:</span>{cpOrder.leather_category}</p>
                        <p className="card-text mb-0"><span className="quotation-info">Leather Type:</span>{cpOrder.leather_type}</p>
                        <p className="card-text mb-0"><span className="quotation-info">Leather Colour:</span>{cpOrder.leather_colour}</p>
                        <p className="card-text mb-0"><span className="quotation-info">Colour Code:</span>{cpOrder.colour_code}</p>
                        <p className="card-text mb-0"><span className="quotation-info">RDP:</span>{cpOrder.rdp}</p>
                    </Card.Body>
                    </Card>
                </Col>
              ))}
        </Row>
        </div> : <Loader/> }
        </Container>
    </>

  )
}

const mapStateToProps = (state) => {
  //
    return {
       currentIntegration: state.cpProduct.currentIntegration,
       isLoading: state.cpProduct.isLoading,
     }
   }
  
 export default connect(mapStateToProps,{getCpProduct})(Product);