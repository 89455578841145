import {
    CREATE_EXPECTED_DELIVERY_DATE,
    RETRIEVE_EXPECTED_DELIVERY_DATE,
    UPDATE_EXPECTED_DELIVERY_DATE,
    DELETE_EXPECTED_DELIVERY_DATE
} from "../ExpectedDeliveryDate/actiontype";

const INITIAL_STATE = {
  currentIntegration: [],
  isLoading: false,
};

const expectedDeliveryDateReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case CREATE_EXPECTED_DELIVERY_DATE:
      return {
        ...state
      };
    case RETRIEVE_EXPECTED_DELIVERY_DATE:
      return {
        ...state,
        currentIntegration: payload,
        isLoading: true,
      };
    case UPDATE_EXPECTED_DELIVERY_DATE:
      return state.map((expectedDeliveryDate) => {
        if (expectedDeliveryDate.id === payload.id) {
          return {
            ...expectedDeliveryDate,
            ...payload,
          };
        } else {
          return expectedDeliveryDate;
        }
      });
    case DELETE_EXPECTED_DELIVERY_DATE:
      return state.filter(({ id }) => id !== payload.id);
    default:
      return state;
  }
};
export default expectedDeliveryDateReducer;