import {
    CREATE_VENDOR_PAIDINCENTIVE,
    RETRIEVE_VENDOR_PAIDINCENTIVE,
    UPDATE_VENDOR_PAIDINCENTIVE,
    DELETE_VENDOR_PAIDINCENTIVE
} from "../VendorPaidIncentive/actiontype";

const INITIAL_STATE = {
  currentIntegration: [],
  isLoading: false,
};

const vendorPaidIncentiveReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  
  switch (type) {
    case CREATE_VENDOR_PAIDINCENTIVE:
      return {
        ...state
      };
    case RETRIEVE_VENDOR_PAIDINCENTIVE:
      return {
        ...state,
        currentIntegration: payload,
        isLoading: true,
      };
    case UPDATE_VENDOR_PAIDINCENTIVE:
      return state.map((vendorPaidIncentive) => {
        if (vendorPaidIncentive.id === payload.id) {
          return {
            ...vendorPaidIncentive,
            ...payload,
          };
        } else {
          return vendorPaidIncentive;
        }
      });
    case DELETE_VENDOR_PAIDINCENTIVE:
      return state.filter(({ id }) => id !== payload.id);
    default:
      return state;
  }
};
export default vendorPaidIncentiveReducer;