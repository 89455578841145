import React, { useState,  useRef, useEffect } from 'react';
import { Row, Col, Container, Card, Modal, Button } from 'react-bootstrap';
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import KErrorMessage from "../../../components/ErrorMessage/KErrorMessage";
import PreviewImage from "../../../components/UploadImage/PreviewImage";
import MaterialTable from 'material-table';
import { connect } from "react-redux";
import { fabric, getFabric, deleteFabric, getFabricImage, updateFabricImage } from '../../../redux/Product/Fabric/action';
import Loader from '../../../components/Loader/Loader';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';

const SUPPORTED_FORMATS =["image/jpg", "image/jpeg", "image/png"];

const validationSchema = yup.object({
  name: yup.string().required("Fabric Name is Required!"),
  image: yup
  .mixed()
  .nullable()
  .required("Fabric Image is Required!")
  .test(
    "FILE_SIZE",
    "Uploaded file is too big.",
    (value) => !value || (value && value.size <= 1024*1024)
  )
  .test(
    "FILE_FORMAT",
    "Uploaded file has supported format.",
    (value) => !value || (value && SUPPORTED_FORMATS.includes(value?.type))
  )

});

const validationSchema2 = yup.object({
  image: yup
  .mixed()
  .nullable()
  .required("Fabric Image is Required!")
  .test(
    "FILE_SIZE",
    "Uploaded file is too big.",
    (value) => !value || (value && value.size <= 1024*1024)
  )
  .test(
    "FILE_FORMAT",
    "Uploaded file has supported format.",
    (value) => !value || (value && SUPPORTED_FORMATS.includes(value?.type))
  )

});

const Fabric = ({ fabric,getFabric,currentIntegration, deleteFabric, isLoading, getFabricImage, fabricImage, updateFabricImage }) =>  {  useEffect(() => {
  getFabric();
}, []);

const [loading, setLoading] = useState(false);
useEffect(() => {
    if (isLoading) {
        setLoading(true);
    }
  });

  const deleteRow = (e) => {
    //
    deleteFabric(e);
  };

  const [tableData, setTableData] = useState([]);
    useEffect(() => {
      if(currentIntegration)
      {
        setTableData(currentIntegration); 
      }
    }, [currentIntegration])

const columns = [
  { title: 'Image', field: 'image', render: rowData => <img src={process.env.REACT_APP_IMAGE_URL+'/'+rowData.image} alt={rowData.image} style={{width: 50, height:50}}/> },
  { title: "Fabric", field: "name" },
]
   
const fileRef = useRef(null);

const [show, setShow] = useState(false);
const modalClose = () => setShow(false);
const modalShow = () => setShow(true);

const editImage = async (e) => {
  console.log('view:'+e);
    setShow({
    isOpen: true,
    })
     getFabricImage(e);
}

 
  return (
    <>
      <Container fluid className="main-content">
        <Row>
        <Col lg={"6"} className="mb-3">
            <Card>
              <Card.Body>
                <Card.Title>Add Fabric</Card.Title>
                <Formik
                  validationSchema={validationSchema}
                  initialValues={{
                    name: "",
                    image:""
                  }}
                  onSubmit={(values) => {
                    fabric(values);
                  }}
                >
                  {({values, setFieldValue}) => (
                  <Form>
                    <Row>
                      <Col sm={12}>
                        <label>Fabric Name</label>
                        <Field name="name" type="text" className="form-control" />
                        <KErrorMessage name="name" />
                      </Col>
                      <Col sm={12}>
                        <label className='d-block'>Upload</label>
                        <div  className='d-flex'>
                            <input
                              ref={fileRef}
                              className="form-control"
                              type="file"
                              name="image"
                              onChange={(event) => {setFieldValue("image", event.target.files[0]);}}
                            />
                             <span className='upload-img'>{values.image && <PreviewImage file={values.image}/>}</span>
                             <button className="btn-upload btn mt-3" hidden onClick={() => {fileRef.current.click();}}>Upload</button>
                        </div>
                        <KErrorMessage name="image"/>
                      </Col>
                    </Row>
                    <div className="mt-3 float-right">
                      <button className="btn btn-primary btn-md" type="submit">Submit</button>
                    </div>
                  </Form>
                   )}
                </Formik>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={"6"} className='mr-2'>
            <div style={{display: 'table', tableLayout:'fixed', width:'100%'}}>
              {loading ?
            <MaterialTable columns={columns} data={tableData}
              editable={{

                onRowUpdate: (newRow, oldRow) => new Promise((resolve, reject) => {
                  const updatedData = [...tableData]
                  updatedData[oldRow.tableData.id] = newRow
                  setTableData(updatedData)
                  setTimeout(() => resolve(), 500)
                }),
                onRowDelete: (selectedRow) => new Promise((resolve, reject) => {
                  deleteRow(selectedRow.id)
                  setTimeout(() => resolve(), 1000)
                })
              }}

              options={{
                sorting: true, search: true,
                paging: true, pageSizeOptions: [10, 25, 50, 100], pageSize: 10,
                exportButton: true,
                exportAllData: true, exportFileName: "Fabric", actionsColumnIndex: -1,
                autoheight: true,
                // headerStyle: { background: "#f44336",color:"#fff"}
              }}
              actions={[
                {
                  icon: () => <InsertPhotoIcon />,
                  tooltip: 'Edit Fabric Image',
                  onClick: (event, rowData) => editImage(rowData.id)
                }
              ]}
              title="Fabric"
            />
            : <Loader/> }
            </div>
          </Col>
          
        </Row>
      </Container>

      {/* Edit Fabric Image Modal Start */}
      <Modal 
          size="md"
          centered
          show={show} 
          onHide={modalClose}>
            <Formik
                 enableReinitialize={true}
                 validationSchema={validationSchema2}
                initialValues={{
                id: fabricImage.id,
                image: null,
                }}
                onSubmit={(values) => {
                  console.log(values);
                  updateFabricImage(values);
                }}
            >
              {({values, setFieldValue}) => (
                <Form>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className='mb-3'>
                    <h6>Uploaded Image</h6>
                    <img src={process.env.REACT_APP_IMAGE_URL+'/'+fabricImage.image} alt={fabricImage.image} width="100px" height="100px"  />
                  </div>
                    <label className='d-block'>Edit Image</label>
                      <div  className='d-flex'>
                          <input
                            ref={fileRef}
                            className="form-control"
                            type="file"
                            name="image"
                            onChange={(event) => {setFieldValue("image", event.target.files[0]);}}
                          />
                            <span className='upload-img'>{values.image && <PreviewImage file={values.image}/>}</span>
                          <button className="btn-upload btn mt-3" hidden onClick={() => {fileRef.current.click();}}>Upload</button>
                      </div>
                      <KErrorMessage name="image"/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={modalClose}>Close</Button>
                    <Button variant="primary" type="submit">Submit</Button>
                </Modal.Footer>
                </Form>
              )}
            </Formik>
        </Modal>
        
    </>
  )
}

const mapStateToProps = (state) => {
  
  return {
     currentIntegration: state.fabric.currentIntegration,
     fabricImage: state.fabric.fabricImage,
     isLoading: state.fabric.isLoading,
   }
 }

 export default connect(mapStateToProps,{fabric,getFabric,deleteFabric,getFabricImage,updateFabricImage})(Fabric);