export const ORDER_LIST = "ORDER_LIST";
export const ORDER_LIST_NAME = "ORDER_LIST_NAME";
export const MEMBER_LIST = "MEMBER_LIST";
export const REMAINING_MEMBER_LIST = "REMAINING_MEMBER_LIST";
export const NO_OF_MEMBER = "NO_OF_MEMBER";
export const PAYMENT_DETAIL = "PAYMENT_DETAIL";
export const REMAINING_AMOUNT = "REMAINING_AMOUNT";
export const PAYMENT = "PAYMENT";
export const CONVERSATION = "CONVERSATION";
export const LATEST_ORDER_ID = "LATEST_ORDER_ID";
export const LATEST_CHAT_DETAIL = "LATEST_CHAT_DETAIL";
export const UPDATE_DATE = "UPDATE_DATE";
export const ADD_PAYMENT = "ADD_PAYMENT";
export const EXIT_GROUP = "EXIT_GROUP";
export const SET_USERTYPE = "SET_USERTYPE";
export const SET_DISPATCH_ORDER = "SET_DISPATCH_ORDER";
export const EXPORT_CHAT = "EXPORT_CHAT";
