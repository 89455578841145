import {
  RETRIEVE_CP_PRODUCT,
} from "../Product/actiontype";

const INITIAL_STATE = {
  currentIntegration: [],
  isLoading: false,
};

const cpProductReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
 
  switch (type) {
    case  RETRIEVE_CP_PRODUCT:
      return {
        ...state,
        currentIntegration: payload,
        isLoading: true,
      };
      default:
        return state;
  }
};
export default cpProductReducer;