import * as React from "react";
import { useState, useEffect } from 'react'
import { Row, Col, Container, Dropdown, Badge } from 'react-bootstrap';
import MaterialTable from 'material-table';
import CheckIcon from '@mui/icons-material/Check';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PrintIcon from '@mui/icons-material/Print';
import { connect } from "react-redux";
import {getViewOrder, orderMarkAsDone} from '../../../redux/BookOrder/ViewOrder/action';
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import Loader from "../../../components/Loader/Loader";
import { useNavigate } from 'react-router-dom';


const ViewOrder = ({ getViewOrder,currentIntegration, orderMarkAsDone, isLoading }) =>  {  useEffect(() => {
    getViewOrder();
}, []);

const [visible, setVisible] = useState(false);
const handleVisibility = () => {
    setVisible(prev => !prev);
};

const [loading, setLoading] = useState(false);
useEffect(() => {
    if (isLoading) {
        setLoading(true);
    }
  });

const [show, setShow] = useState(false);
const modalClose = () => setShow(false);
const modalShow = () => setShow(true);

const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', type: ''})

const [tableData, setTableData] = useState([]);
useEffect(() => {
if(currentIntegration)
{
    setTableData(currentIntegration); 
}
}, [currentIntegration])

const columns = [
    { title: "Order No.", field: "orderNo" },
    { title: "Customer Name", field: "customerName" },
    { title: "Sale Executive", field: "saleExecutive" },
    { title: "Quantity", field: "qty" },
    { title: "Date Of Order", field: "date" },
    { title: "Expected Delivery Date", field: "edd" },
]

const markasdone = async (e,f) => {
    const data = {
        id: e,
        conv_id: f
    }
    setConfirmDialog({
    isOpen: true,
    title: 'Are You Sure To Mark As Done this Order?',
    type: true,
    onConfirm: () => { 
        orderMarkAsDone(data);
        setConfirmDialog({ ...confirmDialog, isOpen: false })
    }
    })
}
const navigate = useNavigate();
const viewOrder = async (e) => {
    navigate('/view-order-other/'+e);
}
const printOrder = async (e) => {
    navigate('/book-order-print');
}
    return (
        <>
        <Container fluid className="main-content">
                <Row>
                    <Col className='d-flex justify-content-between mb-2'>
                        <h3 className="main-title">View Order</h3>
                        <div className="d-flex justify-content-between d-block d-sm-none">
                            <div className="magnifierContainer">
                                <button type="button" className="btn btn-md btn-warning text-white roundedcircle mr-3" onClick={handleVisibility}><i className="fa-solid fa-search"></i></button>
                            </div>
                        </div>
                    </Col>
                    <Col lg={"12"} className='mb-2 d-block d-sm-none'>
                        <div className={visible ? "open" : "close"}>
                            <input placeholder="Search..." type="text" className="form-control is-open mr-3 mb-0 search-input"/>
                        </div>
                    </Col>
                </Row>
                { loading ? <div>
                <Row className="d-none d-sm-block mt-2">
                    <Col lg={"12"}>
                        <div style={{ display: 'table', tableLayout: 'fixed', width: '100%' }}>
                       <MaterialTable columns={columns} data={tableData}
                                actions={[
                                    {
                                      icon: () => <VisibilityIcon color={'primary'} />,
                                      tooltip: 'View',
                                      onClick: (event, rowData) => viewOrder(rowData.id)
                                    },
                                    {
                                      icon: () => <CheckIcon color={'success'} />,
                                      tooltip: 'Mark As Done',
                                      onClick: (event, rowData) => markasdone(rowData.id, rowData.conv_id)
                                    },
                                    {
                                      icon: () => <PrintIcon color={'warning'} />,
                                      tooltip: 'print',
                                      onClick: (event, rowData) => printOrder(rowData.id)
                                    }
                                ]}
                                options={{
                                    sorting: true, search: true,
                                    paging: true, pageSizeOptions: [10, 25, 50, 100], pageSize: 10,
                                    exportButton: true,
                                    exportAllData: true, exportFileName: "View Order", actionsColumnIndex: -1,
                                    // headerStyle: { background: "#f44336",color:"#fff"}
                                }}
                                title="View Order"
                            />
                            
                        </div>
                    </Col>
                </Row>
                <Row className="d-block d-sm-none">
                {currentIntegration.map((book, index) => (
                    <Col xs={"12"}>
                        <div className="card mb-2">
                            <div className="card-header d-flex justify-content-between pb-0">
                                <h6>{book.orderNo} <Badge bg="danger" text="light" className="ml-2">{book.qty}</Badge></h6>
                                <Dropdown>
                                <Dropdown.Toggle variant="light" id="dropdown-basic">
                                    <i className="ri-more-2-line"></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => viewOrder(book.id)}><VisibilityIcon color={'primary'} /> View</Dropdown.Item>
                                    <Dropdown.Item onClick={() => markasdone(book.id, book.conv_id)}><CheckIcon color={'success'} /> Mark As Done</Dropdown.Item>
                                    <Dropdown.Item onClick={() => printOrder(book.id)}><PrintIcon color={'warning'} /> Print</Dropdown.Item>
                                </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className="card-body pt-1">
                                <p className="card-text mb-0"><span className="quotation-info">Customer Name:</span> {book.customerName}</p>
                                <p className="card-text mb-0"><span className="quotation-info">Sale Executive:</span> {book.saleExecutive}</p>
                                <p className="card-text mb-0"><span className="quotation-info">Order Date:</span> {book.date}</p>
                                <p className="card-text mb-0"><span className="quotation-info">Delivery Date:</span> {book.edd}</p>
                            </div>
                        </div>
                    </Col>
                ))}
                </Row>
                </div>
                : <Loader/> }
            </Container>
            <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
            />
           
        </>

    )
}

const mapStateToProps = (state) => {
  
  return {
     currentIntegration: state.viewOrder.currentIntegration,
     isLoading: state.viewOrder.isLoading,
   }
 }

 export default connect(mapStateToProps,{getViewOrder, orderMarkAsDone})(ViewOrder);