import React from 'react'

import { Row, Col, Container, Button, Card, Badge, Dropdown, Modal } from 'react-bootstrap';
import { Formik, Form, Field } from "formik";
import Select from 'react-select';
import {useState,useEffect} from 'react';
import { connect } from "react-redux";
import { getCordinator,getReffered,getProduct,getLocation,getProductName,getProductNameOrder,getEditReady,getEditOrder,getEditProductName,getEditProductNameOrder,deleteReady,deleteOrder} from '../../../redux/Quotation/CreateQuotation/action';
import { getQuotation,updateQuotation,onChangeCalcReady,addImportedReady,onChangeCalcOrder,addImportedOrder} from '../../../redux/Quotation/EditQuotation/action';

  localStorage.setItem('qty', 0);
  localStorage.setItem('netprice', 0);

  const EditQuotation = ({ getCordinator,cordinators,getReffered,reffereds,getQuotation,quotation_detail,quotation_name,imported_ready,imported_order,updateQuotation,getProduct,getLocation,getProductName,addImportedReady,addImportedOrder,getProductNameOrder,products,locations,products_name,ready_nettotal,onChangeCalcReady,colour_code,leather_type,onChangeCalcOrder,order_nettotal,product_order_name,getEditReady,getEditOrder,getEditReadyProduct,editReadyNetamount,getEditOrderProduct,editOrderNetamount,getEditProductName,getEditProductNameOrder,edit_product_name,edit_product_name_ready,edit_product_name_order,branch_detail,subtotal_value,packing_value,fitting_value,grand_total_value,totalamount_value }) => { useEffect(() => {
    const quotation_id = window.location.pathname.split("/").pop();
    getQuotation(quotation_id);
    getCordinator();
    getReffered();
    getProduct();
    getLocation();
}, []);

const [cordinatorList, setCordinatorList] = useState(null);
const [refferedList, setrefferedList] = useState(null);
const [productList, setProductList] = useState(null);
const [locationList, setLocationList] = useState(null);

useEffect(() => {
  if(cordinators)
  {
    setCordinatorList(cordinators); 
  }
}, [cordinators])

useEffect(() => {
  if(reffereds)
  {
    setrefferedList(reffereds); 
  }
}, [reffereds])

useEffect(() => {
  if(products)
  {
    setProductList(products); 
  }
}, [products])

useEffect(() => {
  if(locations)
  {
    setLocationList(locations); 
  }
}, [locations])

  const [show, setShow] = useState(false);
  const modalAddClose = () => setShow(false);
  const modalAddShow = () => setShow(true);

  const [show2, setShow2] = useState(false);
  const modalEditClose = () => setShow2(false);
  const modalEditShow = () => setShow2(true);

  const [show3, setShow3] = useState(false); //imported edit order
  const modalClose3 = () => setShow3(false);
  const modalShow3 = () => setShow3(true);

  const [show4, setShow4] = useState(false); //edit imported Ready
  const modalClose4 = () => setShow4(false);
  const modalShow4 = () => setShow4(true);

//Ready Product
  const getProductDetail = (e) =>
  {
    getProductName(e.value);
    localStorage.setItem('product_id',e.value);
  }

  const getLocationDetail = (e) =>
  {
    localStorage.setItem('location',e.value);
  }
  
  const readySetForm = (e) => {
    var name = e.target.name;
    if(name == 'qty')
    {
      localStorage.setItem('editqty',e.target.value);
    }
    else if(name == 'net_price')
    {
      localStorage.setItem('editnetprice',e.target.value);
    }
    onChangeCalcReady();
  }

  //Order Section Start

  const getProductDetailOrder = (e) =>
  {
    getProductNameOrder(e.value);
    localStorage.setItem('product_id',e.value);
  }

  const orderSetForm = (e) => {
    var name = e.target.name;
    if(name == 'qty')
    {
      localStorage.setItem('editqty',e.target.value);
    }
    else if(name == 'net_price')
    {
      localStorage.setItem('editnetprice',e.target.value);
    }
    onChangeCalcOrder();
  }
  
  const deleteReadyProduct = (e) => {
    deleteReady(e);
  }

  const deleteOnOrder = (e) => {
    deleteOrder(e);
  }

  const editReadyProduct = async (e) => {
    getEditReady(e);
      setShow3({
      isOpen: true,
      })
  }

  const editOnOrder = async (e) => {
    console.log(e);
    getEditOrder(e);
      setShow4({
      isOpen: true,
      })
  }

  const editSetForm = (e) => {
    var name = e.target.name;
    if(name == 'qty')
    {
      localStorage.setItem('editqty',e.target.value);
    }
    else if(name == 'netprice')
    {
      localStorage.setItem('editnetprice',e.target.value);
    }
    onChangeCalcReady();
  }
  
  const editOrderSetForm = (e) => {
    var name = e.target.name;
    if(name == 'qty')
    {
      localStorage.setItem('editqty',e.target.value);
    }
    else if(name == 'netprice')
    {
      localStorage.setItem('editnetprice',e.target.value);
    }
    onChangeCalcOrder();
  }

  const getEditProductDetail = (e) =>
  {
    getEditProductName(e.value);
    localStorage.setItem('product_id',e.value);
  }
  
  const getEditProductDetailOrder = (e) =>
  {
    getEditProductNameOrder(e.value);
    localStorage.setItem('product_id',e.value);
  }
  return (
    <>
      <Container fluid className="main-content">
      < Row>
          <Col className='mb-2'>
              <h3 className="main-title">Edit Quotation</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                  quotation: "",
                  deliverydate: quotation_detail.date,
                  coordinator: quotation_detail.cordinator,
                  refferedby: quotation_detail.reffered_by,
                  name: quotation_detail.name,
                  mobile: quotation_detail.mobile_no,
                  email: quotation_detail.email,
                  address: quotation_detail.address,
                  subtotal: subtotal_value,
                  packing: packing_value,
                  fitting: fitting_value,
                  grandtotal: grand_total_value,
                  total: totalamount_value,
                  }}
                  onSubmit={(values) => {
                    //console.log(values);
                    updateQuotation(values);
                  }}
                >
                  {({ values }) => (
                    <Form>
                      <Row>
                        <Col lg={4} sm={12}>
                          <label>Quotation</label>
                          <Field name="quotation" type="text" className="form-control" readOnly value={quotation_name}/>
                        </Col>
                        <Col lg={{ span: 4, offset: 4 }} sm={12}>
                          <label>Date</label>
                          <Field name="deliverydate" type="date" className="form-control" readOnly value={quotation_detail.date}/>
                        </Col>
                        <Col lg={12}>
                          <div className="line"></div>
                        </Col>
                        <Col lg={12} className='mb-2'>
                            <h5>Consultant:</h5>
                          </Col>
                          <Col lg={4} sm={12}>
                            <label>Cordinator</label>
                            <p >{quotation_detail.cordinator_name},{quotation_detail.cordinator}</p>
                            <Select 
                            defaultValue={{ label: quotation_detail.cordinator_name, value: quotation_detail.cordinator }}
                            options={cordinatorList}
                            name="coordinator" 
                            className='mb-2' 
                          />
                          </Col>
                          <Col lg={{ span: 4, offset: 4 }} sm={12}>
                            <label>Reffered By</label>
                            <p >{quotation_detail.reffered_by_name},{quotation_detail.reffered_by}</p>
                            <Select 
                            defaultValue={{ label: quotation_detail.reffered_by_name, value: quotation_detail.reffered_by }}
                            options={refferedList}
                            name="refferedby" 
                            className='mb-2' 
                          />
                          </Col>
                          <Col lg={12} className='mt-2'>
                            <div className="line"></div>
                          </Col>
                          <Col lg={12} sm={12} className='mb-2'>
                              <h5>Customer Detail:</h5>
                          </Col>
                        <Col lg={3} sm={12}>
                          <label>Customer Name</label>
                          <Field name="name" type="text" className="form-control" />
                        </Col>
                        <Col lg={3} sm={12}>
                          <label>Customer Mobile</label>
                          <Field name="mobile" type="text" className="form-control"/>
                        </Col>
                        <Col lg={3} sm={12}>
                          <label>Customer Email</label>
                          <Field name="email" type="text" className="form-control"/>
                        </Col>
                        <Col lg={3} sm={12}>
                          <label>Address</label>
                          <Field name="address" type="text" className="form-control"/>
                        </Col>
                        <Col lg={12}>
                          <div className="line"></div>
                        </Col>
                      </Row>
                      <Row className='mb-2'>
                        <Col>
                          <div className='d-flex justify-content-between'>
                            <h5>Imported Ready Product</h5>
                            <button type="button" className="btn btn-md btn-info text-white" onClick={modalAddShow} style={{float: 'right'}}><i className="fa-solid fa-plus"></i> Add</button>
                          </div>
                        </Col>
                      </Row>
                      <Row className='mt-4'>
                      {imported_ready && imported_ready.map((quot, index) => (
                       <Col lg={3} sm={"12"} key={index}>
                          <div className="card product mb-2">
                            <div className="card-header d-flex justify-content-between pb-0">
                                <h6 className="mb-0"><span className="card-title">{quot.name}-{quot.model_id}</span> <Badge bg="info" text="light" className="ml-2">{quot.qty}</Badge></h6>
                              {/* <Dropdown>
                                <Dropdown.Toggle variant="light" id="dropdown-basic">
                                  <i className="ri-more-2-line"></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item onClick={() => editReadyProduct(quot.id)}><i className="ri-edit-box-line text-primary"></i> Edit</Dropdown.Item>
                                  <Dropdown.Item onClick={() => deleteReadyProduct(quot.id)}><i className="far fa-trash-alt text-danger"></i> Delete</Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown> */}
                            </div>
                            <div className="card-body pt-1">
                              <Row>
                                <Col lg={4}>
                                  <div>
                                    <img src={process.env.REACT_APP_IMAGE_URL+'/'+quot.image} alt={quot.image} style={{width: 50, height:50}} />
                                  </div>
                                </Col>
                                <Col lg={8}>
                                  <p className="card-text mb-0"><span className="product-info">Category:</span> {quot.category}</p>
                                  <p className="card-text mb-0"><span className="product-info">Quoted Price:</span> {quot.quoted_price}</p>
                                  <p className="card-text mb-0"><span className="product-info">Net Price:</span> {quot.net_price}</p>
                                  <p className="card-text mb-0"><span className="product-info">Net Amount:</span> {quot.net_amount}</p>
                                </Col>
                                <Col lg={12} className='mt-1'>
                                  <p className="card-text mb-0"><span className="product-info">Color Code:</span> {quot.colour_code}</p>
                                  <p className="card-text mb-0"><span className="product-info">Leather Type:</span> {quot.leather_type}</p>
                                  <p className="card-text mb-0"><span className="product-info">Product Location:</span> {quot.location}</p>
                                  <p className="card-text mb-0"><span className="product-info">Remarks:</span> {quot.remarks}</p>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Col>
                         ))}
                        <Col lg={12}>
                          <div className="line"></div>
                        </Col>
                      </Row>
                      <Row className='mb-2'>
                        <Col>
                          <div className='d-flex justify-content-between'>
                            <h5>Imported On Order</h5>
                            <button type="button" className="btn btn-md btn-info text-white" onClick={modalEditShow} style={{float: 'right'}}><i className="fa-solid fa-plus"></i> Add</button>
                          </div>
                        </Col>
                      </Row>
                      <Row className='mt-4'>
                      {imported_order && imported_order.map((quot, index) => (
                       <Col lg={3} sm={"12"} key={index}>
                          <div className="card product mb-2">
                            <div className="card-header d-flex justify-content-between pb-0">
                                <h6 className="mb-0"><span className="card-title">{quot.name}-{quot.model_id}</span> <Badge bg="info" text="light" className="ml-2">{quot.qty}</Badge></h6>
                              {/* <Dropdown>
                                <Dropdown.Toggle variant="light" id="dropdown-basic">
                                  <i className="ri-more-2-line"></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item onClick={() => editOnOrder(quot.id)}><i className="ri-edit-box-line text-primary"></i> Edit</Dropdown.Item>
                                  <Dropdown.Item onClick={() => deleteOnOrder(quot.id)}><i className="far fa-trash-alt text-danger"></i> Delete</Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown> */}
                            </div>
                            <div className="card-body pt-1">
                              <Row>
                                <Col lg={4}>
                                  <div>
                                    <img src={process.env.REACT_APP_IMAGE_URL+'/'+quot.image} alt={quot.image} style={{width: 50, height:50}} />
                                  </div>
                                </Col>
                                <Col lg={8}>
                                  <p className="card-text mb-0"><span className="product-info">Category:</span> {quot.category}</p>
                                  <p className="card-text mb-0"><span className="product-info">Quoted Price:</span> {quot.quoted_price}</p>
                                  <p className="card-text mb-0"><span className="product-info">Net Price:</span> {quot.net_price}</p>
                                  <p className="card-text mb-0"><span className="product-info">Net Amount:</span> {quot.net_amount}</p>
                                </Col>
                                <Col lg={12} className='mt-1'>
                                  <p className="card-text mb-0"><span className="product-info">Color Code:</span> {quot.colour_code}</p>
                                  <p className="card-text mb-0"><span className="product-info">Leather Type:</span> {quot.leather_type}</p>
                                  <p className="card-text mb-0"><span className="product-info">Product Location:</span> {quot.location}</p>
                                  <p className="card-text mb-0"><span className="product-info">Remarks:</span> {quot.remarks}</p>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Col>
                         ))}

                        <Col lg={12}>
                          <div className="line"></div>
                        </Col>
                      </Row>
                      <Row className='mb-2'>
                        <Col>
                        <h5>Billing</h5>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6}>
                          <table className="table table-invoice table-bordered">
                            <tbody>
                              <tr>
                                <td width="60%">
                                  <p><strong>BRANCH DETAIL:</strong></p>
                                  <p className="m-0"> <strong> BENEFICIARY NAME:</strong> <span>{branch_detail.name}</span></p>
                                    <p className="m-0"> <strong> ACCOUNT NO:</strong> <span>{branch_detail.account_no}</span></p>
                                    <p className="m-0"> <strong> IFSC :</strong> <span>{branch_detail.ifsc}</span></p>
                                    <p> <strong> BANK NAME :</strong> <span>{branch_detail.bank_name}</span></p>
                                </td>
                                <td width="40%">
                                    <p><strong>TERMS AND CONDITIONS:</strong></p>
                                    <p className="m-0"> CARTAGE AND PACKING INCLUDED</p>
                                    <p className="m-0">TRANSPORTATION EXTRA</p>
                                    <p className="m-0"> 50% ADVANCE REQUIRED</p>
                                    <p className="m-0">BALANCE 50% BEFORE DELIVERY </p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Col>
                        <Col lg={6}>
                            <Row>
                            <Col lg={{ span: 2, offset: 2 }} sm={3} style={{textAlign: "right"}}>
                              <label>Sub Total</label>
                            </Col>
                            <Col lg={8} sm={9}>
                              <Field name="subtotal" type="number" className="form-control" readOnly/>
                            </Col>
                            <Col lg={{ span: 2, offset: 2 }} sm={3} style={{textAlign: "right"}}>
                              <label>Fitting</label>
                            </Col>
                            <Col lg={8} sm={9}>
                              <Field name="fitting" type="number" className="form-control"/>
                            </Col>
                            <Col lg={{ span: 2, offset: 2 }} sm={3} style={{textAlign: "right"}}>
                              <label>Total</label>
                            </Col>
                            <Col lg={8} sm={9}>
                              <Field name="total" type="number" className="form-control" readOnly/>
                            </Col>
                            {/* <Col lg={{ span: 2, offset: 2 }} sm={3} style={{textAlign: "right"}}>
                              <label>Discount</label>
                            </Col>
                            <Col lg={8} sm={9}>
                              <Field name="discount" type="number" className="form-control" readOnly/>
                            </Col> */}
                            <Col lg={{ span: 2, offset: 2 }} sm={3} style={{textAlign: "right"}}>
                              <label>Packing</label>
                            </Col>
                            <Col lg={8} sm={9}>
                              <Field name="packing" type="number" className="form-control" />
                            </Col>
                            <Col lg={{ span: 2, offset: 2 }} sm={3} style={{textAlign: "right"}}>
                              <label>Grand Total</label>
                            </Col>
                            <Col lg={8} sm={9}>
                              <Field name="grandtotal" type="number" className="form-control" readOnly/>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className='mt-4'>
                        <Col>
                          <Button variant="primary" type="submit" style={{float: 'right'}}> Submit </Button>
                        </Col>
                      </Row>
                  </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      {/* Imported Ready Product Modal Start */}
      <Modal 
          size="lg"
          centered
          show={show} 
          onHide={modalAddClose}>
        <Formik
            enableReinitialize={true}
            initialValues={{
            model: "",
            name: products_name,
            qty: "",
            quoted_price: "",
            net_price: "",
            net_amount: "",
            location_id: "",
            remarks: "",
            rndno:"",
            type: "Ready",
            }}
            onSubmit={(values) => {
              //console.log(values);
              addImportedReady(values);
              setShow(false);
            }}
        >
          {({values, setFieldValue}) => (
        <Form>
          <Modal.Header closeButton>
            <Modal.Title>Imported Ready Product</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg={12} sm={12} className="mb-2">
                <label>Model No.</label>
                <Select options={productList} name="model" onChange={(e) => getProductDetail(e)}/>
              </Col>
              <Col lg={6} sm={12}>
                <label>Name</label>
                <Field name="name" type="text" className="form-control"/>
              </Col>
              <Col lg={6} sm={12}>
                <label>Quantity</label>
                <Field name="qty" type="number" className="form-control" onKeyUp={(e) => { readySetForm(e);}}/>
              </Col>
              <Col lg={6} sm={12}>
                <label>QuotedPrice</label>
                <Field name="quoted_price" type="number" className="form-control" />
              </Col>
              <Col lg={6} sm={12}>
                <label>Net Price</label>
                <Field name="net_price" type="number" className="form-control" onKeyUp={(e) => { readySetForm(e);}}/>
              </Col>
              <Col lg={6} sm={12}>
                <label>Net Amount</label>
                <Field name="net_amount" type="number" className="form-control" readOnly value={ready_nettotal} />
              </Col>
              <Col lg={6} sm={12}>
                <label>Location of Product</label>
                <Select options={locationList} name="location_id" onChange={(e) => getLocationDetail(e)}/>
              </Col>
              <Col lg={6} sm={12}>
                <label>Remarks</label>
                <Field name="remarks" as="textarea" className="form-control" />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit">Submit</Button>
          </Modal.Footer>
        </Form>
            )}
        </Formik>
      </Modal>
      {/* Imported Ready Product Modal End */}
       {/* Edit Imported Ready Product Modal Start */}
       <Modal 
          size="lg"
          centered
          show={show3} 
          onHide={modalClose3}>
        <Formik
            enableReinitialize={true}
            initialValues={{
            id:getEditReadyProduct.id,
            model: getEditReadyProduct.model_id,
            name: edit_product_name,
            qty: edit_product_name_ready,
            quotedprice: getEditReadyProduct.quoted_price,
            netprice: getEditReadyProduct.net_price,
            netamount: getEditReadyProduct.net_amount,
            location: getEditReadyProduct.location_id,
            remarks: getEditReadyProduct.remarks,
            }}
            onSubmit={(values) => {
              console.log(values);
              //updateReady(values);
              setShow4(false);
            }}
        >
          {({values, setFieldValue}) => (
        <Form>
          <Modal.Header closeButton>
            <Modal.Title>Edit Imported Ready Product</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg={12} sm={12} className="mb-2">
                <label>Model No. - { getEditReadyProduct.model_name}</label>
                <Select options={productList} name="model" defaultValue={{ label: getEditReadyProduct.model_name, value: getEditReadyProduct.model_id }} onChange={(e) => getEditProductDetail(e)}/>
              </Col>
              <Col lg={6} sm={12}>
                <label>Name</label>
                <Field name="name" type="text" className="form-control" />
              </Col>
              <Col lg={6} sm={12}>
                <label>Quantity</label>
                <Field name="qty" type="text" className="form-control" onKeyUp={(e) => { editSetForm(e);}}/>
              </Col>
              <Col lg={6} sm={12}>
                <label>QuotedPrice</label>
                <Field name="quotedprice" type="text" className="form-control" />
              </Col>
              <Col lg={6} sm={12}>
                <label>Net Price</label>
                <Field name="netprice" type="text" className="form-control" onKeyUp={(e) => { editSetForm(e);}}/>
              </Col>
              <Col lg={6} sm={12}>
                <label>Net Amount</label>
                <Field name="netamount" type="text" className="form-control" readOnly value={editReadyNetamount}/>
              </Col>
              <Col lg={6} sm={12}>
                <label>Location of Product - { getEditReadyProduct.location} </label>
                <Select defaultValue={{ label: getEditReadyProduct.location, value: getEditReadyProduct.location_id }} options={locationList} name="location" />
              </Col>
              <Col lg={6} sm={12}>
                <label>Remarks</label>
                <Field name="remarks" as="textarea" className="form-control" />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit">Submit</Button>
          </Modal.Footer>
        </Form>
            )}
        </Formik>
      </Modal>
      {/* Edit Imported Ready Product Modal End */}
      {/* Imported On Order Modal Start */}
      <Modal 
          size="lg"
          centered
          show={show2} 
          onHide={modalEditClose}>
        <Formik
            enableReinitialize={true}
            initialValues={{
            model: "",
            name: product_order_name,
            colourcode: colour_code,
            leathertype: leather_type,
            qty: "",
            quoted_price: "",
            net_price: "",
            net_amount: "",
            remarks: "",
            type: "Order",
            session_id: ""
            }}
            onSubmit={(values) => {
              console.log(values);
              addImportedOrder(values);
              setShow2(false);
            }}
        >
          {({values, setFieldValue}) => (
        <Form>
          <Modal.Header closeButton>
            <Modal.Title>Imported on Order</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg={12} sm={12} className="mb-2">
                <label>Model No.</label>
                <Select options={productList} name="model"  onChange={(e) => getProductDetailOrder(e)}/>
              </Col>
              <Col lg={6} sm={12}>
                <label>Product Name</label>
                <Field name="name" type="text" className="form-control" />
              </Col>
              <Col lg={6} sm={12}>
                <label>Colour Code</label>
                <Field name="colourcode" type="text" className="form-control" />
              </Col>
              <Col lg={6} sm={12}>
                <label>Leather Type</label>
                <Field name="leathertype" type="text" className="form-control" />
              </Col>
              <Col lg={6} sm={12}>
                <label>Quantity</label>
                <Field name="qty" type="number" className="form-control"  onKeyUp={(e) => { orderSetForm(e);}}/>
              </Col>
              <Col lg={6} sm={12}>
                <label>QuotedPrice</label>
                <Field name="quoted_price" type="number" className="form-control" />
              </Col>
              <Col lg={6} sm={12}>
                <label>Net Price</label>
                <Field name="net_price" type="number" className="form-control"  onKeyUp={(e) => { orderSetForm(e);}}/>
              </Col>
              <Col lg={6} sm={12}>
                <label>Net Amount</label>
                <Field name="net_amount" type="number" className="form-control" readOnly value={order_nettotal}/>
              </Col>
              <Col lg={6} sm={12}>
                <label>Remarks</label>
                <Field name="remarks" as="textarea" className="form-control" />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit">Submit</Button>
          </Modal.Footer>
        </Form>
            )}
        </Formik>
      </Modal>
      {/* Edit Imported On Order Modal Start */}
      <Modal 
          size="lg"
          centered
          show={show4} 
          onHide={modalClose4}>
        <Formik
            enableReinitialize={true}
            initialValues={{
            id:getEditOrderProduct.id,
            model: getEditOrderProduct.model_id,
            name: edit_product_name_order,
            qty: getEditOrderProduct.qty,
            quotedprice: getEditOrderProduct.quoted_price,
            netprice: getEditOrderProduct.net_price,
            netamount: getEditOrderProduct.net_amount,
            location: getEditOrderProduct.location_id,
            remarks: getEditOrderProduct.remarks,
            }}
            onSubmit={(values) => {
              console.log(values);
             // updateOrder(values);
             setShow4(false);
            }}
        >
          {({values, setFieldValue}) => (
        <Form>
          <Modal.Header closeButton>
            <Modal.Title>Edit Imported on Order</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg={12} sm={12} className="mb-2">
                <label>Model No. - { getEditOrderProduct.model_name}</label>
                <Select options={productList} name="model" defaultValue={{ label: getEditOrderProduct.model_name, value: getEditOrderProduct.model_id }} onChange={(e) => getEditProductDetailOrder(e)}/>
              </Col>
              <Col lg={6} sm={12}>
                <label>Product Name</label>
                <Field name="name" type="text" className="form-control"/>
              </Col>
              <Col lg={6} sm={12}>
                <label>Colour Code</label>
                <Field name="colourcode" type="text" className="form-control" />
              </Col>
              <Col lg={6} sm={12}>
                <label>Leather Type</label>
                <Field name="leathertype" type="text" className="form-control" />
              </Col>
              <Col lg={6} sm={12}>
                <label>Quantity</label>
                <Field name="qty" type="text" className="form-control"  onKeyUp={(e) => { editOrderSetForm(e);}}/>
              </Col>
              <Col lg={6} sm={12}>
                <label>Quoted Price</label>
                <Field name="quotedprice" type="text" className="form-control" />
              </Col>
              <Col lg={6} sm={12}>
                <label>Net Price</label>
                <Field name="netprice" type="text" className="form-control"  onKeyUp={(e) => { editOrderSetForm(e);}}/>
              </Col>
              <Col lg={6} sm={12}>
                <label>Net Amount</label>
                <Field name="netamount" type="text" className="form-control" readOnly value={editOrderNetamount}/>
              </Col>
              <Col lg={6} sm={12}>
                <label>Remarks</label>
                <Field name="remarks" as="textarea" className="form-control" />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit">Submit</Button>
          </Modal.Footer>
        </Form>
            )}
        </Formik>
      </Modal>
    </>
    
  )
}

const mapStateToProps = (state) => {
  console.log(state);
  return {
    cordinators: state.quotationReducer.cordinator,
    reffereds: state.quotationReducer.reffered,
    quotation_detail: state.editQuotationReducer.quotations,
    imported_ready: state.editQuotationReducer.imported_ready,
    imported_order: state.editQuotationReducer.imported_order,
    quotation_name: state.editQuotationReducer.quotation_name,
    products: state.quotationReducer.products,
    locations: state.quotationReducer.locations,
    branch_detail: state.editQuotationReducer.branch_detail,
    products_name: state.quotationReducer.product_name.name,
    ready_nettotal: state.editQuotationReducer.ready_net_amount,
    product_order_name: state.quotationReducer.product_name.name,
    colour_code: state.quotationReducer.product_name.colour_code,
    leather_type: state.quotationReducer.product_name.leather_type,
    order_nettotal: state.editQuotationReducer.order_net_amount,
    getEditReadyProduct: state.quotationReducer.edit_ready_product,
    editReadyNetamount: state.editQuotationReducer.ready_net_amount,
    getEditOrderProduct: state.quotationReducer.edit_order_product,
    editOrderNetamount: state.editQuotationReducer.order_net_amount,
    edit_product_name: state.quotationReducer.edit_product_name,
    edit_product_name_ready: state.quotationReducer.edit_product_name_ready,
    edit_product_name_order: state.quotationReducer.edit_product_name_order,
    // subtotal_value: state.editQuotationReducer.sub_total_amount,
    // totalamount_value: state.editQuotationReducer.total_amount,
    // fitting_value: state.editQuotationReducer.fitting_amount,
    // packing_value: state.editQuotationReducer.packing_amount,
    // grand_total_value: state.editQuotationReducer.grand_total,
  }
}
 
export default connect(mapStateToProps,{getCordinator,getReffered,getQuotation,updateQuotation,getProduct,getLocation,getProductName,addImportedReady,addImportedOrder,getProductNameOrder,onChangeCalcReady,onChangeCalcOrder,getEditOrder,getEditReady,getEditProductName,getEditProductNameOrder})(EditQuotation);