import React from 'react';
import { Row, Col, Container, Card, Table, Modal, Button, Badge } from 'react-bootstrap';
import MaterialTable from 'material-table';
import {useState, useEffect} from 'react';
import Zoom from 'react-medium-image-zoom'
import { Link } from "react-router-dom";
import VisibilityIcon from '@mui/icons-material/Visibility';
import ProgressBar from "@ramonak/react-progress-bar";
import './dashboard.css';
import { Formik, Form, Field } from "formik";
import { Bar, Pie } from 'react-chartjs-2';
import { connect } from "react-redux";
import {getDueDelivery, getDueOrder, getBranchChart, getSaleOrderWise, getSaleIncentiveWise, getAchiever, getDashboard, getDueOrderDetail,getProductList, getStockDetail} from '../../redux/Dashboard/action';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, ArcElement, Title, Tooltip, Legend } from 'chart.js';
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
ChartJS.register(ArcElement, Tooltip, Legend);

const Dashboard = ({ getDueDelivery, currentIntegration, getDueOrder, dueorder, getBranchChart, branchchart, getSaleOrderWise, orderwise, getSaleIncentiveWise, incentivewise, getAchiever, achiever, getDashboard, dashboard, getDueOrderDetail, dueOrderDetail, getProductList, productList, isProductSearch, getStockDetail ,stockDetail}) =>  {  useEffect(() => {
 getDueDelivery();
 getDueOrder();
 
 getBranchChart();
 getSaleOrderWise();
 getSaleIncentiveWise();
 getAchiever();
 getDashboard();
}, []);

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
    },
  };
  const options2 = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
    },
  };

  const labels = orderwise.name;

  const labels2 = incentivewise.name;

  const data = {
    labels,
    datasets:[
      {
        label:'No. Of Order',
        data:orderwise.count,
        backgroundColor: '#8760fb',
      }
    ]
  };
  const data2 = {
    labels,
    datasets: [
      {
        label: 'Invoice',
        data:incentivewise.count,
        backgroundColor: '#eb6f33',
      },
      
    ],
  };

  const data3 = {
    labels: branchchart.name,
    datasets: [
      {
        label: '# of Votes',
        data: branchchart.total,
        backgroundColor: branchchart.color,
        borderColor: branchchart.color,
        borderWidth: 1,
      },
    ],
  };

  const [tableData2, setTableData2] = useState([]);
  useEffect(() => {
    if(dueorder)
    {
      setTableData2(dueorder); 
    }
  }, [dueorder])
    
  const columns2 = [
  { title: "Order No.", field: "orderNo" },
  { title: "Created By ", field: "createdBy" },
  { title: "Customer Name", field: "customerName" },
  { title: "Delivery Date", field: "deliveryDate" },
  { title: "Total Amount", field: "totalAmount" },
  { title: "Total Due", field: "totalDue" },
  ]
  
  const [tableData3, setTableData3] = useState([]);
  useEffect(() => {
    if(dueorder)
    {
      setTableData3(dueorder); 
    }
  }, [dueorder])
    
  const columns3 = [
  { title: "Order No.", field: "orderNo" },
  { title: "Created By ", field: "createdBy" }
  ]

  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    if(currentIntegration)
    {
      setTableData(currentIntegration); 
    }
  }, [currentIntegration])

  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const [show, setShow] = useState(false);
  const modalClose = () => setShow(false);
  const modalShow = () => setShow(true);
  
  const viewDueOrder = async (e) => {
    console.log('view:'+e);
      setShow({
      isOpen: true,
      })
      getDueOrderDetail(e);
  }
  const [show2, setShow2] = useState(false);
  const modalClose2 = () => setShow(false);
  const modalShow2 = () => setShow(true);
  
  const stockDetailList = async (e) => {
    setShow2({
        isOpen: true,
        })
    getStockDetail(e);
  }
  return(
    <>
      <Container fluid className="main-content">
        <Row>
          <Col className='mb-0'>
            <div className='d-flex justify-content-between'>
              <h3 className="main-title mb-3">Welcome {localStorage.getItem('name')}</h3>
              <button type="button" className="btn btn-md mr-3 mb-2" onClick={handleClick}><i className="fa-solid fa-search"></i></button>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
          {click ? 
            <div id="search">
              <button type="button" className="btn close" onClick={handleClick}><i className="fa-solid fa-close"></i></button>
              <Formik
                    initialValues={{
                    model: "",
                    }}
                    onSubmit={(values) => {
                      getProductList(values);
                      handleClick();
                    }}
                >
                {({ values }) => (
                    <Form>
                        <Field name="model" type="search" placeholder="Search A Product (Model No)" autoFocus />
                        <button type="submit" className="btn btn-primary">Search</button>
                    </Form>
                )}
              </Formik>
              {/* <Form>
                <Form.Group className="mb-3">
                  <Form.Control type="search" placeholder="Search A Product (Model No)" autoFocus />
                </Form.Group>
              </Form> */}
              
            </div>
            : null }
          </Col>
        </Row>
        <Row>
          <Col lg={3} sm={12} className='mb-2'>
            <Link to="/branch-wise-report">
            <Card>
              <Card.Body>
                <div className='d-flex justify-content-between'>
                  <p className='text-muted'>Total Turn Over</p>
                  <p className='text-primary'><i className="ri-line-chart-line"></i></p>
                </div>
                <Card.Title className='text-danger'>₹ {dashboard.total_turn_over}</Card.Title>
                <ProgressBar 
                  completed={80}
                  bgColor="#d30639"
                  height="6px"
                  borderRadius="0"
                  labelColor="transparent"
                />
              </Card.Body>
            </Card>
            </Link>
          </Col>
          <Col lg={3} sm={12} className='mb-2'>
            <Link to="/payment">
            <Card>
              <Card.Body>
                <div className='d-flex justify-content-between'>
                  <p className='text-muted'>Total Due</p>
                  <p className='text-info'><i className="ri-exchange-dollar-line"></i></p>
                </div>
                <Card.Title className='text-danger'>₹ {dashboard.total_due}</Card.Title>
                <ProgressBar 
                  completed={70}
                  bgColor="#03c895"
                  height="6px"
                  borderRadius="0"
                  labelColor="transparent"
                />
              </Card.Body>
            </Card>
            </Link>
          </Col>
          <Col lg={3} sm={12} className='mb-2'>
            <Link to="/payment">
            <Card>
              <Card.Body>
                <div className='d-flex justify-content-between'>
                  <p className='text-muted'>Collection</p>
                  <p className='text-warning'><i className="ri-wallet-2-line"></i></p>
                </div>
                <Card.Title className='text-danger'>₹ {dashboard.collection}</Card.Title>
                <ProgressBar 
                  completed={50}
                  bgColor="#01b8ff"
                  height="6px"
                  borderRadius="0"
                  labelColor="transparent"
                />
              </Card.Body>
            </Card>
            </Link>
          </Col>
          <Col lg={3} sm={12} className='mb-2'>
          <Link to="/approved-order">
            <Card>
              <Card.Body>
                <div className='d-flex justify-content-between'>
                  <p className='text-muted'>Total Running Order</p>
                  <p className='text-success'><i className="ri-truck-line"></i></p>
                </div>
                <Card.Title className='text-danger'>{dashboard.running_order}</Card.Title>
                <ProgressBar 
                  completed={40}
                  bgColor="#eb6f33"
                  height="6px"
                  borderRadius="0"
                  labelColor="transparent"
                />
              </Card.Body>
            </Card>
            </Link>
          </Col>
        </Row>
        { isProductSearch ? <div>
        <Row className='mt-4 mb-4'>
          <Col lg="12" className='mb-3'>
            <h5>Stock Query</h5>
          </Col>
          {productList.map((stock, index) => (
            <Col lg="3" className='mb-4' key={index}>
                <Card>
                <Zoom zoomMargin={40}><Card.Img variant="top" src={process.env.REACT_APP_IMAGE_URL+'/'+stock.image} alt={stock.image} height="180"/></Zoom>
                <Card.Body>
                    <Card.Title className='d-flex justify-content-between'>
                        <p className='mb-0'>{stock.model} <span><Badge bg="info">{stock.stock_qty}</Badge></span></p>   
                        <p className='mb-0'><Badge bg="secondary" className='mb-0'  onClick={() => stockDetailList(stock.id)}><i className="ri-eye-line"></i></Badge></p>
                    </Card.Title>
                    <p className="card-text mb-0"><span className="quotation-info">Product Category:</span>{stock.cateory}</p>
                    <p className="card-text mb-0"><span className="quotation-info">Leather Category:</span>{stock.leather_category}</p>
                    <p className="card-text mb-0"><span className="quotation-info">Leather Type:</span>{stock.leather_type}</p>
                    <p className="card-text mb-0"><span className="quotation-info">Leather Colour:</span>{stock.leather_colour}</p>
                    <p className="card-text mb-0"><span className="quotation-info">Item Colour:</span>{stock.colour_code}</p>
                </Card.Body>
                </Card>
            </Col>
              ))}
        </Row>
        </div> : null}
       <Row className='mt-4 mb-4'>
          <Col lg={"5"} className='mb-2'>
           <Card>
             <Card.Body>
             <Card.Title>
              <h6>Top Sales Executive (Order Wise)</h6>
             </Card.Title>
                <Bar options={options} data={data} />
             </Card.Body>
           </Card>
          </Col>
          <Col lg={"5"} className='mb-2'>
           <Card>
             <Card.Body>
             <Card.Title>
                <h6>Top Sales Executive (Incentive Wise)</h6>
             </Card.Title>
                <Bar options={options2} data={data2} />
             </Card.Body>
           </Card>
          </Col>
          <Col lg={"2"} className='mb-2'>
           <Card>
             <Card.Body>
             <Card.Title>
                <h6 className='text-center'>Last Month Achiever</h6>
             </Card.Title>
              <div className='text-center mt-2 mb-2'>
                <img src={process.env.REACT_APP_IMAGE_URL+'/'+achiever.pic} alt={achiever.pic} width="80" height="80" className="roundedcircle" />
              </div>
              <h5 className='text-center'>{achiever.name}</h5>
              <p className='text-muted text-center mt-2 mb-2'>Sales Executive</p>
              <p className="user-info-rating text-center mb-2">
                  <span><i className="fa fa-star text-warning"> </i></span>
                  <span><i className="fa fa-star text-warning"> </i></span>
                  <span><i className="fa fa-star text-warning"> </i></span>
                  <span><i className="fa fa-star text-warning"> </i></span>
                  <span><i className="far fa-star text-warning"> </i></span>
              </p>
              <div className='text-center'>
                  <p href="javascript:void(0);" className="btn ripple btn-primary btn-sm text-center mb-3"> Incentive: ₹ {achiever.incentive}</p>
              </div>
             </Card.Body>
             <Card.Footer className='p-0 bg-white'>
                <div className="row text-center">
                  <div className="col border-right">
                    <div className="description-block">
                      <h6 className="description-header">Orders</h6> <span className="text-muted">{achiever.count}</span> </div>
                  </div>
                  <div className="col">
                    <div className="description-block">
                      <h6 className="description-header">Volume</h6> <span className="text-muted">₹ {achiever.volume}</span> </div>
                  </div>
                </div>
             </Card.Footer>
           </Card>
          </Col>
       </Row>
       <Row className='mt-4 mb-4'>
          <Col lg={"4"} className='mb-2'>
           <Card>
             <Card.Body>
             <Card.Title>
              <h6>Branch Detail</h6>
             </Card.Title>
             <Pie data={data3} />
             </Card.Body>
           </Card>
          </Col>
          <Col lg={"8"} className='mb-2'>
           <Card>
             <Card.Body>
             <Card.Title>
              <h6>Due Delivery</h6>
             </Card.Title>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>ORDER NO</th>
                      <th>EDD</th>
                      <th>DUE FOR(DAYS)</th>
                    </tr>
                  </thead>
                  <tbody>
                  {currentIntegration.map((delivery, index) => (
                    <tr key={index}>
                      <td>{delivery.orderNo}</td>
                      <td>{delivery.edd}</td>
                      <td className='text-danger'>{delivery.days}</td>
                    </tr>
                  ))}
                  </tbody>
                </Table>
             </Card.Body>
           </Card>
          </Col>
        </Row>
        <Row className='d-none d-sm-block mt-4'>
          <Col lg={"12"}>
            <div style={{display: 'table', tableLayout:'fixed', width:'100%'}} className='dashboard-table'>
              <MaterialTable columns={columns2} data={tableData2}
                  options={{
                  sorting: true, search: true,
                  paging: true, pageSizeOptions: [10, 25, 50, 100], pageSize: 10,
                  exportButton: true,
                  exportAllData: true, exportFileName: "Order Dues", actionsColumnIndex: -1,
                  }}
                  // actions={[
                  //   {
                  //     icon: VisibilityIcon,
                  //     tooltip: 'View Order',
                  //     onClick: (event, rowData) => alert("You view " + rowData.orderNo)
                  //   }
                  // ]}
                  title="Order Dues"
              />
            </div>
          </Col>
        </Row>
        <Row className='d-block d-sm-none mt-4'>
          <Col lg={"12"}>
            <div style={{display: 'table', tableLayout:'fixed', width:'100%'}} className=''>
              <MaterialTable columns={columns3} data={tableData3}
                  options={{
                  sorting: true, search: true,
                  paging: true, pageSizeOptions: [10, 25, 50, 100], pageSize: 10,
                  exportButton: true,
                  exportAllData: true, exportFileName: "Order Dues", actionsColumnIndex: -1,
                  }}
                  actions={[
                    {
                      icon: () => <VisibilityIcon color={'primary'} />,
                      tooltip: 'View Order',
                      onClick: (event, rowData) => viewDueOrder(rowData.id)
                    }
                  ]}
                  title="Order Dues"
              />
            </div>
          </Col>
        </Row>
      </Container>
      
    {/* view Due Order Modal Start */}
    <Modal 
      size="md"
      centered
      show={show} 
      onHide={modalClose}>
        <Modal.Header closeButton>
            <Modal.Title>{dueOrderDetail.orderNo}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table striped bordered hover>
            <tbody>
              <tr>
                <td>Created By</td>
                <td>{dueOrderDetail.createdBy}</td>
              </tr>
              <tr>
                  <td>Customer Name</td>
                  <td>{dueOrderDetail.customerName}</td>
                </tr>
                <tr>
                  <td>Delivery Date</td>
                  <td>{dueOrderDetail.deliveryDate}</td>
                </tr>
                <tr>
                  <td>Total Amount</td>
                  <td>{dueOrderDetail.totalDue}</td>
                </tr>
                <tr>
                  <td>Total Due</td>
                  <td>{dueOrderDetail.totalAmount}</td>
                </tr>
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
            <Button variant="secondary" onClick={modalClose}>Close</Button>
        </Modal.Footer>
    </Modal>
    {/* view Due Order Modal End */}
    <Modal 
      size="md"
      centered
      show={show2} 
      onHide={modalClose2}
    >
            <Modal.Header closeButton>
                <Modal.Title>{stockDetail.model_no}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Branch Name</th>
                      <th>Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                  {stockDetail.stock && stockDetail.stock.map((detail, index) => (
                    <tr key={index}> 
                      <td>{detail.no}</td>
                      <td>{detail.name}</td>
                      <td>{detail.qty}</td>
                    </tr>
                  ))}
                 </tbody>
                </Table>
            </Modal.Body>
        </Modal>

    </>
  ) 
}

const mapStateToProps = (state) => {
console.log(state.dashboard.stockDetail.model_no);
  return {
     dashboard: state.dashboard.dashboard,
     currentIntegration: state.dashboard.currentIntegration,
     dueorder: state.dashboard.dueorder,
     dueOrderDetail: state.dashboard.singleDueOrder,
     branchchart: state.dashboard.branchchart,
     orderwise: state.dashboard.orderwise,
     incentivewise: state.dashboard.incentivewise,
     achiever: state.dashboard.achiever,
     productList: state.dashboard.productList,
     isProductSearch: state.dashboard.isProductSearch,
     stockDetail: state.dashboard.stockDetail,
   }
 }

 export default connect(mapStateToProps,{getDueDelivery, getDueOrder, getBranchChart, getSaleOrderWise, getSaleIncentiveWise, getAchiever, getDashboard, getDueOrderDetail, getProductList, getStockDetail})(Dashboard);
