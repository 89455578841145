import {
    CART_DETAIL,
    PLACE_ORDER
  } from "../PlaceOrder/actiontype";
  
  import api from "../../../api";
  import { toast } from 'react-toastify';

  let userid = JSON.parse(localStorage.getItem('userid'));

export const getCartOrder= (data) => async (dispatch) => {
    try {
        const res = await api.get(`/cp/viewCart/` +userid);
        dispatch({
            type:  CART_DETAIL,
            payload: res.data.data,
        });
    } catch (err) {    
        dispatch({
            type:  CART_DETAIL,
            payload: [],
        });
    }
};

export const placeOrder= (data) => async (dispatch) => {
    try {
        const res = await api.get(`/cp/placeOrder/` +userid);
        if (res.status === 200) {
            toast.success(res.data.message);
            dispatch({
                type:  PLACE_ORDER,
                payload: res.data.data,
            });
        }
    } catch (err) {    
        dispatch({
            type:  PLACE_ORDER,
            payload: [],
        });
    }
};