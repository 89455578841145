import {
  CREATE_UNAPPROVED_ORDERS,
  RETRIEVE_UNAPPROVED_ORDERS,
  UPDATE_UNAPPROVED_ORDERS,
  DELETE_UNAPPROVED_ORDERS,
  DECLINE_UNAPPROVED_ORDERS,
  GET_BRANCHES
} from "../UnapprovedOrder/actiontype";

const INITIAL_STATE = {
currentIntegration: [],
branches :[],
isLoading: false,
};

const unapprovedOrderReducer = (state = INITIAL_STATE, action) => {
const { type, payload } = action;

switch (type) {
  case CREATE_UNAPPROVED_ORDERS:
    return {
      ...state
    };
  case RETRIEVE_UNAPPROVED_ORDERS:
    return {
      ...state,
      currentIntegration: payload,
      isLoading: true,
    };
  case GET_BRANCHES:
    return{
      ...state,
      branches:payload,
    }
  case UPDATE_UNAPPROVED_ORDERS:
    return state.map((unapprovedOrder) => {
      if (unapprovedOrder.id === payload.id) {
        return {
          ...unapprovedOrder,
          ...payload,
        };
      } else {
        return unapprovedOrder;
      }
    });
  case DECLINE_UNAPPROVED_ORDERS:
    return {
      ...state,
      currentIntegration: state.currentIntegration.filter((item) => parseInt(item.id) !== parseInt(payload)),
    };
  case DELETE_UNAPPROVED_ORDERS:
    return {
      ...state,
      currentIntegration: state.currentIntegration.filter((item) => parseInt(item.id) !== parseInt(payload)),
    };
  default:
    return state;
}
};
export default unapprovedOrderReducer;