import {
    LOGIN,
} from "../Authentication/actiontype";

//import { toast } from 'react-toastify';
import api from "../../api";
import { toast } from 'react-toastify';

export const chkUser= (data) => async (dispatch) => {
    const body = JSON.stringify(data);
    try {
      const res = await api.post(`/login`,body);
     console.log(res);
      //
      localStorage.setItem('userid', res.data.data.id);
      localStorage.setItem('usertype', res.data.data.usertype_id);
      localStorage.setItem('name', res.data.data.name);
      localStorage.setItem('image', res.data.data.image);
      dispatch({
        type: LOGIN,
        payload: res.data.data.success,
      });
    } catch (err) {
      toast.warn('Incorrect Detail, Please Try Again');
     }
  };