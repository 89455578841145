import {
    CREATE_BOOK_ORDER,
    RETRIEVE_BOOK_ORDER,
} from "../BookedOrder/actiontype";

const INITIAL_STATE = {
  currentIntegration: [],
  isLoading: false,
};

const bookedOrderReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
 
  switch (type) {
    case CREATE_BOOK_ORDER:
      return {
        ...state
      };
    case RETRIEVE_BOOK_ORDER:
      return {
        ...state,
        currentIntegration: payload,
        isLoading: true,
      };
      default:
        return state;
  }
};
export default bookedOrderReducer;