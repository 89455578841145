import {
    RETRIEVE_DASHBOARD,
    DUE_DELIVERY,
    DUE_ORDER,
    BRANCH_DETAIL,
    SALE_ORDER_WISE,
    SALE_INCENTIVE_WISE,
    LAST_MONTH_ACHIEVER,
    RETRIEVE_SINGLE_DUEORDER,
    RETRIEVE_PRODUCT_LIST,
    RETRIEVE_STOCK_QUERY_DETAIL
} from "../Dashboard/actiontype";


const INITIAL_STATE = {
  dashboard: [],
  currentIntegration: [],
  dueorder: [],
  singleDueOrder: [],
  branchchart: [],
  orderwise: [],
  incentivewise: [],
  achiever: [],
  productList: [],
  isProductSearch: false,
  stockDetail: [],
};

const dashboardReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  
  switch (type) {
    
    case RETRIEVE_DASHBOARD:
      return {
        ...state,
        dashboard: payload,
      };
    case DUE_DELIVERY:
      return {
        ...state,
        currentIntegration: payload,
      };
    case DUE_ORDER:
      return {
        ...state,
        dueorder: payload,
      };
    case RETRIEVE_SINGLE_DUEORDER:
      return {
        ...state,
        singleDueOrder: payload,
      };
    case RETRIEVE_PRODUCT_LIST:
      return {
        ...state,
        productList: payload,
        isProductSearch: true,
      };
      case  RETRIEVE_STOCK_QUERY_DETAIL:
        return {
          ...state,
          stockDetail: payload,
        };
    case BRANCH_DETAIL:
      return {
        ...state,
        branchchart: payload,
      };
    case SALE_ORDER_WISE:
      return {
        ...state,
        orderwise: payload,
      };
    case SALE_INCENTIVE_WISE:
      return {
        ...state,
        incentivewise: payload,
      };
    case LAST_MONTH_ACHIEVER:
      return {
        ...state,
        achiever: payload,
      };
    
    default:
      return state;
  }
};
export default dashboardReducer;