import React from 'react';
import { Row, Col, Container, Card } from 'react-bootstrap';
import MaterialTable from 'material-table';
import {useState, useEffect} from 'react';
import ProgressBar from "@ramonak/react-progress-bar";
import './dashboard.css';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import {getDashboardAccounts, getReceivedPayment, getTopIncentive} from '../../redux/DashboardAccounts/action';


const DashboardAccounts = ({ getDashboardAccounts, dashboard, getReceivedPayment, receivedpayment, getTopIncentive, topincentive }) =>  {  useEffect(() => {
  getDashboardAccounts();
  getReceivedPayment();
  getTopIncentive();
 }, []);
 
  
 const [tableData, setTableData] = useState([]);
 useEffect(() => {
   if(receivedpayment)
   {
     setTableData(receivedpayment); 
   }
 }, [receivedpayment])
  
  const columns = [
  { title: "Order No.", field: "orderNo" },
  { title: "Amount ", field: "amount" },
  { title: "Date", field: "date" },
  ]

  const [tableData2, setTableData2] = useState([]);
  useEffect(() => {
    if(topincentive)
    {
      setTableData2(topincentive); 
    }
  }, [topincentive])
  
  const columns2 = [
  { title: "Order No.", field: "orderNo" },
  { title: "Incentive ", field: "incentive" },
  
  ]
  return(
    <>
      <Container fluid className="main-content">
        <Row>
          <Col className='mb-0'>
              <h3 className="main-title mb-0">Welcome {localStorage.getItem('name')}</h3>
              <p style={{color: "#f53163"}}>Accounts</p>
          </Col>
        </Row>
        <Row>
          <Col lg={4} sm={12} className='mb-2'>
            <Link to="/approved-payment">
              <Card>
                <Card.Body>
                  <div className='d-flex justify-content-between'>
                    <p className='text-muted'>Today Collection</p>
                    <p className='text-primary'><i className="ri-line-chart-line"></i></p>
                  </div>
                  <Card.Title className='text-danger'>₹ {dashboard.today_collection}</Card.Title>
                  <ProgressBar 
                    completed={80}
                    bgColor="#d30639"
                    height="6px"
                    borderRadius="0"
                    labelColor="transparent"
                  />
                </Card.Body>
              </Card>
            </Link>
          </Col>
          <Col lg={4} sm={12} className='mb-2'>
            <Link to="/approved-payment">
              <Card>
                <Card.Body>
                  <div className='d-flex justify-content-between'>
                    <p className='text-muted'>Total Collection</p>
                    <p className='text-info'><i className="ri-exchange-dollar-line"></i></p>
                  </div>
                  <Card.Title className='text-danger'>₹ {dashboard.total_collection}</Card.Title>
                  <ProgressBar 
                    completed={70}
                    bgColor="#03c895"
                    height="6px"
                    borderRadius="0"
                    labelColor="transparent"
                  />
                </Card.Body>
              </Card>
            </Link>
          </Col>
          <Col lg={4} sm={12} className='mb-2'>
            <Link to="/receive-payment">
              <Card>
                <Card.Body>
                  <div className='d-flex justify-content-between'>
                    <p className='text-muted'>Unapproved Payment</p>
                    <p className='text-warning'><i className="ri-wallet-2-line"></i></p>
                  </div>
                  <Card.Title className='text-danger'>₹ {dashboard.unapproved}</Card.Title>
                  <ProgressBar 
                    completed={50}
                    bgColor="#01b8ff"
                    height="6px"
                    borderRadius="0"
                    labelColor="transparent"
                  />
                </Card.Body>
              </Card>
            </Link>
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col lg={"6"} sm={12} className='mb-4'>
            <div style={{display: 'table', tableLayout:'fixed', width:'100%'}} className='dashboard-table'>
              <MaterialTable columns={columns} data={tableData}
                  options={{
                  sorting: true, search: true,
                  paging: true, pageSizeOptions: [5, 10, 25, 50, 100], pageSize: 5,
                  exportButton: true,
                  exportAllData: true, exportFileName: "Received Payment", actionsColumnIndex: -1,
                  }}
                  title="Received Payment"
              />
            </div>
          </Col>
          <Col lg={"6"} sm={12} className='mb-4'>
            <div style={{display: 'table', tableLayout:'fixed', width:'100%'}} className='dashboard-table'>
              <MaterialTable columns={columns2} data={tableData2}
                  options={{
                  sorting: true, search: true,
                  paging: true, pageSizeOptions: [5, 10, 25, 50, 100], pageSize: 5,
                  exportButton: true,
                  exportAllData: true, exportFileName: "Top Incentive", actionsColumnIndex: -1,
                  }}
                  title="Top Incentive"
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  ) 
}

const mapStateToProps = (state) => {
  //
    return {
       dashboard: state.dashboardaccounts.dashboard,
       receivedpayment: state.dashboardaccounts.receivedpayment,
       topincentive: state.dashboardaccounts.topincentive,
     }
   }
  
   export default connect(mapStateToProps,{getDashboardAccounts, getReceivedPayment, getTopIncentive})(DashboardAccounts);