import React, {useState, useEffect} from 'react'
import { Row, Col, Container, Breadcrumb, Card } from 'react-bootstrap';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Zoom from 'react-medium-image-zoom'
import { connect } from "react-redux";
import {getQuotationDetail} from '../../../redux/Quotation/ViewQuotation/action';

const DetailViewQuotation = ({ getQuotationDetail,currentIntegration,imported_ready_product, imported_order_product, branchdetail }) =>  {  useEffect(() => {
    const quotation_id = window.location.pathname.split("/").pop();
    getQuotationDetail(quotation_id);
}, []);

const [show,setShow]=useState(true)

  return (
    <>
        <Container fluid className="main-content">
            <Row>
                <Col>
                    <h3 className="main-title mb-0">Quotation - {currentIntegration.quotationNo}</h3>
                    <Breadcrumb>
                        <Breadcrumb.Item>Quotation</Breadcrumb.Item>
                        <Breadcrumb.Item active>View Quotation Detail</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
                <Col>
                    <div className='d-flex justify-content-end'>
                        <button type="button" className="btn btn-md btn-primary mb-0" style={{marginRight: "20px"}}><i class="ri-printer-line"></i> Print</button>
                        <button type="button" className="btn btn-md btn-warning text-white"><i class="ri-mail-send-line"></i> Mail</button>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <Row className='mt-3'>
                                <Col lg={12}>
                                    <div className='d-flex justify-content-between'>
                                        <h6 className="card-title mb-3 ml-3">{currentIntegration.quotationNo}</h6>
                                        <div className="ml-auto" style={{float: "right"}}>
                                            <p className="mb-0"><span style={{fontWeight: "500"}}>Date :</span> {currentIntegration.date}</p>
                                            <p className="mb-0"><span style={{fontWeight: "500"}}>Branch :</span> {branchdetail}</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col>
                                    <div className='line'></div>
                                </Col>
                            </Row>
                            
                            <Row>
                                <Col lg={12}>
                                    <div className='d-lg-flex d-sm-block justify-content-between' >
                                        <div className='mb-3'>
                                            <h5>Customer Detail:</h5>
                                            <p className='mb-0'>Name : {currentIntegration.customerName}</p>
                                            <p className='mb-0'>Mobile : {currentIntegration.customerMobile}</p>
                                            <p className='mb-0'>Address : {currentIntegration.customerAddress}</p>
                                            <p className='mb-0'>Email : {currentIntegration.customerEmail}</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col>
                                    <div className='line mt-0'></div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <h6 className='text-danger mb-4'>Imported Ready Product</h6>
                                </Col>
                                <Col>
                                    <Table striped bquotationed hover>
                                        <Thead>
                                            <Tr>
                                            <Th className='custom-table-header'>#</Th>
                                            <Th className='custom-table-header'>IMAGE</Th>
                                            <Th className='custom-table-header'>MODEL NO</Th>
                                            <Th className='custom-table-header'>DESCRIPTION</Th>
                                            <Th className='custom-table-header'>LEATHER TYPE</Th>
                                            <Th className='custom-table-header'>QUOTED PRICE</Th>
                                            <Th className='custom-table-header'>NET PRICE</Th>
                                            <Th className='custom-table-header'>NET AMOUNT</Th>
                                            <Th className='custom-table-header'>LOCATION OF PRODUCT</Th>
                                            <Th className='custom-table-header'>REMARKS</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                        {imported_ready_product && imported_ready_product.map((quotation, index) => (
                                            <Tr  key={index}>
                                                <Td className='custom-table'>{index+1}</Td>
                                                <Td className='custom-table'><Zoom><img src={process.env.REACT_APP_IMAGE_URL+'/'+quotation.image} alt={quotation.image} style={{width: 50, height:50}}/></Zoom></Td>
                                                <Td className='custom-table'>{quotation.model_no}</Td>
                                                <Td className='custom-table'>
                                                    <ul class="description-list">
                                                        <li>Product Photo: {quotation.name}</li>
                                                        <li>Product Category: {quotation.product_category} </li>
                                                        <li>Color Code: {quotation.colour_code}</li>
                                                        <li>Qty: {quotation.qty}</li>
                                                    </ul>
                                                </Td>
                                                <Td className='custom-table'>{quotation.leather_type}</Td>
                                                <Td className='custom-table'>{quotation.quoted_price}</Td>
                                                <Td className='custom-table'>{quotation.net_price}</Td>
                                                <Td className='custom-table'>{quotation.net_amount}</Td>
                                                <Td className='custom-table'>{quotation.location}</Td>
                                                <Td className='custom-table'>{quotation.remark}</Td>
                                            </Tr>
                                            ))}
                                        </Tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <h6 className='text-danger mb-3 mt-3'>Imported Order Product</h6>
                                </Col>
                                <Col>
                                    <Table striped bquotationed hover>
                                        <Thead>
                                            <Tr>
                                            <Th className='custom-table-header'>#</Th>
                                            <Th className='custom-table-header'>IMAGE</Th>
                                            <Th className='custom-table-header'>MODEL NO</Th>
                                            <Th className='custom-table-header'>DESCRIPTION</Th>
                                            <Th className='custom-table-header'>LEATHER TYPE</Th>
                                            <Th className='custom-table-header'>QUOTED PRICE</Th>
                                            <Th className='custom-table-header'>NET PRICE</Th>
                                            <Th className='custom-table-header'>NET AMOUNT</Th>
                                            <Th className='custom-table-header'>LOCATION OF PRODUCT</Th>
                                            <Th className='custom-table-header'>REMARKS</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                        {imported_order_product && imported_order_product.map((quotation, index) => (
                                            <Tr  key={index}>
                                                <Td className='custom-table'>{index+1}</Td>
                                                <Td className='custom-table'><Zoom><img src={process.env.REACT_APP_IMAGE_URL+'/'+quotation.image} alt={quotation.image} style={{width: 50, height:50}}/></Zoom></Td>
                                                <Td className='custom-table'>{quotation.model_no}</Td>
                                                <Td className='custom-table'>
                                                    <ul class="description-list">
                                                        <li>Product Photo: {quotation.name}</li>
                                                        <li>Product Category: {quotation.product_category} </li>
                                                        <li>Color Code: {quotation.colour_code}</li>
                                                        <li>Qty: {quotation.qty}</li>
                                                    </ul>
                                                </Td>
                                                <Td className='custom-table'>{quotation.leather_type}</Td>
                                                <Td className='custom-table'>{quotation.quotated_price}</Td>
                                                <Td className='custom-table'>{quotation.net_price}</Td>
                                                <Td className='custom-table'>{quotation.net_amount}</Td>
                                                <Td className='custom-table'>{quotation.location}</Td>
                                                <Td className='custom-table'>{quotation.remark}</Td>
                                            </Tr>
                                            ))}
                                        </Tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <div className='line'></div>
                                </Col>
                                </Row>
                                <Row>
                                <Col lg={6} sm={12}>
                                    <p>Note : <span onClick={()=>setShow(!show)}><i className="fa-solid fa-info"></i></span></p>
                                    <div>
                                        {
                                        show? <table className="table table-invoice table-bordered">
                                        <tbody>
                                          <tr>
                                            <td width="50%">
                                               <p><strong>BRANCH DETAIL:</strong></p>
                                                <p className="m-1"> <strong> BENEFICIARY NAME :</strong> <span> {currentIntegration.beneficiary_name}</span></p>
                                                <p className="m-1"> <strong> ACCOUNT NO :</strong> <span> {currentIntegration.account_no}</span></p>
                                                <p className="m-1"> <strong> IFSC :</strong> <span> {currentIntegration.ifsc}</span></p>
                                                <p> <strong> BANK NAME :</strong> <span> {currentIntegration.bank_name}</span></p>
                                            </td>
                                            <td width="50%">
                                                <p><strong>TERMS AND CONDITIONS:</strong></p>
                                                <p className="m-1"> CARTAGE AND PACKING INCLUDED</p>
                                                <p className="m-1">TRANSPORTATION EXTRA</p>
                                                <p className="m-1"> 50% ADVANCE REQUIRED</p>
                                                <p className="m-1">BALANCE 50% BEFORE DELIVERY </p>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </table> :null
                                        }
                                    </div>
                                </Col>
                                
                                <Col lg={6} sm={12}>
                                    <div class="table-responsive">
                                        <table class="table table-invoice table-bordered">
                                            <thead> </thead>
                                            <tbody>
                                                <tr>
                                                    <td class="tx-right tx-11">Sub Total</td>
                                                    <td class="tx-right tx-12" colspan="2">₹ {currentIntegration.sub_total}</td>
                                                </tr>
                                                <tr>
                                                    <td class="tx-right tx-11">GST (18%)</td>
                                                    <td class="tx-right tx-12" colspan="2">₹ {currentIntegration.gst}</td>
                                                </tr>
                                                <tr>
                                                    <td class="tx-right tx-11">Total</td>
                                                    <td class="tx-right tx-12" colspan="2">₹ {currentIntegration.total}</td>
                                                </tr>
                                                <tr>
                                                    <td class="tx-right tx-11">Discount</td>
                                                    <td class="tx-right tx-12" colspan="2">₹ {currentIntegration.discount}</td>
                                                </tr>
                                                <tr>
                                                    <td class="tx-right tx-11">Packing</td>
                                                    <td class="tx-right tx-12" colspan="2">₹ {currentIntegration.packing}</td>
                                                </tr>
                                                <tr>
                                                    <td class="tx-right tx-11">Grand Total</td>
                                                    <td class="tx-right tx-12" colspan="2">₹ {currentIntegration.grand_total}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    </>
  )
}

const mapStateToProps = (state) => {
    return {
       currentIntegration: state.quotationDetail.currentIntegration,
       imported_ready_product: state.quotationDetail.currentIntegration.imported_ready,
       imported_order_product: state.quotationDetail.currentIntegration.imported_order,
       branchdetail: state.quotationDetail.currentIntegration.branch,
     }
   }
  
   export default connect(mapStateToProps,{getQuotationDetail})(DetailViewQuotation);