import * as React from "react";
import {useState, useEffect} from 'react'
import { Row, Col, Container, Button, Modal, Dropdown } from 'react-bootstrap';
import MaterialTable from 'material-table';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import KErrorMessage from "../../../components/ErrorMessage/KErrorMessage";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import { connect } from "react-redux";
import { vendor, getVendor, getSingleVendor, updateVendor, deleteVendor} from '../../../redux/Vendor/Vendor/action';
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import SearchBar from "../../../components/SearchBar/SearchBar";
import Loader from "../../../components/Loader/Loader";


const validationSchema = yup.object({
  name: yup.string().required("Vendor Name is Required"),
  mobile: yup
  .number()
  .min(1000000000, "Not Valid Mobile Number")
  .max(9999999999, "Not Valid Mobile Number")
  .required("Mobile Number is Required"),
  address: yup
  .string()
  .min(5, "too small!")
  .max(500, "Too Long String")
  .required("Address is Required"),
});

const Vendor = ({ vendor,getVendor,currentIntegration,getSingleVendor,singleVendor,updateVendor,deleteVendor,isLoading, isUpdate }) =>  {  useEffect(() => {
    getVendor();
  }, []);
  
const [visible, setVisible] = useState(false);
const handleVisibility = () => {
    setVisible(prev => !prev);
};

const [loading, setLoading] = useState(false);
useEffect(() => {
    if (isLoading) {
        setLoading(true);
    }
  });


const [show, setShow] = useState(false);
const modalAddClose = () => setShow(false);
const modalAddShow = () => setShow(true);

const [show2, setShow2] = useState(false);
const modalEditClose = () => setShow2(false);
const modalEditShow = () => setShow2(true);

const [tableData, setTableData] = useState([]);
  useEffect(() => {
    if(currentIntegration)
    {
      setTableData(currentIntegration); 
    }
  }, [currentIntegration])

const columns = [
{ title: " Vendor Name", field: "name" },
{ title: "Mobile No.", field: "mobile" },
{ title: "Address", field: "address" },
]

const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', type: ''})

  const editVendor = async (e) => {
      setShow2({
      isOpen: true,
      })
      getSingleVendor(e);
  }

  const vendorDelete = async (e) => {
    
    setConfirmDialog({
    isOpen: true,
    title: 'Are You Sure To Delete This Vendor?',
    type: false,
    onConfirm: () => { 
        deleteVendor(e);
        setConfirmDialog({ ...confirmDialog, isOpen: false })
    }
    })
  }   
  
  // Search for Card view start
  const [searchValue, setSearchValue] = useState("");

  const filterVendor = ({ name }) => {
    return name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
  };
  // Search for Card view end
  
  useEffect(() => {
    if(isUpdate)
    {
      window.location.reload(false);
    }
  }, [isUpdate])

  return (
    <>
        <Container fluid className="main-content">
            <Row>
                <Col className='d-flex justify-content-between mb-2'>
                    <h3 className="main-title">Vendor</h3>
                    <button type="button" className="btn btn-md btn-primary d-none d-sm-block" onClick={modalAddShow}><i className="fa-solid fa-plus"></i> Create</button>
                    <div className="d-flex justify-content-between d-block d-sm-none">
                      <div className="magnifierContainer">
                        <button type="button" className="btn btn-md btn-warning text-white roundedcircle mr-3" onClick={handleVisibility}><i className="fa-solid fa-search"></i></button>
                      </div>
                      <button type="button" className="btn btn-md btn-primary d-block d-sm-none roundedcircle ml-3"  onClick={modalAddShow}><i className="fa-solid fa-plus"></i></button>
                    </div>
                </Col>
                <Col lg={"12"} className='mb-2 d-block d-sm-none'>
                  <div className={visible ? "open" : "close"}>
                    <SearchBar onSearch={setSearchValue} value={searchValue} />
                  </div>
                </Col>
            </Row>
            {loading ? <div>
            <Row className="d-none d-sm-block mt-2">
              <Col lg={"12"}>
                <div style={{display: 'table', tableLayout:'fixed', width:'100%'}}>
                <MaterialTable columns={columns} data={tableData}
                   actions={[
                    {
                      icon: () => <EditIcon color={'primary'} />,
                      tooltip: 'Edit',
                      onClick: (event, rowData) => editVendor(rowData.id)
                    },
                    
                    {
                      icon: () => <DeleteIcon color={'error'} />,
                      tooltip: 'Edit',
                      onClick: (event, rowData) => vendorDelete(rowData.id)
                    },
                  ]}
                    options={{
                    sorting: true, search: true,
                    paging: true, pageSizeOptions: [10, 25, 50, 100], pageSize: 10,
                    exportButton: true,
                    exportAllData: true, exportFileName: "Vendor", actionsColumnIndex: -1,
                    // headerStyle: { background: "#f44336",color:"#fff"}
                    }}
                    title="Vendor"
                />
                </div>
              </Col>
            </Row>
            <Row className="d-block d-sm-none">
            {currentIntegration.filter(filterVendor).map((vendor, index) => ( 
              <Col xs={"12"}  key={index}>
                <div className="card mb-2">
                  <div className="card-header d-flex justify-content-between pb-0">
                      <h6>{vendor.name}</h6>
                    <Dropdown>
                      <Dropdown.Toggle variant="light" id="dropdown-basic">
                        <i className="ri-more-2-line"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                          <Dropdown.Item onClick={() => editVendor(vendor.id)}><EditIcon color={'primary'} /> Edit</Dropdown.Item>
                          <Dropdown.Item onClick={() => vendorDelete(vendor.id)}><DeleteIcon color={'error'} /> Delete</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="card-body pt-1">
                    <p className="card-text mb-0"><span className="user-info">Mobile:</span>{vendor.mobile}</p>
                    <p className="card-text mb-0"><span className="user-info">Address:</span>{vendor.address}</p>
                  </div>
                </div>
              </Col>
               ))}  
            </Row>
            </div> : <Loader/>}
        </Container>
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
        {/* Add Vendor Modal Start */}
        <Modal 
          size="md"
          centered
          show={show} 
          onHide={modalAddClose}>
            <Formik
                validationSchema={validationSchema}
                initialValues={{
                name: "",
                mobile: "",
                address: ""
                }}
                onSubmit={(values) => {
                  vendor(values);
                  modalAddClose();
                }}
            >
               {({ values }) => (
                <Form>
                <Modal.Header closeButton>
                    <Modal.Title>Add Vendor</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label>Vendor Name</label>
                    <Field name="name" type="text" className="form-control" />
                    <KErrorMessage name="name" />
                    <label>Mobile No.</label>
                    <Field name="mobile" type="number" className="form-control" />
                    <KErrorMessage name="mobile" />
                    <label>Address</label>
                    <Field name="address" as="textarea" className="form-control" />
                    <KErrorMessage name="address" />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={modalAddClose}>Close</Button>
                    <Button variant="primary" type="submit">Submit</Button>
                </Modal.Footer>
                </Form>
               )}
            </Formik>
        </Modal>
        {/* Add Vendor Modal End */}
        {/* Edit Vendor Modal Start */}
        <Modal 
          size="md"
          centered
          show={show2} 
          onHide={modalEditClose}>
            <Formik
                enableReinitialize={true}
                validationSchema={validationSchema}
                initialValues={{
                id: singleVendor.id,
                name: singleVendor.name,
                mobile: singleVendor.mobile,
                address: singleVendor.address
                }}
                onSubmit={(values) => {
                  updateVendor(values);
                  modalEditClose();
                }}
            >
               {({ values }) => (
                <Form >
                <Modal.Header closeButton>
                    <Modal.Title>Edit Vendor</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Field name="id" type="hidden" className="form-control" />
                    <label>Vendor Name</label>
                    <Field name="name" type="text" className="form-control" />
                    <KErrorMessage name="name" />
                    <label>Mobile No.</label>
                    <Field name="mobile" type="number" className="form-control" />
                    <KErrorMessage name="mobile" />
                    <label>Address</label>
                    <Field name="address" as="textarea" className="form-control" />
                    <KErrorMessage name="address" />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={modalEditClose}>Close</Button>
                    <Button variant="primary" type="submit">Submit</Button>
                </Modal.Footer>
                </Form>
               )}
            </Formik>
        </Modal>
      {/* Edit Vendor Modal End */}
    </>

  )
}

const mapStateToProps = (state) => {
  console.log(state);
  return {
     currentIntegration: state.vendors.currentIntegration,
     isLoading: state.vendors.isLoading,
     isUpdate: state.vendors.isUpdate,
     singleVendor: state.vendors.singleVendor,
   }
 }

 export default connect(mapStateToProps,{vendor,getVendor,getSingleVendor,updateVendor,deleteVendor})(Vendor);