import React, {useState, useEffect, useRef} from 'react'
import { Row, Col, Container, Breadcrumb, Card, Badge } from 'react-bootstrap';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { useReactToPrint } from "react-to-print";
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import Zoom from 'react-medium-image-zoom'
import { connect } from "react-redux";
import {getOrderDetail} from '../../../redux/Order/ViewOrder/action';

const DetailViewOrder = ({ getOrderDetail,currentIntegration,imported_ready_product, imported_order_product,payment_history }) =>  {  useEffect(() => {
    const order_id = window.location.pathname.split("/").pop();
    getOrderDetail(order_id);
}, []);

const [show,setShow]=useState(false)

const componentRef = useRef();
const handlePrint = useReactToPrint({
  content: () => componentRef.current,
});

  return (
    <>
        <Container fluid className="main-content">
            <Row>
                <Col>
                    <h3 className="main-title mb-0">Order - {currentIntegration.order_name}</h3>
                    <Breadcrumb>
                        <Breadcrumb.Item>Order</Breadcrumb.Item>
                        <Breadcrumb.Item active>View Order</Breadcrumb.Item>
                    </Breadcrumb>
                </Col>
                <Col>
                    <div className='d-flex justify-content-end'>
                        <button type="button" onClick={handlePrint} className="btn btn-md btn-warning text-white mb-0"><i class="ri-printer-line"></i> Print</button>
                    </div>
                </Col>
            </Row>
            <div ref={componentRef}>
            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <Row className='mt-3'>
                                <Col lg={12}>
                                    <div className='d-flex justify-content-between'>
                                        <h6 className="card-title mb-3 ml-3">{currentIntegration.order_name}</h6>
                                        <div className="ml-auto" style={{float: "right"}}>
                                            <p className="mb-0"><span style={{fontWeight: "500"}}>DOB :</span> {currentIntegration.dob}</p>
                                            <p className="mb-0"><span style={{fontWeight: "500"}}>EDD:</span> {currentIntegration.edd}</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col>
                                    <div className='line'></div>
                                </Col>
                            </Row>
                            
                            <Row>
                                <Col lg={12}>
                                    <div className='d-lg-flex d-sm-block justify-content-between' >
                                        <div className='mb-3'>
                                            <p className='mb-0'>Name: {currentIntegration.name}</p>
                                            <p className='mb-0'>Mobile: {currentIntegration.mobile_no}</p>
                                            <p className='mb-0'>Address: {currentIntegration.address}</p>
                                            <p className='mb-0'>Email: {currentIntegration.email}</p>
                                        </div>
                                        <div className='mb-3'>
                                            <p className='mb-0'>Cordinator: {currentIntegration.coordinator}</p>
                                            <p className='mb-0'>Reffered by : {currentIntegration.reffered_by}</p>
                                            <p className='mb-0'>Approved by: {currentIntegration.approved_by}</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col>
                                    <div className='line mt-0'></div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <h6 className='text-danger mb-4'>Imported Ready Product</h6>
                                </Col>
                                <Col>
                                    <Table striped bordered hover>
                                        <Thead>
                                            <Tr>
                                            <Th className='custom-table-header'>#</Th>
                                            <Th className='custom-table-header'>IMAGE</Th>
                                            <Th className='custom-table-header'>MODEL NO</Th>
                                            <Th className='custom-table-header'>DESCRIPTION</Th>
                                            <Th className='custom-table-header'>LEATHER TYPE</Th>
                                            <Th className='custom-table-header'>QUOTED PRICE</Th>
                                            <Th className='custom-table-header'>NET PRICE</Th>
                                            <Th className='custom-table-header'>NET AMOUNT</Th>
                                            <Th className='custom-table-header'>LOCATION OF PRODUCT</Th>
                                            <Th className='custom-table-header'>REMARKS</Th>
                                            <Th className='custom-table-header'>CRTN</Th>
                                            <Th className='custom-table-header'>INC</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                        {imported_ready_product && imported_ready_product.map((order, index) => (
                                            <Tr  key={index}>
                                                <Td className='custom-table'>{index+1}</Td>
                                                <Td className='custom-table'><Zoom><img src={process.env.REACT_APP_IMAGE_URL+'/'+order.image} alt={order.image} style={{width: 50, height:50}}/></Zoom></Td>
                                                <Td className='custom-table'>{order.model_no}</Td>
                                                <Td className='custom-table'>
                                                    <ul class="description-list">
                                                        <li>Product Photo: {order.name}</li>
                                                        <li>Product Category: {order.product_category} </li>
                                                        <li>Color Code: {order.colour_code}</li>
                                                        <li>Qty: {order.qty}</li>
                                                    </ul>
                                                </Td>
                                                <Td className='custom-table'>{order.leather_type}</Td>
                                                <Td className='custom-table'>{order.quotated_price}</Td>
                                                <Td className='custom-table'>{order.net_price}</Td>
                                                <Td className='custom-table'>{order.net_amount}</Td>
                                                <Td className='custom-table'>{order.location}</Td>
                                                <Td className='custom-table'>{order.remark}</Td>
                                                <Td className='custom-table'>{order.crtn}</Td>
                                                <Td className='custom-table'>{order.inc}</Td>
                                            </Tr>
                                            ))}
                                        </Tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <h6 className='text-danger mb-3 mt-3'>Imported Order Product</h6>
                                </Col>
                                <Col>
                                    <Table striped bordered hover>
                                        <Thead>
                                            <Tr>
                                            <Th className='custom-table-header'>#</Th>
                                            <Th className='custom-table-header'>Image</Th>
                                            <Th className='custom-table-header'>MODEL NO</Th>
                                            <Th className='custom-table-header'>DESCRIPTION</Th>
                                            <Th className='custom-table-header'>LEATHER TYPE</Th>
                                            <Th className='custom-table-header'>QUOTED PRICE</Th>
                                            <Th className='custom-table-header'>NET PRICE</Th>
                                            <Th className='custom-table-header'>NET AMOUNT</Th>
                                            <Th className='custom-table-header'>LOCATION OF PRODUCT</Th>
                                            <Th className='custom-table-header'>REMARKS</Th>
                                            <Th className='custom-table-header'>CRTN</Th>
                                            <Th className='custom-table-header'>INC</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                        {imported_order_product && imported_order_product.map((order, index) => (
                                            <Tr  key={index}>
                                                <Td className='custom-table'>{index+1}</Td>
                                                <Td className='custom-table'><Zoom><img src={process.env.REACT_APP_IMAGE_URL+'/'+order.image} alt={order.image} style={{width: 50, height:50}}/></Zoom></Td>
                                                <Td className='custom-table'>{order.model_no}</Td>
                                                <Td className='custom-table'>
                                                    <ul class="description-list">
                                                        <li>Product Photo: {order.name}</li>
                                                        <li>Product Category: {order.product_category} </li>
                                                        <li>Color Code: {order.colour_code}</li>
                                                        <li>Qty: {order.qty}</li>
                                                    </ul>
                                                </Td>
                                                <Td className='custom-table'>{order.leather_type}</Td>
                                                <Td className='custom-table'>{order.quotated_price}</Td>
                                                <Td className='custom-table'>{order.net_price}</Td>
                                                <Td className='custom-table'>{order.net_amount}</Td>
                                                <Td className='custom-table'>{order.location}</Td>
                                                <Td className='custom-table'>{order.remark}</Td>
                                                <Td className='custom-table'>{order.crtn}</Td>
                                                <Td className='custom-table'>{order.inc}</Td>
                                            </Tr>
                                            ))}
                                        </Tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <div className='line'></div>
                                </Col>
                                </Row>
                                <Row>
                                <Col lg={6} sm={12}>
                                    <p>Note : <span onClick={()=>setShow(!show)}><i className="fa-solid fa-info"></i></span></p>
                                    <div>
                                        {
                                        show? <div class="invoice-notes">
                                                <p class="tx-9-f">GST, Packing and Cartage will be extra as applicable</p>
                                            </div> :null
                                        }
                                    </div>
                                </Col>
                                
                                <Col lg={6} sm={12}>
                                    <div class="table-responsive">
                                        <table class="table table-invoice table-bordered">
                                            <thead> </thead>
                                            <tbody>
                                                <tr>
                                                    <td class="tx-right tx-11">Total</td>
                                                    <td class="tx-right tx-12" colspan="2">₹ {currentIntegration.total_amount}</td>
                                                </tr>
                                                <tr>
                                                    <td class="tx-right tx-11">Fitting</td>
                                                    <td class="tx-right tx-12" colspan="2">₹ {currentIntegration.fitting}</td>
                                                </tr>
                                                <tr>
                                                    <td class="tx-right tx-11">Packing</td>
                                                    <td class="tx-right tx-12" colspan="2">₹ {currentIntegration.packing}</td>
                                                </tr>
                                                <tr>
                                                    <td class="tx-right tx-11">Cartage</td>
                                                    <td class="tx-right tx-12" colspan="2">₹ {currentIntegration.cartage}</td>
                                                </tr>
                                                <tr>
                                                    <td class="tx-right tx-11">Other Charges ({currentIntegration.other_name} )</td>
                                                    <td class="tx-right tx-12" colspan="2">₹ {currentIntegration.other}</td>
                                                </tr>
                                                <tr>
                                                    <td class="tx-right tx-11">Gross Total</td>
                                                    <td class="tx-right tx-12" colspan="2">₹ {currentIntegration.gross_total}</td>
                                                </tr>
                                                <tr>
                                                    <td class="tx-right tx-uppercase tx-bold tx-inverse">Paid Amount</td>
                                                    <td class="tx-right tx-12" colspan="2">
                                                        <h4 class="tx-bold tx-12">₹ {currentIntegration.paid_amount}</h4> </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </Col>
                                <Col lg={12}>
                                    <div className='line'></div>
                                </Col>
                                <Col lg={12}>
                                     <h5>Payment Detail <span style={{marginLeft: "10px"}}><Badge bg="danger" className='text-white'>₹ {currentIntegration.due_amount}</Badge></span></h5>
                                </Col>
                            </Row>
                            <Row className='mt-3'>
                                <Col lg={6} sm={12}>
                                    <Timeline>
                                    {payment_history && payment_history.map((payment, index) => (
                                        <TimelineItem key={index}>
                                            <TimelineSeparator>
                                            <TimelineDot />
                                            <TimelineConnector />
                                            </TimelineSeparator>
                                            <TimelineContent>₹ {payment.amount} recorded by {payment.recorded_by} On {payment.date} with {payment.mode}</TimelineContent>
                                        </TimelineItem>
                                    ))}
                                    </Timeline>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            </div>
        </Container>
    </>
  )
}

const mapStateToProps = (state) => {
    console.log(state);
    return {
       currentIntegration: state.orderDetail.currentIntegration,
       imported_ready_product: state.orderDetail.currentIntegration.imported_ready,
       imported_order_product: state.orderDetail.currentIntegration.imported_order,
       payment_history: state.orderDetail.currentIntegration.payment_history,
     }
   }
  
   export default connect(mapStateToProps,{getOrderDetail})(DetailViewOrder);