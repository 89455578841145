import {
    CREATE_DELIVERYDUE_ORDERS,
    RETRIEVE_DELIVERYDUE_ORDERS,
    GET_BILLING,
    ADD_BILLING
} from "../DeliveryDueOrder/actiontype";

import { toast } from 'react-toastify';
import api from "../../../api";



export const getDeliveryDueOrder= (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  try {
    const res = await api.get(`/order/deliveryDueOrder`);
    dispatch({
      type: RETRIEVE_DELIVERYDUE_ORDERS,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_DELIVERYDUE_ORDERS,
      payload: [],
    });
  }
};

export const getbilling= (id) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  try {
    const res = await api.get(`/order/getBilling/`+ id);
    dispatch({
      type: GET_BILLING,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type: GET_BILLING,
      payload: [],
    });
  }
};

export const addBilling = (data) => async (dispatch) => {
  const body = JSON.stringify(data);
  try {
  
    const res = await api.post('/order/addBilling', body);
    if (res.status === 200) {
      toast.success(res.data.message);
        dispatch({
            type: ADD_BILLING,
            payload: res.data.data.info,
          });
         }
      } catch (err) {
    
    toast.warn('Invalid inputs! Order Not Decline');
    // dispatch({
    //   type: REGISTER_FAIL,
    // });
  }
};

