import {
    CREATE_USERS,
    RETRIEVE_USERS,
    UPDATE_USERS,
    DELETE_USERS,
    SET_USERTYPE,
    RETRIEVE_SINGLE_USERS,
    SET_SUBBRANCH
} from "../Users/actiontype";


const INITIAL_STATE = {
  currentIntegration: [],
  usertype: [],
  singleUser: [],
  subBranch: [],
  isLoading: false,
  isUpdate: false,
};

const userReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case CREATE_USERS:
      return {
        ...state,
        currentIntegration: [...state.currentIntegration, payload],
      };
    case RETRIEVE_USERS:
      return {
        ...state,
        currentIntegration: payload,
        isLoading: true,
      };
    case RETRIEVE_SINGLE_USERS:
      return {
        ...state,
        singleUser: payload,
      };
      case UPDATE_USERS:
      return {
        ...state,
        currentIntegration: payload,
        isUpdate: true,
      };
      case SET_USERTYPE:
      return {
        ...state,
        usertype: payload,
      };
      case SET_SUBBRANCH:
      return {
        ...state,
        subBranch: payload,
      };
    // case UPDATE_USERS:
    //   return state.map((brand) => {
    //     if (brand.id === payload.id) {
    //       return {
    //         ...brand,
    //         ...payload,
    //       };
    //     } else {
    //       return brand;
    //     }
    //   });
    case DELETE_USERS:
      return {
        ...state,
        currentIntegration: state.currentIntegration.filter((item) => parseInt(item.id) !== parseInt(payload)),
      };
    default:
      return state;
  }
};


export default userReducer;