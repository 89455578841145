import * as React from "react";
import {useState, useEffect} from 'react'
import { Row, Col, Container, Badge, Dropdown } from 'react-bootstrap';
import MaterialTable from 'material-table';
import { connect } from "react-redux";
import {getProductWiseReport} from '../../redux/Report/ProductWise/action';
import SearchBar from "../../components/SearchBar/SearchBar";
import Loader from "../../components/Loader/Loader";

const ProductWise = ({ getProductWiseReport,currentIntegration,isLoading }) =>  {  useEffect(() => {
    getProductWiseReport();
  }, []);


const [visible, setVisible] = useState(false);
const handleVisibility = () => {
    setVisible(prev => !prev);
};  

const [loading, setLoading] = useState(false);
useEffect(() => {
    if (isLoading) {
        setLoading(true);
    }
  });

const [tableData, setTableData] = useState([]);
    useEffect(() => {
    if(currentIntegration)
    {
        setTableData(currentIntegration); 
    }
    }, [currentIntegration])

const columns = [
{ title: "Date", field: "date" },
{ title: "Customer Name", field: "customerName" },
{ title: "Brands", field: "brand" },
{ title: "Items", field: "item" },
{ title: "Quantity", field: "qty" },
{ title: "Net Price", field: "netPrice" },
{ title: "Net Amount", field: "netAmount" },
{ title: "Branch", field: "branch" },
{ title: "Sales Person", field: "salesPerson" },
{ title: "Vendor", field: "vendor" },
]

// Search for Card view start
const [searchValue, setSearchValue] = useState("");

const filterItems = ({ customerName }) => {
  return customerName.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
};
// Search for Card view end

  return (
    <>
        <Container fluid className="main-content">
          <Row>
              <Col lg={"12"} className='d-flex justify-content-between mb-2'>
                  <h3 className="main-title">Product Wise Report</h3>
                  <div className=" d-block d-sm-none">
                      <div className="magnifierContainer">
                          <button type="button" className="btn btn-md btn-warning text-white roundedcircle mr-3" onClick={handleVisibility}><i className="fa-solid fa-search"></i></button>
                      </div>
                  </div>
              </Col>
              <Col lg={"12"} className='mb-2 d-block d-sm-none'>
                <div className={visible ? "open" : "close"}>
                  <SearchBar onSearch={setSearchValue} value={searchValue} />
                </div>
              </Col>
          </Row>
          { loading ? <div>
          <Row className="d-none d-sm-block mt-2">
            <Col lg={"12"} className="mt-3">
              <div style={{display: 'table', tableLayout:'fixed', width:'100%'}}>
              <MaterialTable columns={columns} data={tableData}
                  options={{
                  sorting: true, search: true,
                  paging: true, pageSizeOptions: [10, 25, 50, 100], pageSize: 10,
                  exportButton: true,
                  exportAllData: true, exportFileName: "Approved Quotation", actionsColumnIndex: -1,
                  // headerStyle: { background: "#f44336",color:"#fff"}
                  }}
                  title="Product Wise Report"
              />
              </div>
            </Col>
          </Row>
          <Row className="d-block d-sm-none">
          {currentIntegration.filter(filterItems).map((report, index) => (
              <Col xs={"12"} key={index}>
                  <div className="card mb-2">
                      <div className="card-header d-flex justify-content-between pb-0">
                          <h6>{report.item} <Badge bg="danger" text="light" className="ml-2">{report.qty}</Badge></h6>
                      </div>
                      <div className="card-body pt-1">
                          <p className="card-text mb-0"><span className="quotation-info">Customer Name:</span> {report.customerName}</p>
                          <p className="card-text mb-0"><span className="quotation-info">Brand:</span> {report.brand}</p>
                          <p className="card-text mb-0"><span className="quotation-info">Net Price:</span> {report.netPrice}</p>
                          <p className="card-text mb-0"><span className="quotation-info">Net Amount:</span> {report.netAmount}</p>
                          <p className="card-text mb-0"><span className="quotation-info">Branch:</span> {report.branch}</p>
                          <p className="card-text mb-0"><span className="quotation-info">Sales Person:</span> {report.salesPerson}</p>
                          <p className="card-text mb-0"><span className="quotation-info">Vendor:</span> {report.vendor}</p>
                          <p className="card-text mb-0"><span className="quotation-info">Delivery Date:</span> {report.date}</p>
                      </div>
                  </div>
              </Col>
          ))}
            </Row>
            </div> : <Loader/> }
        </Container>
    </>

  )
}

const mapStateToProps = (state) => {
  
    return {
       currentIntegration: state.productWiseReport.currentIntegration,
       isLoading: state.productWiseReport.isLoading,
     }
   }
  
export default connect(mapStateToProps,{getProductWiseReport})(ProductWise);