import * as React from "react";
import {useState, useEffect} from 'react'
import { Row, Col, Container, Badge, Dropdown } from 'react-bootstrap';
import MaterialTable from 'material-table';
import { connect } from "react-redux";
import {getConsolidatedReport} from '../../redux/Report/ConsolidatedReport/action';
import SearchBar from "../../components/SearchBar/SearchBar";
import Loader from "../../components/Loader/Loader";

const ConsolidatedReport = ({ getConsolidatedReport,currentIntegration,isLoading}) =>  {  useEffect(() => {
    getConsolidatedReport();
  }, []);

const [visible, setVisible] = useState(false);
const handleVisibility = () => {
    setVisible(prev => !prev);
}; 

const [loading, setLoading] = useState(false);
useEffect(() => {
    if (isLoading) {
        setLoading(true);
    }
  });

const [tableData, setTableData] = useState([]);
useEffect(() => {
if(currentIntegration)
{
    setTableData(currentIntegration); 
}
}, [currentIntegration])

const columns = [
{ title: "Order No.", field: "orderNo" },
{ title: "Branch Name", field: "branchName" },
{ title: "Sales Person ", field: "salePerson" },
{ title: "Doo", field: "doo" },
{ title: "Customer Name", field: "customerName" },
{ title: "Customer Mobile", field: "customerMobile" },
{ title: "Quantity", field: "qty" },
{ title: "Net Amt", field: "netAmt" },
{ title: "Fitting", field: "fitting" },
{ title: "Packing", field: "packing" },
{ title: "Cartage", field: "cartage" },
{ title: "Gross Amount", field: "grossAmt" },
{ title: "Amount Recorded", field: "amtRecorded" },
{ title: "Net Due", field: "netDue" },
{ title: "Total Discount", field: "totalDiscount" },
{ title: "EDD", field: "edd" },
{ title: "status", field: "status" },
]
 
// Search for Card view start
const [searchValue, setSearchValue] = useState("");

const filterOrders = ({ orderNo }) => {
  return orderNo.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
};
// Search for Card view end

  return (
    <>
        <Container fluid className="main-content">
        <Row>
              <Col lg={"12"} className='d-flex justify-content-between mb-2'>
                  <h3 className="main-title">Consolidated Report</h3>
                  <div className=" d-block d-sm-none">
                      <div className="magnifierContainer">
                          <button type="button" className="btn btn-md btn-warning text-white roundedcircle mr-3" onClick={handleVisibility}><i className="fa-solid fa-search"></i></button>
                      </div>
                  </div>
              </Col>
              <Col lg={"12"} className='mb-2 d-block d-sm-none'>
                <div className={visible ? "open" : "close"}>
                  <SearchBar onSearch={setSearchValue} value={searchValue} />
                </div>
              </Col>
          </Row>
          { loading ? <div>
            <Row className="d-none d-sm-block mt-2">
              <Col lg={"12"} className="mt-3">
                <div style={{display: 'table', tableLayout:'fixed', width:'100%'}}>
                  <MaterialTable columns={columns} data={tableData}
                      options={{
                      sorting: true, search: true,
                      paging: true, pageSizeOptions: [10, 25, 50, 100], pageSize: 10,
                      exportButton: true,
                      exportAllData: true, exportFileName: "Consolidated Report", actionsColumnIndex: -1,
                      // headerStyle: { background: "#f44336",color:"#fff"}
                      }}
                      title="Consolidated Report"
                  />
                </div>
              </Col>
          </Row>
          <Row className="d-block d-sm-none">
            {currentIntegration.filter(filterOrders).map((report, index) => (
              <Col xs={"12"} key={index}>
                  <div className="card mb-2">
                      <div className="card-header d-flex justify-content-between pb-0">
                          <h6>{report.orderNo} <Badge bg="danger" text="light" className="ml-2">{report.qty}</Badge></h6>
                      </div>
                      <div className="card-body pt-1">
                          <p className="card-text mb-0"><span className="quotation-info">Branch Name:</span> {report.branchName}</p>
                          <p className="card-text mb-0"><span className="quotation-info">Sale Person:</span> {report.salePerson}</p>
                          <p className="card-text mb-0"><span className="quotation-info">Customer Name:</span> {report.customerName}</p>
                          <p className="card-text mb-0"><span className="quotation-info">Customer Mobile:</span> {report.customerMobile}</p>
                          <p className="card-text mb-0"><span className="quotation-info">Net Amount:</span> {report.netAmt}</p>
                          <p className="card-text mb-0"><span className="quotation-info">Fitting:</span> {report.fitting}</p>
                          <p className="card-text mb-0"><span className="quotation-info">Packing:</span> {report.packing}</p>
                          <p className="card-text mb-0"><span className="quotation-info">Cartage:</span> {report.cartage}</p>
                          <p className="card-text mb-0"><span className="quotation-info">Gross Amount:</span> {report.grossAmt}</p>
                          <p className="card-text mb-0"><span className="quotation-info">Amount Recorded:</span> {report.amtRecorded}</p>
                          <p className="card-text mb-0"><span className="quotation-info">Net Due:</span> {report.netDue}</p>
                          <p className="card-text mb-0"><span className="quotation-info">Total Discount:</span> {report.totalDiscount}</p>
                          <p className="card-text mb-0"><span className="quotation-info">Order Date:</span> {report.doo}</p>
                          <p className="card-text mb-0"><span className="quotation-info">Expected Date:</span> {report.edd}</p>
                          <p className="card-text mb-0"><span className="quotation-info">Status:</span> {report.status}</p>
                      </div>
                  </div>
              </Col>
              ))}
            </Row>
            </div> : <Loader/> }
        </Container>
    </>

  )
}

const mapStateToProps = (state) => {
  
  return {
     currentIntegration: state.consolidatedReport.currentIntegration,
     isLoading: state.consolidatedReport.isLoading,
   }
 }

export default connect(mapStateToProps,{getConsolidatedReport})(ConsolidatedReport);