import {
    QUOTATION_DETAIL,
    UPDATE_QUOTATION,
    GET_IMPORTED_READY,
    GET_IMPORTED_ORDER,
    GET_TOTAL_AMOUNT,
    GET_QUOTATION_NAME,
    READY_NET_AMOUNT,
    ORDER_NET_AMOUNT,
    GROSS_TOTAL,
    BALANCE_AMT,
    BRANCH_DETAIL,
} from "./actionType";

import { toast } from 'react-toastify';
import api from "../../../api";

let rndno = JSON.parse(localStorage.getItem('rndno'));

export const getQuotation = (detail) => async (dispatch) => {
  try 
  {
      let data = {
          quotation_id : detail
      };
      const res = await api.post(`/quotation/getQuotation`,data);
      localStorage.setItem('rndno',res.data.data.rndno);
      localStorage.setItem('total_amount',res.data.data.quotation.amount);
      localStorage.setItem('fitting',res.data.data.quotation.gst_amount);
      localStorage.setItem('packing',res.data.data.quotation.packing);
      // localStorage.setItem('total_amount',res.data.data.quotation.total_amount);
      // localStorage.setItem('grand_total',res.data.data.quotation.grand_total);

        dispatch({
          type: QUOTATION_DETAIL,
          payload: res.data.data.quotation,
        });
        dispatch({
          type: GET_IMPORTED_READY,
          payload: res.data.data.ready,
        });
        dispatch({
          type: GET_IMPORTED_ORDER,
          payload: res.data.data.order,
        });
        dispatch({
          type: GET_QUOTATION_NAME,
          payload: res.data.data.qt_no,
        });
        
        const res1 = await api.get(`/setting/getBranch/`+res.data.data.quotation.branch_id);
        dispatch({
          type: BRANCH_DETAIL,
          payload: res1.data.data,
        });

        dispatch({
          type: GET_TOTAL_AMOUNT,
          payload: res.data.data.quotation.amount,
        });
        // dispatch({
        //   type: FITTING_AMOUNT,
        //   payload: res.data.data.quotation.gst_amount,
        // });

        
        dispatch({
          type: GROSS_TOTAL,
          payload: res.data.data.quotation.total_amount,
        });

        // dispatch({
        //   type: PACKING_AMOUNT,
        //   payload: res.data.data.quotation.packing,
        // });

        dispatch({
          type: BALANCE_AMT,
          payload: res.data.data.quotation.grand_total,
        });

  } catch (err) { }
};

/*****************************************************  Ready Product ******************************************** */
// Calculation Ready Product
export const onChangeCalcReady = (data)  => async (dispatch) => {    
  let qty = localStorage.getItem('editqty');
  let price = localStorage.getItem('editnetprice');
  let calc = parseFloat(qty) * parseInt(price);
  console.log(calc);
  dispatch({
    type: READY_NET_AMOUNT,
    payload: calc,
  });
};

//add imported product
export const addImportedReady = (data)  => async (dispatch) => {
  var detail= data;
  detail['session_id'] = localStorage.getItem('rndno');
  detail['product_id'] = localStorage.getItem('product_id');
  detail['location_id'] = localStorage.getItem('location');
 
  const body = JSON.stringify(detail);
  console.log(body);
  try {
    const res = await api.post('/quotation/orderImportedReadyProduct', body);
    dispatch({
      type: GET_IMPORTED_READY,
      payload: res.data.data,
    });

    const rndno = localStorage.getItem('rndno');
    const res1 = await api.get(`/quotation/getTotalAmount/`+rndno);
    localStorage.setItem('total_amount', res1.data.data);
    dispatch({
      type: GET_TOTAL_AMOUNT,
      payload: res1.data.data,
    });
  } catch (err) {}
};

//edit ready
// export const getEditReady = (data)  => async (dispatch) => {
//   try{
//     const res = await api.get(`/quotation/getEditReady/`+data);
//     console.log(res.data.data[0]);
//     console.log(res.data.data[0].net_amount);
//     dispatch({
//       type: EDIT_READY_PRODUCT,
//       payload: res.data.data[0],
//     });

//     localStorage.setItem('editqty',res.data.data[0].qty);
//     localStorage.setItem('editnetprice',res.data.data[0].net_amount);

//     dispatch({
//       type: EDIT_NET_AMOUNT,
//       payload: res.data.data[0].net_amount,
//     });

//   } catch(err) {}
// };

// //delete order
// export const deleteReady = (data)  => async (dispatch) => {
//   try {
//     const res = await api.get('/quotation/deleteReadyProduct/'+data);
//     dispatch({
//       type: GET_IMPORTED_READY,
//       payload: res.data.data,
//     });

//     const rndno = localStorage.getItem('rndno');
//     const res1 = await api.get(`/quotation/getTotalAmount/`+rndno);
//     localStorage.setItem('total_amount', res1.data.data);
//     dispatch({
//       type: GET_TOTAL_AMOUNT,
//       payload: res1.data.data,
//     });
//   } catch (err) {}
// };

/******************************************************  Order Product   *******************************************/

// Calculation Ready Product
export const onChangeCalcOrder = (data)  => async (dispatch) => {    
  let qty = localStorage.getItem('editqty');
  let price = localStorage.getItem('editnetprice');
  let calc = parseFloat(qty) * parseInt(price);
  console.log(calc);
  dispatch({
    type: ORDER_NET_AMOUNT,
    payload: calc,
  });
};

//add imported product
export const addImportedOrder = (data)  => async (dispatch) => {
  var detail= data;
  detail['session_id'] = localStorage.getItem('rndno');
  detail['product_id'] = localStorage.getItem('product_id');
 
  const body = JSON.stringify(detail);
  console.log(body);
  try {
    const res = await api.post('/quotation/orderImportedProductOrder', body);
    dispatch({
      type: GET_IMPORTED_ORDER,
      payload: res.data.data,
    });

    const rndno = localStorage.getItem('rndno');
    const res1 = await api.get(`/quotation/getTotalAmount/`+rndno);
    localStorage.setItem('total_amount', res1.data.data);
    dispatch({
      type: GET_TOTAL_AMOUNT,
      payload: res1.data.data,
    });
  } catch (err) {}
};

//edit order
// export const getEditOrder = (data)  => async (dispatch) => {
//   try{
//     const res = await api.get(`/quotation/getEditOrder/`+data);
//     console.log(res.data.data[0]);
//     console.log(res.data.data[0].net_amount);
//     dispatch({
//       type: EDIT_ORDER_PRODUCT,
//       payload: res.data.data[0],
//     });

//     localStorage.setItem('editqty',res.data.data[0].qty);
//     localStorage.setItem('editnetprice',res.data.data[0].net_amount);

//     dispatch({
//       type: EDIT_ORDER_NET_AMOUNT,
//       payload: res.data.data[0].net_amount,
//     });

//   } catch(err) {}
// };

//delete order 
// export const deleteOrder = (data)  => async (dispatch) => {
//   try {
//     const res = await api.get('/quotation/deleteOrderProduct/'+data);
//     dispatch({
//       type: GET_IMPORTED_ORDER,
//       payload: res.data.data,
//     });

//     const rndno = localStorage.getItem('rndno');
//     const res1 = await api.get(`/quotation/getTotalAmount/`+rndno);
//     localStorage.setItem('total_amount', res1.data.data);
//     dispatch({
//       type: GET_TOTAL_AMOUNT,
//       payload: res1.data.data,
//     });

//   } catch (err) {}
// };

export const updateQuotation = (data) => async (dispatch) => {
    const body = JSON.stringify(data);
    console.log(body);
  // try {
  //   const res = await api.post('/quotation/updateQuotation', body);
   
  //   if (res.status === 200) {
  //     toast.success('Quotation Updated Successfully');
  //       dispatch({
  //           type: UPDATE_QUOTATION,
  //           payload: res.data.data.info[0],
  //         });
  //       }
  //     } catch (err) {
  //       toast.warn('Invalid inputs! Quotation Not Updated');
  //   }
}