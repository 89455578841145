import {
    RETRIEVE_PENDING_DESIGN,
    DESIGN_MARKAS_COMPLETE
} from "../PendingDesign/actiontype";

const INITIAL_STATE = {
  currentIntegration: [],
  isLoading: false,
};

const pendingDesignReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
 
  switch (type) {
    case RETRIEVE_PENDING_DESIGN:
      return {
        ...state,
        currentIntegration: payload,
        isLoading: true,
      };
      case  DESIGN_MARKAS_COMPLETE:
        return state.filter(({ id }) => id !== payload.id);
        
      default:
        return state;
  }
};
export default pendingDesignReducer;