import {
  RETRIEVE_PENDING_To_DISPATCH
} from "../PendingToDispatch/actiontype";

const INITIAL_STATE = {
  currentIntegration: [],
};

const peningToDispatchReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
 
  switch (type) {
    case RETRIEVE_PENDING_To_DISPATCH:
      return {
        ...state,
        currentIntegration: payload,
      };
      default:
        return state;
  }
};
export default peningToDispatchReducer;