import {
    RETRIEVE_VIEW_ORDER,
    ORDER_MARKAS_DONE
} from "../ViewOrder/actiontype";

import { toast } from 'react-toastify';
import api from "../../../api";

let usertype = JSON.parse(localStorage.getItem('usertype'));
let userid = JSON.parse(localStorage.getItem('userid'));

export const getViewOrder= (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  let data = {
    usertype : usertype,
    userid : userid
  };

  try {
    const res = await api.post(`/bookSection/pendingBook`, data);
    
    dispatch({
      type: RETRIEVE_VIEW_ORDER,
      payload: res.data.data,
    });
  
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_VIEW_ORDER,
      payload: [],
    });
  }
};

export const orderMarkAsDone= (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  // let data = {
  //   id: id,
  //   conv_id : conv_id
  // };

  try {
    const res = await api.post(`/bookSection/bookOrderMarkAsDone`, data);
    if (res.status === 200) {
      toast.success(res.data.message);
    dispatch({
      type: ORDER_MARKAS_DONE,
      payload: res.data.data,
    });
  }
  } catch (err) {
    
    dispatch({
      type: ORDER_MARKAS_DONE,
      payload: [],
    });
  }
};



