import React, { useRef, useState, useEffect } from 'react';
import { Row, Col, Container, Dropdown, Button, Modal, Table } from 'react-bootstrap';
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import KErrorMessage from "../../../components/ErrorMessage/KErrorMessage";
import PreviewImage from "../../../components/UploadImage/PreviewImage";
import MaterialTable from 'material-table';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintIcon from '@mui/icons-material/Print';
import { connect } from "react-redux";
import { importedProduct, getImportedProduct, getBrandList, getProductCategory, getLeatherCategory, getLeatherType, getLeatherColour, getColourCode, deleteImportedProduct,getSingleImportedProduct, getQrCodeData} from '../../../redux/Product/ImportedProduct/action';
import Select from 'react-select'
import Zoom from 'react-medium-image-zoom'
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import Loader from '../../../components/Loader/Loader';
import { useReactToPrint } from "react-to-print";

const SUPPORTED_FORMATS =["image/jpg", "image/jpeg", "image/png"];
const validationSchema = yup.object({
  // brand: yup.string().required("Brand Name is Required"),
  // productcode: yup.string().required("Product Code is Required"),
  // category: yup.string().required("category is Required"),
  // leathertype: yup.string().required("Leather Type is Required"),
  // rdp: yup.string().required("RDP is Required"),
  // colourcode: yup.string().required("Colour Code is Required"),
  // productname: yup.string().required("Product Name is Required"),
  // size: yup.string().required("Size is Required"),
  // leathercategory: yup.string().required("Leather Category is Required"),
  // leathercolour: yup.string().required("Leather Colour is Required"),
  // specification: yup.string().required("Specification is Required"),
  // netprice: yup.string().required("Net Price is Required"),
  files: yup
  .mixed()
  .nullable()
  .required("Product Image is Required!")
  .test(
      "FILE_SIZE",
      "Uploaded file is too big.",
      (value) => !value || (value && value.size <= 1024*1024)
  )
  .test(
      "FILE_FORMAT",
      "Uploaded file has supported format.",
      (value) => !value || (value && SUPPORTED_FORMATS.includes(value?.type))
  )
});

  const ImportedProduct = ({ importedProduct, getImportedProduct,currentIntegration,brandlist, getBrandList, getProductCategory, categorylist, getLeatherCategory, leathercategorylist, getLeatherType, leathertypelist, getLeatherColour, leathercolourlist, getColourCode, colourcodelist, deleteImportedProduct,getSingleImportedProduct,singleImportedProduct,isLoading, getQrCodeData,qrCodeData }) =>  {  useEffect(() => {
    getImportedProduct();
    getBrandList();
    getProductCategory();
    getLeatherCategory();
    getLeatherType();
    getLeatherColour();
    getColourCode();
    
  }, []);

const [show, setShow] = useState(false);
const modalAddClose = () => setShow(false);
const modalAddShow = () => setShow(true);

const [show2, setShow2] = useState(false);
const modalEditClose = () => setShow2(false);
const modalEditShow = () => setShow2(true);

const [show3, setShow3] = useState(false);
const modalPrintClose = () => setShow3(false);
const modalPrintShow = () => setShow3(true);

const [visible, setVisible] = useState(false);
const handleVisibility = () => {
    setVisible(prev => !prev);
};

const [loading, setLoading] = useState(false);
useEffect(() => {
    if (isLoading) {
        setLoading(true);
    }
  });

const fileRef = useRef(null);

const [tableData, setTableData] = useState([]);
useEffect(() => {
  if(currentIntegration)
  {
    setTableData(currentIntegration); 
  }
}, [currentIntegration])

  const columns = [
    { title: "Image", field: 'image', render: rowData => <img src={process.env.REACT_APP_IMAGE_URL+'/'+rowData.image} alt={rowData.image} style={{width: 60, height:60}} /> },
    { title: "Brand", field: "brand" },
    { title: "Name", field: "name" },
    { title: "Model No.", field: "model_no" },
    { title: "Category", field: "category" },
    { title: "Leather Category", field: "leather_category" },
    { title: "Leather Type", field: "leather_type" },
    { title: "Leather Colour", field: "leather_colour" },
    { title: "Colour Code", field: "colour_code" },
    { title: "Specification", field: "spec" },
    { title: "RDP", field: "rdp" },
    { title: "Net Price", field: "amount" },
  ]

//Get brand list
const [brandList, setBrandList] = useState(null);

useEffect(() => {
  if(brandlist)
  {
    setBrandList(brandlist); 
  }
}, [brandlist])

//Get category list
const [categoryList, setCategoryList] = useState(null);
useEffect(() => {
if(categorylist)
{
  setCategoryList(categorylist); 
}
}, [categorylist])

//Get Leather category list
const [leatherCategoryList, setLeatherCategoryList] = useState(null);
useEffect(() => {
if(leathercategorylist)
{
  setLeatherCategoryList(leathercategorylist); 
}
}, [leathercategorylist])


//Get Leather Type list
const [leatherTypeList, setLeatherTypeList] = useState(null);
useEffect(() => {
if(leathertypelist)
{
  setLeatherTypeList(leathertypelist); 
}
}, [leathertypelist])


//Get Leather Colour list
const [leatherColourList, setLeatherColourList] = useState(null);
useEffect(() => {
if(leathercolourlist)
{
  setLeatherColourList(leathercolourlist); 
}
}, [leathercolourlist])


//Get  Colour Code list
const [colourCodeList, setColourCodeList] = useState(null);
useEffect(() => {
if(colourcodelist)
{
  setColourCodeList(colourcodelist); 
}
}, [colourcodelist])


const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', type: ''})

  const editImportedProduct = async (e) => {
    console.log('view:'+e);
      setShow2({
      isOpen: true,
      })
      getSingleImportedProduct(e);
  }

  const printImportedProduct = async (e) => {
    setIsShown(isShown => false);
    setIsButton(isButton => true);
    console.log('view:'+e);
      setShow3({
      isOpen: true,
      })
      getQrCodeData(e);
  }

  const importedProductDelete = async (e) => {
    //console.log(e);
    setConfirmDialog({
    isOpen: true,
    title: 'Are You Sure To Delete This Product?',
    type: false,
    onConfirm: () => { 
      deleteImportedProduct(e);
        setConfirmDialog({ ...confirmDialog, isOpen: false })
    }
    })
  }   

// *****************************************************************
const [temp, setTemp] = useState("");
const [word, setWord] = useState("");
const [size, setSize] = useState(200);
const [bgColor, setBgColor] = useState("ffffff");
const [qrCode, setQrCode] = useState("");
const [isShown, setIsShown] = useState(false);
const [isButton, setIsButton] = useState(true);
// Changing the URL only when the user
// changes the input
useEffect(() => {
  setQrCode
(`http://api.qrserver.com/v1/create-qr-code/?data=${word}&size=${size}x${size}&bgcolor=${bgColor}`);
}, [word, size, bgColor]);

// Updating the input word when user
// click on the generate button

function handleClick() {
  setWord(qrCodeData.id);
  setIsShown(isShown => true);
  setIsButton(isButton => false);
}
// *******************************************************************

const componentRef = useRef();
const handlePrint = useReactToPrint({
  content: () => componentRef.current,
});

  return (
    <>
      <Container fluid className="main-content">
        <Row>
            <Col className='d-flex justify-content-between mb-2'>
                <h3 className="main-title">Imported Product</h3>
                <button type="button" className="btn btn-md btn-primary d-none d-sm-block" onClick={modalAddShow}><i className="fa-solid fa-plus"></i> Create</button>
                <div className="d-flex justify-content-between d-block d-sm-none">
                  <div className="magnifierContainer">
                    <button type="button" className="btn btn-md btn-warning text-white roundedcircle mr-3" onClick={handleVisibility}><i className="fa-solid fa-search"></i></button>
                  </div>
                  <button type="button" className="btn btn-md btn-primary d-block d-sm-none roundedcircle ml-3"  onClick={modalAddShow}><i className="fa-solid fa-plus"></i></button>
                </div>
            </Col>
            <Col lg={"12"} className='mb-2 d-block d-sm-none'>
              <div className={visible ? "open" : "close"}>
                  <input placeholder="Search..." type="text" className="form-control is-open mr-3 mb-0 search-input"/>
              </div>
            </Col>
        </Row>
        {loading ? <div>
        <Row className=" d-none d-sm-block mt-2">
          <Col lg={"12"}>
            <div style={{display: 'table', tableLayout:'fixed', width:'100%'}}>
            <MaterialTable columns={columns} data={tableData}
              actions={[
                {
                  icon: () => <EditIcon color={'primary'} />,
                  tooltip: 'Edit',
                  onClick: (event, rowData) => editImportedProduct(rowData.id)
                },
                
                {
                  icon: () => <DeleteIcon color={'error'} />,
                  tooltip: 'Delete',
                  onClick: (event, rowData) => importedProductDelete(rowData.id)
                },
                {
                  icon: () => <PrintIcon color={'warning'} />,
                  tooltip: 'Print',
                  onClick: (event, rowData) => printImportedProduct(rowData.id)
                },
              ]}
              options={{
                sorting: true, search: true,
                paging: true, pageSizeOptions: [10, 25, 50, 100], pageSize: 10,
                exportButton: true,
                exportAllData: true, exportFileName: "Imported Product", actionsColumnIndex: -1,
                autoheight: true,
                // headerStyle: { background: "#f44336",color:"#fff"}
              }}
              title="Imported Product"
            />
            </div>
        </Col>
        </Row>
        <Row className="d-block d-sm-none">
          {currentIntegration.map((imported, index) => ( 
             <Col xs={"12"} key={index}>
              <div className="card mb-2">
                <div className="card-header d-flex justify-content-between pb-0">
                    <h6>{imported.name}</h6>
                  <Dropdown>
                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                      <i className="ri-more-2-line"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => editImportedProduct(imported.id)}><i className="ri-edit-box-line text-primary"></i> Edit</Dropdown.Item>
                      <Dropdown.Item onClick={() => importedProductDelete(imported.id)}><i className="far fa-trash-alt text-danger"></i> Delete</Dropdown.Item>
                      <Dropdown.Item onClick={() => printImportedProduct(imported.id)}><i className="far fa-trash-alt text-danger"></i> Print</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="card-body pt-1">
                  <Row>
                    <Col xs={4}>
                        <div>
                          <Zoom> <img src={process.env.REACT_APP_IMAGE_URL+'/'+imported.image} alt={imported.image} className='product-image' width="100%" height="60px"/></Zoom>
                       
                        </div>
                      </Col>
                      <Col xs={8}>
                        <p className="card-text mb-0"><span className="user-info">Brand:</span> {imported.brand}</p>
                        <p className="card-text mb-0"><span className="user-info">Model No.:</span> {imported.model_no}</p>
                        <p className="card-text mb-0"><span className="user-info">Category:</span> {imported.category}</p>
                       
                      </Col>
                      <Col lg={12}>
                      <p className="card-text mb-0"><span className="quotation-info">Leather Category:</span> {imported.leather_category}</p>
                        <p className="card-text mb-0"><span className="quotation-info">Leathert Type:</span> {imported.leather_type}</p>
                        <p className="card-text mb-0"><span className="quotation-info">Leather Colour:</span> {imported.leather_colour}</p>
                        <p className="card-text mb-0"><span className="quotation-info">Colour Code:</span> {imported.colour_code}</p>
                        <p className="card-text mb-0"><span className="quotation-info">Specification:</span> {imported.spec}</p>
                        <p className="card-text mb-0"><span className="quotation-info">RDP:</span> {imported.rdp}</p>
                        <p className="card-text mb-0"><span className="quotation-info">Net Price:</span> {imported.amount}</p>
                      </Col>
                  </Row>
                </div>
              </div>
            </Col>
             ))}  
        </Row>
        </div> : <Loader/>}
      </Container>
      <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
      />
    {/* Add Imported Product Modal Start */}
    <Modal 
          size="lg"
          centered
          show={show} 
          onHide={modalAddClose}>
            <Formik
                validationSchema={validationSchema}
                initialValues={{
                 brand: "",
                 model: "",
                 category: "",
                 leathertype: "",
                 rdp: "",
                 colourcode: "",
                 productname: "",
                 size: "",
                 leathercategory: "",
                 leathercolour: "",
                 specification: "",
                 amount: "",
                 files: null,
                }}
                onSubmit={(values) => {
                  importedProduct(values);
                }}
            >
                 {({values, setFieldValue}) => (
                <Form>
                <Modal.Header closeButton>
                    <Modal.Title>Add Imported Poduct</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                      <Col lg={"6"}>
                          <label>Brand Name</label>
                          <Select 
                            //value={defaultValueBrand(brandlist, values.name)} 
                            options={brandList}
                            name="brand"
                            onChange={newValue => setFieldValue("brand", newValue.value)} 
                            className='mb-2' />
                          
                          <label>Product Code</label>
                          <Field name="model" type="text" className="form-control"/>
                          
                          <label>Product Category</label>
                          <Select 
                            //value={defaultValueCategory(categorylist, values.name)}
                            options={categoryList}
                            name="category" 
                            onChange={newValue => setFieldValue("category", newValue.value)} 
                            className='mb-2' 
                          />
                          
                          <label>Leather Type</label>
                          <Select 
                            //value={defaultValueLeatherType(leathertypelist, values.name)}
                            options={leatherTypeList} 
                            name="leathertype"
                            onChange={newValue => setFieldValue("leathertype", newValue.value)}  
                            className='mb-2'
                          />
                          
                          <label>Colour Code</label>
                          <Select
                             //value={defaultValueColourCode(colourcodelist, values.name)} 
                            options={colourCodeList} 
                            name="colourcode" 
                            onChange={newValue => setFieldValue("colourcode", newValue.value)} 
                            className='mb-2' 
                          />
                          
                          <label>RDP</label>
                          <Field name="rdp" type="text" className="form-control"/>
                      </Col>
                      <Col lg={"6"}>
                          <label>Product Name</label>
                          <Field name="productname" type="text" className="form-control" />
                          
                          <label>Size</label>
                          <Field name="size" type="text" className="form-control" />
                          
                          <label>Leather Category</label>
                          <Select
                            //value={defaultValueLeatherCategory(leathercategorylist, values.name)} 
                            options={leatherCategoryList}
                            name="leathercategory"
                            onChange={newValue => setFieldValue("leathercategory", newValue.value)} 
                            className='mb-2'
                          />
                          
                          <label>Leather Colour</label>
                          <Select 
                            //value={defaultValueLeatherColour(leathercolourlist, values.name)}
                            options={leatherColourList} 
                            name="leathercolour"
                            onChange={newValue => setFieldValue("leathercolour", newValue.value)}  
                            className='mb-2' 
                          />
                          
                          <label>Specification</label>
                          <Field name="specification" type="text" className="form-control" />
                          
                          <label>Net Price</label>
                          <Field name="amount" type="text" className="form-control" />
                      </Col>
                      <Col lg={"12"}>
                          <label className='d-block'>Upload</label>
                          <div  className='d-flex'>
                              <input
                                  ref={fileRef}
                                  className="form-control"
                                  type="file"
                                  name="files"
                                  onChange={(event) => {setFieldValue("files", event.target.files[0]);}}
                              />
                                <span className='upload-img'>{values.files && <PreviewImage file={values.files}/>}</span>
                              <button className="btn-upload btn mt-3" hidden onClick={() => {fileRef.current.click();}}>Upload</button>
                          </div>
                          <KErrorMessage name="files"/>
                      </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={modalAddClose}>Close</Button>
                    <Button variant="primary" type="submit">Submit</Button>
                </Modal.Footer>
                </Form>
                  )}
            </Formik>
        </Modal>
        {/* Add Imported Product Modal End */}
        {/* Edit Imported Product Modal Start */}
        <Modal 
          size="lg"
          centered
          show={show2} 
          onHide={modalEditClose}>
            <Formik
                enableReinitialize={true}
                validationSchema={validationSchema}
                initialValues={{
                 brand: singleImportedProduct.brand_id,
                 model: singleImportedProduct.model_no,
                 category: singleImportedProduct.category_id,
                 leathertype: singleImportedProduct.leather_type_id,
                 rdp: singleImportedProduct.rdp,
                 colourcode: singleImportedProduct.colour_code_id,
                 productname: singleImportedProduct.name,
                 size: singleImportedProduct.size,
                 leathercategory: singleImportedProduct.leather_category_id,
                 leathercolour: singleImportedProduct.leather_colour_id,
                 specification: singleImportedProduct.spec,
                 amount: singleImportedProduct.amount,
                 files: null,
                }}
                onSubmit={(values) => {
                  console.log(values);
                }}
            >
                 {({values, setFieldValue}) => (
                <Form>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Imported Poduct</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                      <Col lg={"6"}>
                          <label>Brand Name</label>
                          <Select 
                            defaultValue={{ label: singleImportedProduct.brand_name, value: singleImportedProduct.brand_id }}
                            options={brandList}
                            name="brand"
                            onChange={newValue => setFieldValue("brand", newValue.value)} 
                            className='mb-2' />
                          
                          <label>Product Code</label>
                          <Field name="model" type="text" className="form-control"/>
                          
                          <label>Product Category</label>
                          <Select 
                            defaultValue={{ label: singleImportedProduct.category_name, value: singleImportedProduct.category_id }}
                            options={categoryList}
                            name="category" 
                            onChange={newValue => setFieldValue("category", newValue.value)} 
                            className='mb-2' 
                          />
                          
                          <label>Leather Type</label>
                          <Select 
                            defaultValue={{ label: singleImportedProduct.leather_type_name, value: singleImportedProduct.leather_type_id }}
                            options={leatherTypeList} 
                            name="leathertype"
                            onChange={newValue => setFieldValue("leathertype", newValue.value)}  
                            className='mb-2'
                          />
                          
                          <label>Colour Code</label>
                          <Select
                            defaultValue={{ label: singleImportedProduct.colour_code_name, value: singleImportedProduct.colour_code_id }}
                            options={colourCodeList} 
                            name="colourcode" 
                            onChange={newValue => setFieldValue("colourcode", newValue.value)} 
                            className='mb-2' 
                          />
                          
                          <label>RDP</label>
                          <Field name="rdp" type="text" className="form-control"/>
                      </Col>
                      <Col lg={"6"}>
                          <label>Product Name</label>
                          <Field name="productname" type="text" className="form-control" />
                          
                          <label>Size</label>
                          <Field name="size" type="text" className="form-control" />
                          
                          <label>Leather Category</label>
                          <Select
                            defaultValue={{ label: singleImportedProduct.leather_category_name, value: singleImportedProduct.leather_category_id }}
                            options={leatherCategoryList}
                            name="leathercategory"
                            onChange={newValue => setFieldValue("leathercategory", newValue.value)} 
                            className='mb-2'
                          />
                          
                          <label>Leather Colour</label>
                          <Select 
                            defaultValue={{ label: singleImportedProduct.leather_colour_name, value: singleImportedProduct.leather_colour_id }}
                            options={leatherColourList} 
                            name="leathercolour"
                            onChange={newValue => setFieldValue("leathercolour", newValue.value)}  
                            className='mb-2' 
                          />
                          
                          <label>Specification</label>
                          <Field name="specification" type="text" className="form-control" />
                          
                          <label>Net Price</label>
                          <Field name="amount" type="text" className="form-control" />
                      </Col>
                      <Col lg={"6"}>
                          <label className='d-block'>Upload</label>
                          <div  className='d-flex'>
                              <input
                                  ref={fileRef}
                                  className="form-control"
                                  type="file"
                                  name="files"
                                  onChange={(event) => {setFieldValue("files", event.target.files[0]);}}
                              />
                                <span className='upload-img'>{values.files && <PreviewImage file={values.files}/>}</span>
                              <button className="btn-upload btn mt-3" hidden onClick={() => {fileRef.current.click();}}>Upload</button>
                          </div>
                          <KErrorMessage name="files"/>
                      </Col>
                      <Col lg={"6"}>
                        <label className='d-block'>Uploaded Image</label>
                        <img src={process.env.REACT_APP_IMAGE_URL+'/'+singleImportedProduct.image} alt={singleImportedProduct.image} width="50px" height="50px"  />
                      </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={modalEditClose}>Close</Button>
                    <Button variant="primary" type="submit">Submit</Button>
                </Modal.Footer>
                </Form>
                  )}
            </Formik>
        </Modal>
      {/* Edit Imported Product Modal End */}
      {/* Edit Imported Product Modal Start */}
      <Modal 
          size="md"
          centered
          show={show3} 
          onHide={modalPrintClose}>
            <Modal.Header closeButton>
                  <Modal.Title>Print QR</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col lg={6}>
                    {isButton && (
                      <div className="input-box">
                        <div className="gen">
                          <Button variant="primary" type="button" onClick={handleClick}>Generate QR</Button>
                        </div>
                      </div>
                    )}
                </Col>
                <Col lg={6}>
                  <div className='text-right'>
                    <Button variant="warning" className="text-white" type="button"  onClick={handlePrint}><i class="ri-printer-line"></i> Print</Button>
                  </div>
                </Col>
              </Row>
              <div ref={componentRef} className="qrcode-print">
              <Row>
                <Col lg={{ span: 8, offset: 2 }}>
                {isShown && (
                <div className="output-box text-center">
                  <img src={qrCode} alt="" />
                  <a href={qrCode} download="QRCode">
                  {/* <button type="button">Download</button> */}
                  </a>
                </div>
              )}
                </Col>
              </Row>
              <Row>
                <Col lg={{ span: 8, offset: 2 }}>
                  <Table striped bordered hover className='mt-3'>
                    <tbody>
                      <tr>
                        <td>ITEM</td>
                        <td>{qrCodeData.name}</td>
                      </tr>
                      <tr>
                        <td>MODEL</td>
                        <td>{qrCodeData.model_no}</td>
                      </tr>
                      <tr>
                        <td>MAKE</td>
                        <td>{qrCodeData.brand}</td>
                      </tr>
                      <tr>
                        <td>COLOUR</td>
                        <td>{qrCodeData.colour_code}</td>
                      </tr>
                      <tr>
                        <td>CODE</td>
                        <td>{qrCodeData.amount}/-*</td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
              </div>
            </Modal.Body>
        </Modal>
      {/* Edit Imported Product Modal End */}
    </>
  )
}

const mapStateToProps = (state) => {
  console.log(state);
  return {
     currentIntegration: state.importedProducts.currentIntegration,
     isLoading: state.importedProducts.isLoading,
     brandlist: state.importedProducts.brands,
     categorylist: state.importedProducts.category,
     leathercategorylist: state.importedProducts.leathercategory,
     leathertypelist: state.importedProducts.leathertype,
     leathercolourlist: state.importedProducts.leathercolour,
     colourcodelist: state.importedProducts.colourcode,
     singleImportedProduct: state.importedProducts.singleImportedProduct,
     qrCodeData: state.importedProducts.qrCode,

   }
 }

 export default connect(mapStateToProps,{importedProduct, getImportedProduct,getBrandList,getProductCategory,getLeatherCategory,getLeatherType,getLeatherColour,getColourCode,deleteImportedProduct,getSingleImportedProduct,getQrCodeData})(ImportedProduct);