import {
    CREATE_IMPORTEDPRODUCT,
    RETRIEVE_IMPORTEDPRODUCT,
    UPDATE_IMPORTEDPRODUCT,
    DELETE_IMPORTEDPRODUCT,
    SET_BRANDS,
    SET_PRODUCT_CATEGORY,
    SET_LEATHER_CATEGORY,
    SET_LEATHER_TYPE,
    SET_LEATHER_COLOUR,
    SET_COLOUR_CODE,
    RETRIEVE_SINGLE_IMPORTEDPRODUCT,
    PRINT_QRCODE
} from "../ImportedProduct/actiontype";


const INITIAL_STATE = {
  currentIntegration: [],
  brands: [],
  category: [],
  leathercategory: [],
  leathertype: [],
  leathercolour: [],
  colourcode: [],
  singleImportedProduct: [],
  isLoading: false,
  qrCode: [],
};

const importedProductReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case CREATE_IMPORTEDPRODUCT:
      return {
        ...state
      };
    case RETRIEVE_IMPORTEDPRODUCT:
      return {
        ...state,
        currentIntegration: payload,
        isLoading: true,
      };
    case RETRIEVE_SINGLE_IMPORTEDPRODUCT:
      return {
        ...state,
        singleImportedProduct: payload,
      };
      case SET_BRANDS:
      return {
        ...state,
        brands: payload,
      };
      case SET_PRODUCT_CATEGORY:
      return {
        ...state,
        category: payload,
      };
      case SET_LEATHER_CATEGORY:
      return {
        ...state,
        leathercategory: payload,
      };
      case SET_LEATHER_TYPE:
      return {
        ...state,
        leathertype: payload,
      };
      case SET_LEATHER_COLOUR:
      return {
        ...state,
        leathercolour: payload,
      };
      case  SET_COLOUR_CODE:
      return {
        ...state,
        colourcode: payload,
      };
      case PRINT_QRCODE:
      return {
        ...state,
        qrCode: payload,
      };
    // case UPDATE_USERS:
    //   return state.map((brand) => {
    //     if (brand.id === payload.id) {
    //       return {
    //         ...brand,
    //         ...payload,
    //       };
    //     } else {
    //       return brand;
    //     }
    //   });
    case  DELETE_IMPORTEDPRODUCT:
      return {
        ...state,
        currentIntegration: state.currentIntegration.filter((item) => parseInt(item.id) !== parseInt(payload)),
      };
    default:
      return state;
  }
};


export default importedProductReducer;