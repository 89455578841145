export const MenuItems = [
    {
      title: 'Brand',
      path: '/brand',
      cName: 'nav-link'
    },
    {
      title: 'Polish',
      path: '/polish',
      cName: 'nav-link'
    },
    {
      title: 'Fabric',
      path: '/fabric',
      cName: 'nav-link'
    },
    {
      title: 'Unit',
      path: '/unit',
      cName: 'nav-link'
    },
    {
      title: 'Category',
      path: '/category',
      cName: 'nav-link'
    },
    {
      title: 'Colour Code',
      path: '/colourcode',
      cName: 'nav-link'
    },
    {
      title: 'Leather Category',
      path: '/leathercategory',
      cName: 'nav-link'
    },
    {
      title: 'Leather Type',
      path: '/leathertype',
      cName: 'nav-link'
    },
    {
      title: 'Leather Colour',
      path: '/leathercolour',
      cName: 'nav-link'
    },
    {
      title: 'Imported Product',
      path: '/product-imported',
      cName: 'nav-link'
    },
    {
      title: 'Assign Product',
      path: '/assign-product',
      cName: 'nav-link'
    }
    
  ];
  export const User = [
    {
      title: 'Users',
      path: '/users',
      cName: 'nav-link'
    },
    {
      title: 'Customers',
      path: '/customers',
      cName: 'nav-link'
    }
    
  ];
  export const Vendor = [
    {
      title: 'Vendor',
      path: '/vendor',
      cName: 'nav-link'
    },
    {
      title: 'Incentive',
      path: '/vendor-incentive',
      cName: 'nav-link'
    },
    {
      title: 'Paid Incentive',
      path: '/vendor-paid-incentive',
      cName: 'nav-link'
    }
    
  ];
  export const Order = [
    // {
    //   title: 'Received Order',
    //   path: '/received-order',
    //   cName: 'nav-link'
    // },
    {
      title: 'Unapproved Order',
      path: '/order',
      cName: 'nav-link'
    },
    {
      title: 'Approved List',
      path: '/approved-order',
      cName: 'nav-link'
    },
    {
      title: 'Delivery Due List',
      path: '/delivery-due-order',
      cName: 'nav-link'
    },
    {
      title: 'Expected Delivery Date',
      path: '/expected-delivery-date',
      cName: 'nav-link'
    },
    {
      title: 'Out For Delivery',
      path: '/out-for-delivery',
      cName: 'nav-link'
    },
    {
      title: 'Delivered List',
      path: '/delivered-order',
      cName: 'nav-link'
    },
    {
      title: 'Declined List',
      path: '/declined-order',
      cName: 'nav-link'
    },
    {
      title: 'Payment List',
      path: '/payment',
      cName: 'nav-link'
    },
    // {
    //   title: 'Make To Order',
    //   path: '/make-order',
    //   cName: 'nav-link'
    // },
    {
      title: 'Orders',
      path: '/make-imported',
      cName: 'nav-link'
    }
    
  ];
  export const ManagementOrder = [
    
    {
      title: 'Unapproved Order',
      path: '/order',
      cName: 'nav-link'
    },
    {
      title: 'Approved List',
      path: '/approved-order',
      cName: 'nav-link'
    },
    {
      title: 'Delivery Due List',
      path: '/delivery-due-order',
      cName: 'nav-link'
    },
    {
      title: 'Expected Delivery Date',
      path: '/expected-delivery-date',
      cName: 'nav-link'
    },
    {
      title: 'Out For Delivery',
      path: '/out-for-delivery',
      cName: 'nav-link'
    },
    
    {
      title: 'Payment List',
      path: '/payment',
      cName: 'nav-link'
    },
    {
      title: 'Make To Order',
      path: '/make-order',
      cName: 'nav-link'
    },
    {
      title: 'Orders',
      path: '/make-imported',
      cName: 'nav-link'
    }
    
    
  ];
  export const Setting = [
    {
      title: 'User Type',
      path: '/usertype',
      cName: 'nav-link'
    },
    {
      title: 'Branch',
      path: '/branch',
      cName: 'nav-link'
    }
    
  ];
  export const Incentive = [
    {
      title: 'Inentive List',
      path: '/incentive',
      cName: 'nav-link'
    },
    {
      title: 'Paid Incentive',
      path: '/paid-incentive',
      cName: 'nav-link'
    }
    
  ];
  export const Quotation = [
    {
      title: 'Quotation',
      path: '/quotation',
      cName: 'nav-link'
    },
    {
      title: 'Approved List',
      path: '/approved-quotation',
      cName: 'nav-link'
    },
    {
      title: 'Declined List',
      path: '/declined-quotation',
      cName: 'nav-link'
    }
    
  ];
  export const Report = [
    {
      title: 'Branch Wise',
      path: '/branch-wise-report',
      cName: 'nav-link'
    },
    {
      title: 'Executive Wise',
      path: '/executive-wise-report',
      cName: 'nav-link'
    },
    {
      title: 'Consolidated Report',
      path: '/consolidated-report',
      cName: 'nav-link'
    },
    {
      title: 'Product Wise Report',
      path: '/product-wise-report',
      cName: 'nav-link'
    },
    {
      title: 'Stock Report',
      path: '/stock-report',
      cName: 'nav-link'
    },
    {
      title: 'Columner Report',
      path: '/columner-report',
      cName: 'nav-link'
    }
    
  ];
  export const Payment = [
    {
      title: 'Receive Payment',
      path: '/receive-payment',
      cName: 'nav-link'
    },
    {
      title: 'Approved Payment',
      path: '/approved-payment',
      cName: 'nav-link'
    }
    
  ];
  export const AccountIncentive = [
    {
      title: 'Due Incentive',
      path: '/due-incentive',
      cName: 'nav-link'
    },
    {
      title: 'Paid Incentive',
      path: '/account-paid-incentive',
      cName: 'nav-link'
    }
    
  ];
  