import {
    CREATE_LEATHERCATEGORY,
    RETRIEVE_LEATHERCATEGORY,
    UPDATE_LEATHERCATEGORY,
    DELETE_LEATHERCATEGORY
} from "../LeatherCategory/actiontype";

const INITIAL_STATE = {
  currentIntegration: [],
  isLoading: false,
  isUpdate: false,
};
const leatherCategoryReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case CREATE_LEATHERCATEGORY:
      return {
        ...state,
        currentIntegration: [...state.currentIntegration, payload],
      };
    case RETRIEVE_LEATHERCATEGORY:
      return {
        ...state,
        currentIntegration: payload,
        isLoading: true,
      };
    case UPDATE_LEATHERCATEGORY:
      return {
        ...state,
        currentIntegration: payload,
        isUpdate: true,
      };
    case DELETE_LEATHERCATEGORY:
      return {
        ...state,
        currentIntegration: state.currentIntegration.filter((item) => parseInt(item.id) !== parseInt(payload)),
      };
    default:
      return state;
  }
};
export default leatherCategoryReducer;