import React from 'react';
import {useState, useEffect} from 'react'
import { Row, Col, Container, Button, Card, Modal, Badge, Table} from 'react-bootstrap';
import Select from 'react-select';
import product from '../../../assets/images/product.jpg'
import Zoom from 'react-medium-image-zoom'
import Search from "../../../components/SearchBar/Search";
import { connect } from "react-redux";
import {getStockQuery,getStockDetail} from '../../../redux/Peddler/StockQuery/action';
import Loader from '../../../components/Loader/Loader';

  const StockQuery = ({ getStockQuery,currentIntegration,isLoading,getStockDetail,peddlerStockDetail }) =>  {  useEffect(() => {
    getStockQuery();
  }, []);
  
  const [loading, setLoading] = useState(false);
  useEffect(() => {
      if (isLoading) {
          setLoading(true);
      }
    });


const [show, setShow] = useState(false);
const modalClose = () => setShow(false);
const modalShow = () => setShow(true);

const stockDetail = async (e) => {
  setShow({
      isOpen: true,
      })
  
  
  getStockDetail(e);
}
// Search for Card view start
const [searchValue, setSearchValue] = useState("");

const filterStock = ({ model }) => {
  return model.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
};
// Search for Card view end

  return (
    <>
         <Container fluid className="main-content">
            <Row>
                <Col lg={'9'}>
                    <h3 className="main-title">Stock Query</h3>
                </Col>
                <Col lg={'3'}>
                    <Search onSearch={setSearchValue} value={searchValue}/>
                </Col>
            </Row>
            {loading ? 
            <Row className='mt-4 mb-4'>
              {currentIntegration.filter(filterStock).map((stock, index) => (
                <Col lg="3" className='mb-4' key={index}>
                    <Card>
                    <Zoom zoomMargin={40}><Card.Img variant="top" src={process.env.REACT_APP_IMAGE_URL+'/'+stock.image} alt={stock.image} height="180"/></Zoom>
                    <Card.Body>
                        <Card.Title className='d-flex justify-content-between'>
                            <p className='mb-0'>{stock.model} <span><Badge bg="info">{stock.stock_qty}</Badge></span></p>   
                            <p className='mb-0'><Badge bg="secondary" className='mb-0'  onClick={() => stockDetail(stock.id)}><i className="ri-eye-line"></i></Badge></p>
                        </Card.Title>
                        <p className="card-text mb-0"><span className="quotation-info">Product Category:</span>{stock.cateory}</p>
                        <p className="card-text mb-0"><span className="quotation-info">Leather Category:</span>{stock.leather_category}</p>
                        <p className="card-text mb-0"><span className="quotation-info">Leather Type:</span>{stock.leather_type}</p>
                        <p className="card-text mb-0"><span className="quotation-info">Leather Colour:</span>{stock.leather_colour}</p>
                        <p className="card-text mb-0"><span className="quotation-info">Item Colour:</span>{stock.colour_code}</p>
                    </Card.Body>
                    </Card>
                </Col>
                 ))}
            </Row>
            :  <Loader/>}
         </Container>
         <Modal 
          size="md"
          centered
          show={show} 
          onHide={modalClose}
          >
            <Modal.Header closeButton>
                <Modal.Title>{peddlerStockDetail.model_no}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th>No</th>
                      <th>Branch Name</th>
                      <th>Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                  {peddlerStockDetail.stock && peddlerStockDetail.stock.map((detail, index) => (
                    <tr key={index}> 
                      <td>{detail.no}</td>
                      <td>{detail.name}</td>
                      <td>{detail.qty}</td>
                    </tr>
                  ))}
                  </tbody>
                </Table>
            </Modal.Body>
        </Modal>
    </>
  )
}

const mapStateToProps = (state) => {
  
    return {
       currentIntegration: state.peddlerStockQuery.currentIntegration,
       isLoading: state.peddlerStockQuery.isLoading,
       peddlerStockDetail: state.peddlerStockQuery.peddlerStockDetail,
     }
   }
  
   export default connect(mapStateToProps,{getStockQuery,getStockDetail})(StockQuery);