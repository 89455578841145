import {
  RETRIEVE_ALLOTED_ORDER,
  ADD_PRODUCT_TO_CART
} from "../AllotedOrder/actiontype";

import api from "../../../api";
import { toast } from 'react-toastify';

//let usertype = JSON.parse(localStorage.getItem('usertype'));
let userid = JSON.parse(localStorage.getItem('userid'));

export const getAllotedOrder= (data) => async (dispatch) => {
  try {
    const res = await api.get(`/cp/allotedOrder/` +userid);
    dispatch({
      type:  RETRIEVE_ALLOTED_ORDER,
      payload: res.data.data,
    });
  } catch (err) {    
    dispatch({
      type:  RETRIEVE_ALLOTED_ORDER,
      payload: [],
    });
  }
};


export const  addProductToCart= (pid,qty,) => async (dispatch) => {
  try {
    let data = {
      pid : pid,
      qty : qty,
      cp_id: userid
    };
    const res = await api.post(`/cp/addProductToCart`, data);
    if (res.status === 200) {
      toast.success(res.data.message);
      dispatch({
        type:  ADD_PRODUCT_TO_CART,
        payload: res.data.data.count,
      });
    }
  } catch (err) {  
    dispatch({
      type:  ADD_PRODUCT_TO_CART,
      payload: [],
    });
  }
};

export const  cartCount = (data) => async (dispatch) => {
  try {    
    const res = await api.get(`/cp/cartQty/`+userid);
   
    if (res.status === 200) {
      dispatch({
        type:  ADD_PRODUCT_TO_CART,
        payload: res.data.data,
      });
    }
  } catch (err) {  
    dispatch({
      type:  ADD_PRODUCT_TO_CART,
      payload: [],
    });
  }
};
