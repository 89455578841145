import {
    CREATE_DELIVERYDUE_ORDERS,
    RETRIEVE_DELIVERYDUE_ORDERS,
    UPDATE_DELIVERYDUE_ORDERS,
    DELETE_DELIVERYDUE_ORDERS,
    GET_BILLING,
    ADD_BILLING
} from "../DeliveryDueOrder/actiontype";

const INITIAL_STATE = {
  currentIntegration: [],
  billing: [],
  isLoading: false,
};

const deliveryDueOrderReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case CREATE_DELIVERYDUE_ORDERS:
      return {
        ...state
      };
    case ADD_BILLING:
      return {
        ...state
      };
    case RETRIEVE_DELIVERYDUE_ORDERS:
      return {
        ...state,
        currentIntegration: payload,
        isLoading: true,
      };
    case  GET_BILLING:
      return {
        ...state,
        billing: payload,
      };
    case UPDATE_DELIVERYDUE_ORDERS:
      return state.map((deliveryDueOrder) => {
        if (deliveryDueOrder.id === payload.id) {
          return {
            ...deliveryDueOrder,
            ...payload,
          };
        } else {
          return deliveryDueOrder;
        }
      });
    case DELETE_DELIVERYDUE_ORDERS:
      return state.filter(({ id }) => id !== payload.id);
    default:
      return state;
  }
};
export default deliveryDueOrderReducer;