import * as React from "react";
import {useState, useRef, useEffect} from 'react'
import { Row, Col, Container, Button, Modal, Badge, Dropdown } from 'react-bootstrap';
import MaterialTable from 'material-table';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import KErrorMessage from "../../../components/ErrorMessage/KErrorMessage";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import PreviewImage from "../../../components/UploadImage/PreviewImage";
import { connect } from "react-redux";
import { user, getUser, getUsertype, deleteUser, getSingleUser, getSubBranch , updateUser} from '../../../redux/Users/Users/action';
import Select from 'react-select'
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import SearchBar from "../../../components/SearchBar/SearchBar";
import Loader from "../../../components/Loader/Loader";


const SUPPORTED_FORMATS =["image/jpg", "image/jpeg", "image/png"];
const validationSchema = yup.object({
  name: yup.string().required("User Name is Required"),
  email: yup.string().email('Must be a valid email').max(255).required('Email is Required'),
  password: yup
    .string()
    .required("Password is Required"),
    // .matches(
    //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
    //   "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    // )
    
  usertype: yup.string().required("User Type is Required"),
  image: yup
  .mixed()
  .nullable()
  .required("Profile Image is Required!")
  .test(
    "FILE_SIZE",
    "Uploaded file is too big.",
    (value) => !value || (value && value.size <= 1024*1024)
  )
  .test(
    "FILE_FORMAT",
    "Uploaded file has supported format.",
    (value) => !value || (value && SUPPORTED_FORMATS.includes(value?.type))
  )
});

const validationSchema2 = yup.object({
  name: yup.string().required("User Name is Required"),
  email: yup.string().email('Must be a valid email').max(255).required('Email is Required'),
  usertype_id: yup.string().required("User Type is Required"),
  
});

const Users = ({ user,getUser,usertypelist,currentIntegration,getUsertype,deleteUser,getSingleUser,singleUser,isLoading, getSubBranch, subbranchlist, updateUser, isUpdate }) =>  {  useEffect(() => {
  getUser();
  getUsertype();
  getSubBranch();
}, []);

// const [formData, setFormData] = useState({
//   name: "",
//   email: "",
//   mobile: "",
//   password: "",
//   usertype: "",
//   subbranch: "",
//   image: ""
// });

const [visible, setVisible] = useState(false);
const handleVisibility = () => {
  setVisible(prev => !prev);
};

const [loading, setLoading] = useState(false);
useEffect(() => {
    if (isLoading) {
        setLoading(true);
    }
});

const [show, setShow] = useState(false);
const modalAddClose = () => setShow(false);
const modalAddShow = () => setShow(true);

const [show2, setShow2] = useState(false);
const modalEditClose = () => setShow2(false);
const modalEditShow = () => setShow2(true);

const fileRef = useRef(null);

const [tableData, setTableData] = useState([]);
useEffect(() => {
  if(currentIntegration)
  {
    setTableData(currentIntegration); 
  }
}, [currentIntegration])

const columns = [
{ title: 'Profile Image', field: 'image', render: rowData => <img src={process.env.REACT_APP_IMAGE_URL+'/'+rowData.image} alt={rowData.image} style={{width: 50, height:50}}/> },
{ title: "Profile Name", field: "name" },
{ title: "Email", field: "email" },
{ title: "Password", field: "password" },                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                           
{ title: "User Type", field: "usertype" },
{ title: "Sub Branch", field: "branch" },
]

const [showSubBranch, setShowSubBranch] = useState(false);

// const { name, email, mobile, usertype, subbranch,  password, image } = formData;
const [usertypeList, setUsertypeList] = useState(null);

useEffect(() => {
  if(usertypelist)
  {
    setUsertypeList(usertypelist); 
  }
}, [usertypelist])

// const onSelectChangeUsertype = (e) =>
//   {
//     setFormData({ usertype: e.value });

//     if (e.value === 12 ){
//       setShowSubBranch(true);
//     }
//     else {
//       setShowSubBranch(false);
//     }
//   }


const [subBranchList, setSubBranchList] = useState(null);

useEffect(() => {
  if(subbranchlist)
  {
    setSubBranchList(subbranchlist); 
  }
}, [subbranchlist])


// const onSelectChangeSubBranch = (e) =>
// {
//   setFormData({ ...formData, subbranch: e.value });
// }

// const onChange = (e) =>
//   {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   }

//   const imageUpload = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.files[0] });
//   };

//   const onSubmit = async (e) => {
//     e.preventDefault();
//     user({name, mobile, email, password, usertype, subbranch, image}); // action name
//   };
 
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', type: ''})

  const editUser = async (e) => {
    console.log('view:'+e);
      setShow2({
      isOpen: true,
      })
      getSingleUser(e);
  }

  const userDelete = async (e) => {
    console.log(e);
    setConfirmDialog({
    isOpen: true,
    title: 'Are You Sure To Delete This User?',
    type: false,
    onConfirm: () => { 
        deleteUser(e);
        setConfirmDialog({ ...confirmDialog, isOpen: false })
    }
    })
  } 
  
  // Search for Card view start
  const [searchValue, setSearchValue] = useState("");

  const filterUser = ({ name }) => {
      return name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
  };
  // Search for Card view end
  
  // const defaultValue = (usertypelist, value) => {
  //   //console.log("value here", value);
  //   return usertypelist ? usertypelist.find(option => option.value === value) : "";
  // };

  // const defaultValueBranch = (subbranchlist, value) => {
  //   //console.log("value here", value);
  //   return subbranchlist ? subbranchlist.find(option => option.value === value) : "";
  // };
  useEffect(() => {
    if(isUpdate)
    {
      window.location.reload(false);
    }
  }, [isUpdate])
  
  return (
    <>
        <Container fluid className="main-content">
            <Row>
                <Col className='d-flex justify-content-between mb-2'>
                    <h3 className="main-title">Users</h3>
                    <button type="button" className="btn btn-md btn-primary d-none d-sm-block" onClick={modalAddShow}><i className="fa-solid fa-plus"></i> Create</button>
                    <div className="d-flex justify-content-between d-block d-sm-none">
                      <div className="magnifierContainer">
                        <button type="button" className="btn btn-md btn-warning text-white roundedcircle mr-3" onClick={handleVisibility}><i className="fa-solid fa-search"></i></button>
                      </div>
                      <button type="button" className="btn btn-md btn-primary d-block d-sm-none roundedcircle ml-3"  onClick={modalAddShow}><i className="fa-solid fa-plus"></i></button>
                    </div>
                </Col>
                <Col lg={"12"} className='mb-2 d-block d-sm-none'>
                  <div className={visible ? "open" : "close"}>
                    <SearchBar onSearch={setSearchValue} value={searchValue} />
                  </div>
                </Col>
            </Row>
            {loading ? <div>
            <Row className="d-none d-sm-block mt-2">
              <Col lg={"12"}>
            <div style={{display: 'table', tableLayout:'fixed', width:'100%'}}>
            <MaterialTable columns={columns} data={tableData}
                 actions={[
                  {
                    icon: () => <EditIcon color={'primary'} />,
                    tooltip: 'Edit',
                    onClick: (event, rowData) => editUser(rowData.id)
                  },
                  
                  {
                    icon: () => <DeleteIcon color={'error'} />,
                    tooltip: 'Delete',
                    onClick: (event, rowData) => userDelete(rowData.id)
                  },
                ]}
                options={{
                sorting: true, search: true,
                paging: true, pageSizeOptions: [10, 25, 50, 100], pageSize: 10,
                exportButton: true,
                exportAllData: true, exportFileName: "Users", actionsColumnIndex: -1,
                // headerStyle: { background: "#f44336",color:"#fff"}
                }}
                title="Users"
            />
            </div>
          </Col>
        </Row>
        <Row className="d-block d-sm-none">
        {currentIntegration && currentIntegration.map((user, index) => ( 
          <Col xs={"12"}  key={index}>
            <div className="card mb-2">
              <div className="card-header d-flex justify-content-between pb-0">
                <div className="d-flex">
                  <img src={process.env.REACT_APP_IMAGE_URL+'/'+user.image} alt={user.image} width="50" height="50" className="roundedcircle"/>
                  <p className="mb-0"><span className="user-detail d-block">{user.name}</span><Badge bg="info" text="light" className="ml-2">{user.usertype}</Badge></p>
                </div>
                <Dropdown>
                  <Dropdown.Toggle variant="light" id="dropdown-basic">
                    <i className="ri-more-2-line"></i>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item onClick={() => editUser(user.id)}><i className="ri-edit-box-line text-primary"></i> Edit</Dropdown.Item>
                    <Dropdown.Item onClick={() => userDelete(user.id)}><i className="far fa-trash-alt text-danger"></i> Delete</Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="card-body pt-1">
                <p className="card-text mb-0"><span className="user-info">Email:</span>{user.email}</p>
                <p className="card-text mb-0"><span className="user-info">Password:</span>{user.password}</p>
              </div>
            </div>
          </Col>
        ))}
        </Row>
        </div> : <Loader/>}
        </Container>
        <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
        {/* Add Users Modal Start */}
        <Modal 
          size="md"
          centered
          show={show} 
          onHide={modalAddClose}>
            <Formik
                validationSchema={validationSchema}
                initialValues={{
                name: "",
                email: "",
                mobile: "",
                password: "",
                usertype: "",
                image: null,
                }}
                onSubmit={(values) => {
                  user(values);
                }}
            >
              {({values, setFieldValue}) => (
                <Form>
                <Modal.Header closeButton>
                    <Modal.Title>Add User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label>Name</label>
                    <Field name="name" type="text" className="form-control" />
                    <KErrorMessage name="name"/>

                    <label>Email</label>
                    <Field name="email" type="email" className="form-control"/>
                    <KErrorMessage name="email"/>

                    <label>Mobile</label>
                    <Field name="mobile" type="text" className="form-control"/>
                    {/* <KErrorMessage name="mobile"/> */}

                    <label>Password:</label>
                    <Field name="password" type="password" className="form-control"/>
                    <KErrorMessage name="password"/>

                     <label>User Type</label>
                    <Select 
                      // value={defaultValue(usertypelist, values.name)} 
                      options={usertypeList} 
                      name="usertype"
                      onChange={newValue => setFieldValue("usertype", newValue.value)} 
                      className='mb-2' 
                    /> 
                    <KErrorMessage name="usertype"/>

                      {/* {showSubBranch ? <div> */}
                    <label>Sub Branch</label>
                    <Select
                      // value={defaultValueBranch(subbranchlist, values.name)} 
                      options={subBranchList} 
                      name="subbranch" 
                      onChange={newValue => setFieldValue("subbranch", newValue.value)} 
                      className='mb-2' 
                     />
                     
                     {/* </div> : null}  */}
                    {/* <KErrorMessage name="subbranch"/> */}

                    <label className='d-block'>Upload</label>
                      <div  className='d-flex'>
                          <input
                            ref={fileRef}
                            className="form-control"
                            type="file"
                            name="image"
                            onChange={(event) => {setFieldValue("image", event.target.files[0]);}}
                          />
                            <span className='upload-img'>{values.image && <PreviewImage file={values.image}/>}</span>
                          <button className="btn-upload btn mt-3" hidden onClick={() => {fileRef.current.click();}}>Upload</button>
                      </div>
                      <KErrorMessage name="image"/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={modalAddClose}>Close</Button>
                    <Button variant="primary" type="submit">Submit</Button>
                </Modal.Footer>
                </Form>
              )}
            </Formik>
        </Modal>
        {/* Add Users Modal End */}
        {/* Edit Users Modal Start */}
        <Modal 
          size="md"
          centered
          show={show2} 
          onHide={modalEditClose}>
            <Formik
                enableReinitialize={true}
                validationSchema={validationSchema2}
                initialValues={{
                id: singleUser.id,
                name: singleUser.name,
                email: singleUser.email,
                mobile: singleUser.mobile,
                usertype_id: singleUser.usertype_id,
                branch_id: singleUser.branch_id,
                image: null,
                }}
               
                onSubmit={(values) => {
                  console.log(values);
                   updateUser(values);
                }}
                
            >
              {({values, setFieldValue}) => (
                <Form>
                <Modal.Header closeButton>
                    <Modal.Title>Edit User</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label>Name</label>
                    <Field name="name" type="text" className="form-control" />
                    <KErrorMessage name="name"/>

                    <label>Email</label>
                    <Field name="email" type="email" className="form-control"/>
                    <KErrorMessage name="email"/>

                    <label>Mobile</label>
                    <Field name="mobile" type="text" className="form-control"/>
                    {/* <KErrorMessage name="mobile"/> */}

                     <label>User Type</label>
                    <Select 
                      defaultValue={{ label: singleUser.usertype, value: singleUser.usertype_id }}
                      options={usertypeList} 
                      name="usertype_id"
                      onChange={newValue => setFieldValue("usertype", newValue.value)} 
                      className='mb-2' 
                    /> 
                    <KErrorMessage name="usertype"/>

                      {/* {showSubBranch ? <div> */}
                    <label>Sub Branch</label>
                    <Select
                      defaultValue={{ label: singleUser.branch, value: singleUser.branch_id }} 
                      options={subBranchList} 
                      name="branch_id" 
                      onChange={newValue => setFieldValue("branch_id", newValue.value)} 
                      className='mb-2' 
                     />
                     
                     {/* </div> : null}  */}
                    {/* <KErrorMessage name="subbranch"/> */}

                    <label className='d-block'>Upload</label>
                      <div  className='d-flex'>
                          <input
                            ref={fileRef}
                            className="form-control"
                            type="file"
                            name="image"
                            onChange={(event) => {setFieldValue("image", event.target.files[0]);}}
                          />
                            <span className='upload-img'>{values.image && <PreviewImage file={values.image}/>}</span>
                          <button className="btn-upload btn mt-3" hidden onClick={() => {fileRef.current.click();}}>Upload</button>
                      </div>
                      <div className='mb-3'>
                        <h6>Uploaded Image</h6>
                        <img src={process.env.REACT_APP_IMAGE_URL+'/'+singleUser.image} alt={singleUser.image} width="50px" height="50px"  />
                      </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={modalEditClose}>Close</Button>
                    <Button variant="primary" type="submit">Submit</Button>
                </Modal.Footer>
                </Form>
              )}
            </Formik>
        </Modal>
        {/* Edit Users Modal End */}
    </>

  )
}

const mapStateToProps = (state) => {
  console.log(state);
  return {
     currentIntegration: state.users.currentIntegration,
     isLoading: state.users.isLoading,
     isUpdate: state.users.isUpdate,
     usertypelist: state.users.usertype,
     subbranchlist: state.users.subBranch,
     singleUser: state.users.singleUser,

   }
 }

 export default connect(mapStateToProps,{user,getUser,getUsertype,deleteUser,getSingleUser,getSubBranch,updateUser})(Users);