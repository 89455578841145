import {
    CREATE_PRODUCTION_UNDER_PROCESSING,
    RETRIEVE_PRODUCTION_UNDER_PROCESSING,
} from "../ProductionUnderProcessing/actiontype";

const INITIAL_STATE = {
  currentIntegration: [],
};

const productionUnderProcessingReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
 
  switch (type) {
    case CREATE_PRODUCTION_UNDER_PROCESSING:
      return {
        ...state
      };
    case RETRIEVE_PRODUCTION_UNDER_PROCESSING:
      return {
        ...state,
        currentIntegration: payload,
      };
      default:
        return state;
  }
};
export default productionUnderProcessingReducer;