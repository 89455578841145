import * as React from "react";
import {useState, useEffect} from 'react'
import { Row, Col, Container, Badge, Dropdown } from 'react-bootstrap';
import MaterialTable from 'material-table';
import { connect } from "react-redux";
import {getColumnerReport} from '../../redux/Report/ColumnerReport/action';
import SearchBar from "../../components/SearchBar/SearchBar";
import Loader from "../../components/Loader/Loader";

const ColumnerReport = ({ getColumnerReport,currentIntegration, isLoading }) =>  {  useEffect(() => {
    getColumnerReport();
  }, []);

const [visible, setVisible] = useState(false);
const handleVisibility = () => {
    setVisible(prev => !prev);
};  

const [tableData, setTableData] = useState([]);
    useEffect(() => {
    if(currentIntegration)
    {
        setTableData(currentIntegration); 
    }
    }, [currentIntegration])

const columns = [
{ title: "S.NO", field: "SNO" },
{ title: "Model No", field: "ModelNo" },
{ title: "Colour Code", field: "ColourCode" },
{ title: "Category", field: "Category" },
{ title: "Specification", field: "Spec" },
{ title: "Aakriti", field: "Aakriti" },
{ title: "Aakriti Amount", field: "AakritiAmount" },
{ title: "Ambiance", field: "Ambiance" },
{ title: "Ambiance Amount", field: "AmbianceAmount" },
{ title: "Arterio", field: "Arterio" },
{ title: "Arterio Amount", field: "ArterioAmount" },
{ title: "ART GD", field: "ARTGD" },
{ title: "ART GD Amount", field: "ARTGDAmount" },
{ title: "ART SR", field: "ARTSR" },
{ title: "ART SR Amount", field: "ARTSRAmount" },
{ title: "AMB SR", field: "AMBSR" },
{ title: "AMB SR Amount", field: "AMBSRAmount" },
{ title: "AMB GD", field: "AMBGD" },
{ title: "AMB GD Amount", field: "AMBGDAmount" },
{ title: "AKT GD", field: "AKTGD" },
{ title: "AKT GD Amount", field: "AKTGDAmount" },
{ title: "AKT SR", field: "AKTSR" },
{ title: "AKT SR Amount", field: "AKTSRAmount" },
{ title: "IND GD", field: "INDGD" },
{ title: "IND GD Amount", field: "INDGDAmount" },
{ title: "IND SR", field: "INDSR" },
{ title: "IND SR Amount", field: "INDSRAmount" },
{ title: "ON SEA", field: "ONSEA" },
{ title: "ON SEA Amount", field: "ONSEAAmount" },
{ title: "BOOKED", field: "BOOKED" },
{ title: "BOOKED Amount", field: "BOOKEDAmount" },
{ title: "CLIENT ON SEA", field: "CLIENTONSEA" },
{ title: "CLIENT ON SEA Amount", field: "CLIENTONSEAAmount" },
{ title: "7/26GD", field: "7/26GD" },
{ title: "7/26GD Amount", field: "7/26GDAmount" },
{ title: "Total Stock", field: "TotalStock" },
{ title: "Total Amount", field: "TotalAmount" },
]

const [loading, setLoading] = useState(false);
useEffect(() => {
    if (isLoading) {
        setLoading(true);
    }
  });

  return (
    <>
        <Container fluid className="main-content">
          <Row>
              <Col lg={"12"} className='d-flex justify-content-between mb-2'>
                  <h3 className="main-title">Columner Report</h3>
                  <div className=" d-block d-sm-none">
                      <div className="magnifierContainer">
                          <button type="button" className="btn btn-md btn-warning text-white roundedcircle mr-3" onClick={handleVisibility}><i className="fa-solid fa-search"></i></button>
                      </div>
                  </div>
              </Col>
              <Col lg={"12"} className='mb-2 d-block d-sm-none'>
                <div className={visible ? "open" : "close"}>
                    <input placeholder="Search..." type="text" className="form-control is-open mr-3 mb-0 search-input"/>
                </div>
              </Col>
          </Row>
          { loading ? <div>
          <Row className="d-none d-sm-block mt-2">
            <Col lg={"12"} className="mt-3">
              <div style={{display: 'table', tableLayout:'fixed', width:'100%'}}>
              <MaterialTable columns={columns} data={tableData}
                  options={{
                  sorting: true, search: true,
                  paging: true, pageSizeOptions: [10, 25, 50, 100], pageSize: 10,
                  exportButton: true,
                  exportAllData: true, exportFileName: "Columner Report", actionsColumnIndex: -1,
                  // headerStyle: { background: "#f44336",color:"#fff"}
                  }}
                  title="Columner Report"
              />
              </div>
            </Col>
          </Row>
          {/* <Row className="d-block d-sm-none">
            {currentIntegration.map((report, index) => ( 
              <Col xs={"12"} key={index}>
                  <div className="card mb-2">
                      <div className="card-header d-flex justify-content-between pb-0">
                          <h6>{report.model} <Badge bg="danger" text="light" className="ml-2">{report.qty}</Badge></h6>
                          <Dropdown>
                          <Dropdown.Toggle variant="light" id="dropdown-basic">
                              <i className="ri-more-2-line"></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                              <Dropdown.Item href="#/action-1"><i className="ri-edit-box-line text-primary"></i> Edit</Dropdown.Item>
                              <Dropdown.Item href="#/action-2"><i className="far fa-trash-alt text-danger"></i> Delete</Dropdown.Item>
                          </Dropdown.Menu>
                          </Dropdown>
                      </div>
                      <div className="card-body pt-1">
                          <p className="card-text mb-0"><span className="quotation-info">Product Category:</span> {report.productCategory}</p>
                          <p className="card-text mb-0"><span className="quotation-info">Leather Category:</span> {report.leatherCategory}</p>
                          <p className="card-text mb-0"><span className="quotation-info">Leather Type:</span>5000</p>
                          <p className="card-text mb-0"><span className="quotation-info">Colour Code:</span>1000</p>
                          <p className="card-text mb-0"><span className="quotation-info">Item Colour:</span>Akriti</p>
                          <p className="card-text mb-0"><span className="quotation-info">RDP:</span> Rohit</p>
                      </div>
                  </div>
              </Col>
            ))}
            </Row> */}
            </div> : <Loader/> }
        </Container>
    </>

  )
}

const mapStateToProps = (state) => {
  
    return {
       currentIntegration: state.columnerReport.currentIntegration,
       isLoading: state.columnerReport.isLoading,
     }
   }
  
export default connect(mapStateToProps,{getColumnerReport})(ColumnerReport);