import {
    RETRIEVE_VENDOR_INCENTIVE,
    REALEASE_INCENTIVE,
    CREATE_VENDOR_INCENTIVE
} from "../VendorIncentive/actiontype";

import { toast } from 'react-toastify';
import api from "../../../api";


export const getVendorIncentive= (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  try {
    const res = await api.get(`/vendorIncentive/vendorIncentive`);
   
    
    dispatch({
      type: RETRIEVE_VENDOR_INCENTIVE,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_VENDOR_INCENTIVE,
      payload: [],
    });
  }
};


export const releaseIncentive= (id) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  
 
  try {
    const res = await api.get(`/vendorIncentive/releaseIncentive/` + id);
    
    if (res.status === 200) {
      toast.success(res.data.message);
      dispatch({
        type:  REALEASE_INCENTIVE,
        payload: res.data.data,
      });
     
    }
    
  } catch (err) {
    
    dispatch({
      type:  REALEASE_INCENTIVE,
      payload: [],
    });
  }
};


export const addVendorIncentive = (data) => async (dispatch) => {
  //const body = JSON.stringify(data);
  
  try {
    let body = {
      id: data.id,
     amount : data.amount
   };
    const res = await api.post('/vendorIncentive/vendorAddIncentive', body);
    if (res.status === 200) {
      toast.success('Incentive Added Successfully');
        // dispatch({
        //     type: CREATE_VENDOR_INCENTIVE,
        //     payload: res.data.data.info[0],
        //   });
        }
      } catch (err) {
    toast.warn('Invalid inputs! Incentive Not Added');
    // dispatch({
    //   type: REGISTER_FAIL,
    // });
  }
};