import * as React from "react";
import { useState, useEffect } from 'react'
import { Row, Col, Container, Badge, Dropdown } from 'react-bootstrap';
import MaterialTable from 'material-table';
import { connect } from "react-redux";
import {getVendorPaidIncentive} from '../../../redux/Vendor/VendorPaidIncentive/action';
import SearchBar from "../../../components/SearchBar/SearchBar";
import Loader from "../../../components/Loader/Loader";

const VendorPaidIncentive = ({ getVendorPaidIncentive,currentIntegration,isLoading }) =>  {  useEffect(() => {
    getVendorPaidIncentive();
  }, []);
    
const [visible, setVisible] = useState(false);
const handleVisibility = () => {
    setVisible(prev => !prev);
};

const [loading, setLoading] = useState(false);
useEffect(() => {
    if (isLoading) {
        setLoading(true);
    }
  });

const [tableData, setTableData] = useState([]);
useEffect(() => {
if(currentIntegration)
{
    setTableData(currentIntegration); 
}
}, [currentIntegration])

const columns = [
    { title: "Order No.", field: "orderNo" },
    { title: "Vendor Name", field: "vendorName" },
    { title: "Created By", field: "createdBy" },
    { title: "Approved By", field: "approvedBy" },
    { title: "No. Of Items", field: "qty" },
    { title: "Net Total Amount", field: "totalAmount" },
    { title: "Total Discount", field: "discount" },
    { title: "Grand Total", field: "grandTotal" },
    { title: "Date Of Order", field: "orderDate" },
    { title: "Delivery Date", field: "deliveryDate" },
    { title: "Incentive", field: "incentive" },
]

// Search for Card view start
const [searchValue, setSearchValue] = useState("");

const filterVendor = ({ vendorName }) => {
    return vendorName.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
};
// Search for Card view end

    return (
        <>
            <Container fluid className="main-content">
                <Row>
                    <Col className='d-flex justify-content-between mb-2'>
                        <h3 className="main-title">Paid Incentive</h3>
                        <div className="d-flex justify-content-between d-block d-sm-none">
                            <div className="magnifierContainer">
                                <button type="button" className="btn btn-md btn-warning text-white roundedcircle mr-3" onClick={handleVisibility}><i className="fa-solid fa-search"></i></button>
                            </div>
                        </div>
                    </Col>
                    <Col lg={"12"} className='mb-2 d-block d-sm-none'>
                        <div className={visible ? "open" : "close"}>
                            <SearchBar onSearch={setSearchValue} value={searchValue} />
                        </div>
                    </Col>
                </Row>
                {loading ? <div>
                <Row className="d-none d-sm-block mt-2">
                    <Col lg={"12"}>
                        <div style={{ display: 'table', tableLayout: 'fixed', width: '100%' }}>
                            <MaterialTable columns={columns} data={tableData}
                                options={{
                                    sorting: true, search: true,
                                    paging: true, pageSizeOptions: [10, 25, 50, 100], pageSize: 10,
                                    exportButton: true,
                                    exportAllData: true, exportFileName: "Vendor Paid Incentive", actionsColumnIndex: -1,
                                    // headerStyle: { background: "#f44336",color:"#fff"}
                                }}
                                title="Paid Incentive"
                            />
                        </div>
                    </Col>
                </Row>
                <Row className="d-block d-sm-none">
                    {currentIntegration.filter(filterVendor).map((paidincentive, index) => ( 
                    <Col xs={"12"} key={index}>
                        <div className="card mb-2">
                        <div className="card-header d-flex justify-content-between pb-0">
                            <h6>{paidincentive.vendorName}	 <Badge bg="danger" text="light" className="ml-2">{paidincentive.qty}</Badge></h6>
                        </div>
                        <div className="card-body pt-1">
                        <p className="card-text mb-0"><span className="quotation-info">Vendor Name:</span> {paidincentive.vendorName}</p>
                            <p className="card-text mb-0"><span className="quotation-info">Created By:</span> {paidincentive.createdBy}</p>
                            <p className="card-text mb-0"><span className="quotation-info">Approved By:</span> {paidincentive.approvedBy}</p>
                            <p className="card-text mb-0"><span className="quotation-info">Net Amount:</span> {paidincentive.totalAmount}</p>
                            <p className="card-text mb-0"><span className="quotation-info">Discount:</span> {paidincentive.discount}</p>
                            <p className="card-text mb-0"><span className="quotation-info">Grand Total:</span> {paidincentive.grandTotal}</p>
                            <p className="card-text mb-0"><span className="quotation-info">Order Date:</span> {paidincentive.orderDate}</p>
                            <p className="card-text mb-0"><span className="quotation-info">Delivery Date:</span> {paidincentive.expectedDate}</p>
                            <p className="card-text mb-0"><span className="quotation-info">Incentive:</span> {paidincentive.incentive}</p>
                        </div>
                        </div>
                    </Col>
                    ))}
                </Row>
                </div> : <Loader/>}
            </Container>
        </>

    )
}

const mapStateToProps = (state) => {
  
    return {
       currentIntegration: state.vendorPaidIncentive.currentIntegration,
       isLoading: state.vendorPaidIncentive.isLoading,
     }
   }
  
   export default connect(mapStateToProps,{getVendorPaidIncentive})(VendorPaidIncentive);