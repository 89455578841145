import {
    CREATE_USERS,
    RETRIEVE_USERS,
    SET_USERTYPE,
    DELETE_USERS,
    RETRIEVE_SINGLE_USERS,
    SET_SUBBRANCH,
    UPDATE_USERS
} from "../Users/actiontype";

import { toast } from 'react-toastify';
import api from "../../../api";

 export const getUsertype = (data) => async (dispatch) => {
  try {
    const res = await api.get(`/common/usertypeList`);
   
        dispatch({
          type: SET_USERTYPE,
          payload: res.data.data,
        });
  } catch (err) { }
};


export const user = ({
  name,
  email,
  mobile,
  password,
  usertype,
  subbranch,
  image,
}) => async (dispatch) => {
  
  try {
    let data = new FormData();
        data.append('name', name);
        data.append('email', email);
        data.append('mobile', mobile);
        data.append('password', password);
        data.append('usertype_id', usertype);
        data.append('branch_id', subbranch);
        data.append('image', image);
        const res = await api.post('/user/addUser', data);
        if (res.status === 200) {
          toast.success('User added successfully');
          dispatch({
            type: CREATE_USERS,
            payload: res.data.data.info[0],
          });
         
        }
  } catch (error) {
    // toast.error('Unable to update profile!');
    // dispatch({
    //   type: UPDATE_PROFILE_ERROR,
    // });
  }
};

export const getUser = (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  try {
    const res = await api.get(`/user/user`);
    dispatch({
      type: RETRIEVE_USERS,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_USERS,
      payload: [],
    });
  }
};

export const getSingleUser = (id) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  try {
    const res = await api.get(`/user/getUser/` + id);
   
    dispatch({
      type: RETRIEVE_SINGLE_USERS,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_SINGLE_USERS,
      payload: [],
    });
  }
};

export const updateUser = (registerData) => async (dispatch) => {
  const body = JSON.stringify(registerData);
  try {
    const res = await api.post('/user/updateUser', body);
   
    if (res.status === 200) {
      toast.success('User Updated Successfully');
        dispatch({
            type: UPDATE_USERS,
            payload: res.data.data,
          });
        }
      } catch (err) {
    
    toast.warn('Invalid inputs! Customer Not Updated');
    // dispatch({
    //   type: REGISTER_FAIL,
    // });
  }
};


export const deleteUser = (id) => async (dispatch) => {
  try {
    const res = await api.delete('/user/deleteUser/' + id);
   
    if (res.status === 200) {
      toast.success(res.data.message);
      dispatch({
        type:  DELETE_USERS,
        payload: res.data.data.info,
      });
      
    }
  } catch (error) {
    
    toast.error('Failed to delete');
    dispatch({
      type:  DELETE_USERS,
      payload: [],
    });
  }
};


export const getSubBranch = (data) => async (dispatch) => {
  try {
    const res = await api.get(`/common/getSubBranch`);
   
        dispatch({
          type: SET_SUBBRANCH,
          payload: res.data.data,
        });
  } catch (err) { }
};