import {
    CREATE_POLISH,
    RETRIEVE_POLISH,
    UPDATE_POLISH,
    DELETE_POLISH,
    RETRIEVE_POLISH_IMAGE
} from "../Polish/actiontype";


const INITIAL_STATE = {
  currentIntegration: [],
  polishImage: [],
  isLoading: false,
};

const polishReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case CREATE_POLISH:
      return {
        ...state,
        currentIntegration: [...state.currentIntegration, payload],
      };
    case RETRIEVE_POLISH:
      return {
        ...state,
        currentIntegration: payload,
        isLoading: true,
      };
    case RETRIEVE_POLISH_IMAGE:
      return {
        ...state,
        polishImage: payload,
      };
    case UPDATE_POLISH:
      return state.map((brand) => {
        if (brand.id === payload.id) {
          return {
            ...brand,
            ...payload,
          };
        } else {
          return brand;
        }
      });
    case DELETE_POLISH:
      return {
        ...state,
        currentIntegration: state.currentIntegration.filter((item) => parseInt(item.id) !== parseInt(payload)),
      };
    default:
      return state;
  }
};


export default polishReducer;