import React from 'react'
import {MultiStepForm} from '../../../components/QuotationToOrder/Steps'
import { Row, Col, Container } from 'react-bootstrap';

export default function QuotationToOrder() {
  return (
    <>
      <Container fluid className="main-content">
      < Row>
          <Col className='mb-2'>
              <h3 className="main-title">Quotation To Order</h3>
          </Col>
        </Row>
        <Row>
          <Col>
              <div className="card pb-4">
                <div className="card-body">
                  <MultiStepForm />
                </div>
              </div>
            
          </Col>
        </Row>
      </Container>
    </>
    
  )
}

