import React from 'react'
import {useEffect} from 'react'
import { Row, Col, Container, Button, Form} from 'react-bootstrap';
import { connect } from "react-redux";
import { getTotal,createOrder } from '../../redux/Quotation/QuotationToOrder/action';

const coordinator_store = JSON.parse(localStorage.getItem('coordinator'));
const refferedBy_store = JSON.parse(localStorage.getItem('refferedby'));
const createdby = JSON.parse(localStorage.getItem('userid'));
const branch_id = window.location.pathname.split("/").pop();

const StepFour = ({ formData, setForm, navigation,getTotal,total_amount,createOrder }) => {
  useEffect(() => {
    getTotal();
    calc();
  }, []);

  const {fitting, packing, cartage, othercharge, advance, other  } = formData;
  //const { go } = navigation;

  function calc(){
    let total_amount = document.getElementsByName('total_amount')[0].value;
    let fitting = document.getElementsByName('fitting')[0].value;
    let packing = document.getElementsByName('packing')[0].value;
    let cartage = document.getElementsByName('cartage')[0].value;
    let othercharge = document.getElementsByName('othercharge')[0].value;
    let advance = document.getElementsByName('advance')[0].value;
    
    if(total_amount === '')
		{
			total_amount =0;
		}
		if(fitting === '')
		{
			fitting =0;
		}
		if(packing === '')
		{
			packing =0;
		}
		if(cartage === '')
		{
			cartage =0;
		}
		if(othercharge === '')
		{
			othercharge =0;
		}
		if(advance === '')
		{
			advance =0;
		}
    let totalbill = parseInt(total_amount) + parseInt(fitting) + parseInt(packing) + parseInt(cartage) + parseInt(othercharge) - parseInt(advance);
    document.getElementsByName("grosstotal")[0].value = totalbill;
    document.getElementsByName("balance")[0].value = totalbill;
  }
  

  function submitOrder(){
    const rndno = JSON.parse(localStorage.getItem('rndno'));
    
    formData.branch_id = branch_id;
    formData.created_by = createdby;
    formData.coordinator = coordinator_store;
    formData.refferedby = refferedBy_store;
    formData.rndno = rndno;
    formData.grosstotal = document.getElementsByName("grosstotal")[0].value;
    formData.balance = document.getElementsByName("balance")[0].value;
    formData.total = document.getElementsByName("total_amount")[0].value;
    createOrder(formData);
    
  }
  return (
      <>
       <Container fluid>
          <Row className='mb-2'>
            <Col>
            <h5>Billing</h5>
            </Col>
          </Row>
          <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
            <Form.Label column sm={3}>Total</Form.Label>
            <Col sm={9}>
              <Form.Control name="total_amount" value={total_amount} type="number" onChange={setForm} readOnly/>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
            <Form.Label column sm={3}>Fitting</Form.Label>
            <Col sm={9}>
              <Form.Control name="fitting" value={fitting} type="number" onChange={(e) => { setForm(e); calc();}}/>
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
            <Form.Label column sm={3}>Packing</Form.Label>
            <Col sm={9}>
              <Form.Control name="packing" value={packing} type="number" onChange={(e) => { setForm(e); calc();}} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
            <Form.Label column sm={3}>Cartage</Form.Label>
            <Col sm={9}>
              <Form.Control name="cartage" value={cartage} type="number" onChange={(e) => { setForm(e); calc();}} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
            <Form.Label column sm={1}>Other charges</Form.Label>
            <Col sm={2}>
              <Form.Control name="other" value={other} type="text" onChange={setForm} />
            </Col>
            <Col sm={9}>
              <Form.Control name="othercharge" value={othercharge} type="number" onChange={(e) => { setForm(e); calc();}} />
            </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
              <Form.Label column sm={3}>Gross Total</Form.Label>
              <Col sm={9}>
                <Form.Control name="grosstotal"  type="number" onChange={setForm} readOnly/>
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
              <Form.Label column sm={3}>Advance</Form.Label>
              <Col sm={9}>
                <Form.Control name="advance" value={advance} type="number" onChange={(e) => { setForm(e); calc();}} />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
              <Form.Label column sm={3}>Balance</Form.Label>
              <Col sm={9}>
                <Form.Control name="balance" type="number" onChange={setForm} readOnly/>
              </Col>
            </Form.Group>
            <Row className='mt-4'>
              <Col>
                <Button variant="primary" onClick={() => navigation.previous()} > Back </Button>
              </Col>
              <Col>
                <Button variant="primary" style={{float: 'right'}} onClick={submitOrder}> Submit </Button>
              </Col>
            </Row>
       </Container>
      </>
  )
}

const mapStateToProps = (state) => {
  //
  return {
     total_amount: state.quotationToOrderReducer.total_amount,
   }
 }
 
 export default connect(mapStateToProps,{getTotal,createOrder})(StepFour);
