import React from 'react'
import { Row, Col, Container, Button, Modal, Badge, Dropdown } from 'react-bootstrap';
import {useState, useEffect} from 'react'
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import ConfirmDialog from "../../components/ConfirmDialog/ConfirmDialog";
import { connect } from "react-redux";
import { getOrderList,getConversation,getMemberList,getPaymentDetail,getRemainingMember,addPayment,exitChatGroup,addMember,getUsertype,addChatData,clearChatDetail,markAsDispatch} from "../../redux/Chat/action";
import Loader from "../../components/Loader/Loader";
import Search from '../../components/SearchBar/Search';
import ChatLoader from '../../components/Loader/ChatLoader';
import KErrorMessage from "../../components/ErrorMessage/KErrorMessage";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';

const validationSchema = yup.object({
  amount: yup.string().required("Amount is Required"),
  mode: yup.string().required("Payment Mode is Required"),
  transaction_id: yup.string().required("Transaction Id is Required"),
});

let userid = JSON.parse(localStorage.getItem('userid'));
let serial_no = JSON.parse(localStorage.getItem('serial_no'));
let usertype = JSON.parse(localStorage.getItem('usertype'));

const Chat = ({ getOrderList,orderlist,getConversation,conversation,no_of_member,order_name,updated_date,getMemberList,memberList,getPaymentDetail,remaining_amt,payments,getRemainingMember,remainingMemberList,isLoading,isLoadingConversation, addPayment,exitChatGroup,addMember,getUsertype,usertypelist,addChatData,clearChatDetail,markAsDispatch,isDispatch}) =>  {  useEffect(() => {
  getOrderList();
  getUsertype();
}, []);

const [formData, setFormData] = useState({
 usertype: "",
 writechat: "",
 files: ""
});

const { usertype, writechat,files} = formData;

// Search Order List start
const [searchValue, setSearchValue] = useState("");

const filterOrders = ({ order_id }) => {
  return order_id.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
};
// Search Order List end

// Search Member start
const [searchValue2, setSearchValue2] = useState("");

const filterMember = ({ name }) => {
  return name.toLowerCase().indexOf(searchValue2.toLowerCase()) !== -1;
};
// Search Member end

  const parse = require('html-react-parser');

  const [show, setShow] = useState(false);
  const modalPaymentClose = () => setShow(false);
  const modalPaymentShow = () => setShow(true);

  const [show2, setShow2] = useState(false);
  const modalParticipantClose = () => setShow2(false);
  const modalParticipantShow = () => setShow2(true);

  const [show3, setShow3] = useState(false);
  const modalMemberClose = () => setShow3(false);
  const modalMemberShow = () => setShow3(true);
  const [chatData, setChatDetail] = useState('');
  const [departmentData, setDepartmentDetail] = useState('');
  
  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', type: ''})

  const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (isLoading) {
            setLoading(true);
        }
      }); 
 
    const [dispatch, setDispatch] = useState(false);
    useEffect(() => {
        if (isDispatch) {
            setDispatch(true);
        }
      }); 


      const [usertypeList, setUsertypeList] = useState(null);

      useEffect(() => {
        if(usertypelist)
        {
          setUsertypeList(usertypelist); 
        }
      }, [usertypelist])
      
      const onSelectChangeUsertype = (e) =>
        {
          setFormData({ ...formData, usertype: e.value });
        }

        
  const [loadingConversation, setLoadingConversation] = useState(false);
    useEffect(() => {
        if (isLoadingConversation) {
            setLoadingConversation(true);
        }
      }); 

  const exitGroup = async (e) => {
    e.preventDefault();
    const data = {
    serial_no: serial_no,
    userid: userid,
  };
    setConfirmDialog({
    isOpen: true,
    title: 'Are You Sure To Exit From  Group?',
    type: true,
     onConfirm: () => { 
      exitChatGroup(data);
       setConfirmDialog({ ...confirmDialog, isOpen: false })
     }
    })
  }   

  const clearChat = async (e) => {
      e.preventDefault();
      setConfirmDialog({
      isOpen: true,
      title: 'Are You Sure To Clear This Chat?',
      type: true,
      onConfirm: () => { 
          clearChatDetail();
        }
    })
  }   

  function getChatDetail(x)
  {
    getConversation(x);
  }

  function getMember()
  {
    getMemberList();
  }

  function getPayment()
  {
    getPaymentDetail();
  }

  function remainMemberList()
  {
    getRemainingMember();
  }

  function addParticipant(x,y)
  {
    const data = {
      userid: x,
      usertype: y,
    };
    addMember(data);
  }

  //
  const onChangeChat = (e) =>
  {
    setFormData({ ...formData, writechat: e.target.value });
  }

  const imageUpload = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.files[0] });
  };

  function addChat()
  {
    
    const data = {
      department:  formData.usertype,
      chat:  formData.writechat,
      files: formData.files
    };
    addChatData(data);
    setFormData({ ...formData, writechat: "" });
  }

  const dispatchOrder = async (e) => {
    e.preventDefault();
    setConfirmDialog({
    isOpen: true,
    title: 'Are You Sure To Mark Order As Dispatch?',
    type: true,
    onConfirm: () => { 
        markAsDispatch();
      }
  })
}  

const navigate = useNavigate();
const exportChat = async (e) => {
  navigate('/export-chat/'+serial_no);
} 
  return (
    <>
      <Container fluid style={{paddingLeft: "20px", paddingRight: "20px", backgroundColor: "#eff1f9 "}}>
        <Row>
          <Col>
            <h3 className='main-title mb-3 mt-3'>Chat</h3>
          </Col>
        </Row>
        <Row>
          <Col sm={12} md={7} xl={8}>
            <div className="card custom-card mb-3">
              <div className="main-content-app pt-0">
                <div className="main-content-body main-content-body-chat">
                  <input type="hidden" id="active_orderid" value="2642"/>
                  <div className="main-chat-header pt-3 chatUserDetail">
                    <div className="main-chat-msg-name">
                      <h6 className='text-danger'>{order_name}</h6> 
                      <span className="dot-label bg-success"></span>
                      <small className="mr-3">Last Updated On {updated_date}</small> 
                    </div>
                    <nav className="nav">
                        <div className="dropdown-menu dropdown-menu-right shadow">
                          <a className="dropdown-item">
                            <i className="ri-arrow-drop-right-line mr-1"></i> Add Participant
                          </a>
                        </div>
                        { dispatch ? 
                        <a className="nav-link" onClick={dispatchOrder}>
                          <i className="ri-checkbox-line"></i>
                        </a> : '' }
                        <a className="nav-link mg-r-6"  onClick={() => {modalParticipantShow();getMember();}} >
                          <i className="ri-group-line position-relative"><span className="notify-badge">{no_of_member}</span></i>
                        </a>
                        <a className="nav-link" onClick={() => {modalPaymentShow();getPayment();}}>
                          <i className="fas fa-money-bill-alt"></i>
                        </a>
                        <Dropdown>
                          <Dropdown.Toggle variant="primary"  className='text-white' style={{marginLeft: "10px"}}>
                            <i className="ri-more-2-line"></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className='chat-dropdown'>
                            <Dropdown.Item onClick={() => {modalMemberShow();remainMemberList();}}><i class="ri-arrow-drop-right-line" style={{verticalAlign: "middle"}}></i> Add Prticipant</Dropdown.Item>
                            <Dropdown.Item href="#/action-2"><i class="ri-arrow-drop-right-line" style={{verticalAlign: "middle"}}></i> Export Media</Dropdown.Item>
                            <Dropdown.Item onClick={exportChat}><i class="ri-arrow-drop-right-line" style={{verticalAlign: "middle"}}></i> Export Chat</Dropdown.Item>
                            <Dropdown.Item  onClick={exitGroup}><i class="ri-arrow-drop-right-line" style={{verticalAlign: "middle"}}></i> Exit From Group</Dropdown.Item>
                            <Dropdown.Item  onClick={clearChat}><i class="ri-arrow-drop-right-line" style={{verticalAlign: "middle"}}></i> Clear Chat</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        {/* <a href="#" data-toggle="dropdown" className="mg-x-5" aria-haspopup="true" aria-expanded="true"> 
                          <i className="ri-more-2-line"></i>
                         </a> */}
                    </nav>
                  </div>
                 
                  <div className="main-chat-body chatDetail ps" id="ChatBody">
                  {loadingConversation ? <div>
                  {parse(conversation)} 
                  </div> : <ChatLoader/>}
                  </div>
                  
                  <div className="row">
                    <div className="col-sm-12" style={{padding: "0 30px"}}>
                      <div className="form-group">
                      <Select options={usertypeList} name="usertype" onChange={(e) => onSelectChangeUsertype(e)} className='mb-2' />
                      </div>
                    </div>
                  </div>
                  <div className="main-chat-footer">
                    <nav className="nav">
                    <a data-target="#modalUploadMedia" data-toggle="modal"  href="" title="Add Contact"><i for="attach"  className="ri-edit-box-line-paperclip"></i></a> 
                      <div className="image-upload">
                        <label className="m-0">
                          <input id="attach" type="file" className='d-none' name="files"
                                   onChange={(e) => imageUpload(e)}/> <span className="nav-link" data-toggle="tooltip" href="" title="" data-original-title="Attach a File"><i for="attach" className="fa fa-paperclip"></i></span> </label>
                      </div>
                    </nav>
                    <input className="form-control" placeholder="Type your message here..." type="text" id="message" value={writechat} onChange={(e) => onChangeChat(e)}/> <a className="main-msg-send" href="javascript:void(0);" onClick={addChat}><i className="far fa-paper-plane"></i></a> </div>
                </div>
              </div>
            </div>
          </Col>
          <Col sm={12} md={5} xl={4}>
            <div className="card custom-card">
              <div className="main-content-app pt-0">
                <div className="main-content-left main-content-left-chat">
                  <div className="card-body">
                  <Search onSearch={setSearchValue} value={searchValue} />
                  </div>
                  <div className="main-chat-list ps" id="ChatList">
                  {loading ? <div>
                  {orderlist.filter(filterOrders).map((list, index) => (
                    <div className="media bm-1017"  onClick={()=>getChatDetail(list.serial_no)}>
                      <div className="media-body">
                        <div className="media-contact-name"> <span>{list.order_id}</span> <span>DOA {list.date}</span> </div>
                        <div className="media-contact-name"> <span>{list.created_by}</span> <span>{list.customer}</span> </div>
                      </div>
                    </div>
                  ))}
                  </div> : <Loader/>}
                  </div>
                  {/* <!-- main-chat-list --> */}
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
      {/* Add Payment Modal Start */}
      <Modal 
        size="md"
        centered
        show={show} 
        onHide={modalPaymentClose}
      >
        <Formik
          validationSchema={validationSchema}
          initialValues={{
          usertype: usertype,
          userid: userid,
          serial_no: serial_no,
          amount: "",
          mode: "",
          transaction_id: "",
          }}
          onSubmit={(values) => {
            addPayment(values);
          }}
        >
          {({ values }) => (
          <Form>
            <Modal.Header closeButton>
                <Modal.Title>Add Payment - <Badge bg="danger" text="light" className="ml-2">₹ {remaining_amt}</Badge></Modal.Title>
            </Modal.Header>
            {remaining_amt ? <div>
            <Modal.Body>
                <label>Amount</label>
                <Field name="amount" type="text" className="form-control"  />
                <KErrorMessage name="amount" />
                <label>Payment Mode</label>
                <Field as="select" name="mode" className="form-control">
                  <option>Select Payment Mode</option>
                  <option value="Cash">Cash</option>
                  <option value="Card">Card</option>
                  <option value="UPI">UPI</option>
                  <option value="Bank">Bank</option>
                </Field>
                <KErrorMessage name="mode" />
                <label>Transaction</label>
                <Field name="transaction_id" type="text" className="form-control"  />
                <KErrorMessage name="transaction_id" />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={modalPaymentClose}>Close</Button>
                <Button variant="primary" type="submit">Submit</Button>
            </Modal.Footer>
            </div> : ''}
          </Form>
              )}
       </Formik>
          <Row className='mt-3'>
            <Col lg={12} sm={12}>
                <Timeline>
                {payments.map((payment_detail, index) => (
                    <TimelineItem key={index}>
                        <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>₹ {payment_detail.amount} recorded by {payment_detail.recorded_by} On {payment_detail.date} with Cash</TimelineContent>
                    </TimelineItem>
                ))}    
                </Timeline>
            </Col>
          </Row>
      </Modal>
      {/*Add  Payment Modal End */}
      {/* Add Participant Modal Start */}
      <Modal 
        size="md"
        centered
        show={show3} 
        onHide={modalMemberClose}
      >
        <Modal.Header closeButton>
            <Modal.Title>All Contacts</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* <Form.Control type="text" id="amount" placeholder="Search Members..." /> */}
          <Search onSearch={setSearchValue2} value={searchValue2} />
             <div className="main-mail-list mt-3">
              {remainingMemberList && remainingMemberList.filter(filterMember).map((member, index) => ( 
              <div className="main-mail-item">
                <div className="main-mail-checkbox">
                  <label className="ckbox">
                    <input type="checkbox" onClick={()=>addParticipant(member.id, member.usertype_id)} /> <span></span></label>
                </div>
                <div className="main-img-user"><img alt="img" src="https://cdn.pixabay.com/photo/2014/04/02/10/25/man-303792_960_720.png" className="m-0" /></div>
                <div className="main-mail-body">
                  <div className="main-mail-subject"> <strong>{member.name}</strong>
                    <br></br><span>{member.usertype}</span> </div>
                </div>
              </div>
            ))}
            </div>
        </Modal.Body>
      </Modal>
      {/*Add  Participant Modal End */}
      {/* All Participants Modal Start */}
      <Modal 
        size="md"
        centered
        show={show2} 
        onHide={modalParticipantClose}
      >
        <Modal.Header closeButton>
            <Modal.Title>All Participants </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="media">
            <div class="media-body overflow-hidden participants-list" id="mailContent">
            {memberList && memberList.map((member, index) => (
              <div class="main-mail-item" key={index}>
                <div class="main-img-user"><img alt="img" src="https://cdn.pixabay.com/photo/2014/04/02/10/25/man-303792_960_720.png"/></div>
                <div class="main-mail-body">
                  <div class="main-mail-subject"> <strong>{member.name}</strong>
                  <br></br><span>{member.usertype}</span> </div>
                </div>
              </div>
            ))}
            </div>
          </div>
        </Modal.Body>
      </Modal>
        {/*All Participants Modal End */}
    </>
  )
}

const mapStateToProps = (state) => {
    
    return {
       orderlist: state.chat.order_list,
       isLoading: state.chat.isLoading,
       isLoadingConversation: state.chat.isLoadingConversation,
       conversation :state.chat.conversation,
       no_of_member :state.chat.no_of_member,
       order_name :state.chat.order_name,
       updated_date :state.chat.update_date,
       memberList :state.chat.member_list,
       payments :state.chat.payment_list,
       remaining_amt :state.chat.remaining_amount,
       remainingMemberList :state.chat.remaining_member_list,
       usertypelist :state.chat.usertype,
       isDispatch : state.chat.isDispatch,
     }
   }
  
export default connect(mapStateToProps,{getOrderList,getConversation,getMemberList,getPaymentDetail,getRemainingMember,addPayment,exitChatGroup,addMember,getUsertype,addChatData,clearChatDetail,markAsDispatch})(Chat);