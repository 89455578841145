import {
    CREATE_RECEIVE_PAYMENT,
    RETRIEVE_RECEIVE_PAYMENT,
    APPROVE_RECEIVE_PAYMENT,
    DECLINE_RECEIVE_PAYMENT,
    VIEWDETAIL_RECEIVE_PAYMENT
} from "../ReceivePayment/actiontype";

const INITIAL_STATE = {
  currentIntegration: [],
  paymentDetail: [],
};

const receivePaymentReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case CREATE_RECEIVE_PAYMENT:
      return {
        ...state
      };
    case RETRIEVE_RECEIVE_PAYMENT:
      return {
        ...state,
        currentIntegration: payload,
      };
    case VIEWDETAIL_RECEIVE_PAYMENT:
      return {
        ...state,
        paymentDetail: payload,
      };
    case  APPROVE_RECEIVE_PAYMENT:
    return state.filter(({ id }) => id !== payload.id);

    case  DECLINE_RECEIVE_PAYMENT:
    return state.filter(({ id }) => id !== payload.id);

      default:
        return state;
  }
};
export default receivePaymentReducer;