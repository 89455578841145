import {
  CREATE_STOCKIN
} from "../StockIn/actiontype";

import { toast } from 'react-toastify';
import api from "../../../api";
//let userid = JSON.parse(localStorage.getItem('userid'));

export const addStockIn = (registerData) => async (dispatch) => {
  const body = JSON.stringify(registerData);
  try {
    const res = await api.post('/branch/addStock', body);
   
    if (res.status === 200) {
      toast.success('Stock Added Successfully');
        dispatch({
            type: CREATE_STOCKIN,
            payload: res.data.data.info[0],
          });
        }
      } catch (err) {
    
    toast.warn('Invalid inputs! Stock Not Added');
    // dispatch({
    //   type: REGISTER_FAIL,
    // });
  }
};

