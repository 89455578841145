import {
  RETRIEVE_STOCK_QUERY,
  RETRIEVE_STOCK_QUERY_DETAIL
} from "../StockQuery/actiontype";
import api from "../../../api";

//let usertype = JSON.parse(localStorage.getItem('usertype'));

export const getStockQuery= (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
 
  try {
    const res = await api.get(`/peddler/productInfo`);
    dispatch({
      type:  RETRIEVE_STOCK_QUERY,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type:  RETRIEVE_STOCK_QUERY,
      payload: [],
    });
  }
};

export const getStockDetail= (id) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
 
  try {
   
    const res = await api.get(`/peddler/productStockDetail/` +id);
    dispatch({
      type:  RETRIEVE_STOCK_QUERY_DETAIL,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type:  RETRIEVE_STOCK_QUERY_DETAIL,
      payload: [],
    });
  }
};


