import * as React from "react";
import {useState, useEffect} from 'react'
import { Row, Col, Container, Badge, Dropdown } from 'react-bootstrap';
import MaterialTable from 'material-table';
import { connect } from "react-redux";
import {getStockReport} from '../../redux/Report/StockReport/action';
import SearchBar from "../../components/SearchBar/SearchBar";
import Loader from "../../components/Loader/Loader";
import Zoom from 'react-medium-image-zoom'

const StockReport = ({ getStockReport,currentIntegration,isLoading }) =>  {  useEffect(() => {
    getStockReport();
  }, []);


const [visible, setVisible] = useState(false);
const handleVisibility = () => {
    setVisible(prev => !prev);
};  

const [loading, setLoading] = useState(false);
useEffect(() => {
    if (isLoading) {
        setLoading(true);
    }
  });

const [tableData, setTableData] = useState([]);
    useEffect(() => {
    if(currentIntegration)
    {
        setTableData(currentIntegration); 
    }
    }, [currentIntegration])

const columns = [
{ title: "Product Image", field: 'image', render: rowData => <img src={process.env.REACT_APP_IMAGE_URL+'/'+rowData.image} alt={rowData.image} style={{width: 50, height: 50}}/> },
{ title: "Model No.", field: "model" },
{ title: "Product Category", field: "cateory" },
{ title: "Leather Category", field: "leather_category" },
{ title: "Leather Type", field: "leather_type" },
{ title: "Leather Type", field: "leather_colour" },
{ title: "Colour Code", field: "colour_Code" },
{ title: "RDP", field: "rdp" },
{ title: "Quantity", field: "stock_qty" },
]

// Search for Card view start
const [searchValue, setSearchValue] = useState("");

const filterReport = ({ model }) => {
  return model.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
};
// Search for Card view end
    
  return (
    <>
        <Container fluid className="main-content">
          <Row>
              <Col lg={"12"} className='d-flex justify-content-between mb-2'>
                  <h3 className="main-title">Stock Report</h3>
                  <div className=" d-block d-sm-none">
                      <div className="magnifierContainer">
                          <button type="button" className="btn btn-md btn-warning text-white roundedcircle mr-3" onClick={handleVisibility}><i className="fa-solid fa-search"></i></button>
                      </div>
                  </div>
              </Col>
              <Col lg={"12"} className='mb-2 d-block d-sm-none'>
                <div className={visible ? "open" : "close"}>
                    <SearchBar onSearch={setSearchValue} value={searchValue} />
                </div>
              </Col>
          </Row>
          {loading ?  <div>
          <Row className="d-none d-sm-block mt-2">
            <Col lg={"12"} className="mt-3">
              <div style={{display: 'table', tableLayout:'fixed', width:'100%'}}>
              <MaterialTable columns={columns} data={tableData}
                  options={{
                  sorting: true, search: true,
                  paging: true, pageSizeOptions: [10, 25, 50, 100], pageSize: 10,
                  exportButton: true,
                  exportAllData: true, exportFileName: "Stock Report", actionsColumnIndex: -1,
                  // headerStyle: { background: "#f44336",color:"#fff"}
                  }}
                  title="Stock Report"
              />
              </div>
            </Col>
          </Row>
          <Row className="d-block d-sm-none">
            {currentIntegration.filter(filterReport).map((report, index) => ( 
              <Col xs={"12"} key={index}>
                  <div className="card mb-3">
                      <Zoom zoomMargin={20}><img class="card-img-top" src={process.env.REACT_APP_IMAGE_URL+'/'+report.image} alt={report.image} height="150"/></Zoom>
                      <div className="card-header d-flex justify-content-between pb-0">
                          <h6>{report.model} <Badge bg="danger" text="light" className="ml-2">{report.stock_qty}</Badge></h6>
                      </div>
                      <div className="card-body pt-1">
                          <p className="card-text mb-0"><span className="quotation-info">Product Category:</span> {report.cateory}</p>
                          <p className="card-text mb-0"><span className="quotation-info">Leather Category:</span> {report.leather_category}</p>
                          <p className="card-text mb-0"><span className="quotation-info">Leather Type:</span> {report.leather_type}</p>
                          <p className="card-text mb-0"><span className="quotation-info">Leather Colour:</span> {report.leather_colour}</p>
                          <p className="card-text mb-0"><span className="quotation-info">Colour Code:</span> {report.colour_code}</p>
                          <p className="card-text mb-0"><span className="quotation-info">RDP:</span> {report.rdp}</p>
                      </div>
                  </div>
              </Col>
            ))}
            </Row>
            </div> : <Loader/> }
        </Container>
    </>

  )
}

const mapStateToProps = (state) => {
   
    return {
       currentIntegration: state.stockReport.currentIntegration,
       isLoading: state.stockReport.isLoading,
     }
   }
  
export default connect(mapStateToProps,{getStockReport})(StockReport);