import {
    RETRIEVE_DECLINED_QUOTATION
} from "../DeclinedQuotation/actiontype";

import { toast } from 'react-toastify';
import api from "../../../api";
let usertype = JSON.parse(localStorage.getItem('usertype'));
let userid = JSON.parse(localStorage.getItem('userid'));

// export const vendor = (registerData) => async (dispatch) => {
//   const body = JSON.stringify(registerData);
//   try {
//     const res = await api.post('/vendor/addVendor', body);
//    
//     if (res.status === 200) {
//       toast.success('Vendor Added Successfully');
//         // dispatch({
//         //     type: REGISTER_SUCCESS,
//         //     payload: res.data,
//         //   });
//         }
//       } catch (err) {
//     
//     toast.warn('Invalid inputs! Vendor Not Added');
//     // dispatch({
//     //   type: REGISTER_FAIL,
//     // });
//   }
// };

export const getDeclinedQuotation= (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  let data = {
    usertype : usertype,
    userid : userid
  };
  
  try {
    const res = await api.post(`/quotation/declinedQuotation`, data);
   
    
    dispatch({
      type: RETRIEVE_DECLINED_QUOTATION,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_DECLINED_QUOTATION,
      payload: [],
    });
  }
};



