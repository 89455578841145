import React from "react";
import { useForm, useStep } from "react-hooks-helper";
import {StepFour} from "./StepFour";
import {StepOne} from "./StepOne";
import {StepTwo} from "./StepTwo";
import {StepThree} from "./StepThree";


const defaultData = {
  orderNo: "",
  orderno: "",
  bookingdate: "", 
  quotation: "", 
  deliverydate: "", 
  name: "", 
  mobile: "", 
  email: "", 
  address: "", 
  coordinator: "", 
  refferedby: "",
  total: "",
  fitting: "",
  packing: "",
  cartage: "",
  otherchare: "",
  grosstotal: "",
  advance: "",
  balance: ""
};

const steps = [
  { id: "stepone" },
  { id: "steptwo" },
  { id: "stepthree" },
  { id: "stepfour" },
  
];

export const MultiStepForm = () => {
  const [formData, setForm] = useForm(defaultData);
  const { step, navigation } = useStep({
    steps,
    initialStep: 0,
  });

  const props = { formData, setForm, navigation };

  switch (step.id) {
    case "stepone":
      return <StepOne {...props} />;
    case "steptwo":
      return <StepTwo {...props} />;
    case "stepthree":
      return <StepThree {...props} />;
    case "stepfour":
      return <StepFour {...props} />;
    
  }

  return (
    <div>
      <h1>Multi step form</h1>
    </div>
  );
};
