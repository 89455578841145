import React from 'react'
import { Row, Col, Container, Card, Button} from 'react-bootstrap';
import { Formik, Form, Field } from "formik";

export default function ChangePassword() {
  return (
    <>
    <Container fluid className="main-content">
        <Row>
            <Col>
                <h3 className="main-title mb-0">Change Password</h3>
            </Col>
        </Row>
        <Row className='mt-4 mb-4'>
            <Col lg={6}>
                <Card>
                    <Formik
                        initialValues={{
                        oldpassword: "",
                        newpassword: "",
                        confirmpassword: "",
                        }}
                        onSubmit={(values) => {
                            //console.log(values);
                        }}
                    >
                        {({values, setFieldValue}) => (
                        <Form>
                            <Card.Body>
                                <Col lg={12} sm={12}>
                                    <label>Old Password</label>
                                    <Field name="oldpassword" type="text" className="form-control" />
                                </Col>
                                <Col lg={12} sm={12}>
                                    <label>New Password</label>
                                    <Field name="newpassword" type="text" className="form-control" />
                                </Col>
                                <Col lg={12} sm={12}>
                                    <label>Confirm Password</label>
                                    <Field name="confirmpassword" type="text" className="form-control" />
                                </Col>
                                <Col lg={12} sm={12}>
                                    <Button variant="primary" type="submit" style={{float: "right"}} className='mb-4 mt-2'>Submit</Button>
                                </Col>
                            </Card.Body>
                        </Form>
                        )}
                    </Formik>
                </Card>
            </Col>
        </Row>
    </Container>
    </>
  )
}
