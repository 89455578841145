import {
    RETRIEVE_DASHBOARD,
    ACTIVE_ORDERS,
    ACTIVE_QUOTATIONS,
   
} from "../DashboardSales/actiontype";


const INITIAL_STATE = {
  dashboard: [],
  order: [],
  quotation: [],
 
};

const dashboardSaleReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  
  switch (type) {
    
    case RETRIEVE_DASHBOARD:
      return {
        ...state,
        dashboard: payload,
      };
    case ACTIVE_ORDERS:
      return {
        ...state,
        order: payload,
      };
    case ACTIVE_QUOTATIONS:
      return {
        ...state,
        quotation: payload,
      };
    
    default:
      return state;
  }
};
export default dashboardSaleReducer;