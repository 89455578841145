import {
    // CREATE_VIEW_ORDER,
    RETRIEVE_VIEW_ORDER,
    ORDER_MARKAS_DONE
} from "../ViewOrder/actiontype";

const INITIAL_STATE = {
  currentIntegration: [],
  isLoading: false,
};

const viewOrderReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
 
  switch (type) {
    // case CREATE_VIEW_ORDER:
    //   return {
    //     ...state
    //   };
    case RETRIEVE_VIEW_ORDER:
      return {
        ...state,
        currentIntegration: payload,
        isLoading: true,
      };

      case ORDER_MARKAS_DONE:
        return state.filter(({ id }) => id !== payload.id);

      default:
        return state;
  }
};
export default viewOrderReducer;