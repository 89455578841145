import {
    CREATE_PENDING_INCENTIVE,
    RETRIEVE_PENDING_INCENTIVE,
    UPDATE_PENDING_INCENTIVE,
    DELETE_PENDING_INCENTIVE
} from "../PendingIncentive/actiontype";

const INITIAL_STATE = {
  currentIntegration: [],
  isLoading: false,
};

const pendingIncentiveReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case CREATE_PENDING_INCENTIVE:
      return {
        ...state
      };
    case RETRIEVE_PENDING_INCENTIVE:
      return {
        ...state,
        currentIntegration: payload,
        isLoading: true,
      };
    case UPDATE_PENDING_INCENTIVE:
      return state.map((pendingIncentive) => {
        if (pendingIncentive.id === payload.id) {
          return {
            ...pendingIncentive,
            ...payload,
          };
        } else {
          return pendingIncentive;
        }
      });
    case DELETE_PENDING_INCENTIVE:
      return state.filter(({ id }) => id !== payload.id);
    default:
      return state;
  }
};
export default pendingIncentiveReducer;