import {
  RETRIEVE_STOCK_REPORT
} from "../StockReport/actiontype";


const INITIAL_STATE = {
  currentIntegration: [],
  isLoading: false,

};

const  stockReportReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    
    case RETRIEVE_STOCK_REPORT:
      return {
        ...state,
        currentIntegration: payload,
        isLoading: true,
      };
    default:
      return state;
  }
};
export default stockReportReducer;