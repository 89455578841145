import {
    ORDER_DETAIL,
    UPDATE_ORDER,
    GET_IMPORTED_READY,
    GET_IMPORTED_ORDER,
    GET_TOTAL_AMOUNT,
    GET_ORDER_NAME,
    READY_NET_AMOUNT,
    GROSS_TOTAL,
    BALANCE_AMT,
    EDIT_PRODUCT,
    EDIT_PRODUCT_NAME
} from "./actiontype";

import { toast } from 'react-toastify';
import api from "../../../api";

let rndno = JSON.parse(localStorage.getItem('rndno'));

export const getOrder = (detail) => async (dispatch) => {
  try 
  {
      let data = {
          rndno : rndno,
          order_id : detail
      };
      const res = await api.post(`/order/getOrder`,data);
      localStorage.setItem('rndno',res.data.data.randno);
      localStorage.setItem('total_amount',res.data.data.order.total_amount);
      localStorage.setItem('fitting',res.data.data.order.fitting);
      localStorage.setItem('packing',res.data.data.order.packing);
      localStorage.setItem('cartage',res.data.data.order.cartage);
      localStorage.setItem('other',res.data.data.order.other);
      localStorage.setItem('advance',res.data.data.order.advance);
      localStorage.setItem('coordinator',res.data.data.order.cordinator);
      localStorage.setItem('refferedby',res.data.data.order.reffered_by);

        dispatch({
          type: ORDER_DETAIL,
          payload: res.data.data.order,
        });
        dispatch({
          type: GET_IMPORTED_READY,
          payload: res.data.data.ready,
        });
        dispatch({
          type: GET_IMPORTED_ORDER,
          payload: res.data.data.product_order,
        });
        dispatch({
          type: GET_ORDER_NAME,
          payload: res.data.data.order_name,
        });
        dispatch({
          type: GET_TOTAL_AMOUNT,
          payload: res.data.data.order.total_amount,
        });
        dispatch({
          type: GROSS_TOTAL,
          payload: res.data.data.order.gross_total,
        });
        dispatch({
          type: BALANCE_AMT,
          payload: res.data.data.order.balance,
        });
  } catch (err) { }
};

/******************************************************* Ready Product ******************************* */
export const onChangeCalcReady = (data)  => async (dispatch) => {    
  let qty = localStorage.getItem('editqty');
  let price = localStorage.getItem('editnetprice');
  let calc = parseFloat(qty) * parseInt(price);
  dispatch({
    type: READY_NET_AMOUNT,
    payload: calc,
  });
};

//add imported product
export const addImportedReady = (data)  => async (dispatch) => {
  var detail= data;
  detail['model'] = localStorage.getItem('product_id');
  detail['location'] = localStorage.getItem('location_id');
  detail['session_id'] = localStorage.getItem('rndno');
  
  const body = JSON.stringify(detail);
  console.log(body);
  try {
    const res = await api.post('/order/orderProduct', body);
    dispatch({
      type: GET_IMPORTED_READY,
      payload: res.data.data,
    });
    
    const rndno = localStorage.getItem('rndno');
    const res1 = await api.get(`/order/getTotalAmount/`+rndno);
    localStorage.setItem('total_amount', res1.data.data);
    dispatch({
      type: GET_TOTAL_AMOUNT,
      payload: res1.data.data,
    });

    const total = localStorage.getItem('total_amount');
    const fitting = localStorage.getItem('fitting');
    const packing = localStorage.getItem('packing');
    const cartage = localStorage.getItem('cartage');
    const other = localStorage.getItem('other');
    const advance = localStorage.getItem('advance');
    
    let totalbill = parseInt(total) + parseInt(fitting) + parseInt(packing) + parseInt(cartage) + parseInt(other);
    dispatch({
      type: GROSS_TOTAL,
      payload: totalbill,
    });

    let balance = totalbill - parseInt(advance);
    dispatch({
      type: BALANCE_AMT,
      payload: balance,
    });

  } catch (err) {}
};

//edit imported Product
export const getEditReady = (data)  => async (dispatch) => {
  try{
    const res = await api.get(`/order/getEditReady/`+data);
    dispatch({
      type: EDIT_PRODUCT,
      payload: res.data.data[0],
    });

    dispatch({
      type: EDIT_PRODUCT_NAME,
      payload: res.data.data[0].name,
    });
    
    localStorage.setItem('editqty',res.data.data[0].qty);
    localStorage.setItem('editnetprice',res.data.data[0].net_price);

    dispatch({
      type: READY_NET_AMOUNT,
      payload: res.data.data[0].net_amount,
    });
  } catch(err) {}
};

export const getEditProductNameReady = (data)  => async (dispatch) => {
  try{
    const res = await api.get(`/common/getProductName/`+data);
    dispatch({
      type: EDIT_PRODUCT_NAME,
      payload: res.data.data.name,
    });
  } catch(err) {}
};

//update imported product
export const updateImportedReady = (data)  => async (dispatch) => {
  var detail= data;
  detail['model'] = localStorage.getItem('product_id');
  detail['location'] = localStorage.getItem('location_id');
  
  const body = JSON.stringify(detail);
  console.log(body);
  try {
    const res = await api.post('/order/updateReadyProduct', body);
    dispatch({
      type: GET_IMPORTED_READY,
      payload: res.data.data,
    });
    
    const rndno = localStorage.getItem('rndno');
    const res1 = await api.get(`/order/getTotalAmount/`+rndno);
    localStorage.setItem('total_amount', res1.data.data);
    dispatch({
      type: GET_TOTAL_AMOUNT,
      payload: res1.data.data,
    });

    const total = localStorage.getItem('total_amount');
    const fitting = localStorage.getItem('fitting');
    const packing = localStorage.getItem('packing');
    const cartage = localStorage.getItem('cartage');
    const other = localStorage.getItem('other');
    const advance = localStorage.getItem('advance');
    
    let totalbill = parseInt(total) + parseInt(fitting) + parseInt(packing) + parseInt(cartage) + parseInt(other);
    dispatch({
      type: GROSS_TOTAL,
      payload: totalbill,
    });

    let balance = totalbill - parseInt(advance);
    dispatch({
      type: BALANCE_AMT,
      payload: balance,
    });

  } catch (err) {}
};

//deleted imported product
export const deleteImportedReady = (data)  => async (dispatch) => {
  try{
    const res = await api.get(`/order/deleteReadyProduct/`+data);
    dispatch({
      type: GET_IMPORTED_READY,
      payload: res.data.data.name,
    });

    const rndno = localStorage.getItem('rndno');
    const res1 = await api.get(`/order/getTotalAmount/`+rndno);
    localStorage.setItem('total_amount', res1.data.data);
    dispatch({
      type: GET_TOTAL_AMOUNT,
      payload: res1.data.data,
    });

    const total = localStorage.getItem('total_amount');
    const fitting = localStorage.getItem('fitting');
    const packing = localStorage.getItem('packing');
    const cartage = localStorage.getItem('cartage');
    const other = localStorage.getItem('other');
    const advance = localStorage.getItem('advance');
    
    let totalbill = parseInt(total) + parseInt(fitting) + parseInt(packing) + parseInt(cartage) + parseInt(other);
    dispatch({
      type: GROSS_TOTAL,
      payload: totalbill,
    });

    let balance = totalbill - parseInt(advance);
    dispatch({
      type: BALANCE_AMT,
      payload: balance,
    });

  } catch(err) {}
}

/******************************************************* Order Product ******************************* */
//add imported order product
export const addImportedOrder = (data)  => async (dispatch) => {
  var detail= data;
  detail['model'] = localStorage.getItem('product_id');
  detail['session_id'] = localStorage.getItem('rndno');

  const body = JSON.stringify(detail);
  try {
    const res = await api.post('/order/orderImportedProductOrder', body);
    dispatch({
      type: GET_IMPORTED_ORDER,
      payload: res.data.data,
    });

    const rndno = localStorage.getItem('rndno');
    const res1 = await api.get(`/order/getTotalAmount/`+rndno);
    localStorage.setItem('total_amount', res1.data.data);
    dispatch({
      type: GET_TOTAL_AMOUNT,
      payload: res1.data.data,
    });

    const total = localStorage.getItem('total_amount');
    const fitting = localStorage.getItem('fitting');
    const packing = localStorage.getItem('packing');
    const cartage = localStorage.getItem('cartage');
    const other = localStorage.getItem('other');
    const advance = localStorage.getItem('advance');
    
    let totalbill = parseInt(total) + parseInt(fitting) + parseInt(packing) + parseInt(cartage) + parseInt(other);
    dispatch({
      type: GROSS_TOTAL,
      payload: totalbill,
    });

    let balance = totalbill - parseInt(advance);
    dispatch({
      type: BALANCE_AMT,
      payload: balance,
    });
  } catch (err) {}
};

 //update imported product
 export const updateImportedOrder = (data)  => async (dispatch) => {
  var detail= data;
  detail['model'] = localStorage.getItem('product_id');
  
  const body = JSON.stringify(detail);
  console.log(body);
  try {
    const res = await api.post('/order/updateOrderProduct', body);
    dispatch({
      type: GET_IMPORTED_ORDER,
      payload: res.data.data,
    });
    
    const rndno = localStorage.getItem('rndno');
    const res1 = await api.get(`/order/getTotalAmount/`+rndno);
    localStorage.setItem('total_amount', res1.data.data);
    dispatch({
      type: GET_TOTAL_AMOUNT,
      payload: res1.data.data,
    });

    const total = localStorage.getItem('total_amount');
    const fitting = localStorage.getItem('fitting');
    const packing = localStorage.getItem('packing');
    const cartage = localStorage.getItem('cartage');
    const other = localStorage.getItem('other');
    const advance = localStorage.getItem('advance');
    
    let totalbill = parseInt(total) + parseInt(fitting) + parseInt(packing) + parseInt(cartage) + parseInt(other);
    dispatch({
      type: GROSS_TOTAL,
      payload: totalbill,
    });

    let balance = totalbill - parseInt(advance);
    dispatch({
      type: BALANCE_AMT,
      payload: balance,
    });

  } catch (err) {}
};

//deleted imported product
export const deleteImportedOrder = (data)  => async (dispatch) => {
  try{
    const res = await api.get(`/order/deleteOrderProduct/`+data);
    dispatch({
      type: GET_IMPORTED_ORDER,
      payload: res.data.data.name,
    });

    const rndno = localStorage.getItem('rndno');
    const res1 = await api.get(`/order/getTotalAmount/`+rndno);
    localStorage.setItem('total_amount', res1.data.data);
    dispatch({
      type: GET_TOTAL_AMOUNT,
      payload: res1.data.data,
    });

    const total = localStorage.getItem('total_amount');
    const fitting = localStorage.getItem('fitting');
    const packing = localStorage.getItem('packing');
    const cartage = localStorage.getItem('cartage');
    const other = localStorage.getItem('other');
    const advance = localStorage.getItem('advance');
    
    let totalbill = parseInt(total) + parseInt(fitting) + parseInt(packing) + parseInt(cartage) + parseInt(other);
    dispatch({
      type: GROSS_TOTAL,
      payload: totalbill,
    });

    let balance = totalbill - parseInt(advance);
    dispatch({
      type: BALANCE_AMT,
      payload: balance,
    });

  } catch(err) {}
}

// Update Order
export const updateOrder = (data) => async (dispatch) => {
    var detail= data;
    detail['coordinator'] = localStorage.getItem('coordinator');
    detail['refferedby'] = localStorage.getItem('refferedby');
    detail['rndno'] = localStorage.getItem('rndno');
    const body = JSON.stringify(data);
    console.log(body);
  try {
    const res = await api.post('/order/updateOrder', body);
   
    if (res.status === 200) {
      toast.success('Order Updated Successfully');
        dispatch({
            type: UPDATE_ORDER,
            payload: res.data.data.info[0],
          });
        }
      } catch (err) {
        toast.warn('Invalid inputs! Order Not Updated');
    }
}