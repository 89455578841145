import * as React from "react";
import { useState, useEffect } from 'react'
import { Row, Col, Container, Dropdown, Badge } from 'react-bootstrap';
import MaterialTable from 'material-table';
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Menu, MenuItem } from "@material-ui/core";
import CheckIcon from '@mui/icons-material/Check';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import { styled, alpha } from '@mui/material/styles';
import { connect } from "react-redux";
import {getStockOutOrder} from '../../../redux/Sap/StockOutOrder/action';

const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(2),
      minWidth: 160,
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 14,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.2),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  }));

    const StockOutOrder = ({ getStockOutOrder,currentIntegration }) =>  {  useEffect(() => {
        getStockOutOrder();
    }, []);

    const [visible, setVisible] = useState(false);
    const handleVisibility = () => {
        setVisible(prev => !prev);
    };

    const [show, setShow] = useState(false);
    const modalClose = () => setShow(false);
    const modalShow = () => setShow(true);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [tableData, setTableData] = useState([]);
    useEffect(() => {
    if(currentIntegration)
    {
        setTableData(currentIntegration); 
    }
    }, [currentIntegration])

    const columns = [
        { title: "Order No.", field: "orderNo" },
        { title: "Order Date", field: "date" },
        { title: "Expected Delivery Date", field: "edd" },
        { title: "Quantity", field: "qty" },
        { title: "Created By", field: "name" },
    ]

    return (
        <>
            <Container fluid className="main-content">
                <Row>
                    <Col className='d-flex justify-content-between mb-2'>
                        <h3 className="main-title">Order Stock Out</h3>
                        <div className="d-flex justify-content-between d-block d-sm-none">
                            <div className="magnifierContainer">
                                <button type="button" className="btn btn-md btn-warning text-white roundedcircle mr-3" onClick={handleVisibility}><i className="fa-solid fa-search"></i></button>
                            </div>
                        </div>
                    </Col>
                    <Col lg={"12"} className='mb-2 d-block d-sm-none'>
                        <div className={visible ? "open" : "close"}>
                            <input placeholder="Search..." type="text" className="form-control is-open mr-3 mb-0 search-input"/>
                        </div>
                    </Col>
                </Row>
                <Row className="d-none d-sm-block mt-2">
                    <Col lg={"12"}>
                        <div style={{ display: 'table', tableLayout: 'fixed', width: '100%' }}>
                            <MaterialTable columns={columns} data={tableData}
                                actions={[
                                    {
                                    icon: MoreVertIcon,
                                    tooltip: "More",
                                    onClick: handleClick,
                                    },
                                ]}
                                options={{
                                    sorting: true, search: true,
                                    paging: true, pageSizeOptions: [10, 25, 50, 100], pageSize: 10,
                                    exportButton: true,
                                    exportAllData: true, exportFileName: "Order Stock Out", actionsColumnIndex: -1,
                                    // headerStyle: { background: "#f44336",color:"#fff"}
                                }}
                                title="Order Stock Out"
                            />
                            <StyledMenu
                                id="demo-customized-menu"
                                MenuListProps={{
                                'aria-labelledby': 'demo-customized-button',
                                }}
                                anchorEl={anchorEl}
                                open={open}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={handleClose}>
                                    <VisibilityIcon className='text-primary' /> View
                                </MenuItem>
                                <MenuItem onClick={handleClose}>
                                    <CheckIcon className='text-secondary' /> Approve Payment
                                </MenuItem>
                                <MenuItem onClick={handleClose}>
                                    <CloseIcon className='text-danger' /> Decline Payment
                                </MenuItem>
                            </StyledMenu>
                        </div>
                    </Col>
                </Row>
                <Row className="d-block d-sm-none">
                    {currentIntegration.map((sap, index) => (
                    <Col xs={"12"} key={index}>
                        <div className="card mb-2">
                            <div className="card-header d-flex justify-content-between pb-0">
                                <h6>{sap.orderNo}	 <Badge bg="danger" text="light" className="ml-2">{sap.qty}</Badge></h6>
                                <Dropdown>
                                <Dropdown.Toggle variant="light" id="dropdown-basic">
                                    <i className="ri-more-2-line"></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1"><i className="ri-edit-box-line text-primary"></i> Edit</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2"><i className="far fa-trash-alt text-danger"></i> Delete</Dropdown.Item>
                                </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className="card-body pt-1">
                                <p className="card-text mb-0"><span className="quotation-info">Created By:</span> {sap.name}</p>
                                <p className="card-text mb-0"><span className="quotation-info">Order Date:</span> {sap.date}</p>
                                <p className="card-text mb-0"><span className="quotation-info">Delivery Date:</span> {sap.edd}</p>
                            </div>
                        </div>
                    </Col>
                    ))}
                </Row>
            </Container>
        </>

    )
}

const mapStateToProps = (state) => {

  return {
     currentIntegration: state.stockOutOrder.currentIntegration,
   }
 }

 export default connect(mapStateToProps,{getStockOutOrder})(StockOutOrder);