import {
    UPDATE_INCENTIVE,
    APPROVE_INCENTIVE
} from "../ApproveOrder/actiontype";

import { toast } from 'react-toastify';
import api from "../../../api";

export const updateIncentive= (data) => async (dispatch) => {
    try {
        const res = await api.post(`/order/updateIncentive`,data);
    } catch (err) {}
};

export const approveOrder= (data) => async (dispatch) => {
    try {
        const res = await api.post(`/order/approveOrder`,data);
       
        dispatch({
            type: APPROVE_INCENTIVE,
            payload: res.data.data,
          });
        } catch (err) {
          
          dispatch({
            type: APPROVE_INCENTIVE,
            payload: [],
          });
        }
};