import React from 'react';
import {useRef, useEffect} from 'react';
import {Row, Col, Container, Table, Card, Button} from 'react-bootstrap';
import { useReactToPrint } from "react-to-print";
import { connect } from "react-redux";
import { getExportChat } from "../../redux/Chat/action";

const ExportChat = ({ getExportChat,exportedChat,exportedOrder}) =>  {  useEffect(() => {
    getExportChat();
}, []);

const componentRef = useRef();
const handlePrint = useReactToPrint({
  content: () => componentRef.current,
});

  return (
        <>
        <Container fluid className="main-content">
            <Row>
                <Col>
                    <div className='d-flex justify-content-between'>
                        <h5>Export Chat</h5>
                        <Button variant="warning" className='text-white mb-3' onClick={handlePrint} style={{textAlign:"right"}}>Download</Button>
                    </div>
                </Col>
                <div ref={componentRef}>
                <Col>
                    <Card>
                        <Card.Title>
                            <h5 className='text-primary text-center mt-3 mb-0'>Exported Chat {exportedOrder}</h5>
                        </Card.Title>
                        <Card.Body>
                            <Table striped bordered hover className="pb-3">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Date</th>
                                        <th>Name</th>
                                        <th>Message</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {exportedChat && exportedChat.map((chatdetail, index) => (
                                    <tr key={index}>
                                        <td>{++index}</td>
                                        <td>{chatdetail.date}</td>
                                        <td>{chatdetail.name}</td>
                                        <td>{chatdetail.message}</td>
                                    </tr>
                                ))}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
                </div>
            </Row>
        </Container>
        </>
  )
}

const mapStateToProps = (state) => {
    
      return {
         exportedChat: state.chat.export_chat.chat,
         exportedOrder: state.chat.export_chat.order,
       }
     }
    
  export default connect(mapStateToProps,{getExportChat})(ExportChat);