import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Card } from 'react-bootstrap';
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import KErrorMessage from "../../../components/ErrorMessage/KErrorMessage";
import MaterialTable from 'material-table';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { brand,getBrand,updateBrand,deleteBrand } from '../../../redux/Product/Brand/action';
import { toast } from 'react-toastify';
import Loader from '../../../components/Loader/Loader';
import { useNavigate } from 'react-router-dom';

const validationSchema = yup.object({
  name: yup.string().required("Brand Name is Required!")
});

const Brand = ({ brand,getBrand,currentIntegration,deleteBrand,updateBrand, isLoading, isUpdate}) =>  {  useEffect(() => {
  getBrand();
}, []);

const [loading, setLoading] = useState(false);
useEffect(() => {
    if (isLoading) {
        setLoading(true);
    }
  });
  
//update row
const updateRow = async (e) => {
  const data = {
    name:e.name
  }
  updateBrand(e.id,data);
}

const deleteRow = (e) => {
  deleteBrand(e);
};

const [tableData, setTableData] = useState([]);
const navigate = useNavigate();

useEffect(() => {
  if(currentIntegration)
  {
    setTableData(currentIntegration); 
  }
}, [currentIntegration])
 
useEffect(() => {
  if(isUpdate)
  {
    window.location.reload(false);
  }
}, [isUpdate])

const columns = [
  { title: "Brand Name", field: "name" },
]

  return (
    <>
      <Container fluid className="main-content">
        <Row>
        <Col lg={"6"} className="mb-3">
            <Card>
              <Card.Body>
                <Card.Title>Add Brand</Card.Title>
                <Formik
                  validationSchema={validationSchema}
                  initialValues={{
                    name: ""
                  }}
                  onSubmit={(values) => {
                    brand(values);
                  }}
                >
                {({ values }) => (
                  <Form>
                    <label>Brand Name</label>
                    <Field name="name" type="text" className="form-control" />
                    <KErrorMessage name="name" />
                    <div className="mt-3 float-right">
                      <button className="btn btn-primary btn-md" type="submit">Submit</button>
                    </div>
                  </Form>
                )}
                </Formik>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={"6"}>
            <div style={{display: 'table', tableLayout:'fixed', width:'100%'}}>
              {loading ?
            <MaterialTable columns={columns} data={tableData}
              editable={{
                onRowUpdate: (newRow, oldRow) => new Promise((resolve, reject) => {
                  updateRow(newRow);
                  setTimeout(() => resolve(), 500)
                }),
                onRowDelete: (selectedRow) => new Promise((resolve, reject) => {
                  deleteRow(selectedRow.id)
                  setTimeout(() => resolve(), 1000)
                })
              }}

              options={{
                sorting: true, search: true,
                paging: true, pageSizeOptions: [10, 25, 50, 100], pageSize: 10,
                exportButton: true,
                exportAllData: true, exportFileName: "BRAND", actionsColumnIndex: -1,
                // headerStyle: { background: "#f44336",color:"#fff"}
              }}
              title="Brand"
            />
            : <Loader/>}
            </div>
            
          </Col>
        </Row>
      </Container>
    </>
  )
}

const mapStateToProps = (state) => {
  console.log(state);
 return {
    currentIntegration: state.brand.currentIntegration,
    isLoading: state.brand.isLoading,
    isUpdate: state.brand.isUpdate,
  }
}


export default connect(mapStateToProps,{brand,getBrand,updateBrand,deleteBrand})(Brand);

