import * as React from 'react';
import {useEffect} from 'react'
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { chkUser } from '../../redux/Authentication/action';
import { connect } from "react-redux";
import { Redirect, useHistory, useNavigate } from 'react-router-dom';

// function Copyright(props) {
//   return (
//     <Typography variant="body2" color="text.secondary" align="center" {...props}>
//       {'Copyright © '}
//       <Link color="inherit" href="https://mui.com/">
//         Your Website
//       </Link>{' '}
//       {new Date().getFullYear()}
//       {'.'}
//     </Typography>
//   );
// }

const theme = createTheme();

// const Auth = ({
//   isAuthenticated,
//   toggle,
//   login_toggle,
//   register_toggle,
//   forget_toggle,
//   loading,
//   forget_flag,
// }) => {
//   if (isAuthenticated) {
//     return <Redirect to='/dashboard' />;
//   }

const Login = ({ chkUser,isAuthenticated }) =>  {  
  //const history = useHistory();
  const navigate = useNavigate();
  // if (isAuthenticated) {
  //   //history.push("/brand");
  //   return <Redirect to='/brand' />
  // }

  useEffect(() => {
    if (isAuthenticated) {
      let usertype = JSON.parse(localStorage.getItem('usertype'));
      
      if(usertype === 1 || usertype === 2)
      {
        navigate('/dashboard');
      }
      else if(usertype === 3)
      {
        navigate('/dashboard-sales');
      }
      else if(usertype === 4)
      {
        navigate('/dashboard-accounts');
      }
      else if(usertype === 5)
      {
        navigate('/production-view');
      }
      else if(usertype === 6)
      {
        navigate('/dispatch-Order');
      }
      else if(usertype === 7)
      {
        navigate('/sap-booking');
      }
      else if(usertype === 8)
      {
        navigate('/view-pending-design');
      }
      else if(usertype === 9)
      {
        navigate('/view-order');
      }
      else if(usertype === 11)
      {
        navigate('/peddler-stock-query');
      }
      else if(usertype === 12)
      {
        navigate('/branch-dashboard');
      }
      else if(usertype === 13)
      {
        navigate('/create-cp-order');
      }
      else
      {
        navigate('/dashboard');
      }
      
    }
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    const data1 = new FormData(event.currentTarget);
    const  data ={
      email: data1.get('email'),
      password: data1.get('password'),
    };
    chkUser(data);
  };

  return (
    <ThemeProvider theme={theme}>
      <Grid container component="main" sx={{ height: '100vh' }}>
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          sx={{
            backgroundImage: 'url(https://soho.kukabyarterio.com/assets/logo-5.gif)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 18,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Sign In
              </Button>
              <Grid container></Grid>
              {/* <Copyright sx={{ mt: 5 }} /> */}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}

// const mapStateToProps = (state) => (
// {
  
//   isAuthenticated: state.auth.isAuthenticated,
// });
const mapStateToProps = (state) => {

  return {
     isAuthenticated: state.login.isAuthenticated,
   }
 }
export default connect(mapStateToProps,{chkUser})(Login);