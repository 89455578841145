import {
    CREATE_BRANCHWISE_REPORT,
    RETRIEVE_BRANCHWISE_REPORT,
    UPDATE_BRANCHWISE_REPORT,
    DELETE_BRANCHWISE_REPORT
} from "../BranchWise/actiontype";

const INITIAL_STATE = {
  currentIntegration: [],
  isLoading: false,

};

const  branchWiseReportReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case CREATE_BRANCHWISE_REPORT:
      return {
        ...state
      };
    case RETRIEVE_BRANCHWISE_REPORT:
      return {
        ...state,
        currentIntegration: payload,
        isLoading: true,
      };
    case UPDATE_BRANCHWISE_REPORT:
      return state.map((branchWiseReport) => {
        if (branchWiseReport.id === payload.id) {
          return {
            ...branchWiseReport,
            ...payload,
          };
        } else {
          return branchWiseReport;
        }
      });
    case DELETE_BRANCHWISE_REPORT:
      return state.filter(({ id }) => id !== payload.id);
    default:
      return state;
  }
};
export default branchWiseReportReducer;