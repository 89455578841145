import {
    CREATE_CATEGORY,
    RETRIEVE_CATEGORY,
    DELETE_CATEGORY,
    UPDATE_CATEGORY

} from "../Category/actiontype";

import { toast } from 'react-toastify';
import api from "../../../api";

export const category = (registerData) => async (dispatch) => {
  const body = JSON.stringify(registerData);
  try {
    const res = await api.post('/category/addCategory', body);
   
    if (res.status === 200) {
      toast.success('Category Added Successfully');
        dispatch({
            type: CREATE_CATEGORY,
            payload: res.data.data.info[0],
          });
        }
      } catch (err) {
    
    toast.warn('Invalid inputs! Category Not Added');
    // dispatch({
    //   type: REGISTER_FAIL,
    // });
  }
};

export const getCategory = (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  try {
    const res = await api.get(`/category/category`);
   
    
    dispatch({
      type: RETRIEVE_CATEGORY,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_CATEGORY,
      payload: [],
    });
  }
};

//export const updateCategory
export const updateCategory = (appId, formData) => async (dispatch) => {
  formData.id = appId;
  try {
    const res = await api.put('/category/updateCategory', formData);
   
    if (res.status === 200) {
      dispatch({
        type: UPDATE_CATEGORY,
        payload: res.data.data.info,
      });
      toast.success(res.data.message);
    }
  } catch (error) {
    
    toast.error('Failed to update data!');
    // dispatch({
    //   type: APP_DATA_ERROR,
    //   payload: 'ERROR' + uuidv1(),
    // });
  }
};


export const deleteCategory = (id) => async (dispatch) => {
  try {
    const res = await api.delete('/category/deleteCategory/' + id);
   
    if (res.status === 200) {
      dispatch({
        type: DELETE_CATEGORY,
        payload: res.data.data.info,
      });
      toast.success(res.data.message);
    }
  } catch (error) {
    
    toast.error('Failed to delete');
    dispatch({
      type: DELETE_CATEGORY,
      payload: [],
    });
  }
};