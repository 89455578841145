import {
    CREATE_VENDORS,
    RETRIEVE_VENDORS,
    RETRIEVE_SINGLE_VENDORS,
    UPDATE_VENDORS,
    DELETE_VENDORS
} from "../Vendor/actiontype";

import { toast } from 'react-toastify';
import api from "../../../api";

export const vendor = (registerData) => async (dispatch) => {
  const body = JSON.stringify(registerData);
  try {
    const res = await api.post('/vendor/addVendor', body);
   
    if (res.status === 200) {
      toast.success('Vendor Added Successfully');
        dispatch({
            type: CREATE_VENDORS,
            payload: res.data.data.info[0],
          });
        }
      } catch (err) {
    
    toast.warn('Invalid inputs! Vendor Not Added');
    // dispatch({
    //   type: REGISTER_FAIL,
    // });
  }
};

export const getVendor= (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  try {
    const res = await api.get(`/vendor/vendor`);
   
    
    dispatch({
      type: RETRIEVE_VENDORS,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_VENDORS,
      payload: [],
    });
  }
};

export const getSingleVendor = (id) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  try {
    const res = await api.get(`/vendor/getVendor/` + id);
   
    dispatch({
      type: RETRIEVE_SINGLE_VENDORS,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_SINGLE_VENDORS,
      payload: [],
    });
  }
};

export const updateVendor = (registerData) => async (dispatch) => {
  const body = JSON.stringify(registerData);
  try {
    const res = await api.post('/vendor/updateVendor', body);
   
    if (res.status === 200) {
      toast.success(res.data.message);
        dispatch({
            type: UPDATE_VENDORS,
            payload: res.data.data.info[0],
          });
        }
      } catch (err) {
    
    toast.warn('Invalid inputs! Vendor Not Updated');
    // dispatch({
    //   type: REGISTER_FAIL,
    // });
  }
};

export const deleteVendor = (id) => async (dispatch) => {
  try {
    const res = await api.delete('/vendor/deleteVendor/' + id);
    if (res.status === 200) {
      toast.success(res.data.message);
      dispatch({
        type:  DELETE_VENDORS,
        payload: res.data.data.info,
      });
      
    }
  } catch (error) {
    
    toast.error('Failed to delete');
    dispatch({
      type:  DELETE_VENDORS,
      payload: [],
    });
  }
};