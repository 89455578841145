import {
  CREATE_STOCKOUT
} from "../StockOut/actiontype";

import { toast } from 'react-toastify';
import api from "../../../api";

export const addStockOut = (registerData) => async (dispatch) => {
  const body = JSON.stringify(registerData);
  
  try {
    const res = await api.post('/branch/addStockOut', body);
   
    if (res.status === 200) {
      toast.success('Stock Added Successfully');
        dispatch({
            type: CREATE_STOCKOUT,
            payload: res.data.data.info[0],
          });
        }
      } catch (err) {
    
    toast.warn('Invalid inputs! Stock Not Added');
    // dispatch({
    //   type: REGISTER_FAIL,
    // });
  }
};

