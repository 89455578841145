import {
  RETRIEVE_CP_PRODUCT,
} from "../Product/actiontype";
import api from "../../../api";

//let usertype = JSON.parse(localStorage.getItem('usertype'));
let userid = JSON.parse(localStorage.getItem('userid'));

export const getCpProduct= (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
 
    

  try {
    const res = await api.get(`/cp/allotedOrder/` +userid);
   
    
    dispatch({
      type:  RETRIEVE_CP_PRODUCT,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type:  RETRIEVE_CP_PRODUCT,
      payload: [],
    });
  }
};

