import React,{ useState,useEffect } from 'react'
import { Row, Col, Container, Button, Card, Badge, Dropdown, Modal } from 'react-bootstrap';
import { Formik, Form, Field } from "formik";
import Select from 'react-select';
import { connect } from "react-redux";
import { getBrandName,getCordinator,getReffered,getProduct,getLocation,getProductName,addImportedReady,addImportedOrder,getProductNameOrder,calGrossAmt,createQuotation,getEditReady,onChangeCalcReady,updateReady,onChangeCalcOrder,updateOrder,getEditOrder,deleteReady,deleteOrder,getEditProductNameReady,getEditProductNameOrder} from '../../../redux/Quotation/CreateQuotation/action';
import { useNavigate } from 'react-router-dom';

  const rnd = Math.floor(Math.random() * 10000) + 1;
  localStorage.setItem('rndno', rnd);
  localStorage.removeItem('quotation_info');
  localStorage.setItem('total_amount', 0);
  localStorage.setItem('fitting', 0);
  localStorage.setItem('packing', 0);
  localStorage.removeItem('quotation_coordinator');
  localStorage.removeItem('quotation_refferedby');

  const CreateQuotation = ({ getBrandName,quotation,getCordinator,cordinators,getReffered,reffereds,getProduct,getLocation,products,locations,getProductName,products_name,addImportedReady,imported_ready,colour_code,leather_type,getProductNameOrder,order_products_name,imported_order,addImportedOrder,total_amount,calGrossAmt,gross_amt,balance_amt,createQuotation,isQuotationCreated,branch_detail,getEditReady,getEditReadyProduct,onChangeCalcReady,editReadyNetamount,updateReady,getEditOrderProduct,onChangeCalcOrder,editOrderNetamount,updateOrder,getEditOrder,deleteReady,deleteOrder,getEditProductNameReady,edit_product_name_ready,getEditProductNameOrder,edit_product_name_order}) => { useEffect(() => {
    const branch_id = window.location.pathname.split("/").pop();
    getBrandName(branch_id);
    getCordinator();
    getReffered();
    getProduct();
    getLocation();
  }, []);

  const [formDataModel, setFormData] = useState({
    product_id: "",
    name: "",
    qty: "",
    quoted_price: "",
    net_price: "",
    net_amount: "",
    location_id: "",
    remarks: "",
  });
  
  const { qty,quoted_price,net_price,remarks } = formDataModel;

  const [formDataModelOrder, setFormDataOrder] = useState({
    order_product_id: "",
    order_colourcode: "",
    order_qty: "",
    order_quoted_price: "",
    order_net_price: "",
    order_net_amount: "",
    order_remarks: "",
  });

  const { order_qty,order_quoted_price,order_net_price,order_remarks } = formDataModelOrder;

  const [cordinatorList, setCordinatorList] = useState(null);
  const [refferedList, setrefferedList] = useState(null);
  const [productList, setProductList] = useState(null);
  const [locationList, setLocationList] = useState(null);

  const [show, setShow] = useState(false); //imported Ready
  const modalAddClose = () => setShow(false); 
  const modalAddShow = () => setShow(true);

  const [show2, setShow2] = useState(false); //imported  order
  const modalEditClose = () => setShow2(false); 
  const modalEditShow = () => setShow2(true);

  const [show3, setShow3] = useState(false); //imported edit order
  const modalClose3 = () => setShow3(false);
  const modalShow3 = () => setShow3(true);

  const [show4, setShow4] = useState(false); //edit imported Ready
  const modalClose4 = () => setShow4(false);
  const modalShow4 = () => setShow4(true);

  const editReadyProduct = async (e) => {
    getEditReady(e);
      setShow3({
      isOpen: true,
      })
  }

  const editOnOrder = async (e) => {
    getEditOrder(e);
      setShow4({
      isOpen: true,
      })
  }

  useEffect(() => {
    if(cordinators)
    {
      setCordinatorList(cordinators); 
    }
  }, [cordinators])

  useEffect(() => {
    if(reffereds)
    {
      setrefferedList(reffereds); 
    }
  }, [reffereds])

  const onSelectChangeCordinator = (e) =>
  {
    localStorage.setItem('quotation_coordinator', e.value);
  }

  const onSelectChangeRefferedBy = (e) =>
  {
    localStorage.setItem('quotation_refferedby', e.value);
  }

  useEffect(() => {
    if(products_name)
    {
      setFormData({ ...formDataModel, name: products_name });
    }
  }, [products_name])
  
  useEffect(() => {
    if(products)
    {
      setProductList(products); 
    }
  }, [products])
  
  useEffect(() => {
    if(locations)
    {
      setLocationList(locations); 
    }
  }, [locations])

  const onChange = (e) =>
  {
    setFormData({ ...formDataModel, [e.target.name]: e.target.value });
    if(e.target.name === 'net_price' || e.target.name === 'qty' ){
      calc();
    }
  }

  function calc()
  {
    var net_price1 = document.getElementsByName("net_price")[0].value;
    var qty1 = document.getElementsByName("qty")[0].value;
    var calc = net_price1*qty1;
    document.getElementsByName("net_amount")[0].value = calc;
  }

  const getLocationDetail = (e) =>
  {
    setFormData({ ...formDataModel, location_id: e.value });
  }
  
  const getProductDetail = (e) =>
  {
    setFormData({ ...formDataModel, product_id: e.value });
    getProductName(e.value);
  }
  
  function submitProduct(){
    const rndno = JSON.parse(localStorage.getItem('rndno'));
    const data = {
      type: 'Ready',
      session_id: rndno,
      product_id:  formDataModel.product_id,
      name:  formDataModel.name,
      qty:  formDataModel.qty,
      quoted_price:  formDataModel.quoted_price,
      net_price:  formDataModel.net_price,
      net_amount:  formDataModel.net_price*formDataModel.qty,
      location_id:  formDataModel.location_id,
      remarks:  formDataModel.remarks,
    };
    addImportedReady(data);
    setShow(false);
  }

  // Order Actions

  useEffect(() => {
    if(order_products_name)
    {
      setFormDataOrder({ ...formDataModelOrder, order_product: order_products_name });
    }
  }, [order_products_name])
  
  useEffect(() => {
    if(colour_code)
    {
      setFormDataOrder({ ...formDataModelOrder, colourcode: colour_code });
    }
  }, [colour_code])
  
  useEffect(() => {
    if(leather_type)
    {
      setFormDataOrder({ ...formDataModelOrder, leathertype: leather_type });
    }
  }, [leather_type])

  useEffect(() => {
    if(products)
    {
      setProductList(products); 
    }
  }, [products])

  const getProductDetailOrder = (e) =>
  {
    setFormDataOrder({ ...formDataModelOrder, order_product_id: e.value });
    getProductNameOrder(e.value);
  }

  const onChangeOrder = (e) =>
  {
    setFormDataOrder({ ...formDataModelOrder, [e.target.name]: e.target.value });
    if(e.target.name === 'order_net_price' || e.target.name === 'order_qty' ){
      calcOrder();
    }
  }

  function calcOrder()
  {
    var net_price1 = document.getElementsByName("order_net_price")[0].value;
    var qty1 = document.getElementsByName("order_qty")[0].value;
    var calc1 = net_price1*qty1;
    document.getElementsByName("order_net_amount")[0].value = calc1;
  }

  function submitProductOrder(){
    const rndno = JSON.parse(localStorage.getItem('rndno'));
    const product_name = localStorage.getItem('product_name');
    const data = {
      type: 'Order',
      session_id: rndno,
      product_id:  formDataModelOrder.order_product_id,
      name:  product_name,
      leather_type:  formDataModelOrder.leathertype,
      qty:  formDataModelOrder.order_qty,
      quoted_price:  formDataModelOrder.order_quoted_price,
      net_price:  formDataModelOrder.order_net_price,
      net_amount:  formDataModelOrder.order_net_price*formDataModelOrder.order_qty,
      remarks:  formDataModelOrder.order_remarks,
    };
    
    addImportedOrder(data);
    document.getElementsByName("order_net_amount")[0].value = "";
    setFormDataOrder({ ...formDataModelOrder, order_products_name: "",order_qty:"",colour_code:"",leather_type:"",order_quoted_price:"",order_net_price:"",order_remarks:"",order_incentive:"" });
    setShow2(false);
  }
  
  const setForm = (e) => {
    var name = e.target.name;
    
    if(name === 'fitting')
    {
      localStorage.setItem('fitting', e.target.value);
    }
    else if(name === 'packing')
    {
      localStorage.setItem('packing', e.target.value);
    }    
    calGrossAmt();
  }

  const navigate = useNavigate();
 
  useEffect(()=>{
    if(isQuotationCreated)
    {
      localStorage.removeItem('rndno');
      localStorage.removeItem('quotation_coordinator')
      localStorage.removeItem('quotation_refferedby')
      localStorage.removeItem('fitting')
      localStorage.removeItem('total_amount')
      localStorage.removeItem('cartage')
      navigate('/quotation');
    }
  });

  const editSetForm = (e) => {
    var name = e.target.name;
    if(name == 'qty')
    {
      localStorage.setItem('editqty',e.target.value);
    }
    else if(name == 'netprice')
    {
      localStorage.setItem('editnetprice',e.target.value);
    }
    onChangeCalcReady();
  }
  
  const editOrderSetForm = (e) => {
    var name = e.target.name;
    if(name == 'qty')
    {
      localStorage.setItem('editqty',e.target.value);
    }
    else if(name == 'netprice')
    {
      localStorage.setItem('editnetprice',e.target.value);
    }
    onChangeCalcOrder();
  }

  const deleteReadyProduct = (e) => {
    deleteReady(e);
  }

  const deleteOnOrder = (e) => {
    deleteOrder(e);
  }

  // edit 
  const getEditProductDetailReady = (e) =>
  {
    getEditProductNameReady(e.value);
    localStorage.setItem('product_id',e.value);
  }

  const getEditLocationReady = (e) => 
  {
    localStorage.setItem('location_id',e.value);
  }
  
  const getEditProductDetailOrder = (e) =>
  {
    getEditProductNameOrder(e.value);
    localStorage.setItem('product_id',e.value);
  }

  return (
    <>
      <Container fluid className="main-content">
      < Row>
          <Col className='mb-2'>
              <h3 className="main-title">Create Quotation</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                  quotation: "",
                  deliverydate: "",
                  name: "",
                  mobile: "",
                  email: "",
                  address: "",
                  subtotal: "",
                  packing: "",
                  fitting: "",
                  discount: "",
                  grandtotal: "",
                  total: "",
                  }}
                  onSubmit={(values) => {
                    createQuotation(values);
                  }}
                >
                  {({ values }) => (
                    <Form>
                      <Row>
                        <Col lg={4} sm={12}>
                          <label>Quotation</label>
                          <Field name="quotation" type="text" className="form-control" readOnly  value={quotation}/>
                        </Col>
                        <Col lg={{ span: 4, offset: 4 }} sm={12}>
                          <label>Date</label>
                          <Field name="deliverydate" type="date" className="form-control" />
                        </Col>
                        <Col lg={12}>
                          <div className="line"></div>
                        </Col>
                        <Col lg={12} className='mb-2'>
                            <h5>Consultant:</h5>
                          </Col>
                          <Col lg={4} sm={12}>
                            <label>Cordinator</label>
                            <Select options={cordinatorList} name="coordinator" onChange={(e) => onSelectChangeCordinator(e)}/>
                          </Col>
                          <Col lg={{ span: 4, offset: 4 }} sm={12}>
                            <label>Reffered By</label>
                            <Select options={refferedList} name="refferedby" onChange={(e) => onSelectChangeRefferedBy(e)}/>
                          </Col>
                          <Col lg={12} className='mt-2'>
                            <div className="line"></div>
                          </Col>
                          <Col lg={12} sm={12} className='mb-2'>
                              <h5>Customer Detail:</h5>
                          </Col>
                        <Col lg={3} sm={12}>
                          <label>Customer Name</label>
                          <Field name="name" type="text" className="form-control" />
                        </Col>
                        <Col lg={3} sm={12}>
                          <label>Customer Mobile</label>
                          <Field name="mobile" type="text" className="form-control"/>
                        </Col>
                        <Col lg={3} sm={12}>
                          <label>Customer Email</label>
                          <Field name="email" type="text" className="form-control"/>
                        </Col>
                        <Col lg={3} sm={12}>
                          <label>Address</label>
                          <Field name="address" type="text" className="form-control"/>
                        </Col>
                        <Col lg={12}>
                          <div className="line"></div>
                        </Col>
                      </Row>
                      <Row className='mb-2'>
                        <Col>
                          <div className='d-flex justify-content-between'>
                            <h5>Imported Ready Product</h5>
                            <button type="button" className="btn btn-md btn-info text-white" onClick={modalAddShow} style={{float: 'right'}}><i className="fa-solid fa-plus"></i> Add</button>
                          </div>
                        </Col>
                      </Row>
                      <Row className='mt-4'>
                        {imported_ready.map((prod, index) => (
                          <Col lg={4} sm={"12"} key={index}>
                            <div className="card product mb-2">
                              <div className="card-header d-flex justify-content-between pb-0">
                                  <h6 className="mb-0"><span className="card-title">{prod.name}</span> <Badge bg="info" text="light" className="ml-2">{prod.qty}</Badge></h6>
                                <Dropdown>
                                  <Dropdown.Toggle variant="light" id="dropdown-basic">
                                    <i className="ri-more-2-line"></i>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => editReadyProduct(prod.id)}><i className="ri-edit-box-line text-primary"></i> Edit</Dropdown.Item>
                                    <Dropdown.Item onClick={() => deleteReadyProduct(prod.id)}><i className="far fa-trash-alt text-danger"></i> Delete</Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                              <div className="card-body pt-1">
                                <Row>
                                  <Col lg={4}>
                                    <div>
                                      <img src={process.env.REACT_APP_IMAGE_URL+'/'+prod.image} alt="prod_img" className='product-image' width="100%"/>
                                    </div>
                                  </Col>
                                  <Col lg={8}>
                                    <p className="card-text mb-0"><span className="product-info">Model No:</span> {prod.model_id}</p>
                                    <p className="card-text mb-0"><span className="product-info">Quoted Price:</span> {prod.quoted_price}</p>
                                    <p className="card-text mb-0"><span className="product-info">Net Price:</span> {prod.net_price}</p>
                                    <p className="card-text mb-0"><span className="product-info">Net Amount:</span> {prod.net_amount}</p>
                                  </Col>
                                  <Col lg={12} className='mt-1'>
                                    <p className="card-text mb-0"><span className="product-info">Color Code:</span> {prod.colour_code}</p>
                                    <p className="card-text mb-0"><span className="product-info">Leather Type:</span> {prod.leather_type}</p>
                                    <p className="card-text mb-0"><span className="product-info">Product Location:</span> {prod.location}</p>
                                    <p className="card-text mb-0"><span className="product-info">Remarks:</span> {prod.remarks}</p>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </Col>
                        ))}                       
                        <Col lg={12}>
                          <div className="line"></div>
                        </Col>
                      </Row>
                      <Row className='mb-2'>
                        <Col>
                          <div className='d-flex justify-content-between'>
                            <h5>Imported On Order</h5>
                            <button type="button" className="btn btn-md btn-info text-white" onClick={modalEditShow} style={{float: 'right'}}><i className="fa-solid fa-plus"></i> Add</button>
                          </div>
                        </Col>
                      </Row>
                      <Row className='mt-4'>
                      {imported_order.map((prod, index) => (
                        <Col lg={4} sm={"12"} key={index}>
                          <div className="card product mb-2">
                            <div className="card-header d-flex justify-content-between pb-0">
                            <h6 className="mb-0"><span className="card-title">{prod.name}</span> <Badge bg="info" text="light" className="ml-2">{prod.qty}</Badge></h6>
                              <Dropdown>
                                <Dropdown.Toggle variant="light" id="dropdown-basic">
                                  <i className="ri-more-2-line"></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item onClick={() => editOnOrder(prod.id)}><i className="ri-edit-box-line text-primary"></i> Edit</Dropdown.Item>
                                  <Dropdown.Item onClick={() => deleteOnOrder(prod.id)}><i className="far fa-trash-alt text-danger"></i> Delete</Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                            <div className="card-body pt-1">
                              <Row>
                                <Col lg={4}>
                                  <div>
                                  <img src={process.env.REACT_APP_IMAGE_URL+'/'+prod.image} alt="prod_img" className='product-image' width="100%"/>
                                  </div>
                                </Col>
                                <Col lg={8}>
                                <p className="card-text mb-0"><span className="product-info">Model No:</span> {prod.model_id}</p>
                                  <p className="card-text mb-0"><span className="product-info">Quoted Price:</span> {prod.quoted_price}</p>
                                  <p className="card-text mb-0"><span className="product-info">Net Price:</span> {prod.net_price}</p>
                                  <p className="card-text mb-0"><span className="product-info">Net Amount:</span> {prod.net_amount}</p>
                                </Col>
                                <Col lg={12} className='mt-1'>
                                <p className="card-text mb-0"><span className="product-info">Color Code:</span> {prod.colour_code}</p>
                                  <p className="card-text mb-0"><span className="product-info">Leather Type:</span> {prod.leather_type}</p>
                                  <p className="card-text mb-0"><span className="product-info">Remarks:</span> {prod.remarks}</p>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Col>
                      ))}
                        <Col lg={12}>
                          <div className="line"></div>
                        </Col>
                      </Row>
                      <Row className='mb-2'>
                        <Col>
                        <h5>Billing</h5>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6}>
                          <table className="table table-invoice table-bordered">
                            <tbody>
                              <tr>
                                <td width="60%">
                                  <p><strong>BRANCH DETAIL:</strong></p>
                                    <p className="m-0"> <strong> BENEFICIARY NAME:</strong> <span>{branch_detail.name}</span></p>
                                    <p className="m-0"> <strong> ACCOUNT NO:</strong> <span>{branch_detail.account_no}</span></p>
                                    <p className="m-0"> <strong> IFSC :</strong> <span>{branch_detail.ifsc}</span></p>
                                    <p> <strong> BANK NAME :</strong> <span>{branch_detail.bank_name}</span></p>
                                </td>
                                <td width="40%">
                                    <p><strong>TERMS AND CONDITIONS:</strong></p>
                                    <p className="m-0"> CARTAGE AND PACKING INCLUDED</p>
                                    <p className="m-0">TRANSPORTATION EXTRA</p>
                                    <p className="m-0"> 50% ADVANCE REQUIRED</p>
                                    <p className="m-0">BALANCE 50% BEFORE DELIVERY </p>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Col>
                        <Col lg={6}>
                            <Row>
                            <Col lg={{ span: 2, offset: 2 }} sm={3} style={{textAlign: "right"}}>
                              <label>Sub Total</label>
                            </Col>
                            <Col lg={8} sm={9}>
                              <Field name="subtotal" type="text" className="form-control" value={total_amount} readOnly/>
                            </Col>
                            <Col lg={{ span: 2, offset: 2 }} sm={3} style={{textAlign: "right"}}>
                              <label>Fitting</label>
                            </Col>
                            <Col lg={8} sm={9}>
                              <Field name="fitting" type="text" className="form-control" onKeyUp={(e) => { setForm(e);}}/>
                            </Col>
                            <Col lg={{ span: 2, offset: 2 }} sm={3} style={{textAlign: "right"}}>
                              <label>Total</label>
                            </Col>
                            <Col lg={8} sm={9}>
                              <Field name="total" type="text" className="form-control" readOnly value={gross_amt}/>
                            </Col>
                            <Col lg={{ span: 2, offset: 2 }} sm={3} style={{textAlign: "right"}}>
                              <label>Packing</label>
                            </Col>
                            <Col lg={8} sm={9}>
                              <Field name="packing" type="text" className="form-control" onKeyUp={(e) => { setForm(e);}}/>
                            </Col>
                            <Col lg={{ span: 2, offset: 2 }} sm={3} style={{textAlign: "right"}}>
                              <label>Grand Total</label>
                            </Col>
                            <Col lg={8} sm={9}>
                              <Field name="grandtotal" type="text" className="form-control" readOnly value={balance_amt}/>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className='mt-4'>
                        <Col>
                          <Button variant="primary" type="submit" style={{float: 'right'}}> Submit </Button>
                        </Col>
                      </Row>
                  </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      {/* Add Imported Ready Product Modal Start */}
      <Modal 
          size="lg"
          centered
          show={show} 
          onHide={modalAddClose}>
        <Formik
            initialValues={{
            model: "",
            name: "",
            qty: "",
            quoted_price: "",
            netprice: "",
            netamount: "",
            location: "",
            remarks: "",
            incentive: ""
            }}
            onSubmit={(values) => {
           
            }}
        >
          {({values, setFieldValue}) => (
        <Form>
          <Modal.Header closeButton>
            <Modal.Title>Imported Ready Product</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg={12} sm={12} className="mb-2">
                <label>Model No.</label>
                <Select options={productList} name="product_id" onChange={(e) => getProductDetail(e)}/>
              </Col>
              <Col lg={6} sm={12}>
                <label>Name</label>
                <Field name="name" type="text" className="form-control" value={products_name} />
              </Col>
              <Col lg={6} sm={12}>
                <label>Quantity</label>
                <Field name="qty" type="number" className="form-control" value={qty} onChange={(e) => onChange(e)}/>
              </Col>
              <Col lg={6} sm={12}>
                <label>QuotedPrice</label>
                <Field name="quoted_price" type="text" className="form-control" value={quoted_price} onChange={(e) => onChange(e)} />
              </Col>
              <Col lg={6} sm={12}>
                <label>Net Price</label>
                <Field name="net_price" type="text" className="form-control" value={net_price} onChange={(e) => onChange(e)} />
              </Col>
              <Col lg={6} sm={12}>
                <label>Net Amount</label>
                <Field name="net_amount" type="text" className="form-control" readOnly/>
              </Col>
              <Col lg={6} sm={12}>
                <label>Location of Product</label>
                <Select options={locationList} name="location_id" onChange={(e) => getLocationDetail(e)}/>
              </Col>
              <Col lg={6} sm={12}>
                <label>Remarks</label>
                <Field name="remarks" as="textarea" className="form-control" value={remarks} onChange={(e) => onChange(e)}/>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit" onClick={submitProduct} >Submit</Button>
          </Modal.Footer>
        </Form>
            )}
        </Formik>
      </Modal>
      {/* Add Imported Ready Product Modal End */}
      {/* Edit Imported Ready Product Modal Start */}
      <Modal 
          size="lg"
          centered
          show={show3} 
          onHide={modalClose3}>
        <Formik
            enableReinitialize={true}
            initialValues={{
            id:getEditReadyProduct.id,
            model: getEditReadyProduct.model_id,
            name: edit_product_name_ready,
            qty: getEditReadyProduct.qty,
            quotedprice: getEditReadyProduct.quoted_price,
            netprice: getEditReadyProduct.net_price,
            netamount: getEditReadyProduct.net_amount,
            location: getEditReadyProduct.location_id,
            remarks: getEditReadyProduct.remarks,
            }}
            onSubmit={(values) => {
              updateReady(values);
              setShow3(false);
            }}
        >
          {({values, setFieldValue}) => (
        <Form>
          <Modal.Header closeButton>
            <Modal.Title>Edit Imported Ready Product</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg={12} sm={12} className="mb-2">
                <label>Model No. - { getEditReadyProduct.model_name}</label>
                <Select options={productList} name="model" defaultValue={{ label: getEditReadyProduct.model_name, value: getEditReadyProduct.model_id }} onChange={(e) => getEditProductDetailReady(e)}/>
              </Col>
              <Col lg={6} sm={12}>
                <label>Name</label>
                <Field name="name" type="text" className="form-control" />
              </Col>
              <Col lg={6} sm={12}>
                <label>Quantity</label>
                <Field name="qty" type="text" className="form-control" onKeyUp={(e) => { editSetForm(e);}}/>
              </Col>
              <Col lg={6} sm={12}>
                <label>QuotedPrice</label>
                <Field name="quotedprice" type="text" className="form-control" />
              </Col>
              <Col lg={6} sm={12}>
                <label>Net Price</label>
                <Field name="netprice" type="text" className="form-control" onKeyUp={(e) => { editSetForm(e);}}/>
              </Col>
              <Col lg={6} sm={12}>
                <label>Net Amount</label>
                <Field name="netamount" type="text" className="form-control" readOnly value={editReadyNetamount}/>
              </Col>
              <Col lg={6} sm={12}>
                <label>Location of Product - { getEditReadyProduct.location} </label>
                <Select defaultValue={{ label: getEditReadyProduct.location, value: getEditReadyProduct.location_id }} options={locationList} name="location" onChange={(e) => getEditLocationReady(e)}/>
              </Col>
              <Col lg={6} sm={12}>
                <label>Remarks</label>
                <Field name="remarks" as="textarea" className="form-control" />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit">Submit</Button>
          </Modal.Footer>
        </Form>
            )}
        </Formik>
      </Modal>
      {/* Edit Imported Ready Product Modal End */}
      {/* Add Imported On Order Modal Start */}
      <Modal 
          size="lg"
          centered
          show={show2} 
          onHide={modalEditClose}>
        <Formik
            initialValues={{
            model: "",
            name: "",
            qty: "",
            quotedprice: "",
            netprice: "",
            netamount: "",
            location: "",
            remarks: "",
            incentive: ""
            }}
            onSubmit={(values) => {
           
            }}
        >
          {({values, setFieldValue}) => (
        <Form>
          <Modal.Header closeButton>
            <Modal.Title>Imported on Order</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg={12} sm={12} className="mb-2">
                <label>Model No.</label>
                <Select options={productList} name="order_product_id" onChange={(e) => getProductDetailOrder(e)} />
              </Col>
              <Col lg={6} sm={12}>
                <label>Product Name</label>
                <Field name="order_product" type="text" className="form-control" value={order_products_name} />
              </Col>
              <Col lg={6} sm={12}>
                <label>Colour Code</label>
                <Field name="colourcode" type="text" className="form-control" value={colour_code}/>
              </Col>
              <Col lg={6} sm={12}>
                <label>Leather Type</label>
                <Field name="leathertype" type="text" className="form-control"  value={leather_type}/>
              </Col>
              <Col lg={6} sm={12}>
                <label>Quantity</label>
                <Field name="order_qty" type="text" className="form-control" value={order_qty} onChange={(e) => onChangeOrder(e)}/>
              </Col>
              <Col lg={6} sm={12}>
                <label>QuotedPrice</label>
                <Field name="order_quoted_price" type="text" className="form-control" value={order_quoted_price} onChange={(e) => onChangeOrder(e)} />
              </Col>
              <Col lg={6} sm={12}>
                <label>Net Price</label>
                <Field name="order_net_price" type="text" className="form-control" value={order_net_price} onChange={(e) => onChangeOrder(e)} />
              </Col>
              <Col lg={6} sm={12}>
                <label>Net Amount</label>
                <Field name="order_net_amount" type="text" className="form-control" readOnly/>
              </Col>
              <Col lg={6} sm={12}>
                <label>Remarks</label>
                <Field name="order_remarks" as="textarea" className="form-control" value={order_remarks} onChange={(e) => onChangeOrder(e)}/>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="button" onClick={submitProductOrder}>Submit</Button>
          </Modal.Footer>
        </Form>
            )}
        </Formik>
      </Modal>
      {/* Add Imported On Order Modal End */}
      {/* Edit Imported On Order Modal Start */}
      <Modal 
          size="lg"
          centered
          show={show4} 
          onHide={modalClose4}>
        <Formik
            enableReinitialize={true}
            initialValues={{
            id:getEditOrderProduct.id,
            model: getEditOrderProduct.model_id,
            name: edit_product_name_order,
            leathertype: getEditOrderProduct.leather_type,
            qty: getEditOrderProduct.qty,
            quotedprice: getEditOrderProduct.quoted_price,
            netprice: getEditOrderProduct.net_price,
            netamount: getEditOrderProduct.net_amount,
            location: getEditOrderProduct.location_id,
            remarks: getEditOrderProduct.remarks,
            }}
            onSubmit={(values) => {
              updateOrder(values);
              setShow4(false);
            }}
        >
          {({values, setFieldValue}) => (
        <Form>
          <Modal.Header closeButton>
            <Modal.Title>Edit Imported on Order</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg={12} sm={12} className="mb-2">
                <label>Model No. - { getEditOrderProduct.model_name}</label>
                <Select options={productList} name="model" defaultValue={{ label: getEditOrderProduct.model_name, value: getEditOrderProduct.model_id }}  onChange={(e) => getEditProductDetailOrder(e)}/>
              </Col>
              <Col lg={6} sm={12}>
                <label>Product Name</label>
                <Field name="name" type="text" className="form-control" />
              </Col>
              <Col lg={6} sm={12}>
                <label>Colour Code</label>
                <Field name="colourcode" type="text" className="form-control" />
              </Col>
              <Col lg={6} sm={12}>
                <label>Leather Type</label>
                <Field name="leathertype" type="text" className="form-control" />
              </Col>
              <Col lg={6} sm={12}>
                <label>Quantity</label>
                <Field name="qty" type="text" className="form-control"  onKeyUp={(e) => { editOrderSetForm(e);}}/>
              </Col>
              <Col lg={6} sm={12}>
                <label>Quoted Price</label>
                <Field name="quotedprice" type="text" className="form-control" />
              </Col>
              <Col lg={6} sm={12}>
                <label>Net Price</label>
                <Field name="netprice" type="text" className="form-control"  onKeyUp={(e) => { editOrderSetForm(e);}}/>
              </Col>
              <Col lg={6} sm={12}>
                <label>Net Amount</label>
                <Field name="netamount" type="text" className="form-control" readOnly value={editOrderNetamount}/>
              </Col>
              <Col lg={6} sm={12}>
                <label>Remarks</label>
                <Field name="remarks" as="textarea" className="form-control" />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit">Submit</Button>
          </Modal.Footer>
        </Form>
            )}
        </Formik>
      </Modal>
    </>
  )
}

  const mapStateToProps = (state) => {
    return {
      quotation: state.quotationReducer.quotation,
      cordinators: state.quotationReducer.cordinator,
      reffereds: state.quotationReducer.reffered,
      products: state.quotationReducer.products,
      locations: state.quotationReducer.locations,
      products_name: state.quotationReducer.product_name.name,
      imported_ready: state.quotationReducer.imported_ready,
      imported_order: state.quotationReducer.imported_order,
      colour_code: state.quotationReducer.product_name.colour_code,
      leather_type: state.quotationReducer.product_name.leather_type,
      order_products_name: state.quotationReducer.product_name.name,
      total_amount: state.quotationReducer.total_amount,
      gross_amt: state.quotationReducer.gross_total,
      balance_amt : state.quotationReducer.balance,
      isQuotationCreated: state.quotationReducer.isQuotationCreated,
      branch_detail: state.quotationReducer.branch_detail,
      getEditReadyProduct: state.quotationReducer.edit_ready_product,
      editReadyNetamount: state.quotationReducer.edit_ready_netamount,
      getEditOrderProduct: state.quotationReducer.edit_order_product,
      editOrderNetamount: state.quotationReducer.edit_order_netamount,
      edit_product_name_ready: state.quotationReducer.edit_product_name_ready,
      edit_product_name_order: state.quotationReducer.edit_product_name_order,
    }
  }
  
  export default connect(mapStateToProps,{getBrandName,getCordinator,getReffered,getProduct,getLocation,getProductName,addImportedReady,addImportedOrder,getProductNameOrder,calGrossAmt,createQuotation,getEditReady,onChangeCalcReady,updateReady,onChangeCalcOrder,updateOrder,getEditOrder,deleteReady,deleteOrder,getEditProductNameReady,getEditProductNameOrder})(CreateQuotation);

