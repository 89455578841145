import * as React from "react";
import { useState, useEffect } from 'react'
import { Row, Col, Container, Dropdown, Badge } from 'react-bootstrap';
import MaterialTable from 'material-table';
import { connect } from "react-redux";
import {getApprovePayment} from '../../../redux/Payment/ApprovePayment/action';
import SearchBar from "../../../components/SearchBar/SearchBar";

const ApprovePayment = ({ getApprovePayment,currentIntegration }) =>  {  useEffect(() => {
    getApprovePayment();
  }, []);

    const [visible, setVisible] = useState(false);
    const handleVisibility = () => {
        setVisible(prev => !prev);
    };

    const [tableData, setTableData] = useState([]);
    useEffect(() => {
    if(currentIntegration)
    {
        setTableData(currentIntegration); 
    }
    }, [currentIntegration])

    const columns = [
        { title: "Order No.", field: "orderNo" },
        { title: "Customer Name", field: "customerName" },
        { title: "Total Order", field: "totalOrder" },
        { title: "Due Amount", field: "dueAmount" },
        { title: "Payment", field: "payment" },
        { title: "Mode", field: "mode" },
        { title: "Sold By", field: "soldBy" },
        { title: "Date", field: "date" },
        { title: "Approval Date", field: "approvedDate" },
    ]
    // Search for Card view start
    const [searchValue, setSearchValue] = useState("");

    const filterPayment = ({ orderNo }) => {
        return orderNo.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
    };
    // Search for Card view end

    return (
        <>
            <Container fluid className="main-content">
                <Row>
                    <Col className='d-flex justify-content-between mb-2'>
                        <h3 className="main-title">Approve Payment</h3>
                        <div className="d-flex justify-content-between d-block d-sm-none">
                            <div className="magnifierContainer">
                                <button type="button" className="btn btn-md btn-warning text-white roundedcircle mr-3" onClick={handleVisibility}><i className="fa-solid fa-search"></i></button>
                            </div>
                        </div>
                    </Col>
                    <Col lg={"12"} className='mb-2 d-block d-sm-none'>
                        <div className={visible ? "open" : "close"}>
                            <SearchBar onSearch={setSearchValue} value={searchValue} />
                        </div>
                    </Col>
                </Row>
                <Row className="d-none d-sm-block mt-2">
                    <Col lg={"12"}>
                        <div style={{ display: 'table', tableLayout: 'fixed', width: '100%' }}>
                            <MaterialTable columns={columns} data={tableData}
                                options={{
                                    sorting: true, search: true,
                                    paging: true, pageSizeOptions: [25, 50, 100], pageSize: 25,
                                    exportButton: true,
                                    exportAllData: true, exportFileName: "Approve Payment", actionsColumnIndex: -1,
                                    // headerStyle: { background: "#f44336",color:"#fff"}
                                }}
                                title="Approve Payment"
                            />
                        </div>
                    </Col>
                </Row>
                <Row className="d-block d-sm-none">
                    {currentIntegration.filter(filterPayment).map((payment, index) => (
                    <Col xs={"12"} key={index}>
                        <div className="card mb-2">
                            <div className="card-header d-flex justify-content-between pb-0">
                                <h6>{payment.orderNo}</h6>
                            </div>
                            <div className="card-body pt-1">
                                <p className="card-text mb-0"><span className="quotation-info">Customer Name:</span> {payment.customerName}</p>
                                <p className="card-text mb-0"><span className="quotation-info">Total Order:</span> {payment.totalOrder}</p>
                                <p className="card-text mb-0"><span className="quotation-info">Due Amount:</span> {payment.dueAmount}</p>
                                <p className="card-text mb-0"><span className="quotation-info">Payment:</span> {payment.payment}</p>
                                <p className="card-text mb-0"><span className="quotation-info">Mode:</span> {payment.mode}</p>
                                <p className="card-text mb-0"><span className="quotation-info">Sold By:</span> {payment.soldBy}</p>
                                <p className="card-text mb-0"><span className="quotation-info">Order Date:</span> {payment.date}</p>
                                <p className="card-text mb-0"><span className="quotation-info">Approval Date:</span> {payment.approvedDate}</p>
                            </div>
                        </div>
                    </Col>
                    ))}
                </Row>
            </Container>
        </>

    )
}

const mapStateToProps = (state) => {

  return {
     currentIntegration: state.approvePayment.currentIntegration,
   }
 }

 export default connect(mapStateToProps,{getApprovePayment})(ApprovePayment);