import React from 'react'
import {useState} from 'react'
import { Row, Col, Container, Modal, Button, Badge, Dropdown} from 'react-bootstrap';
import { Formik, Form, Field } from "formik";
import Select from 'react-select'

export const StepThree = ({ formData, setForm, navigation }) => {

  const [show, setShow] = useState(false);
  const modalAddClose = () => setShow(false);
  const modalAddShow = () => setShow(true);
  
  const model = [
    { value: '1', label: 'INDESIGN#QT_1002' },
    { value: '2', label: 'INDESIGN#QT_1003' },
    { value: '3', label: 'INDESIGN#QT_10024' }
  ]
  const location = [
    { value: '1', label: 'INDESIGN#QT_1002' },
    { value: '2', label: 'INDESIGN#QT_1003' },
    { value: '3', label: 'INDESIGN#QT_10024' }
  ]

  return (
      <>
       <Container fluid>
          <Row className='mb-2'>
            <Col>
            <h5>Imported On Order</h5>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <button type="button" className="btn btn-md btn-info text-white" onClick={modalAddShow} style={{float: 'right'}}><i className="fa-solid fa-plus"></i> Add</button>
            </Col>
          </Row>
          <Row className='mt-4'>
            <Col lg={4} sm={"12"}>
              <div className="card product mb-2">
                <div className="card-header d-flex justify-content-between pb-0">
                    <h6 className="mb-0"><span className="card-title">CC101160</span> <Badge bg="info" text="light" className="ml-2">Qty</Badge></h6>
                  <Dropdown>
                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                      <i className="ri-more-2-line"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1"><i className="ri-edit-box-line text-primary"></i> Edit</Dropdown.Item>
                      <Dropdown.Item href="#/action-2"><i className="far fa-trash-alt text-danger"></i> Delete</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="card-body pt-1">
                  <Row>
                    <Col lg={4}>
                      <div>
                        <img src="https://www.betterhomeindia.com/wp-content/uploads/2019/01/crown-revolving-chair-betterhomeindia.jpg" alt="user name" className='product-image' width="100%"/>
                      </div>
                    </Col>
                    <Col lg={8}>
                      <p className="card-text mb-0"><span className="product-info">Category:</span> Chair</p>
                      <p className="card-text mb-0"><span className="product-info">Quoted Price:</span> 8000</p>
                      <p className="card-text mb-0"><span className="product-info">Net Price:</span> 5000</p>
                      <p className="card-text mb-0"><span className="product-info">Net Amount:</span> 4000</p>
                      <p className="card-text mb-0"><span className="product-info">Incentive:</span> 2000</p>
                    </Col>
                    <Col lg={12} className='mt-1'>
                      <p className="card-text mb-0"><span className="product-info">Color Code:</span> No</p>
                      <p className="card-text mb-0"><span className="product-info">Leather Type:</span> NA</p>
                      <p className="card-text mb-0"><span className="product-info">Remarks:</span> NA</p>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={4} sm={"12"}>
              <div className="card product mb-2">
                <div className="card-header d-flex justify-content-between pb-0">
                    <h6 className="mb-0"><span className="card-title">CC101160</span> <Badge bg="info" text="light" className="ml-2">Qty</Badge></h6>
                  <Dropdown>
                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                      <i className="ri-more-2-line"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1"><i className="ri-edit-box-line text-primary"></i> Edit</Dropdown.Item>
                      <Dropdown.Item href="#/action-2"><i className="far fa-trash-alt text-danger"></i> Delete</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="card-body pt-1">
                  <Row>
                    <Col lg={4}>
                      <div>
                        <img src="https://www.betterhomeindia.com/wp-content/uploads/2019/01/crown-revolving-chair-betterhomeindia.jpg" alt="user name" className='product-image' width="100%"/>
                      </div>
                    </Col>
                    <Col lg={8}>
                      <p className="card-text mb-0"><span className="product-info">Category:</span> Chair</p>
                      <p className="card-text mb-0"><span className="product-info">Quoted Price:</span> 8000</p>
                      <p className="card-text mb-0"><span className="product-info">Net Price:</span> 5000</p>
                      <p className="card-text mb-0"><span className="product-info">Net Amount:</span> 4000</p>
                      <p className="card-text mb-0"><span className="product-info">Incentive:</span> 2000</p>
                    </Col>
                    <Col lg={12} className='mt-1'>
                      <p className="card-text mb-0"><span className="product-info">Color Code:</span> No</p>
                      <p className="card-text mb-0"><span className="product-info">Leather Type:</span> NA</p>
                      <p className="card-text mb-0"><span className="product-info">Remarks:</span> NA</p>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={4} sm={"12"}>
              <div className="card product mb-2">
                <div className="card-header d-flex justify-content-between pb-0">
                    <h6 className="mb-0"><span className="card-title">CC101160</span> <Badge bg="info" text="light" className="ml-2">Qty</Badge></h6>
                  <Dropdown>
                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                      <i className="ri-more-2-line"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1"><i className="ri-edit-box-line text-primary"></i> Edit</Dropdown.Item>
                      <Dropdown.Item href="#/action-2"><i className="far fa-trash-alt text-danger"></i> Delete</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="card-body pt-1">
                  <Row>
                    <Col lg={4}>
                      <div>
                        <img src="https://www.betterhomeindia.com/wp-content/uploads/2019/01/crown-revolving-chair-betterhomeindia.jpg" alt="user name"className='product-image' width="100%" />
                      </div>
                    </Col>
                    <Col lg={8}>
                      <p className="card-text mb-0"><span className="product-info">Category:</span> Chair</p>
                      <p className="card-text mb-0"><span className="product-info">Quoted Price:</span> 8000</p>
                      <p className="card-text mb-0"><span className="product-info">Net Price:</span> 5000</p>
                      <p className="card-text mb-0"><span className="product-info">Net Amount:</span> 4000</p>
                      <p className="card-text mb-0"><span className="product-info">Incentive:</span> 2000</p>
                    </Col>
                    <Col lg={12} className='mt-1'>
                      <p className="card-text mb-0"><span className="product-info">Color Code:</span> No</p>
                      <p className="card-text mb-0"><span className="product-info">Leather Type:</span> NA</p>
                      <p className="card-text mb-0"><span className="product-info">Remarks:</span> NA</p>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
          <Row className='mt-4'>
            <Col>
              <Button variant="primary" onClick={() => navigation.previous()} > Back </Button>
            </Col>
            <Col>
              <Button variant="primary" style={{float: 'right'}} onClick={() => navigation.next()} > Next </Button>
            </Col>
          </Row>
       </Container>
       <Modal 
          size="lg"
          centered
          show={show} 
          onHide={modalAddClose}>
        <Formik
            initialValues={{
            model: "",
            name: "",
            qty: "",
            quotedprice: "",
            netprice: "",
            netamount: "",
            location: "",
            remarks: "",
            incentive: ""
            }}
            onSubmit={(values) => {
           
            }}
        >
          {({values, setFieldValue}) => (
        <Form>
          <Modal.Header closeButton>
            <Modal.Title>Imported on Order</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg={12} sm={12} className="mb-2">
                <label>Model No.</label>
                <Select options={model} name="model" />
              </Col>
              <Col lg={6} sm={12}>
                <label>Name</label>
                <Field name="name" type="text" className="form-control" />
              </Col>
              <Col lg={6} sm={12}>
                <label>Quantity</label>
                <Field name="qty" type="text" className="form-control" />
              </Col>
              <Col lg={6} sm={12}>
                <label>QuotedPrice</label>
                <Field name="quotedprice" type="text" className="form-control" />
              </Col>
              <Col lg={6} sm={12}>
                <label>Net Price</label>
                <Field name="netprice" type="text" className="form-control" />
              </Col>
              <Col lg={6} sm={12}>
                <label>Net Amount</label>
                <Field name="netamount" type="text" className="form-control" readonly/>
              </Col>
              <Col lg={6} sm={12}>
                <label>Incentive</label>
                <Field name="incentive" type="text" className="form-control" />
              </Col>
              <Col lg={6} sm={12}>
                <label>Remarks</label>
                <Field name="remarks" as="textarea" className="form-control" />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit">Submit</Button>
          </Modal.Footer>
        </Form>
            )}
        </Formik>
      </Modal>
      </>
   
  )
}
