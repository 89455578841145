import * as React from "react";
import { useState, useEffect } from 'react'
import { Row, Col, Container, Dropdown, Badge } from 'react-bootstrap';
import MaterialTable from 'material-table';
import PrintIcon from '@mui/icons-material/Print';
import { connect } from "react-redux";
import {getCompleteDispatch} from '../../../redux/Dispatch/CompleteDispatch/action';
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import { useNavigate } from 'react-router-dom';
import Loader from "../../../components/Loader/Loader";

    const CompleteDispatch = ({ getCompleteDispatch,currentIntegration, isLoading}) =>  {  useEffect(() => {
        getCompleteDispatch();
    }, []);

    const [visible, setVisible] = useState(false);
    const handleVisibility = () => {
        setVisible(prev => !prev);
    };

    
    const [tableData, setTableData] = useState([]);
    useEffect(() => {
    if(currentIntegration)
    {
        setTableData(currentIntegration); 
    }
    }, [currentIntegration])

    const columns = [
        { title: "Order No.", field: "orderNo" },
        { title: "Sale Person", field: "salePerson" },
        { title: "Customer Detail", field: "customerDetail" },
        { title: "Quantity", field: "qty" },
        { title: "Order Date", field: "date" },
        { title: "Expected Delivery Date", field: "edd" },
        { title: "Vehicle Detail", field: "vehicleDetail" },
    ]
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', type: ''})
    const navigate = useNavigate();
    
    const printOrder = async (e) => {
        navigate('/dispatch-order-print/'+e);
    }
    const [loading, setLoading] = useState(false);
    useEffect(() => {
      if (isLoading) {
          setLoading(true);
      }
    });
    return (
        <>
            <Container fluid className="main-content">
                <Row>
                    <Col className='d-flex justify-content-between mb-2'>
                        <h3 className="main-title">View Dispatched Order</h3>
                        <div className="d-flex justify-content-between d-block d-sm-none">
                            <div className="magnifierContainer">
                                <button type="button" className="btn btn-md btn-warning text-white roundedcircle mr-3" onClick={handleVisibility}><i className="fa-solid fa-search"></i></button>
                            </div>
                        </div>
                    </Col>
                    <Col lg={"12"} className='mb-2 d-block d-sm-none'>
                        <div className={visible ? "open" : "close"}>
                            <input placeholder="Search..." type="text" className="form-control is-open mr-3 mb-0 search-input"/>
                        </div>
                    </Col>
                </Row>
                {loading ? <div>
                <Row className="d-none d-sm-block mt-2">
                    <Col lg={"12"}>
                        <div style={{ display: 'table', tableLayout: 'fixed', width: '100%' }}>
                            <MaterialTable columns={columns} data={tableData}
                               actions={[
                                {
                                  icon: () => <PrintIcon color={'warning'} />,
                                  tooltip: 'print',
                                  onClick: (event, rowData) => printOrder(rowData.id)
                                }
                            ]}
                                options={{
                                    sorting: true, search: true,
                                    paging: true, pageSizeOptions: [10, 25, 50, 100], pageSize: 10,
                                    exportButton: true,
                                    exportAllData: true, exportFileName: "Dispatched Order", actionsColumnIndex: -1,
                                    // headerStyle: { background: "#f44336",color:"#fff"}
                                }}
                                title="Dispatched Order"
                            />
                        </div>
                    </Col>
                </Row>
                <Row className="d-block d-sm-none">
                    {currentIntegration.map((dispatch, index) => (
                    <Col xs={"12"} key={index}>
                        <div className="card mb-2">
                            <div className="card-header d-flex justify-content-between pb-0">
                                <h6>{dispatch.orderNo}	<Badge bg="danger" text="light" className="ml-2">{dispatch.qty}</Badge></h6>
                                <Dropdown>
                                <Dropdown.Toggle variant="light" id="dropdown-basic">
                                    <i className="ri-more-2-line"></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => printOrder(dispatch.id)}><PrintIcon color={'warning'} /> Print</Dropdown.Item>
                                </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className="card-body pt-1">
                                <p className="card-text mb-0"><span className="quotation-info">Customer Detail:</span> {dispatch.customerDetail}</p>
                                <p className="card-text mb-0"><span className="quotation-info">Sale Person:</span> {dispatch.salePerson}</p>
                                <p className="card-text mb-0"><span className="quotation-info">Order Date:</span> {dispatch.date}</p>
                                <p className="card-text mb-0"><span className="quotation-info">Delivery Date:</span> {dispatch.edd}</p>
                                <p className="card-text mb-0"><span className="quotation-info">Vehicle Detail:</span> {dispatch.vehicleDetail}</p>
                            </div>
                        </div>
                    </Col>
                    ))}
                </Row>
                </div> : <Loader/>}
            </Container>
            <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
            />
        </>

    )
}

const mapStateToProps = (state) => {

  return {
     currentIntegration: state.completeDispatch.currentIntegration,
     isLoading: state.completeDispatch.isLoading,
   }
 }

 export default connect(mapStateToProps,{getCompleteDispatch})(CompleteDispatch);