import React from 'react';
import {useState, useEffect} from 'react'
import { Row, Col, Container, Button, Card, Badge} from 'react-bootstrap';
import Zoom from 'react-medium-image-zoom'
import Counter from '../../components/Counter/counter';
import {Link} from 'react-router-dom'
import { connect } from "react-redux";
import {getAllotedOrder,addProductToCart,cartCount} from '../../redux/CP/AllotedOrder/action';
import Loader from '../../components/Loader/Loader';

const CpCreateOrder = ({ getAllotedOrder,currentIntegration,isLoading,num,addProductToCart,cart,cartCount }) =>  {  useEffect(() => {
  cartCount();
  getAllotedOrder();
}, []);

const [loading, setLoading] = useState(false);
const [cartvalue, setCart] = useState(false);

  useEffect(() => {
    if (isLoading) {
        setLoading(true);
    }
  });
  
  useEffect(() => {
    if (cart) {
      setCart(true);
    }
  });

  function addToCart (pid) {
    let qty = localStorage.getItem('qty');
    addProductToCart(pid,qty);
  }

  return (
    <>
      <Container fluid className="main-content">
        <Row>
            <Col lg={'12'}>
              <div className='d-flex justify-content-between'>
                  <h3 className="main-title">Create Order</h3>
                  {cartvalue ? <h5 className='mb-0 pt-1 ml-2 text-danger cart-quantity'>
                      <Link to="/order-list"><i class="ri-shopping-cart-2-line"></i></Link>
                      <span className="bg-danger text-white qty notification-icon">{cart}</span>
                    </h5> :''
                  }
              </div>
            </Col>
        </Row>
        {loading ? <div>
        <Row className='mt-4 mb-2'>
        {currentIntegration.map((cpOrder, index) => (
            <Col lg="3" key={index} className="mb-4">
                <Card>
                <Zoom zoomMargin={50}><Card.Img variant="top" src={process.env.REACT_APP_IMAGE_URL+'/'+cpOrder.image} alt={cpOrder.image} height="180px" /></Zoom>
                <Card.Body>
                    <Card.Title className='d-flex justify-content-between'>
                        <h5 className='mb-0'>{cpOrder.model} <span><Badge bg="info">{cpOrder.qty}</Badge></span></h5>   
                        <p className='mb-0' style={{width: "85px"}}><Counter value={num}></Counter></p>
                    </Card.Title>
                    <p className="card-text mb-0"><span className="quotation-info">RDP:</span> <Badge bg="success">&#8377; {cpOrder.rdp}</Badge></p>
                    <p className="card-text mb-0"><span className="quotation-info">Product Category:</span>{cpOrder.cateory}</p>
                    <p className="card-text mb-0"><span className="quotation-info">Leather Category:</span>{cpOrder.leather_category}</p>
                    <p className="card-text mb-0"><span className="quotation-info">Leather Type:</span>{cpOrder.leather_type}</p>
                    <p className="card-text mb-0"><span className="quotation-info">Colour Code:</span>{cpOrder.leather_colour}</p>
                    <p className="card-text mb-0"><span className="quotation-info">Item Colour:</span>{cpOrder.colour_code}</p>
                </Card.Body>
                <Card.Footer className="p-0"> <Button variant="primary" className=" w-100" onClick={()=>addToCart(cpOrder.id)}>Add To Cart</Button> </Card.Footer>
                </Card>
            </Col>
              ))}
        </Row>
        </div> : <Loader/>}
      </Container>
    </>
  )
}

const mapStateToProps = (state) => {
  //
    return {
       currentIntegration: state.cpAllotedOrder.currentIntegration,
       isLoading: state.cpAllotedOrder.isLoading,
       cart: state.cpAllotedOrder.cart_qty,
     }
   }
  
export default connect(mapStateToProps,{getAllotedOrder,addProductToCart,cartCount})(CpCreateOrder);