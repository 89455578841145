import {
    RETRIEVE_PENDING_INCENTIVE,
    APPROVE_INCENTIVE
} from "../PendingIncentive/actiontype";

import { toast } from 'react-toastify';
import api from "../../../api";
import { DataArrayTwoTone } from "@mui/icons-material";



export const getPendingIncentive= (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  
  try {
    const res = await api.get(`/incentive/pendingIncentive`);
   
    
    dispatch({
      type: RETRIEVE_PENDING_INCENTIVE,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_PENDING_INCENTIVE,
      payload: [],
    });
  }
};


export const approveIncentive= (detail) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  
  let data = {
    id: detail.id,
   amount : detail.totalIncentive
 };
  try {
    const res = await api.post(`/incentive/approveIncentive`, data);
    
    if (res.status === 200) {
      toast.success(res.data.message);
      dispatch({
        type:  APPROVE_INCENTIVE,
        payload: res.data.data,
      });
     
    }
    
  } catch (err) {
    
    dispatch({
      type:  APPROVE_INCENTIVE,
      payload: [],
    });
  }
};
