import * as React from "react";
import {useState} from 'react'
import { Row, Col, Container, Badge, Dropdown} from 'react-bootstrap';
import MaterialTable from 'material-table';

export default function MakeToOrder() {

const [visible, setVisible] = useState(false);
const handleVisibility = () => {
    setVisible(prev => !prev);
};

const [tableData, setTableData] = useState([
{ image: 'https://avatars0.githubusercontent.com/u/7895451?s=460&v=4', orderNo: "AAKRITI#ORD_1063 Rahul Kumar", model: "SSS1234", customerName: "Prakash Chand", salePersons: "Rahul", doo: "2000", edd: "4000", qty: "09-02-2022", description: "recorded by Prakash Chand On 16-01-2021", drawing: 'drawing',  remarks: 'ok', units: ''},
])

const columns = [
{ title: 'Image', field: 'image', render: rowData => <img src={rowData.image} style={{width: 80}}/> },
{ title: "Order No. / Sales Person", field: "orderNo" },
{ title: "Model No. ", field: "model" },
{ title: "Customer Name", field: "customerName" },
// { title: "Sale Persons", field: "salePersons" },
{ title: "DOO", field: "doo" },
{ title: "EDD", field: "edd" },
{ title: "Qty", field: "qty" },
{ title: "Descripion", field: "description" },
{ title: "Drawing", field: "drawing" },
{ title: "Remarks", field: "remarks" },
{ title: "Units", field: "units" },

]
    
  return (
    <>
        <Container fluid className="main-content">
          <Row>
              <Col>
                  <h3 className="main-title">Make To Order</h3>
              </Col> 
              <Col>
                  <div style={{float: "right"}} className="d-flex">
                    <div className="d-flex justify-content-between d-flex d-sm-none">
                      <div className="magnifierContainer">
                        <button type="button" className="btn btn-md btn-warning text-white roundedcircle mr-3" onClick={handleVisibility}><i className="fa-solid fa-search"></i></button>
                      </div>
                    </div>
                  </div>
              </Col>
              <Col lg={"12"}>
                <div className='d-block d-sm-none mt-2'>
                  <div className={visible ? "open" : "close"}>
                      <input placeholder="Search..." type="text" className="form-control is-open mr-3 mb-0 search-input"/>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="d-none d-sm-block mt-2">
              <Col lg={"12"} className="mt-3">
                <div style={{display: 'table', tableLayout:'fixed', width:'100%'}}>
                <MaterialTable columns={columns} data={tableData}
                    options={{
                    sorting: true, search: true,
                    paging: true, pageSizeOptions: [10, 25, 50, 100], pageSize: 10,
                    exportButton: true,
                    exportAllData: true, exportFileName: "Make To Order", actionsColumnIndex: -1,
                    // headerStyle: { background: "#f44336",color:"#fff"}
                    }}
                    title="Make To Order"
                />
                </div>
              </Col>
            </Row>
            <Row className="d-block d-sm-none mt-2">
            {/* {currentIntegration.map((order, index) => ( */}
            <Col xs={"12"}>
                <div className="card mb-2">
                    <div className="card-header d-flex justify-content-between pb-0">
                        <h6>AAKRITI#ORD_1063  <Badge bg="danger" text="light" className="ml-2">10</Badge></h6>
                        <Dropdown>
                        <Dropdown.Toggle variant="light" id="dropdown-basic">
                            <i className="ri-more-2-line"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item href="#/action-1"><i className="ri-edit-box-line text-primary"></i> Edit</Dropdown.Item>
                            <Dropdown.Item href="#/action-2"><i className="far fa-trash-alt text-danger"></i> Delete</Dropdown.Item>
                        </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="card-body pt-1">
                        <p className="card-text mb-0"><span className="quotation-info">Model No:</span> S123456</p>
                        <p className="card-text mb-0"><span className="quotation-info">Customer Name:</span> </p>
                        <p className="card-text mb-0"><span className="quotation-info">Order Date:</span> </p>
                        <p className="card-text mb-0"><span className="quotation-info">Delivery Date:</span> </p>
                        <p className="card-text mb-0"><span className="quotation-info">Description:</span> </p>
                        <p className="card-text mb-0"><span className="quotation-info">Drawing:</span> </p>
                        <p className="card-text mb-0"><span className="quotation-info">Units:</span> </p>
                        <p className="card-text mb-0"><span className="quotation-info">Remarks:</span> </p>
                    </div>
                </div>
            </Col>
            {/* ))} */}
        </Row>
        </Container>
    </>

  )
}

