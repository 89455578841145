import React, { useState } from 'react';
import { Report } from './MenuItems';
import { NavLink } from 'react-router-dom';

function ReportDropdown() {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);

  return (
    <>
      <ul
        onClick={handleClick}
        className={click ? 'cus-dropdown visible' : 'cus-dropdown'}
      >
        {Report.map((item, index) => {
          return (
            <li key={index}
              className='menu-list'
            >
              <NavLink
                activeclassName="active"
                className={item.cName}
                to={item.path}
                onClick={() => setClick(false)}
              >
                <span className="material-icons">arrow_right_alt</span> {item.title}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </>
  );
}

export default ReportDropdown;
