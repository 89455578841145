import {
    ORDER_LIST,
    LATEST_ORDER_ID,
    CONVERSATION,
    NO_OF_MEMBER,
    ORDER_LIST_NAME,
    UPDATE_DATE,
    MEMBER_LIST,
    PAYMENT_DETAIL,
    REMAINING_AMOUNT,
    REMAINING_MEMBER_LIST,
    ADD_PAYMENT,
    EXIT_GROUP,
    SET_USERTYPE,
    SET_DISPATCH_ORDER,
    EXPORT_CHAT
} from "../Chat/actiontype";

import { toast } from 'react-toastify';
import api from "../../api";

let userid = JSON.parse(localStorage.getItem('userid'));
let usertype = JSON.parse(localStorage.getItem('usertype'));

export const getOrderList= (data) => async (dispatch) => {
  try{
      let detail = {
        userid : userid,
        usertype : usertype,
      };

      const res = await api.post(`/chat/getOrderList`,detail);
      
      dispatch({
        type:  ORDER_LIST,
        payload: res.data.data,
      });

      const res1 = await api.post(`/chat/latestChatId`,detail);
      localStorage.setItem('serial_no',res1.data.data);

      let detail_conversation = {
        userid : userid,
        usertype : usertype,
        serial_no: res1.data.data
      };

      const result = await api.post(`/chat/conversation`,detail_conversation);
      dispatch({
        type:  CONVERSATION,
        payload: result.data.data.chat,
      });

      dispatch({
        type:  NO_OF_MEMBER,
        payload: result.data.data.conversation,
      });

      dispatch({
        type:  ORDER_LIST_NAME,
        payload: result.data.data.orderid,
      });
      
      dispatch({
        type:  UPDATE_DATE,
        payload: result.data.data.updated_date,
      });
    } catch (err) {
    dispatch({
      type:  ORDER_LIST,
      payload: [],
    });
  }
};

export const getConversation= (data) => async (dispatch) => {
  try {
    let detail = {
        userid : userid,
        usertype : usertype,
        serial_no: data
      };

    const res = await api.post(`/chat/conversation`,detail);
    localStorage.setItem('serial_no',data);

    dispatch({
      type:  CONVERSATION,
      payload: res.data.data.chat,
    });

    dispatch({
        type:  NO_OF_MEMBER,
        payload: res.data.data.conversation,
      });

    dispatch({
        type:  ORDER_LIST_NAME,
        payload: res.data.data.orderid,
    });
    
    dispatch({
        type:  UPDATE_DATE,
        payload: res.data.data.updated_date,
    });

  } catch (err) {
    
    dispatch({
      type:  CONVERSATION,
      payload: [],
    });
  }
};

export const getMemberList= (data) => async (dispatch) => {
    let serial_no = JSON.parse(localStorage.getItem('serial_no'));
    
    const res = await api.get(`/chat/getMember/`+serial_no);
    
    dispatch({
        type:  MEMBER_LIST,
        payload: res.data.data,
    });
};

export const getPaymentDetail= (data) => async (dispatch) => {
    let serial_no = JSON.parse(localStorage.getItem('serial_no'));
    
    const res = await api.get(`/chat/getPaymentDetail/`+serial_no);
    dispatch({
        type:  PAYMENT_DETAIL,
        payload: res.data.data.payment_list,
    });
    
    dispatch({
        type:  REMAINING_AMOUNT,
        payload: res.data.data.remaining_amt,
    });
};
export const getRemainingMember= (data) => async (dispatch) => {
    let serial_no = JSON.parse(localStorage.getItem('serial_no'));
    
    const res = await api.get(`/chat/getRemainingMember/`+serial_no);
    dispatch({
        type:  REMAINING_MEMBER_LIST,
        payload: res.data.data,
    });
};

export const addPayment = (registerData) => async (dispatch) => {
  const body = JSON.stringify(registerData);
  try {
    const res = await api.post('/chat/addPayment', body);
    if (res.status === 200) {
      toast.success(res.data.message);
        dispatch({
            type: ADD_PAYMENT,
            payload: res.data.data,
          });
        }
      } catch (err) {
    
    toast.warn('Invalid inputs! Payment Not Added');
    // dispatch({
    //   type: REGISTER_FAIL,
    // });
  }
};


export const exitChatGroup = (data) => async (dispatch) => {
  
  try {
    let body = {
      serial_no: data.serial_no,
      userid : data.userid
   };
    const res = await api.post('/chat/groupExit', body);
    
    if (res.status === 200) {
      toast.success(res.data.message);
        dispatch({
            type: EXIT_GROUP,
            payload: res.data.data.info,
          });
         }
      } catch (err) {
    
    toast.warn('Invalid inputs! Order Not Decline');
    // dispatch({
    //   type: REGISTER_FAIL,
    // });
  }
};

export const addMember = (data) => async (dispatch) => {
  let serial_no = JSON.parse(localStorage.getItem('serial_no'));
  try {
    let body = {
      serial_no: serial_no,
      userid : data.userid,
      usertype : data.usertype
   };
  
    const res = await api.post('/chat/addMember', body);
    if (res.status === 200) {
      toast.success(res.data.message);
        dispatch({
            type: NO_OF_MEMBER,
            payload: res.data.data.count,
          });
         }
      } catch (err) {
    
    toast.warn('Invalid inputs! Member Not Added');
    // dispatch({
    //   type: REGISTER_FAIL,
    // });
  }
};

export const getUsertype = (data) => async (dispatch) => {
  try {
    const res = await api.get(`/common/usertypeList`);
    dispatch({
      type: SET_USERTYPE,
      payload: res.data.data,
    });
  } catch (err) { }
};

 export const addChatData = (data) => async (dispatch) => {
  let serial_no = JSON.parse(localStorage.getItem('serial_no'));
  try {
    let formdata = new FormData();
        formdata.append('serial_no', serial_no);
        formdata.append('userid', userid);
        formdata.append('usertype', usertype);
        formdata.append('department', data.department);
        formdata.append('message', data.chat);
        formdata.append('files', data.files);
        const res = await api.post('/chat/sendMessage', formdata);
       
        
        let detail = {
          userid : userid,
          usertype : usertype,
          serial_no: serial_no
        };

        const result = await api.post(`/chat/conversation`,detail);
        localStorage.setItem('serial_no',serial_no);

        dispatch({
          type:  CONVERSATION,
          payload: result.data.data.chat,
        });

        dispatch({
            type:  NO_OF_MEMBER,
            payload: result.data.data.conversation,
          });

        dispatch({
            type:  ORDER_LIST_NAME,
            payload: result.data.data.orderid,
        });
        
        dispatch({
            type:  UPDATE_DATE,
            payload: result.data.data.updated_date,
        });

    } catch (err) {
      toast.warn('Invalid inputs! Member Not Added');
    // dispatch({
    //   type: REGISTER_FAIL,
    // });
  }
};

export const clearChatDetail = (data) => async (dispatch) => { 
  let serial_no = JSON.parse(localStorage.getItem('serial_no'));
  try {
    const res = await api.get(`/chat/clearChat/`+serial_no);
  } catch (err) { }
};

export const markAsDispatch = (data) => async (dispatch) => { 
  let serial_no = JSON.parse(localStorage.getItem('serial_no'));

  let detail = {
    userid : userid,
    usertype : usertype,
    serial_no: serial_no
  };

  try {
    const res = await api.post(`/chat/sendToDispatch`,detail);
    
    const result = await api.post(`/chat/conversation`,detail);
    localStorage.setItem('serial_no',serial_no);

    dispatch({
      type:  CONVERSATION,
      payload: result.data.data.chat,
    });

    dispatch({
        type:  NO_OF_MEMBER,
        payload: result.data.data.conversation,
      });

    dispatch({
        type:  ORDER_LIST_NAME,
        payload: result.data.data.orderid,
    });
    
    dispatch({
        type:  UPDATE_DATE,
        payload: result.data.data.updated_date,
    });
    
    dispatch({
        type:  SET_DISPATCH_ORDER,
        payload: result.data.data.dispatch_type,
    });

  } catch (err) { }
};

export const getExportChat= (data) => async (dispatch) => {
  let serial_no = JSON.parse(localStorage.getItem('serial_no'));
  
  const res = await api.get(`/chat/exportChat/`+serial_no);
  
  dispatch({
      type:  EXPORT_CHAT,
      payload: res.data.data,
  });
};


