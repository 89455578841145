import {
  RETRIEVE_STOCK_IN_SUMMARY,
} from "../../BranchDashboard/StockIn/actiontype";
import api from "../../../../api";

let userid = JSON.parse(localStorage.getItem('userid'));

export const getStockInSummary= (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
 
  try {
    const res = await api.get(`/branch/stockInSummary/` +userid);
    dispatch({
      type:  RETRIEVE_STOCK_IN_SUMMARY,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type:  RETRIEVE_STOCK_IN_SUMMARY,
      payload: [],
    });
  }
};




