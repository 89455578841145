import * as React from "react";
import { useState, useEffect } from 'react'
import { Row, Col, Container, Dropdown, Badge } from 'react-bootstrap';
import MaterialTable from 'material-table';
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Menu, MenuItem } from "@material-ui/core";
import CheckIcon from '@mui/icons-material/Check';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import { styled, alpha } from '@mui/material/styles';
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import { connect } from "react-redux";
import {getDueIncentive, approveDueIncentive} from '../../../redux/IncentiveAccounts/DueIncentive/action';
import SearchBar from "../../../components/SearchBar/SearchBar";



    const DueIncentive = ({ getDueIncentive,currentIntegration, approveDueIncentive }) =>  {  useEffect(() => {
        getDueIncentive();
    }, []);

    const [visible, setVisible] = useState(false);
    const handleVisibility = () => {
        setVisible(prev => !prev);
    };

    const [show, setShow] = useState(false);
    const modalClose = () => setShow(false);
    const modalShow = () => setShow(true);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', type: ''})

    const [tableData, setTableData] = useState([]);
    useEffect(() => {
    if(currentIntegration)
    {
        setTableData(currentIntegration); 
    }
    }, [currentIntegration])

    const columns = [
        { title: "Order No.", field: "orderNo" },
        { title: "Approved By", field: "approvedBy" },
        { title: "Total Amount", field: "totalAmount" },
        { title: "Discount", field: "discount" },
        { title: "Gross Total", field: "grossTotal" },
        { title: "Date Of Order", field: "date" },
        { title: "Expected Delivery Date", field: "edd" },
        { title: "Incentive", field: "incentive" },
    ]

    const incentiveApproval = async (e) => {
        
        setConfirmDialog({
        isOpen: true,
        title: 'Are You Sure To Approve This Incentive?',
        type: true,
        onConfirm: () => { 
            approveDueIncentive(e);
            setConfirmDialog({ ...confirmDialog, isOpen: false })
        }
        })
    }
    
    // Search for Card view start
    const [searchValue, setSearchValue] = useState("");

    const filterIncentive = ({ orderNo }) => {
        return orderNo.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
    };
    // Search for Card view end


    return (
        <>
            <Container fluid className="main-content">
                <Row>
                    <Col className='d-flex justify-content-between mb-2'>
                        <h3 className="main-title">Due Incentive</h3>
                        <div className="d-flex justify-content-between d-block d-sm-none">
                            <div className="magnifierContainer">
                                <button type="button" className="btn btn-md btn-warning text-white roundedcircle mr-3" onClick={handleVisibility}><i className="fa-solid fa-search"></i></button>
                            </div>
                        </div>
                    </Col>
                    <Col lg={"12"} className='mb-2 d-block d-sm-none'>
                        <div className={visible ? "open" : "close"}>
                            <SearchBar onSearch={setSearchValue} value={searchValue}/>
                        </div>
                    </Col>
                </Row>
                <Row className="d-none d-sm-block mt-2">
                    <Col lg={"12"}>
                        <div style={{ display: 'table', tableLayout: 'fixed', width: '100%' }}>
                            <MaterialTable columns={columns} data={tableData}
                                actions={[
                                    {
                                    icon: () => <CheckIcon color={'success'} />,
                                    tooltip: 'Approve Incentive',
                                    onClick: (event, rowData) => incentiveApproval(rowData.serial_no)
                                    }
                                ]}
                                options={{
                                    sorting: true, search: true,
                                    paging: true, pageSizeOptions: [10, 25, 50, 100], pageSize: 10,
                                    exportButton: true,
                                    exportAllData: true, exportFileName: "Due Incentive", actionsColumnIndex: -1,
                                    // headerStyle: { background: "#f44336",color:"#fff"}
                                }}
                                title="Due Incentive"
                            />
                        </div>
                    </Col>
                </Row>
                <Row className="d-block d-sm-none">
                    {currentIntegration.filter(filterIncentive).map((incentive, index) => (
                    <Col xs={"12"}>
                        <div className="card mb-2">
                            <div className="card-header d-flex justify-content-between pb-0">
                                <h6>{incentive.orderNo}</h6>
                                <Dropdown>
                                <Dropdown.Toggle variant="light" id="dropdown-basic">
                                    <i className="ri-more-2-line"></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => incentiveApproval(incentive.serial_no)}><CheckIcon color={'success'} /> Approve</Dropdown.Item>
                                </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className="card-body pt-1">
                                <p className="card-text mb-0"><span className="quotation-info">Approved By:</span> {incentive.approvedBy}</p>
                                <p className="card-text mb-0"><span className="quotation-info">Total Amount:</span> {incentive.totalAmount}</p>
                                <p className="card-text mb-0"><span className="quotation-info">Discount:</span> {incentive.discount}</p>
                                <p className="card-text mb-0"><span className="quotation-info">Gross Total:</span> {incentive.grossTotal}</p>
                                <p className="card-text mb-0"><span className="quotation-info">Incentive:</span> {incentive.incentive}</p>
                                <p className="card-text mb-0"><span className="quotation-info">Order Date:</span> {incentive.date}</p>
                                <p className="card-text mb-0"><span className="quotation-info">EDD:</span> {incentive.edd}</p>
                            </div>
                        </div>
                    </Col>
                    ))}
                </Row>
            </Container>
            <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
            />
        </>

    )
}

const mapStateToProps = (state) => {
  //
  return {
     currentIntegration: state.dueIncentive.currentIntegration,
   }
 }

 export default connect(mapStateToProps,{getDueIncentive, approveDueIncentive})(DueIncentive);