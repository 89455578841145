import React from 'react'

import { Row, Col, Container, Button, Card, Badge, Dropdown, Modal } from 'react-bootstrap';
import { Formik, Form, Field } from "formik";
import Select from 'react-select';
import {useState,useEffect} from 'react';
import { connect } from "react-redux";
import { getProduct,getLocation,getCordinator,getReffered,getProductName} from '../../../redux/Order/CreateOrder/action';
import {getOrder,updateOrder,onChangeCalcReady,addImportedReady,getEditReady,getEditProductNameReady,updateImportedReady,deleteImportedReady,addImportedOrder,deleteImportedOrder} from '../../../redux/Order/EditOrder/action';

  const EditOrder = ({ getCordinator,cordinators,getReffered,reffereds,getOrder,order_detail,order_name,imported_ready,imported_order,updateOrder,getProduct,getLocation,products,locations,getProductName,products_name,onChangeCalcReady,ready_net_amount,addImportedReady,total_amount,gross_amt,balance_amt,getEditReady,getEditProduct,edit_product_name,getEditProductNameReady,updateImportedReady,deleteImportedReady,addImportedOrder,deleteImportedOrder }) => { useEffect(() => {
    const order_id = window.location.pathname.split("/").pop();
    getOrder(order_id);
    getCordinator();
    getReffered();
    getProduct();
    getLocation();
}, []);

const [cordinatorList, setCordinatorList] = useState(null);
const [refferedList, setrefferedList] = useState(null);
const [productList, setProductList] = useState(null);
const [locationList, setLocationList] = useState(null);

  useEffect(() => {
    if(cordinators)
    {
      setCordinatorList(cordinators); 
    }
  }, [cordinators])

  useEffect(() => {
    if(reffereds)
    {
      setrefferedList(reffereds); 
    }
  }, [reffereds])

  useEffect(() => {
    if(products)
    {
      setProductList(products); 
    }
  }, [products])
  
  useEffect(() => {
    if(locations)
    {
      setLocationList(locations); 
    }
  }, [locations])

  const getProductDetail = (e) =>
  {
    localStorage.setItem('product_id',e.value);
    getProductName(e.value);
  }

  const getLocationDetail = (e) =>
  {
    localStorage.setItem('location_id',e.value)
  }

  const [show, setShow] = useState(false);
  const modalAddClose = () => setShow(false);
  const modalAddShow = () => setShow(true);

  const [show2, setShow2] = useState(false);
  const modalEditClose = () => setShow2(false);
  const modalEditShow = () => setShow2(true);
 
  const [show3, setShow3] = useState(false);
  const modalClose3 = () => setShow3(false);
  const modalShow3 = () => setShow3(true);

  const [show4, setShow4] = useState(false);
  const modalClose4 = () => setShow4(false);
  const modalShow4 = () => setShow4(true);

  const editReadyProduct = async (e) => {
    getEditReady(e);
      setShow3({
      isOpen: true,
      })
  }
  
  const deleteReadyProduct = async (e) => {
    deleteImportedReady(e);
  }

  const editOrderProduct = async (e) => {
    getEditReady(e);
      setShow4({
      isOpen: true,
      })
  }

  const deleteOrderProduct = async (e) => {
    deleteImportedOrder(e);
  }

  const calcAmount = (e) =>
  {
    if(e.target.name == 'qty')
    {
      localStorage.setItem('editqty',e.target.value)
    }
    else if(e.target.name == 'netprice')
    {
      localStorage.setItem('editnetprice',e.target.value)
    }
    onChangeCalcReady();
  }

  const getEditProductDetailReady = (e) =>
  {
    localStorage.setItem('product_id',e.value);
    getEditProductNameReady(e.value);
  }

  return (
    <>
      <Container fluid className="main-content">
      < Row>
          <Col className='mb-2'>
              <h3 className="main-title">Edit Order</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <Formik
                enableReinitialize={true}
                  initialValues={{
                  orderno: order_name,
                  bookingdate: order_detail.date,
                  deliverydate: order_detail.expected_delivery,
                  coordinator: order_detail.cordinator,
                  refferedby: order_detail.reffered_by,
                  name: order_detail.name,
                  mobile: order_detail.mobile_no,
                  email: order_detail.email,
                  address: order_detail.address,
                  total: total_amount,
                  packing: order_detail.packing,
                  fitting: order_detail.fitting,
                  discount: order_detail.discount,
                  grosstotal: gross_amt,
                  cartage: order_detail.cartage,
                  advance: order_detail.advance,
                  balance: balance_amt,
                  other: order_detail.other,
                  other_name: order_detail.other_name,
                  }}
                  onSubmit={(values) => {
                    console.log(values);
                    updateOrder(values);
                  }}
                >
                  {({ values , setFieldValue}) => (
                    <Form>
                      <Row>
                        <Col lg={4} sm={12}>
                          <label>Order No.</label>
                          <Field name="orderno" type="text" className="form-control" readOnly/>
                        </Col>
                        <Col lg={4} sm={12}>
                          <label>Date Of Booking</label>
                          <Field name="bookingdate" type="date" className="form-control" />
                        </Col>
                        <Col lg={4} sm={12}>
                          <label>Expected Date of Delivery</label>
                          <Field name="deliverydate" type="date" className="form-control"/>
                        </Col>
                        
                        <Col lg={12}>
                          <div className="line"></div>
                        </Col>
                        <Col lg={12} className='mb-2'>
                            <h5>Consultant:</h5>
                          </Col>
                          <Col lg={4} sm={12}>
                            <label>Cordinator</label>
                            <p >{order_detail.cordinator_name},{order_detail.cordinator}</p>
                            <Select 
                            defaultValue={{ label: order_detail.cordinator_name, value: order_detail.cordinator }}
                            options={cordinatorList}
                            name="coordinator" 
                            onChange={newValue => setFieldValue("coordinator", newValue.value)} 
                            className='mb-2' 
                          />
                          </Col>
                          <Col lg={{ span: 4, offset: 4 }} sm={12}>
                            <label>Reffered By</label>
                            <p>{order_detail.reffered_by_name},{order_detail.reffered_by}</p>
                            <Select 
                            defaultValue={{ label: order_detail.reffered_by_name, value: order_detail.reffered_by }}
                            options={refferedList}
                            name="refferedby" 
                            onChange={newValue => setFieldValue("refferedby", newValue.value)} 
                            className='mb-2' 
                          />
                          </Col>
                          <Col lg={12} className='mt-2'>
                            <div className="line"></div>
                          </Col>
                          <Col lg={12} sm={12} className='mb-2'>
                              <h5>Customer Detail:</h5>
                          </Col>
                        <Col lg={3} sm={12}>
                          <label>Customer Name</label>
                          <Field name="name" type="text" className="form-control"/>
                        </Col>
                        <Col lg={3} sm={12}>
                          <label>Customer Mobile</label>
                          <Field name="mobile" type="text" className="form-control"/>
                        </Col>
                        <Col lg={3} sm={12}>
                          <label>Customer Email</label>
                          <Field name="email" type="text" className="form-control"/>
                        </Col>
                        <Col lg={3} sm={12}>
                          <label>Address</label>
                          <Field name="address" type="text" className="form-control"/>
                        </Col>
                        <Col lg={12}>
                          <div className="line"></div>
                        </Col>
                      </Row>
                      <Row className='mb-2'>
                        <Col>
                          <div className='d-flex justify-content-between'>
                            <h5>Imported Ready Product</h5>
                            <button type="button" className="btn btn-md btn-info text-white" onClick={modalAddShow} style={{float: 'right'}}><i className="fa-solid fa-plus"></i> Add</button>
                          </div>
                        </Col>
                      </Row>
                      <Row className='mt-4'>
                      {imported_ready && imported_ready.map((ord, index) => (
                        <Col lg={3} sm={"12"} key={index}>
                          <div className="card product mb-2">
                            <div className="card-header d-flex justify-content-between pb-0">
                                <h6 className="mb-0"><span className="card-title">{ord.name}-{ord.model_id}</span> <Badge bg="info" text="light" className="ml-2">{ord.qty}</Badge></h6>
                              <Dropdown>
                                <Dropdown.Toggle variant="light" id="dropdown-basic">
                                  <i className="ri-more-2-line"></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item onClick={() => editReadyProduct(ord.id)}><i className="ri-edit-box-line text-primary"></i> Edit</Dropdown.Item>
                                  <Dropdown.Item onClick={() => deleteReadyProduct(ord.id)}><i className="far fa-trash-alt text-danger"></i> Delete</Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                            <div className="card-body pt-1">
                              <Row>
                                <Col lg={4}>
                                  <div>
                                    <img src={process.env.REACT_APP_IMAGE_URL+'/'+ord.image} alt="user name" className='product-image' style={{width: 50, height:50}}/>
                                  </div>
                                </Col>
                                <Col lg={8}>
                                  <p className="card-text mb-0"><span className="product-info">Category:</span> {ord.category}</p>
                                  <p className="card-text mb-0"><span className="product-info">Quotated Price:</span> {ord.quoted_price}</p>
                                  <p className="card-text mb-0"><span className="product-info">Net Price:</span> {ord.net_price}</p>
                                  <p className="card-text mb-0"><span className="product-info">Net Amount:</span> {ord.net_amount}</p>
                                  <p className="card-text mb-0"><span className="product-info">Incentive:</span> {ord.incentive}</p>
                                </Col>
                                <Col lg={12} className='mt-1'>
                                  <p className="card-text mb-0"><span className="product-info">Color Code:</span> {ord.colour_code}</p>
                                  <p className="card-text mb-0"><span className="product-info">Leather Type:</span> {ord.leather_type}</p>
                                  <p className="card-text mb-0"><span className="product-info">Product Location:</span> {ord.location}</p>
                                  <p className="card-text mb-0"><span className="product-info">Remarks:</span> {ord.remarks}</p>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Col>
                      ))}
                        <Col lg={12}>
                          <div className="line"></div>
                        </Col>
                      </Row>
                      <Row className='mb-2'>
                        <Col>
                          <div className='d-flex justify-content-between'>
                            <h5>Imported On Order</h5>
                            <button type="button" className="btn btn-md btn-info text-white" onClick={modalEditShow} style={{float: 'right'}}><i className="fa-solid fa-plus"></i> Add</button>
                          </div>
                        </Col>
                      </Row>
                      <Row className='mt-4'>
                      {imported_order && imported_order.map((quot, index) => (
                        <Col lg={3} sm={"12"} key={index}>
                          <div className="card product mb-2">
                            <div className="card-header d-flex justify-content-between pb-0">
                                <h6 className="mb-0"><span className="card-title">{quot.name}-{quot.model_id}</span> <Badge bg="info" text="light" className="ml-2">{quot.qty}</Badge></h6>
                              <Dropdown>
                                <Dropdown.Toggle variant="light" id="dropdown-basic">
                                  <i className="ri-more-2-line"></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item onClick={() => editOrderProduct(quot.id)}><i className="ri-edit-box-line text-primary"></i> Edit</Dropdown.Item>
                                  <Dropdown.Item onClick={() => deleteOrderProduct(quot.id)}><i className="far fa-trash-alt text-danger"></i> Delete</Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                            <div className="card-body pt-1">
                              <Row>
                                <Col lg={4}>
                                  <div>
                                    <img src={process.env.REACT_APP_IMAGE_URL+'/'+quot.image}alt={quot.image} style={{width: 50, height:50}}  className='product-image' />
                                  </div>
                                </Col>
                                <Col lg={8}>
                                  <p className="card-text mb-0"><span className="product-info">Category:</span> {quot.category}</p>
                                  <p className="card-text mb-0"><span className="product-info">Quoted Price:</span> {quot.quoted_price}</p>
                                  <p className="card-text mb-0"><span className="product-info">Net Price:</span> {quot.net_price}</p>
                                  <p className="card-text mb-0"><span className="product-info">Net Amount:</span> {quot.net_amount}</p>
                                  <p className="card-text mb-0"><span className="product-info">Incentive:</span> {quot.incentive}</p>
                                </Col>
                                <Col lg={12} className='mt-1'>
                                  <p className="card-text mb-0"><span className="product-info">Color Code:</span> {quot.colour_code}</p>
                                  <p className="card-text mb-0"><span className="product-info">Leather Type:</span> {quot.leather_type}</p>
                                  <p className="card-text mb-0"><span className="product-info">Remarks:</span> {quot.remarks}</p>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Col>
                         ))}

                        <Col lg={12}>
                          <div className="line"></div>
                        </Col>
                      </Row>
                      <Row className='mb-2'>
                        <Col>
                        <h5>Billing</h5>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}>
                            <Row>
                            <Col lg={{ span: 4, offset: 4 }} sm={3} style={{textAlign: "right"}}>
                              <label>Total</label>
                            </Col>
                            <Col lg={4} sm={9}>
                              <Field name="total" type="text" className="form-control"/>
                            </Col>
                            <Col lg={{ span: 4, offset: 4 }} sm={3} style={{textAlign: "right"}}>
                              <label>Fitting</label>
                            </Col>
                            <Col lg={4} sm={9}>
                              <Field name="fitting" type="text" className="form-control"/>
                            </Col>
                            <Col lg={{ span: 4, offset: 4 }} sm={3} style={{textAlign: "right"}}>
                              <label>Packing</label>
                            </Col>
                            <Col lg={4} sm={9}>
                              <Field name="packing" type="text" className="form-control"/>
                            </Col>
                            <Col lg={{ span: 4, offset: 4 }} sm={3} style={{textAlign: "right"}}>
                              <label>Cartage</label>
                            </Col>
                            <Col lg={4} sm={9}>
                              <Field name="cartage" type="text" className="form-control"/>
                            </Col>
                            <Col lg={{ span: 4, offset: 4 }} sm={3} style={{textAlign: "right"}}>
                              <div className='d-flex justify-content-end'>
                              <label>Other Charge</label>
                              <Field name="othercharge" type="text" className="form-control w-50 ml-2" />
                              </div>
                            </Col>
                            <Col lg={4} sm={9}>
                              <Field name="other" type="text" className="form-control" />
                            </Col>
                            <Col lg={{ span: 4, offset: 4 }} sm={3} style={{textAlign: "right"}}>
                              <label>Gross Total</label>
                            </Col>
                            <Col lg={4} sm={9}>
                              <Field name="grosstotal" type="text" className="form-control" />
                            </Col>
                            <Col lg={{ span: 4, offset: 4 }} sm={3} style={{textAlign: "right"}}>
                              <label>Advance</label>
                            </Col>
                            <Col lg={4} sm={9}>
                              <Field name="advance" type="text" className="form-control" />
                            </Col>
                            <Col lg={{ span: 4, offset: 4 }} sm={3} style={{textAlign: "right"}}>
                              <label>Balance</label>
                            </Col>
                            <Col lg={4} sm={9}>
                              <Field name="balance" type="text" className="form-control" />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className='mt-4'>
                        <Col>
                          <Button variant="primary" type="submit" style={{float: 'right'}}> Submit </Button>
                        </Col>
                      </Row>
                  </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      {/* Imported Ready Product Modal Start */}
      <Modal 
          size="lg"
          centered
          show={show} 
          onHide={modalAddClose}>
        <Formik
            enableReinitialize={true}
            initialValues={{
            model: "",
            name: products_name,
            qty: "",
            quotedprice: "",
            netprice: "",
            netamount: "",
            location: "",
            remarks: "",
            incentive: ""
            }}
            onSubmit={(values) => {
              console.log(values);
              addImportedReady(values);
              setShow(false);
            }}
        >
          {({values, setFieldValue}) => (
        <Form>
          <Modal.Header closeButton>
            <Modal.Title>Imported Ready Product</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg={12} sm={12} className="mb-2">
                <label>Model No.</label>
                <Select options={productList} name="model" onChange={(e) => getProductDetail(e)} />
              </Col>
              <Col lg={6} sm={12}>
                <label>Name</label>
                <Field name="name" type="text" className="form-control" />
              </Col>
              <Col lg={6} sm={12}>
                <label>Quantity</label>
                <Field name="qty" type="text" className="form-control" onKeyUp={(e) => calcAmount(e)}/>
              </Col>
              <Col lg={6} sm={12}>
                <label>Quoted Price</label>
                <Field name="quotedprice" type="text" className="form-control" />
              </Col>
              <Col lg={6} sm={12}>
                <label>Net Price</label>
                <Field name="netprice" type="text" className="form-control" onKeyUp={(e) => calcAmount(e)}/>
              </Col>
              <Col lg={6} sm={12}>
                <label>Net Amount</label>
                <Field name="netamount" type="text" className="form-control" readOnly value={ready_net_amount}/>
              </Col>
              <Col lg={6} sm={12}>
                <label>Location of Product</label>
                <Select options={locationList} name="location_id" onChange={(e) => getLocationDetail(e)}/>
              </Col>
              <Col lg={6} sm={12}>
                <label>Remarks</label>
                <Field name="remarks" as="textarea" className="form-control" />
              </Col>
              <Col lg={6} sm={12}>
                <label>Incentive</label>
                <Field name="incentive" type="number" className="form-control" />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit">Submit</Button>
          </Modal.Footer>
        </Form>
            )}
        </Formik>
      </Modal>
      {/* Imported Ready Product Modal End */}
      {/* Edit Imported Ready Product Modal Start */}
      <Modal 
          size="lg"
          centered
          show={show3} 
          onHide={modalClose3}>
        <Formik
            enableReinitialize={true}
            initialValues={{
              id:getEditProduct.id,
              model: getEditProduct.model_id,
              name: edit_product_name,
              qty: getEditProduct.qty,
              quotedprice: getEditProduct.quoted_price,
              netprice: getEditProduct.net_price,
              netamount: getEditProduct.net_amount,
              location: getEditProduct.location_id,
              remarks: getEditProduct.remarks,
              incentive: getEditProduct.incentive,
            }}
            onSubmit={(values) => {
              console.log(values);
              updateImportedReady(values);
              setShow3(false);
            }}
        >
          {({values, setFieldValue}) => (
        <Form>
          <Modal.Header closeButton>
            <Modal.Title>Edit Imported Ready Product</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg={12} sm={12} className="mb-2">
                <label>Model No.- { getEditProduct.model_name}</label>
                <Select options={productList} name="model" defaultValue={{ label: getEditProduct.model_name, value: getEditProduct.model_id }} onChange={(e) => getEditProductDetailReady(e)}/>
              </Col>
              <Col lg={6} sm={12}>
                <label>Name</label>
                <Field name="name" type="text" className="form-control" />
              </Col>
              <Col lg={6} sm={12}>
                <label>Quantity</label>
                <Field name="qty" type="text" className="form-control" onKeyUp={(e) => calcAmount(e)}/>
              </Col>
              <Col lg={6} sm={12}>
                <label>Quoted Price</label>
                <Field name="quotedprice" type="text" className="form-control" />
              </Col>
              <Col lg={6} sm={12}>
                <label>Net Price</label>
                <Field name="netprice" type="text" className="form-control" onKeyUp={(e) => calcAmount(e)}/>
              </Col>
              <Col lg={6} sm={12}>
                <label>Net Amount</label>
                <Field name="netamount" type="text" className="form-control" readOnly value={ready_net_amount}/>
              </Col>
              <Col lg={6} sm={12}>
                <label>Location of Product</label>
                <Select options={locationList} name="location_id" onChange={(e) => getLocationDetail(e)}/>
              </Col>
              <Col lg={6} sm={12}>
                <label>Remarks</label>
                <Field name="remarks" as="textarea" className="form-control" />
              </Col>
              <Col lg={6} sm={12}>
                <label>Incentive</label>
                <Field name="incentive" type="number" className="form-control" />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit">Submit</Button>
          </Modal.Footer>
        </Form>
            )}
        </Formik>
      </Modal>
      {/* Edit Imported Ready Product Modal End */}
      {/* Imported On Order Modal Start */}
      <Modal 
          size="lg"
          centered
          show={show2} 
          onHide={modalEditClose}>
        <Formik
            enableReinitialize={true}
            initialValues={{
            model: "",
            name: products_name,
            qty: "",
            quotedprice: "",
            netprice: "",
            netamount: "",
            location: "",
            remarks: "",
            incentive: ""
            }}
            onSubmit={(values) => {
              console.log(values);
              addImportedOrder(values);
              setShow2(false);
            }}
        >
          {({values, setFieldValue}) => (
        <Form>
          <Modal.Header closeButton>
            <Modal.Title>Imported on Order</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg={12} sm={12} className="mb-2">
                <label>Model No.</label>
                <Select options={productList} name="model" onChange={(e) => getProductDetail(e)}/>
              </Col>
              <Col lg={6} sm={12}>
                <label>Name</label>
                <Field name="name" type="text" className="form-control" />
              </Col>
              <Col lg={6} sm={12}>
                <label>Quantity</label>
                <Field name="qty" type="text" className="form-control" onKeyUp={(e) => calcAmount(e)}/>
              </Col>
              <Col lg={6} sm={12}>
                <label>QuotedPrice</label>
                <Field name="quotedprice" type="text" className="form-control" />
              </Col>
              <Col lg={6} sm={12}>
                <label>Net Price</label>
                <Field name="netprice" type="text" className="form-control" onKeyUp={(e) => calcAmount(e)}/>
              </Col>
              <Col lg={6} sm={12}>
                <label>Net Amount</label>
                <Field name="netamount" type="text" className="form-control" readOnly value={ready_net_amount}/>
              </Col>
              <Col lg={6} sm={12}>
                <label>Remarks</label>
                <Field name="remarks" as="textarea" className="form-control" />
              </Col>
              <Col lg={6} sm={12}>
                <label>Incentive</label>
                <Field name="incentive" type="number" className="form-control" />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit">Submit</Button>
          </Modal.Footer>
        </Form>
            )}
        </Formik>
      </Modal>
      {/* Imported On Order Modal End */}
      {/* Edit Imported On Order Modal Start */}
      <Modal 
          size="lg"
          centered
          show={show4} 
          onHide={modalClose4}>
        <Formik
            enableReinitialize={true}
            initialValues={{
            model: "",
            name: edit_product_name,
            qty: "",
            quotedprice: "",
            netprice: "",
            netamount: "",
            location: "",
            remarks: "",
            incentive: ""
            }}
            onSubmit={(values) => {
           
            }}
        >
          {({values, setFieldValue}) => (
        <Form>
          <Modal.Header closeButton>
            <Modal.Title>Edit Imported on Order</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg={12} sm={12} className="mb-2">
                <label>Model No.</label>
                <Select options={productList} name="model" />
              </Col>
              <Col lg={6} sm={12}>
                <label>Name</label>
                <Field name="name" type="text" className="form-control" />
              </Col>
              <Col lg={6} sm={12}>
                <label>Quantity</label>
                <Field name="qty" type="text" className="form-control" />
              </Col>
              <Col lg={6} sm={12}>
                <label>QuotedPrice</label>
                <Field name="quotedprice" type="text" className="form-control" />
              </Col>
              <Col lg={6} sm={12}>
                <label>Net Price</label>
                <Field name="netprice" type="text" className="form-control" />
              </Col>
              <Col lg={6} sm={12}>
                <label>Net Amount</label>
                <Field name="netamount" type="text" className="form-control" readOnly/>
              </Col>
              <Col lg={6} sm={12}>
                <label>Remarks</label>
                <Field name="remarks" as="textarea" className="form-control" />
              </Col>
              <Col lg={6} sm={12}>
                <label>Incentive</label>
                <Field name="incentive" type="text" className="form-control" />
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit">Submit</Button>
          </Modal.Footer>
        </Form>
            )}
        </Formik>
      </Modal>
      {/* Edit Imported On Order Modal End */}
    </>
    
  )
}

const mapStateToProps = (state) => {
  console.log(state); 
  return {
    order_detail: state.editOrders.orders,
    imported_ready: state.editOrders.imported_ready,
    imported_order: state.editOrders.imported_order,
    order_name: state.editOrders.order_name,
    cordinators: state.orderReducer.cordinator,
    reffereds: state.orderReducer.reffered,
    products: state.orderReducer.products,
    locations: state.orderReducer.locations,
    products_name: state.orderReducer.product_name.name,
    ready_net_amount: state.orderReducer.ready_net_amount,
    total_amount: state.editOrders.total_amount,
    gross_amt: state.editOrders.gross_total,
    balance_amt : state.editOrders.balance,
    getEditProduct: state.editOrders.edit_product,
    edit_product_name: state.editOrders.edit_product_name,
   }
 }
 
export default connect(mapStateToProps,{getProduct,getLocation,getCordinator,getReffered,getOrder,updateOrder,getProductName,onChangeCalcReady,addImportedReady,getEditReady,getEditProductNameReady,updateImportedReady,deleteImportedReady,addImportedOrder,deleteImportedOrder})(EditOrder);

