import {
    LOGIN,
} from "../Authentication/actiontype";

const INITIAL_STATE = {
  isAuthenticated: null,
};

const loginReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOGIN:
      return {
        ...state,
        isAuthenticated: true,
      };
    default:
      return state;
  }
};
export default loginReducer;