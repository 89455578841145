import React, { useRef, useState, useEffect } from 'react';
import { Row, Col, Container, Card, Modal, Button } from 'react-bootstrap';
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import KErrorMessage from "../../../components/ErrorMessage/KErrorMessage";
import PreviewImage from "../../../components/UploadImage/PreviewImage";
import MaterialTable from 'material-table';
import { connect } from "react-redux";
import { polish, getPolish, deletePolish, getPolishImage } from '../../../redux/Product/Polish/action';
import Loader from '../../../components/Loader/Loader';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';

const SUPPORTED_FORMATS =["image/jpg", "image/jpeg", "image/png"];
const validationSchema = yup.object({
  name: yup.string().required("Polish Name is Required!"),
  image: yup
  .mixed()
  .nullable()
  .required("Polsh Image is Required!")
  .test(
    "FILE_SIZE",
    "Uploaded file is too big.",
    (value) => !value || (value && value.size <= 1024*1024)
  )
  .test(
    "FILE_FORMAT",
    "Uploaded file has supported format.",
    (value) => !value || (value && SUPPORTED_FORMATS.includes(value?.type))
  )

});



const Polish = ({ polish,getPolish,currentIntegration,deletePolish,isLoading, getPolishImage, polishImage}) =>  {  useEffect(() => {
  getPolish();
}, []);


const [loading, setLoading] = useState(false);
useEffect(() => {
    if (isLoading) {
        setLoading(true);
    }
  });

  const deleteRow = (e) => {
    //
    deletePolish(e);
  };

  const fileRef = useRef(null);
  const [tableData, setTableData] = useState([]);
    useEffect(() => {
      if(currentIntegration)
      {
        setTableData(currentIntegration); 
      }
    }, [currentIntegration])

  const columns = [
    { title: 'Image', field: 'image', render: rowData => <img src={process.env.REACT_APP_IMAGE_URL+'/'+rowData.image} alt={rowData.image} style={{width: 50, height:50}}/> },
    { title: "Polish", field: "name" },
  ]

  const [show, setShow] = useState(false);
  const modalClose = () => setShow(false);
  const modalShow = () => setShow(true);

  const editImage = async (e) => {
    console.log('view:'+e);
      setShow({
      isOpen: true,
      })
      getPolishImage(e);
  }

  return (
    <>
      <Container fluid className="main-content">
        <Row>
          <Col lg={"6"} className="mb-3">
            <Card>
              <Card.Body>
                <Card.Title>Add Polish</Card.Title>
                <Formik
                  validationSchema={validationSchema}
                  initialValues={{
                    name: "",
                    image: "",
                  }}
                  onSubmit={(values) => {
                    polish(values);
                  }}
                >
                   {({values, setFieldValue}) => (
                  <Form>
                    <Row>
                      <Col sm={12}>
                        <label>Polish Name</label>
                        <Field name="name" type="text" className="form-control" />
                        <KErrorMessage name="name" />
                      </Col>
                      <Col sm={12}>
                        <label className='d-block'>Upload</label>
                        <div  className='d-flex'>
                            <input
                              ref={fileRef}
                              className="form-control"
                              type="file"
                              name="image"
                              onChange={(event) => {setFieldValue("image", event.target.files[0]);}}
                            />
                             <span className='upload-img'>{values.image && <PreviewImage file={values.image}/>}</span>
                            <button className="btn-upload btn mt-3" hidden onClick={() => {fileRef.current.click();}}>Upload</button>
                        </div>
                        <KErrorMessage name="image"/>
                      </Col>
                    </Row>
                    <div className="mt-3 float-right">
                      <button className="btn btn-primary btn-md" type="submit">Submit</button>
                    </div>
                  </Form>
                   )}
                </Formik>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={"6"}>
            <div style={{display: 'table', tableLayout:'fixed', width:'100%'}}>
              {loading ? 
            <MaterialTable columns={columns} data={tableData}
              editable={{
                onRowUpdate: (newRow, oldRow) => new Promise((resolve, reject) => {
                  const updatedData = [...tableData]
                  updatedData[oldRow.tableData.id] = newRow
                  setTableData(updatedData)
                  setTimeout(() => resolve(), 500)
                }),
                onRowDelete: (selectedRow) => new Promise((resolve, reject) => {
                  deleteRow(selectedRow.id)
                  setTimeout(() => resolve(), 1000)
                })
              }}

              options={{
                sorting: true, search: true,
                paging: true, pageSizeOptions: [10, 25, 50, 100], pageSize: 10,
                exportButton: true,
                exportAllData: true, exportFileName: "POLISH", actionsColumnIndex: -1,
                autoheight: true,
                // headerStyle: { background: "#f44336",color:"#fff"}
              }}
              actions={[
                {
                  icon: () => <InsertPhotoIcon />,
                  tooltip: 'Edit Polish Image',
                  onClick: (event, rowData) => editImage(rowData.id)
                }
              ]}
              title="Polish"
            />
            : <Loader/> }
            </div>
          </Col>
        </Row>
      </Container>
      {/* Edit Fabric Image Modal Start */}
      <Modal 
          size="md"
          centered
          show={show} 
          onHide={modalClose}>
            <Formik
                enableReinitialize={true}
                validationSchema={validationSchema}
                initialValues={{
                // id: polishImage.id,
                image: null,
                }}
                onSubmit={(values) => {
                  console.log(values);
                  
                }}
            >
              {({values, setFieldValue, handleSubmit}) => (
                <Form onSubmit={handleSubmit}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className='mb-3'>
                    <h6>Uploaded Image</h6>
                    <img src={process.env.REACT_APP_IMAGE_URL+'/'+ polishImage.image} alt={polishImage.image} width="100px" height="100px"  />
                  </div>
                    <label className='d-block'>Edit Image</label>
                      <div  className='d-flex'>
                          <input
                            ref={fileRef}
                            className="form-control"
                            type="file"
                            name="image"
                            onChange={(event) => {setFieldValue("image", event.target.files[0]);}}
                          />
                            <span className='upload-img'>{values.image && <PreviewImage file={values.image}/>}</span>
                          <button className="btn-upload btn mt-3" hidden onClick={() => {fileRef.current.click();}}>Upload</button>
                      </div>
                      <KErrorMessage name="image"/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={modalClose}>Close</Button>
                    <Button variant="primary" type="submit">Submit</Button>
                </Modal.Footer>
                </Form>
              )}
            </Formik>
        </Modal>
    </>
  )
}

const mapStateToProps = (state) => {
 
  return {
     currentIntegration: state.polish.currentIntegration,
     polishImage: state.polish.polishImage,
     isLoading: state.polish.isLoading,
   }
 }

 export default connect(mapStateToProps,{polish,getPolish,deletePolish,getPolishImage})(Polish);