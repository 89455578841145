import {
    RETRIEVE_VENDOR_PAIDINCENTIVE
} from "../VendorPaidIncentive/actiontype";

import { toast } from 'react-toastify';
import api from "../../../api";

export const getVendorPaidIncentive= (data) => async (dispatch) => {
   try {
    const res = await api.get(`/vendorIncentive/vendorPaidIncentive`);
   
    
    dispatch({
      type: RETRIEVE_VENDOR_PAIDINCENTIVE,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_VENDOR_PAIDINCENTIVE,
      payload: [],
    });
  }
};



