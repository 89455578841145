import {
    CREATE_PENDING_DISPATCH,
    RETRIEVE_PENDING_DISPATCH,
} from "../PendingDispatch/actiontype";

const INITIAL_STATE = {
  currentIntegration: [],
  isLoading: false,
};

const peningDispatchReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
 
  switch (type) {
    case CREATE_PENDING_DISPATCH:
      return {
        ...state
      };
    case RETRIEVE_PENDING_DISPATCH:
      return {
        ...state,
        currentIntegration: payload,
        isLoading: true,
      };
      default:
        return state;
  }
};
export default peningDispatchReducer;