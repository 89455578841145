import React,{ useState,useEffect } from 'react'
import { Row, Col, Container, Form, Button} from 'react-bootstrap';
import Select from 'react-select'
import { connect } from "react-redux";
import { getBrandName,getCordinator,getReffered} from '../../redux/Quotation/QuotationToOrder/action';

const rnd = Math.floor(Math.random() * 10000) + 1;
localStorage.setItem('rndno', rnd);
localStorage.removeItem('order_info')
const branch_id = window.location.pathname.split("/").pop();

const StepOne = ({ formData, setForm, navigation,getBrandName,orderName,getCordinator,cordinators,getReffered,reffereds }) => { useEffect(() => {
  getBrandName(branch_id);
  getCordinator();
  getReffered();
}, []);

  const {bookingdate, deliverydate, name, mobile, email, address } = formData;

  const [cordinatorList, setCordinatorList] = useState(null);
  const [refferedList, setrefferedList] = useState(null);

  useEffect(() => {
    if(cordinators)
    {
      setCordinatorList(cordinators); 
    }
  }, [cordinators])

  useEffect(() => {
    if(reffereds)
    {
      setrefferedList(reffereds); 
    }
  }, [reffereds])

  const onSelectChangeCordinator = (e) =>
  {
    localStorage.setItem('coordinator', e.value);
  }

  const onSelectChangeReffredBy = (e) =>
  {
    localStorage.setItem('refferedby', e.value);
  }

  return (
      <>
       <Container fluid>
          <Row className='mb-2'>
            <Col>
            <h5>Order Detail</h5>
            </Col>
          </Row>
          <Row>
            <Col lg={4} sm={12}>
              <label>Order No.</label>
              <Form.Control name="orderno" type="text" value={orderName} readOnly/>
            </Col>
            <Col lg={4} sm={12}>
              <label>Date Of Booking</label>
              <Form.Control name="bookingdate" value={bookingdate} type="date" onChange={setForm} />
            </Col>
            <Col lg={4} sm={12}>
              <label>Expected Date of Delivery</label>
              <Form.Control name="deliverydate" value={deliverydate} type="date" onChange={setForm} />
            </Col>
            <Col lg={12}>
              <div className="line"></div>
            </Col>
            <Col lg={4} sm={12}>
              <label>Customer Name</label>
              <Form.Control name="name" value={name} type="text" onChange={setForm} />
            </Col>
            <Col lg={4} sm={12}>
              <label>Customer Mobile</label>
              <Form.Control name="mobile" value={mobile} type="text" onChange={setForm} />
            </Col>
            <Col lg={4} sm={12}>
              <label>Customer Email</label>
              <Form.Control name="email" value={email} type="text" onChange={setForm} />
            </Col>
            <Col lg={4} sm={12}>
              <label>Address</label>
              <Form.Control name="address" value={address} type="text" onChange={setForm} />
            </Col>
            <Col lg={4} sm={12}>
              <label>Cordinator</label>
              <Select options={cordinatorList} name="coordinator" onChange={(e) => onSelectChangeCordinator(e)}/>
            </Col>
            <Col lg={4} sm={12}>
              <label>Reffered By</label>
              <Select options={refferedList} name="refferedby" onChange={(e) => onSelectChangeReffredBy(e)}/>
            </Col>
          </Row>
          <Row className='mt-4'>
            {/* <Col>
              <Button variant="primary" onClick={() => navigation.previous()} > Back </Button>
            </Col> */}
            <Col>
              <Button variant="primary" style={{float: 'right'}} onClick={() => navigation.next()} > Next </Button>
            </Col>
          </Row>
       </Container>
      </>
   
  );
};

const mapStateToProps = (state) => {
  //
  return {
     orderName: state.quotationToOrderReducer.orderName,
     cordinators: state.quotationToOrderReducer.cordinator,
     reffereds: state.quotationToOrderReducer.reffered,
   }
 }
 
 export default connect(mapStateToProps,{getBrandName,getCordinator,getReffered})(StepOne);