import {
    CREATE_DUE_INCENTIVE,
    RETRIEVE_DUE_INCENTIVE,
    APPROVE_DUE_INCENTIVE
} from "../DueIncentive/actiontype";

const INITIAL_STATE = {
  currentIntegration: [],
};

const dueIncentiveReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case CREATE_DUE_INCENTIVE:
      return {
        ...state
      };
    case RETRIEVE_DUE_INCENTIVE:
      return {
        ...state,
        currentIntegration: payload,
      };
      case  APPROVE_DUE_INCENTIVE:
    return state.filter(({ id }) => id !== payload.id);

      default:
        return state;
  }
};
export default dueIncentiveReducer;