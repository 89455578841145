import {
    CREATE_FABRICS,
    RETRIEVE_FABRICS,
    UPDATE_FABRICS,
    DELETE_FABRICS,
    RETRIEVE_FABRIC_IMAGE,
    UPDATE_FABRIC_IMAGE
} from "../Fabric/actiontype";


const INITIAL_STATE = {
  currentIntegration: [],
  fabricImage: [],
  isLoading: false,
};

const fabricReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case CREATE_FABRICS:
      return {
        ...state,
        currentIntegration: [...state.currentIntegration, payload],
      };
    case RETRIEVE_FABRICS:
      return {
        ...state,
        currentIntegration: payload,
        isLoading: true,
      };
    case UPDATE_FABRIC_IMAGE:
      return {
        ...state,
        currentIntegration: payload,
      };
    case RETRIEVE_FABRIC_IMAGE:
      return {
        ...state,
        fabricImage: payload,
      };
    case UPDATE_FABRICS:
      return state.map((fabric) => {
        if (fabric.id === payload.id) {
          return {
            ...fabric,
            ...payload,
          };
        } else {
          return fabric;
        }
      });
    case DELETE_FABRICS:
      return {
        ...state,
        currentIntegration: state.currentIntegration.filter((item) => parseInt(item.id) !== parseInt(payload)),
      };
    default:
      return state;
  }
};


export default fabricReducer;