import {
    CREATE_APPROVED_ORDERS,
    RETRIEVE_APPROVED_ORDERS,
    DELETE_APPROVED_ORDERS,
    DECLINE_APPROVED_ORDERS
} from "../ApprovedOrder/actiontype";

import { toast } from 'react-toastify';
import api from "../../../api";

let usertype = JSON.parse(localStorage.getItem('usertype'));
let userid = JSON.parse(localStorage.getItem('userid'));

export const getApprovedOrder= (data) => async (dispatch) => {

  let data = {
    usertype : usertype,
    userid : userid
  };
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  try {
    const res = await api.post(`/order/approvedOrder`,data);
   
    dispatch({
      type: RETRIEVE_APPROVED_ORDERS,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_APPROVED_ORDERS,
      payload: [],
    });
  }
};

export const declineOrder = (data) => async (dispatch) => {
  //const body = JSON.stringify(data);
  
  try {
    let body = {
      id: data.id,
     reason : data.reason
   };
    const res = await api.post('/order/declineOrder', body);
    
    if (res.status === 200) {
      toast.success(res.data.message);
        dispatch({
            type: DECLINE_APPROVED_ORDERS,
            payload: res.data.data.info,
          });
         }
      } catch (err) {
    
    toast.warn('Invalid inputs! Order Not Decline');
    // dispatch({
    //   type: REGISTER_FAIL,
    // });
  }
};

export const deleteOrder = (id) => async (dispatch) => {
  try {
    const res = await api.get('/order/deleteOrder/' + id);
   
    if (res.status === 200) {
      toast.success(res.data.message);
      dispatch({
        type:  DELETE_APPROVED_ORDERS,
        payload: res.data.data.info,
      });
      
    }
  } catch (error) {
    
    toast.error('Failed to delete');
    dispatch({
      type:  DELETE_APPROVED_ORDERS,
      payload: [],
    });
  }
};

