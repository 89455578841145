import {
    CREATE_USERTYPES,
    RETRIEVE_USERTYPES,
    UPDATE_USERTYPES,
    DELETE_USERTYPES
} from "../UserType/actiontype";

const INITIAL_STATE = {
  currentIntegration: [],
  isLoading: false,
  isUpdate: false,
};

const userTypeReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case CREATE_USERTYPES:
      return {
        ...state,
        currentIntegration: [...state.currentIntegration, payload],
      };
    case RETRIEVE_USERTYPES:
      return {
        ...state,
        currentIntegration: payload,
        isLoading: true,
      };
    case UPDATE_USERTYPES:
      return {
        ...state,
        currentIntegration: payload,
        isUpdate: true,
      };
    case DELETE_USERTYPES:
      return {
        ...state,
        currentIntegration: state.currentIntegration.filter((item) => parseInt(item.id) !== parseInt(payload)),
      };
    default:
      return state;
  }
};
export default userTypeReducer;