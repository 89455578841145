import React from 'react'
import { Row, Col, Container, Form, Button} from 'react-bootstrap';

import Select from 'react-select'

const options = [
  { value: '1', label: 'INDESIGN#QT_1002' },
  { value: '2', label: 'INDESIGN#QT_1003' },
  { value: '3', label: 'INDESIGN#QT_10024' }
]
export const StepOne = ({ formData, setForm, navigation }) => {
  const { orderno, bookingdate, quotation, deliverydate, name, mobile, email, address, coordinator, refferedby } = formData;
  return (
      <>
       <Container fluid>
          <Row className='mb-2'>
            <Col>
            <h5>Order Detail</h5>
            </Col>
          </Row>
          <Row>
            <Col lg={4} sm={12}>
              <label>Order No.</label>
              <Form.Control name="orderno" type="text" value={orderno} onChange={setForm} />
            </Col>
            <Col lg={4} sm={12}>
              <label>Date Of Booking</label>
              <Form.Control name="bookingdate" value={bookingdate} type="date" onChange={setForm} />
            </Col>
            <Col lg={4} sm={12}>
              <label>Expected Date of Delivery</label>
              <Form.Control name="deliverydate" value={deliverydate} type="date" onChange={setForm} />
            </Col>
            <Col lg={12}>
              <div className="line"></div>
            </Col>
            <Col lg={4} sm={12}>
              <label>Customer Name</label>
              <Form.Control name="name" value={name} type="text" onChange={setForm} />
            </Col>
            <Col lg={4} sm={12}>
              <label>Customer Mobile</label>
              <Form.Control name="mobile" value={mobile} type="text" onChange={setForm} />
            </Col>
            <Col lg={4} sm={12}>
              <label>Customer Email</label>
              <Form.Control name="email" value={email} type="text" onChange={setForm} />
            </Col>
            <Col lg={4} sm={12}>
              <label>Address</label>
              <Form.Control name="address" value={address} type="text" onChange={setForm} />
            </Col>
            <Col lg={4} sm={12}>
              <label>Cordinator</label>
              <Select options={options} name="coordinator" onChange={setForm} />
            </Col>
            <Col lg={4} sm={12}>
              <label>Reffered By</label>
              <Select options={options} name="refferedby" onChange={setForm} />
            </Col>
          </Row>
          <Row className='mt-4'>
            {/* <Col>
              <Button variant="primary" onClick={() => navigation.previous()} > Back </Button>
            </Col> */}
            <Col>
              <Button variant="primary" style={{float: 'right'}} onClick={() => navigation.next()} > Next </Button>
            </Col>
          </Row>
       </Container>
      </>
   
  );
};
