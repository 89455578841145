import {
    RETRIEVE_STOCK_REPORT
} from "../StockReport/actiontype";

import api from "../../../api";

export const getStockReport= (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  try {
    const res = await api.get(`/report/stockReport`);
    
    dispatch({
      type: RETRIEVE_STOCK_REPORT,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_STOCK_REPORT,
      payload: [],
    });
  }
};



