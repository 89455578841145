import React, {useState, useEffect} from 'react'
import { Row, Col, Container, Button, Card } from 'react-bootstrap';
import { Formik, Form, Field } from "formik";
import Select from 'react-select'
import { connect } from "react-redux";
import { addStockIn } from '../../redux/Branch/StockIn/action';

const StockIn = ({ addStockIn}) =>  {  useEffect(() => {
  }, []);
  

    const model = [
    { value: '1', label: 'INDESIGN#QT_1002' },
    { value: '2', label: 'INDESIGN#QT_1003' },
    { value: '3', label: 'INDESIGN#QT_10024' }
]

const defaultValue = (model, value) => {
    return model ? model.find(option => option.value === value) : "";
  };
  let userid = JSON.parse(localStorage.getItem('userid'));
  return (
   <>
    <Container fluid className="main-content">
        <Row>
            <Col lg={'12'}>
                <h3 className="main-title">Stock In</h3>
            </Col>
        </Row>
        <Formik
            initialValues={{
            userid: userid,
            pid: "",
            qty: "",
            date: "",
            status: "",
            remarks: "",

            }}
            onSubmit={(values) => {
                addStockIn(values);
            }}
        >
            {({values, handleSubmit, setFieldValue}) => (
            <Form onSubmit={handleSubmit}>
                <Row className='mt-3 mb-3'>
                    <Col lg={12}>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Field name="userid" type="hidden" className="form-control" readOnly/>
                                    <Col lg={6} sm={12} className="mb-2">
                                        <label>Model No.</label>
                                        <Select
                                            value={defaultValue(model, values.name)} 
                                            options={model} 
                                            name="pid"
                                            onChange={newValue => setFieldValue("pid", newValue.value)}
                                         />
                                    </Col>
                                    <Col lg={6} sm={12} className="mb-2">
                                        <label>Product Category</label>
                                        <Field name="product_category" type="text" className="form-control" readOnly/>
                                    </Col>
                                    <Col lg={6} sm={12} className="mb-2">
                                        <label>Leather Category</label>
                                        <Field name="leather_category" type="text" className="form-control" readOnly/>
                                    </Col>
                                    <Col lg={6} sm={12} className="mb-2">
                                        <label>Leather Colour</label>
                                        <Field name="leather_colour" type="text" className="form-control" readOnly/>
                                    </Col>
                                    <Col lg={6} sm={12} className="mb-2">
                                        <label>Leather Type</label>
                                        <Field name="leather_type" type="text" className="form-control" readOnly/>
                                    </Col>
                                    <Col lg={6} sm={12} className="mb-2">
                                        <label>Item Colour</label>
                                        <Field name="item_colour" type="text" className="form-control" readOnly/>
                                    </Col>
                                    <Col lg={6} sm={12} className="mb-2">
                                        <label>Quantity</label>
                                        <Field name="qty" type="text" className="form-control" />
                                    </Col>
                                    <Col lg={6} sm={12} className="mb-2">
                                        <label>Date</label>
                                        <Field name="date" type="date" className="form-control" />
                                    </Col>
                                    <Col lg={6} sm={12} className="mb-2">
                                        <label className='d-block'>Reason</label>
                                        <Field name="status" value="purchase" type="radio" />
                                        <label style={{padding: "0 12px", veticalAlign: "middle"}}>Purchase</label>
                                        <Field name="status" value="salereturn" type="radio"/>
                                        <label style={{padding: "0 12px", veticalAlign: "middle"}}>Sale Return</label>
                                    </Col>
                                    <Col lg={6} sm={12} className="mb-2">
                                        <label>Remarks</label>
                                        <Field name="remarks" type="text" className="form-control" />
                                    </Col>
                                </Row>
                                <Row className='mb-3 mt-2'>
                                    <Col lg={12}>
                                        <Button variant="primary" type="submit" style={{float: "right"}}>Submit</Button>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Form>
            )}
        </Formik>
    </Container>
   </>
  )
}

const mapStateToProps = (state) => {
    return {
       currentIntegration: state.stockIn.currentIntegration,
     }
   }
  
   export default connect(mapStateToProps,{addStockIn})(StockIn);