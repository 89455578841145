import React from 'react'
import {useState,useEffect} from 'react'
import { Row, Col, Container, Modal, Button, Badge, Dropdown} from 'react-bootstrap';
import { Formik, Form, Field } from "formik";
import Select from 'react-select';
import { connect } from "react-redux";
import { getProduct,getProductName,addImportedOrder } from '../../redux/Quotation/QuotationToOrder/action';

const StepThree = ({ formData, setForm, navigation,getProduct,products,getProductName,products_name,colour_code,leather_type, addImportedOrder,imported_order  }) => {
  
  useEffect(() => {
    getProduct();
  }, []);

  const [formDataModel, setFormData] = useState({
    product_id: "",
    name: "",
    colourcode: "",
    leathertype: "",
    qty: "",
    quoted_price: "",
    net_price: "",
    net_amount: "",
    remarks: "",
    incentive: "",
  });

  const { qty,quoted_price,net_price,remarks,incentive } = formDataModel;

  const [show, setShow] = useState(false);
  const modalAddClose = () => setShow(false);
  const modalAddShow = () => setShow(true);
  const [productList, setProductList] = useState(null);

  const onChange = (e) =>
  {
    setFormData({ ...formDataModel, [e.target.name]: e.target.value });
    if(e.target.name === 'net_price' || e.target.name === 'qty' ){
      calc();
    }
  }

  function calc()
  {
    var net_price1 = document.getElementsByName("net_price")[0].value;
    var qty1 = document.getElementsByName("qty")[0].value;
    var calc = net_price1*qty1;
    document.getElementsByName("net_amount")[0].value = calc;
  }

  useEffect(() => {
    if(products_name)
    {
      setFormData({ ...formDataModel, name: products_name });
    }
  }, [products_name])
  
  useEffect(() => {
    if(colour_code)
    {
      setFormData({ ...formDataModel, colourcode: colour_code });
    }
  }, [colour_code])
  
  useEffect(() => {
    if(leather_type)
    {
      setFormData({ ...formDataModel, leathertype: leather_type });
    }
  }, [leather_type])

  useEffect(() => {
    if(products)
    {
      setProductList(products); 
    }
  }, [products])

  const getProductDetail = (e) =>
  {
    setFormData({ ...formDataModel, product_id: e.value });
    getProductName(e.value);
  }

  function submitProduct(){
    const rndno = JSON.parse(localStorage.getItem('rndno'));
    const data = {
      type: 'Order',
      session_id: rndno,
      product_id:  formDataModel.product_id,
      name:  formDataModel.name,
      colour_code:  formDataModel.colourcode,
      leather_type:  formDataModel.leathertype,
      qty:  formDataModel.qty,
      quoted_price:  formDataModel.quoted_price,
      net_price:  formDataModel.net_price,
      net_amount:  formDataModel.net_price*formDataModel.qty,
      remarks:  formDataModel.remarks,
      incentive:  formDataModel.incentive,
    };
    addImportedOrder(data);
  }
  
  return (
      <>
       <Container fluid>
          <Row className='mb-2'>
            <Col>
            <h5>Imported On Order</h5>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <button type="button" className="btn btn-md btn-info text-white" onClick={modalAddShow} style={{float: 'right'}}><i className="fa-solid fa-plus"></i> Add</button>
            </Col>
          </Row>
          <Row className='mt-4'>
          {imported_order.map((prod, index) => (
            <Col lg={4} sm={"12"} key={index}>
              <div className="card product mb-2">
                <div className="card-header d-flex justify-content-between pb-0">
                <h6 className="mb-0"><span className="card-title">{prod.name}</span> <Badge bg="info" text="light" className="ml-2">{prod.qty}</Badge></h6>
                  <Dropdown>
                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                      <i className="ri-more-2-line"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1"><i className="ri-edit-box-line text-primary"></i> Edit</Dropdown.Item>
                      <Dropdown.Item href="#/action-2"><i className="far fa-trash-alt text-danger"></i> Delete</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="card-body pt-1">
                  <Row>
                    <Col lg={4}>
                      <div>
                      <img src={process.env.REACT_APP_IMAGE_URL+'/'+prod.image} alt="prod_img" className='product-image' width="100%"/>
                      </div>
                    </Col>
                    <Col lg={8}>
                    <p className="card-text mb-0"><span className="product-info">Model No:</span> {prod.model_id}</p>
                      <p className="card-text mb-0"><span className="product-info">Quoted Price:</span> {prod.quoted_price}</p>
                      <p className="card-text mb-0"><span className="product-info">Net Price:</span> {prod.net_price}</p>
                      <p className="card-text mb-0"><span className="product-info">Net Amount:</span> {prod.net_amount}</p>
                      <p className="card-text mb-0"><span className="product-info">Incentive:</span> {prod.incentive}</p>
                    </Col>
                    <Col lg={12} className='mt-1'>
                    <p className="card-text mb-0"><span className="product-info">Color Code:</span> {prod.colour_code}</p>
                      <p className="card-text mb-0"><span className="product-info">Leather Type:</span> {prod.leather_type}</p>
                      <p className="card-text mb-0"><span className="product-info">Remarks:</span> {prod.remarks}</p>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
           ))}
          </Row>
          <Row className='mt-4'>
            <Col>
              <Button variant="primary" onClick={() => navigation.previous()} > Back </Button>
            </Col>
            <Col>
              <Button variant="primary" style={{float: 'right'}} onClick={() => navigation.next()} > Next </Button>
            </Col>
          </Row>
       </Container>
       <Modal 
          size="lg"
          centered
          show={show} 
          onHide={modalAddClose}>
        <Formik
            initialValues={{
            model: "",
            name: "",
            qty: "",
            quotedprice: "",
            netprice: "",
            netamount: "",
            location: "",
            remarks: "",
            incentive: ""
            }}
            onSubmit={(values) => {
              //console.log(values);
            }}
        >
          {({values, setFieldValue}) => (
        <Form>
          <Modal.Header closeButton>
            <Modal.Title>Imported on Order</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg={12} sm={12} className="mb-2">
                <label>Model No.</label>
                <Select options={productList} name="product_id" onChange={(e) => getProductDetail(e)}/>
              </Col>
              <Col lg={6} sm={12}>
                <label>Name</label>
                <Field name="name" type="text" className="form-control" value={products_name} readOnly/>
              </Col>
              <Col lg={6} sm={12}>
                <label>Colour Code</label>
                <Field name="colourcode" type="text" className="form-control" value={colour_code}/>
              </Col>
              <Col lg={6} sm={12}>
                <label>Leather Type</label>
                <Field name="leathertype" type="text" className="form-control" value={leather_type}/>
              </Col>
              <Col lg={6} sm={12}>
                <label>Quantity</label>
                <Field name="qty" type="number" className="form-control" value={qty} onChange={(e) => onChange(e)}/>
              </Col>
              <Col lg={6} sm={12}>
                <label>Quoted Price</label>
                <Field name="quoted_price" type="number" className="form-control" value={quoted_price} onChange={(e) => onChange(e)} />
              </Col>
              <Col lg={6} sm={12}>
                <label>Net Price</label>
                <Field name="net_price" type="number" className="form-control" value={net_price} onChange={(e) => onChange(e)} />
              </Col>
              <Col lg={6} sm={12}>
                <label>Net Amount</label>
                <Field name="net_amount" type="text" className="form-control" readOnly/>
              </Col>
              <Col lg={6} sm={12}>
                <label>Incentive</label>
                <Field name="incentive" type="text" className="form-control"  value={incentive} onChange={(e) => onChange(e)}/>
              </Col>
              <Col lg={6} sm={12}>
                <label>Remarks</label>
                <Field name="remarks" as="textarea" className="form-control"  value={remarks} onChange={(e) => onChange(e)}/>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
          <Button variant="primary" type="button" onClick={submitProduct}>Submit</Button>
          </Modal.Footer>
        </Form>
            )}
        </Formik>
      </Modal>
      </>
   
  )
}

const mapStateToProps = (state) => {
  return {
     imported_order: state.quotationToOrderReducer.imported_order,
     products: state.quotationToOrderReducer.products,
     locations: state.quotationToOrderReducer.locations,
     products_name: state.quotationToOrderReducer.product_name.name,
     colour_code: state.quotationToOrderReducer.product_name.colour_code,
     leather_type: state.quotationToOrderReducer.product_name.leather_type,
   }
 }
 
 export default connect(mapStateToProps,{getProduct,getProductName,addImportedOrder})(StepThree);