import * as React from "react";
import {useState, useEffect} from 'react'
import { Row, Col, Container, Badge, Dropdown, Modal, Button } from 'react-bootstrap';
import MaterialTable from 'material-table';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import { connect } from "react-redux";
import {getApprovedOrder,deleteOrder,declineOrder} from '../../../redux/Order/ApprovedOrder/action';
import CreateOrder from "../../../components/BranchList/CreateOrder";
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import { useNavigate } from 'react-router-dom';
import SearchBar from "../../../components/SearchBar/SearchBar";
import Loader from "../../../components/Loader/Loader";

const validationSchema = yup.object({
  reason: yup.string().required("Reason is Required"),
});

const ApprovedOrder = ({ getApprovedOrder,currentIntegration,deleteOrder,declineOrder,isLoading}) =>  {  useEffect(() => {
  getApprovedOrder();
}, []);

const [visible, setVisible] = useState(false);
const handleVisibility = () => {
    setVisible(prev => !prev);
};

const [loading, setLoading] = useState(false);
useEffect(() => {
    if (isLoading) {
        setLoading(true);
    }
  });

const [show, setShow] = useState(false);
const modalClose = () => setShow(false);
const modalShow = () => setShow(true);

const [tableData, setTableData] = useState([]);
  useEffect(() => {
    if(currentIntegration)
    {
      setTableData(currentIntegration); 
    }
  }, [currentIntegration])

const columns = [
{ title: "Order No. | Created By", field: "orderNo" },
// { title: "Created By ", field: "createdBy" },
{ title: "Customer Detail", field: "customerDetail" },
// { title: "Customer Mobile", field: "customerMobile" },
{ title: "Qty", field: "qty" },
{ title: "Net Total Amount", field: "totalAmount" },
{ title: "Total Discount", field: "totalDiscount" },
{ title: "Grand Total", field: "grandTotal" },
{ title: "Advance Paid", field: "advancePaid" },
{ title: "Total Paid", field: "totalPaid" },
{ title: "Total Due", field: "totalDue" },
{ title: "Date Of Order", field: "orderDate" },
{ title: "Expected Date Of Order", field: "expectedDate" },

]

const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', type: ''})
const navigate = useNavigate();

const editOrder = async (e) => {
  navigate('/edit-order/'+e);
}
const viewOrder = async (e) => {
  navigate('/detail-View-order/'+e);
}
 
const [formData, setFormData] = useState({
  reason: "",
  id: ""
});

const { reason, id } = formData;

const onChange = (e) =>
{
  setFormData({ ...formData, [e.target.name]: e.target.value });
  
}
const orderDecline = async (e) => {
  setFormData({ ...formData, 'id': e });
  setShow({
      isOpen: true,
      })
  
}

const onSubmit = async (e) => {
  e.preventDefault();
  const data = {
   id: formData.id,
   reason: formData.reason,
 };
 
 declineOrder(data); // action name
};


const orderDelete = async (e) => {
  
  setConfirmDialog({
  isOpen: true,
  title: 'Are You Sure To Delete This Order?',
  type: false,
  onConfirm: () => { 
      deleteOrder(e);
      setConfirmDialog({ ...confirmDialog, isOpen: false })
  }
  })
}   
  
// Search for Card view start
const [searchValue, setSearchValue] = useState("");

const filterOrders = ({ orderNo }) => {
  return orderNo.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
};
// Search for Card view end

  return (
    <>
        <Container fluid className="main-content">
            <Row>
              <Col>
                  <h3 className="main-title">Approved Order</h3>
              </Col> 
              <Col>
                  <div style={{float: "right"}} className="d-flex">
                    <div className="d-flex justify-content-between d-flex d-sm-none">
                      <div className="magnifierContainer">
                        <button type="button" className="btn btn-md btn-warning text-white roundedcircle mr-3" onClick={handleVisibility}><i className="fa-solid fa-search"></i></button>
                      </div>
                    </div>
                    <CreateOrder/>
                  </div>
              </Col>
              <Col lg={"12"}>
                  <div className='d-block d-sm-none mt-2'>
                    <div className={visible ? "open" : "close"}>
                      <SearchBar onSearch={setSearchValue} value={searchValue} />
                    </div>
                  </div>
              </Col>
            </Row>
            {loading ? <div>
            <Row className="d-none d-sm-block mt-2">
              <Col lg={"12"} className="mt-3">
              <div style={{display: 'table', tableLayout:'fixed', width:'100%'}}>
              <MaterialTable columns={columns} data={tableData}
                  actions={[
                    {
                      icon: () => <VisibilityIcon color={'primary'} />,
                      tooltip: 'View',
                      onClick: (event, rowData) => viewOrder(rowData.id)
                    },
                    
                    {
                      icon: () => <EditIcon color={'secondary'} />,
                      tooltip: 'Edit',
                      onClick: (event, rowData) => editOrder(rowData.id)
                    },
                    // {
                    //   icon: () => <CheckIcon color={'success'} />,
                    //   tooltip: 'Approve',
                    //   onClick: (event, rowData) => approveOrder(rowData.id)
                    // },
                    {
                      icon: () => <CloseIcon color={'warning'} />,
                      tooltip: 'Decline',
                      onClick: (event, rowData) => orderDecline(rowData.id)
                    },
                    {
                      icon: () => <DeleteIcon color={'error'} />,
                      tooltip: 'Delete',
                      onClick: (event, rowData) => orderDelete(rowData.id)
                    }
                  ]}
                  options={{
                  sorting: true, search: true,
                  paging: true, pageSizeOptions: [10, 25, 50, 100], pageSize: 10,
                  exportButton: true,
                  exportAllData: true, exportFileName: "Approved Order", actionsColumnIndex: -1,
                  // headerStyle: { background: "#f44336",color:"#fff"}
                  }}
                  title="Approved Order"
              />
              </div>
            </Col>
          </Row>
          <Row className="d-block d-sm-none mt-2">
            {currentIntegration.filter(filterOrders).map((order, index) => (
            <Col xs={"12"} key={index}>
                <div className="card mb-2">
                    <div className="card-header d-flex justify-content-between pb-0">
                        <h6>{order.orderNo}	 <Badge bg="danger" text="light" className="ml-2">{order.qty}</Badge></h6>
                        <Dropdown>
                        <Dropdown.Toggle variant="light" id="dropdown-basic">
                            <i className="ri-more-2-line"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => viewOrder(order.id)}><VisibilityIcon color={'primary'} /> View</Dropdown.Item>
                            <Dropdown.Item onClick={() => editOrder(order.id)}><EditIcon color={'secondary'} /> Edit</Dropdown.Item>
                            <Dropdown.Item onClick={() => orderDecline(order.id)}><CloseIcon color={'warning'} /> Decline</Dropdown.Item>
                            <Dropdown.Item onClick={() => orderDelete(order.id)}><DeleteIcon color={'error'} /> Delete</Dropdown.Item>
                        </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="card-body pt-1">
                        <p className="card-text mb-0"><span className="quotation-info">Customer Detail:</span> {order.customerDetail}</p>
                        <p className="card-text mb-0"><span className="quotation-info">Net Total Amount:</span> {order.totalAmount}</p>
                        <p className="card-text mb-0"><span className="quotation-info">Total Discount:</span> {order.totalDiscount}</p>
                        <p className="card-text mb-0"><span className="quotation-info">Grand Total:</span> {order.grandTotal}</p>
                        <p className="card-text mb-0"><span className="quotation-info">Advance Paid:</span> {order.advancePaid}</p>
                        <p className="card-text mb-0"><span className="quotation-info">Total Paid:</span> {order.totalPaid}</p>
                        <p className="card-text mb-0"><span className="quotation-info">Total Due:</span> {order.totalDue}</p>
                        <p className="card-text mb-0"><span className="quotation-info">Order Date:</span> {order.orderDate}</p>
                        <p className="card-text mb-0"><span className="quotation-info">Delivery Date:</span> {order.expectedDate}</p>
                    </div>
                </div>
            </Col>
            ))}
          </Row>
          </div> : <Loader/>}
        </Container>
        <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
        />
        {/* Decline Order Modal Start */}
       <Modal 
            size="md"
            centered
            show={show} 
            onHide={modalClose}>
                <Formik
                    validationSchema={validationSchema}
                    initialValues={{
                    reason: "",
                    }}
                >
                {({values, setFieldValue}) => (
                    <Form onSubmit={(e) => onSubmit(e)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Decline Quotation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <label>Decline Reason</label>
                        <Field name="reason" as="textarea" className="form-control" value={reason} onChange={(e) => onChange(e)} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={modalClose}>Close</Button>
                        <Button variant="primary" type="submit">Submit</Button>
                    </Modal.Footer>
                    </Form>
                )}
                </Formik>
            </Modal>
        {/* Decline Order Modal End */}
    </>

  )
}

const mapStateToProps = (state) => {

  return {
     currentIntegration: state.approvedOrder.currentIntegration,
     isLoading: state.approvedOrder.isLoading,
   }
 }

 export default connect(mapStateToProps,{getApprovedOrder,deleteOrder,declineOrder})(ApprovedOrder);