import {
    RETRIEVE_CONSOLIDATED_REPORT
} from "../ConsolidatedReport/actiontype";

import { toast } from 'react-toastify';
import api from "../../../api";



export const getConsolidatedReport= (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  try {
    const res = await api.get(`/report/consolidatedReport`);
   
    
    dispatch({
      type: RETRIEVE_CONSOLIDATED_REPORT,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_CONSOLIDATED_REPORT,
      payload: [],
    });
  }
};



