import React, { useState } from 'react';
import { MenuItems } from './MenuItems';
import { NavLink } from 'react-router-dom';

function Dropdown() {
  const [click2, setClick2] = useState(false);

  const handleClick2 = () => setClick2(!click2);
  const closeMobileMenu = () => setClick2(!click2);
  return (
    <>
      <ul
        onClick={handleClick2}
        className={click2 ? 'cus-dropdown visible' : 'cus-dropdown'}
      >
        {MenuItems.map((item, index) => {
          return (
            <li key={index}
              className='menu-list'
              //onClick={handleClick2}
            >
              <NavLink
                activeclassName="active"
                className={item.cName}
                to={item.path}
                //onClick={closeMobileMenu}
              >
                <span className="material-icons">arrow_right_alt</span> {item.title}
              </NavLink>
            </li>
          );
        })}
      </ul>
    </>
  );
}

export default Dropdown;
