import {
    CREATE_CUSTOMERS,
    RETRIEVE_CUSTOMERS,
    DELETE_CUSTOMERS,
    RETRIEVE_SINGLE_CUSTOMERS,
    UPDATE_CUSTOMERS
} from "../Customers/actiontype";

import { toast } from 'react-toastify';
import api from "../../../api";

export const customer = (registerData) => async (dispatch) => {
  const body = JSON.stringify(registerData);
  
  try {
    const res = await api.post('/user/addCustomer', body);
   
    if (res.status === 200) {
      toast.success('Customer Added Successfully');
        dispatch({
            type: CREATE_CUSTOMERS,
            payload: res.data.data.info[0],
          });
        }
      } catch (err) {
    
    toast.warn('Invalid inputs! Customer Not Added');
    // dispatch({
    //   type: REGISTER_FAIL,
    // });
  }
};

export const getCustomer = (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  try {
    const res = await api.get(`/user/customer`);
    dispatch({
      type: RETRIEVE_CUSTOMERS,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_CUSTOMERS,
      payload: [],
    });
  }
};

export const getSingleCustomer = (id) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  try {
    const res = await api.get(`/user/getCustomer/` + id);
   
    dispatch({
      type: RETRIEVE_SINGLE_CUSTOMERS,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_SINGLE_CUSTOMERS,
      payload: [],
    });
  }
};

export const updateCustomer = (registerData) => async (dispatch) => {
  const body = JSON.stringify(registerData);
  try {
    const res = await api.post('/user/updateCustomer', body);
   console.log(res);
    if (res.status === 200) {
      toast.success('Customer Updated Successfully');
        dispatch({
            type: UPDATE_CUSTOMERS,
            payload: res.data.data,
          });
        }
      } catch (err) {
    
    //toast.warn('Invalid inputs! Customer Not Updated');
  }
};


export const deleteCustomer = (id) => async (dispatch) => {
  try {
    const res = await api.delete('/user/deleteCustomer/' + id);
   
    if (res.status === 200) {
      toast.success(res.data.message);
      dispatch({
        type:  DELETE_CUSTOMERS,
        payload: res.data.data.info,
      });
      
    }
  } catch (error) {
    
    toast.error('Failed to delete');
    dispatch({
      type:  DELETE_CUSTOMERS,
      payload: [],
    });
  }
};