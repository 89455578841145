import React,{useEffect,useState} from 'react';
import { Row, Col, Container, Button, Card, Badge} from 'react-bootstrap';
import Zoom from 'react-medium-image-zoom';
import { connect } from "react-redux";
import {getCartOrder,placeOrder} from '../../redux/CP/PlaceOrder/action';
import { Formik, Form, Field } from "formik";
import { useNavigate } from 'react-router-dom';

const OrderList = ({ getCartOrder,currentIntegration,totalqty,totalamount,placeOrder,isUpdate })  =>  {  useEffect(() => {
  getCartOrder();
}, []);
  
const navigate = useNavigate();
useEffect(()=>{
  if(isUpdate)
  {
    navigate('/create-cp-order');
  }
});

  return (
    <>
          <Container fluid className="main-content">
            <Row className='mb-3'>
                <Col lg={'6'}>
                  <h3 className="main-title">Order List</h3>
                </Col>
                <Col lg={'6'}>
                  <h5 className='mb-0 pt-1 ml-2 cart-quantity text-primary' style={{textAlign: "right"}}>
                    <i class="ri-shopping-cart-2-line"></i>
                    <span className="bg-danger text-white qty notification-icon">{totalqty}</span>
                  </h5>
                </Col>
            </Row>
            <Row>
              <Col>
                <Card>
                  <Card.Body>
                    <Formik
                      initialValues={{
                      name: "",
                      mobile: "",
                      email: "",
                      address: "",
                      }}
                      onSubmit={(values) => {
                        placeOrder(values);
                      }}
                    >
                      {({ values }) => (
                        <Form>
                          <Row>
                            <Col lg={12} sm={12} className='mb-2'>
                                <h5>Order Detail:</h5>
                            </Col>
                            <Col lg={12}>
                              <div className="line"></div>
                            </Col>
                            <Col lg={12} sm={12} className='mb-2'>
                                <h5>Customer Detail:</h5>
                            </Col>
                            <Col lg={3} sm={12}>
                              <label>Customer Name</label>
                              <Field name="name" type="text" className="form-control"/>
                            </Col>
                            <Col lg={3} sm={12}>
                              <label>Customer Mobile</label>
                              <Field name="mobile" type="number" className="form-control"/>
                            </Col>
                            <Col lg={3} sm={12}>
                              <label>Customer Email</label>
                              <Field name="email" type="text" className="form-control"/>
                            </Col>
                            <Col lg={3} sm={12}>
                              <label>Address</label>
                              <Field name="address" type="text" className="form-control"/>
                            </Col>
                            <Col lg={12}>
                              <div className="line"></div>
                            </Col>
                          </Row>
                          {/* <Row className='mt-4'>
                            <Col>
                              <Button variant="primary" type ="submit" style={{float: 'right'}} > Next </Button>
                            </Col>
                          </Row> */}
                          <Row className='mb-2'>
                            <Col>
                              <div className='d-flex justify-content-between'>
                                <h5>Place Order Detail</h5>
                              </div>
                            </Col>
                          </Row>
                          <Row className='mt-4 mb-4'>
                            {currentIntegration && currentIntegration.map((cpOrder, index) => (
                              <Col lg="3" key={index}>
                                  <Card className="card product">
                                  <Zoom><Card.Img variant="top" src={process.env.REACT_APP_IMAGE_URL+'/'+cpOrder.image} alt={cpOrder.image} /></Zoom>
                                  <Card.Body>
                                      <Card.Title className='d-flex justify-content-between'>
                                          <p className='mb-0'>{cpOrder.model} <span><Badge bg="info">{cpOrder.qty}</Badge></span></p> 
                                      </Card.Title>
                                      <p className="card-text mb-0"><span className="quotation-info">RDP:</span> <Badge bg="success">&#8377; {cpOrder.rdp}</Badge></p>
                                      <p className="card-text mb-0"><span className="quotation-info">Product Category:</span>{cpOrder.cateory}</p>
                                      <p className="card-text mb-0"><span className="quotation-info">Leather Category:</span>{cpOrder.leather_category}</p>
                                      <p className="card-text mb-0"><span className="quotation-info">Leather Type:</span>{cpOrder.leather_type}</p>
                                      <p className="card-text mb-0"><span className="quotation-info">Colour Code:</span>{cpOrder.leather_colour}</p>
                                      <p className="card-text mb-0"><span className="quotation-info">Item Colour:</span>{cpOrder.colour_code}</p>
                                  </Card.Body>
                                  </Card>
                              </Col>
                            ))}
                          </Row>
                          <Row>
                            <Col>
                              <h4 className='pt-1 mb-0 text-right'><Badge bg="secondary">&#8377; {totalamount}</Badge></h4>
                            </Col>
                          </Row>
                          <Row className='mt-4'>
                            <Col>
                              <Button variant="primary" type="submit" style={{float: 'right'}}> Place Order </Button>
                            </Col>
                          </Row>
                      </Form>
                      )}
                    </Formik>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
         </Container>
    </>
  )
}


const mapStateToProps = (state) => {
  console.log(state.cpPlaceOrder.currentIntegration.product_list);
    return {
       currentIntegration: state.cpPlaceOrder.currentIntegration.product_list,
       totalqty: state.cpPlaceOrder.currentIntegration.qty,
       totalamount: state.cpPlaceOrder.currentIntegration.amount,
       isUpdate: state.cpPlaceOrder.isUpdate,
     }
   }
  
export default connect(mapStateToProps,{getCartOrder,placeOrder})(OrderList);
