import {
    ORDER_DETAIL,
    UPDATE_ORDER,
    GET_IMPORTED_READY,
    GET_IMPORTED_ORDER,
    GET_TOTAL_AMOUNT,
    GET_ORDER_NAME,
    READY_NET_AMOUNT,
    GROSS_TOTAL,
    BALANCE_AMT,
    EDIT_PRODUCT,
    EDIT_PRODUCT_NAME
} from "./actiontype";

const INITIAL_STATE = {
    orders:[],
    imported_ready: [],
    imported_order: [],
    total_amount:'',
    order_name : '',
    ready_net_amount:0,
    gross_total:0,
    balance:0,
    edit_product:[],
    edit_product_name:''
  };

  const editOrderReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
   
    switch (type) {
      case ORDER_DETAIL:
        return {
          ...state,
          orders: payload,
        };
      case GET_IMPORTED_READY:
        return {
          ...state,
          imported_ready: payload,
        };
      case GET_IMPORTED_ORDER:
        return {
          ...state,
          imported_order: payload,
        };
      case GET_TOTAL_AMOUNT:
        return {
          ...state,
          total_amount: payload,
        };
      case GET_ORDER_NAME:
        return {
          ...state,
          order_name: payload,
        };
      case READY_NET_AMOUNT:
        return {
          ...state,
          ready_net_amount: payload,
        };
      case GROSS_TOTAL:
        return {
          ...state,
          gross_total: payload,
        };
      case BALANCE_AMT:
        return {
          ...state,
          balance: payload,
        };
      case EDIT_PRODUCT:
        return {
          ...state,
          edit_product: payload,
        };
      case EDIT_PRODUCT_NAME:
        return {
          ...state,
          edit_product_name: payload,
        };
      default:
        return state;
    }
  };
  
  
  export default editOrderReducer;