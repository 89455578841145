import {
    RETRIEVE_DASHBOARD,
    DUE_DELIVERY,
    DUE_ORDER,
    BRANCH_DETAIL,
    SALE_ORDER_WISE,
    SALE_INCENTIVE_WISE,
    LAST_MONTH_ACHIEVER,
    RETRIEVE_SINGLE_DUEORDER,
    RETRIEVE_PRODUCT_LIST,
    RETRIEVE_STOCK_QUERY_DETAIL
} from "../Dashboard/actiontype";

import api from "../../api";

export const getDashboard= (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  try {
    const res = await api.get(`/dashboard/cardViewAdmin`);
    dispatch({
      type: RETRIEVE_DASHBOARD,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: RETRIEVE_DASHBOARD,
      payload: [],
    });
  }
};

export const getDueDelivery= (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  try {
    const res = await api.get(`/dashboard/dueDelivery`);
    dispatch({
      type: DUE_DELIVERY,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: DUE_DELIVERY,
      payload: [],
    });
  }
};

export const getDueOrder= (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  try {
    const res = await api.get(`/dashboard/dueOrder`);
    dispatch({
      type: DUE_ORDER,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: DUE_ORDER,
      payload: [],
    });
  }
};

export const getBranchChart= (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  try {
    const res = await api.get(`/dashboard/branchChart`);
    dispatch({
      type: BRANCH_DETAIL,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: BRANCH_DETAIL,
      payload: [],
    });
  }
};


export const getSaleOrderWise= (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  try {
    const res = await api.get(`/dashboard/orderWise`);
    dispatch({
      type: SALE_ORDER_WISE,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: SALE_ORDER_WISE,
      payload: [],
    });
  }
};

export const getSaleIncentiveWise= (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  try {
    const res = await api.get(`/dashboard/incentiveWise`);
    dispatch({
      type: SALE_INCENTIVE_WISE,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: SALE_INCENTIVE_WISE,
      payload: [],
    });
  }
};

export const getAchiever= (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  try {
    const res = await api.get(`/dashboard/lastMonthAchieverAdmin`);
    dispatch({
      type: LAST_MONTH_ACHIEVER,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: LAST_MONTH_ACHIEVER,
      payload: [],
    });
  }
};


export const getDueOrderDetail = (id) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  try {
    const res = await api.get(`/dashboard/getDueOrderDetail/` + id);
   
    dispatch({
      type: RETRIEVE_SINGLE_DUEORDER,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_SINGLE_DUEORDER,
      payload: [],
    });
  }
};

export const getProductList = (id) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  
  try {
    const res = await api.post(`/dashboard/stockQuery`, id);
    console.log(res);
    dispatch({
      type: RETRIEVE_PRODUCT_LIST,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_PRODUCT_LIST,
      payload: [],
    });
  }
};

export const getStockDetail= (id) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
 
  try {
   
    const res = await api.get(`/peddler/productStockDetail/` +id);
    dispatch({
      type:  RETRIEVE_STOCK_QUERY_DETAIL,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type:  RETRIEVE_STOCK_QUERY_DETAIL,
      payload: [],
    });
  }
};