import {
    CREATE_CONSOLIDATED_REPORT,
    RETRIEVE_CONSOLIDATED_REPORT,
    UPDATE_CONSOLIDATED_REPORT,
    DELETE_CONSOLIDATED_REPORT
} from "../ConsolidatedReport/actiontype";

const INITIAL_STATE = {
  currentIntegration: [],
  isLoading: false,
};

const  consolidatedReportReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case CREATE_CONSOLIDATED_REPORT:
      return {
        ...state
      };
    case RETRIEVE_CONSOLIDATED_REPORT:
      return {
        ...state,
        currentIntegration: payload,
        isLoading: true,
      };
    case UPDATE_CONSOLIDATED_REPORT:
      return state.map((consolidatedReport) => {
        if (consolidatedReport.id === payload.id) {
          return {
            ...consolidatedReport,
            ...payload,
          };
        } else {
          return consolidatedReport;
        }
      });
    case DELETE_CONSOLIDATED_REPORT:
      return state.filter(({ id }) => id !== payload.id);
    default:
      return state;
  }
};
export default consolidatedReportReducer;