import {
    CREATE_APPROVE_PAYMENT,
    RETRIEVE_APPROVE_PAYMENT,
} from "../ApprovePayment/actiontype";

const INITIAL_STATE = {
  currentIntegration: [],
};

const approvePaymentReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case CREATE_APPROVE_PAYMENT:
      return {
        ...state
      };
    case RETRIEVE_APPROVE_PAYMENT:
      return {
        ...state,
        currentIntegration: payload,
      };
      default:
        return state;
  }
};
export default approvePaymentReducer;