import {
  RETRIEVE_STOCK_OUT_SUMMARY,
} from "../../BranchDashboard/StockOut/actiontype";

const INITIAL_STATE = {
  stockOutSummary: [],
  isLoading: false,
};

const branchStockOutReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
 
  switch (type) {
    case  RETRIEVE_STOCK_OUT_SUMMARY:
      return {
        ...state,
        stockOutSummary: payload,
        isLoading: true,
      };
      default:
        return state;
  }
};
export default branchStockOutReducer;