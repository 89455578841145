import {
  RETRIEVE_ASSIGN_PRODUCT,
  SET_CP_USER,
  ASSIGN_CP_PRODUCT
} from "../AssignProduct/actiontype";

import { toast } from 'react-toastify';
import api from "../../../api";

//  Get Brand Name
export const getCpUserList = (data) => async (dispatch) => {
 try {
   const res = await api.get(`/cp/getCpUser`);
  
       dispatch({
         type: SET_CP_USER,
         payload: res.data.data,
       });
 } catch (err) { }
};


export const getAssignProduct = (data) => async (dispatch) => {
  
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  try {
    const res = await api.get(`/productImported/productImported`);
   
    dispatch({
      type: RETRIEVE_ASSIGN_PRODUCT,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_ASSIGN_PRODUCT,
      payload: [],
    });
  }
};


export const  assignCpProduct= (assign) => async (dispatch) => {
  
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  try {
    let data = {
      cp_id: assign.cpUser,
      products : assign.product
   };
    const res = await api.post(`/cp/assignProductCP`, data);
    if (res.status === 200) {
      toast.success(res.data.message);
    dispatch({
      type:  ASSIGN_CP_PRODUCT,
      payload: res.data.data,
    });
  }
  } catch (err) {
    
    dispatch({
      type:  ASSIGN_CP_PRODUCT,
      payload: [],
    });
  }
};
