import * as React from "react";
import {useState, useEffect} from 'react'
import { Row, Col, Container, Badge, Dropdown } from 'react-bootstrap';
import MaterialTable from 'material-table';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { connect } from "react-redux";
import {getOutForDelivery, paydelace} from '../../../redux/Order/OutForDelivery/action';
import CreateOrder from "../../../components/BranchList/CreateOrder";
import { useNavigate } from 'react-router-dom';
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import Loader from "../../../components/Loader/Loader";

const OutForDelivery = ({ getOutForDelivery,currentIntegration,paydelace,isLoading }) =>  {  useEffect(() => {
  getOutForDelivery();
}, []);
  
const [visible, setVisible] = useState(false);
const handleVisibility = () => {
    setVisible(prev => !prev);
};

const [loading, setLoading] = useState(false);
useEffect(() => {
    if (isLoading) {
        setLoading(true);
    }
  });

const [tableData, setTableData] = useState([]);
useEffect(() => {
if(currentIntegration)
{
    setTableData(currentIntegration); 
}
}, [currentIntegration])

const columns = [
{ title: "Order No. | Created By", field: "orderNo" },
// { title: "Created By ", field: "createdBy" },
{ title: "Customer Detail", field: "customerDetail" },
// { title: "Customer Mobile", field: "customerMobile" },
{ title: "Qty", field: "qty" },
{ title: "Net Total Amount", field: "totalAmount" },
{ title: "Total Discount", field: "totalDiscount" },
{ title: "Grand Total", field: "grandTotal" },
{ title: "Advance Paid", field: "advancePaid" },
{ title: "Total Paid", field: "totalPaid" },
{ title: "Date Of Order", field: "orderDate" },
{ title: "Expected Date Of Order", field: "expectedDate" },

]
const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', type: ''})
const navigate = useNavigate();

const editOrder = async (e) => {
  navigate('/edit-order/'+e);
}
const viewOrder = async (e) => {
  navigate('/detail-View-order/'+e);
}
const markAsDone = async (e) => {
  
  setConfirmDialog({
  isOpen: true,
  title: 'Are You Sure To Mark This Order as Delivered?',
  type: true,
  onConfirm: () => { 
      paydelace(e);
      setConfirmDialog({ ...confirmDialog, isOpen: false })
  }
  })
}      
  return (
    <>
      <Container fluid className="main-content">
        <Row>
          <Col>
              <h3 className="main-title">Out For Delivery</h3>
          </Col> 
          <Col>
              <div style={{float: "right"}} className="d-flex">
                <div className="d-flex justify-content-between d-flex d-sm-none">
                  <div className="magnifierContainer">
                    <button type="button" className="btn btn-md btn-warning text-white roundedcircle mr-3" onClick={handleVisibility}><i className="fa-solid fa-search"></i></button>
                  </div>
                </div>
                <CreateOrder/>
              </div>
          </Col>
          <Col lg={"12"}>
            <div className='d-block d-sm-none mt-2'>
              <div className={visible ? "open" : "close"}>
                  <input placeholder="Search..." type="text" className="form-control is-open mr-3 mb-0 search-input"/>
              </div>
            </div>
          </Col>
        </Row>
        {loading ? <div>
        <Row className="d-none d-sm-block mt-2">
          <Col lg={"12"} className="mt-3">
            <div style={{display: 'table', tableLayout:'fixed', width:'100%'}}>
            <MaterialTable columns={columns} data={tableData}
                 actions={[
                  {
                    icon: () => <VisibilityIcon color={'primary'} />,
                    tooltip: 'View',
                    onClick: (event, rowData) => viewOrder(rowData.id)
                  },
                  
                  {
                    icon: () => <EditIcon color={'secondary'} />,
                    tooltip: 'Edit',
                    onClick: (event, rowData) => editOrder(rowData.id)
                  },
                  
                  {
                    icon: () => <CheckIcon color={'success'} />,
                    tooltip: 'Paydelace',
                    onClick: (event, rowData) => markAsDone(rowData.id)
                  }
                ]}
                options={{
                sorting: true, search: true,
                paging: true, pageSizeOptions: [10, 25, 50, 100], pageSize: 10,
                exportButton: true,
                exportAllData: true, exportFileName: "Out For Delivery", actionsColumnIndex: -1,
                // headerStyle: { background: "#f44336",color:"#fff"}
                }}
                title="Out For Delivery"
            />
            </div>
          </Col>
        </Row>
        <Row className="d-block d-sm-none mt-2">
          {currentIntegration.map((order, index) => (
          <Col xs={"12"} key={index}>
              <div className="card mb-2">
                  <div className="card-header d-flex justify-content-between pb-0">
                      <h6>{order.orderNo}	 <Badge bg="danger" text="light" className="ml-2">{order.qty}</Badge></h6>
                      <Dropdown>
                      <Dropdown.Toggle variant="light" id="dropdown-basic">
                          <i className="ri-more-2-line"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                          <Dropdown.Item onClick={() => viewOrder(order.id)}><VisibilityIcon color={'primary'} /> View</Dropdown.Item>
                          <Dropdown.Item onClick={() => editOrder(order.id)}><EditIcon color={'secondary'} /> Edit</Dropdown.Item>
                          <Dropdown.Item onClick={() => markAsDone(order.id)}><CheckIcon color={'success'} /> Paydelace</Dropdown.Item>
                      </Dropdown.Menu>
                      </Dropdown>
                  </div>
                  <div className="card-body pt-1">
                      <p className="card-text mb-0"><span className="quotation-info">Customer Detail:</span> {order.customerDetail}</p>
                      <p className="card-text mb-0"><span className="quotation-info">Net Total Amount:</span> {order.totalAmount}</p>
                      <p className="card-text mb-0"><span className="quotation-info">Total Discount:</span> {order.totalDiscount}</p>
                      <p className="card-text mb-0"><span className="quotation-info">Grand Total:</span> {order.grandTotal}</p>
                      <p className="card-text mb-0"><span className="quotation-info">Advance Paid:</span> {order.advancePaid}</p>
                      <p className="card-text mb-0"><span className="quotation-info">Total Paid:</span> {order.totalPaid}</p>
                      <p className="card-text mb-0"><span className="quotation-info">Order Date:</span> {order.orderDate}</p>
                      <p className="card-text mb-0"><span className="quotation-info">Delivery Date:</span> {order.expectedDate}</p>
                  </div>
              </div>
          </Col>
          ))}
        </Row>
        </div> : <Loader/>}
      </Container>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />
    </>

  )
}

const mapStateToProps = (state) => {

  return {
     currentIntegration: state.outForDelivery.currentIntegration,
     isLoading: state.outForDelivery.isLoading,
   }
 }

 export default connect(mapStateToProps,{getOutForDelivery,paydelace})(OutForDelivery);