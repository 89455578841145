import React from 'react';
import { Row, Col, Container, Card } from 'react-bootstrap';
import MaterialTable from 'material-table';
import {useState, useEffect} from 'react';
import ProgressBar from "@ramonak/react-progress-bar";
import './dashboard.css';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { getDashboardSale, getActiveOrder, getActiveQuotation} from '../../redux/DashboardSales/action';

const DashboardSale = ({ getDashboardSale, dashboard, getActiveOrder, order, getActiveQuotation, quotation }) =>  {  useEffect(() => {
  getDashboardSale();
  getActiveOrder();
  getActiveQuotation();
 }, []);
 
  
 const [tableData, setTableData] = useState([]);
 useEffect(() => {
   if(order)
   {
     setTableData(order); 
   }
 }, [order])
    
  const columns = [
  { title: "Order No.", field: "orderNo" },
  { title: "Created At ", field: "date" },
  { title: "Expected Delivery", field: "edd" },
  { title: "No. Of Product", field: "no_of_item" },
  { title: "Status", field: "status" },
  ]
  const [tableData2, setTableData2] = useState([]);
 useEffect(() => {
   if(quotation)
   {
     setTableData2(quotation); 
   }
 }, [quotation])
    
  const columns2 = [
  { title: "Quotation No.", field: "quotationNo" },
  { title: "Created At ", field: "date" },
  { title: "No. Of Product", field: "no_of_item" },
  { title: "Status", field: "status" },
  ]

  
  return(
    <>
      <Container fluid className="main-content">
        <Row>
          <Col className='mb-0'>
              <h3 className="main-title mb-0">Welcome {localStorage.getItem('name')}</h3>
              <p style={{color: "#f53163"}}>Sales Executive</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <Link to="/delivered-order">
              <Card>
                <Card.Body>
                  <div className='d-flex justify-content-between'>
                    <p className='text-muted'>Delivered Orders</p>
                    <p className='text-primary'><i className="ri-line-chart-line"></i></p>
                  </div>
                  <Card.Title className='text-danger'>{dashboard.delivered_order}</Card.Title>
                  <ProgressBar 
                    completed={80}
                    bgColor="#eb6f33"
                    height="6px"
                    borderRadius="0"
                    labelColor="transparent"
                  />
                </Card.Body>
              </Card>
            </Link>
          </Col>
          <Col>
            <Link to="/approved-order"> 
                <Card>
                  <Card.Body>
                    <div className='d-flex justify-content-between'>
                      <p className='text-muted'>Running Order</p>
                      <p className='text-info'><i className="ri-exchange-dollar-line"></i></p>
                    </div>
                    <Card.Title className='text-danger'>{dashboard.running_order}</Card.Title>
                    <ProgressBar 
                      completed={70}
                      bgColor="#03c895"
                      height="6px"
                      borderRadius="0"
                      labelColor="transparent"
                    />
                  </Card.Body>
                </Card>
              </Link>
          </Col>
          <Col>
          <Link to="/payment">
              <Card>
                <Card.Body>
                  <div className='d-flex justify-content-between'>
                    <p className='text-muted'>Dues Amount</p>
                    <p className='text-warning'><i className="ri-wallet-2-line"></i></p>
                  </div>
                  <Card.Title className='text-danger'>₹ {dashboard.due}</Card.Title>
                  <ProgressBar 
                    completed={50}
                    bgColor="#d30639"
                    height="6px"
                    borderRadius="0"
                    labelColor="transparent"
                  />
                </Card.Body>
              </Card>
            </Link>
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col lg={"12"}>
            <div style={{display: 'table', tableLayout:'fixed', width:'100%'}} className='dashboard-table'>
              <MaterialTable columns={columns} data={tableData}
                  options={{
                  sorting: true, search: true,
                  paging: true, pageSizeOptions: [10, 25, 50, 100], pageSize: 10,
                  exportButton: true,
                  exportAllData: true, exportFileName: "Active Orders List", actionsColumnIndex: -1,
                  }}
                  title="Active Orders List"
              />
            </div>
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col lg={"12"}>
            <div style={{display: 'table', tableLayout:'fixed', width:'100%'}} className='dashboard-table'>
              <MaterialTable columns={columns2} data={tableData2}
                  options={{
                  sorting: true, search: true,
                  paging: true, pageSizeOptions: [5, 10, 25, 50, 100], pageSize: 5,
                  exportButton: true,
                  exportAllData: true, exportFileName: "Active Quotations", actionsColumnIndex: -1,
                  }}
                  title="Active Quotations"
              />
            </div>
          </Col>
        </Row>
      </Container>

    </>
  ) 
}

const mapStateToProps = (state) => {
  //
    return {
       dashboard: state.dashboardsales.dashboard,
       order: state.dashboardsales.order,
       quotation: state.dashboardsales.quotation,
       
     }
   }
  
   export default connect(mapStateToProps,{getDashboardSale, getActiveOrder, getActiveQuotation})(DashboardSale);