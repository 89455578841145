import {
  RETRIEVE_CURRENT_STOCK
} from "../CurrentStock/actiontype";

const INITIAL_STATE = {
  currentIntegration: [],
  isLoading: false,
};

const branchCurrentStockReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
 
  switch (type) {
    case  RETRIEVE_CURRENT_STOCK:
      return {
        ...state,
        currentIntegration: payload,
        isLoading: true,
      };
      default:
        return state;
  }
};
export default branchCurrentStockReducer;