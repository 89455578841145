import {
    RETRIEVE_PRODUCTWISE_REPORT
} from "../ProductWise/actiontype";
import api from "../../../api";

export const getProductWiseReport= (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  try {
    const res = await api.get(`/report/productWiseReport`);
   
    
    dispatch({
      type: RETRIEVE_PRODUCTWISE_REPORT,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_PRODUCTWISE_REPORT,
      payload: [],
    });
  }
};



