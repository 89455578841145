import {
    CREATE_EXPECTED_DELIVERY_DATE,
    RETRIEVE_EXPECTED_DELIVERY_DATE
} from "../ExpectedDeliveryDate/actiontype";

import { toast } from 'react-toastify';
import api from "../../../api";
let usertype = JSON.parse(localStorage.getItem('usertype'));
let userid = JSON.parse(localStorage.getItem('userid'));


export const getExpectedDeliveryDate= (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  let data = {
    usertype : usertype,
    userid : userid
  };

  try {
    const res = await api.post(`/order/expectedDeliveryDate`,data);
   
    
    dispatch({
      type: RETRIEVE_EXPECTED_DELIVERY_DATE,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_EXPECTED_DELIVERY_DATE,
      payload: [],
    });
  }
};



