import {
  RETRIEVE_ORDERS
} from "../Orders/actiontype";

const INITIAL_STATE = {
  currentIntegration: [],
  isLoading: false,
};

const orders = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case RETRIEVE_ORDERS:
      return {
        ...state,
        currentIntegration: payload,
        isLoading: true,
      };
    default:
      return state;
  }
};
export default orders;