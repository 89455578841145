import React, {useState, useEffect} from 'react'
import { Row, Col, Container, Button, Card, Accordion, Form } from 'react-bootstrap';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { connect } from "react-redux";
import {getPendingToDispatch} from '../../../redux/Dispatch/PendingToDispatch/action';



const PendingToDispatch = ({ getPendingToDispatch,currentIntegration, readyProduct }) =>  {  useEffect(() => {
    const serial_no_data = window.location.pathname.split("/").pop();
    getPendingToDispatch(serial_no_data);
}, []);


  return (
    <>
        <Container fluid className="main-content">
            <Row>
                <Col>
                    <h3 className="main-title mb-4">Order - {currentIntegration.orderNo}</h3>
                </Col>
                {/* <Col>
                    <div className='d-flex justify-content-end'>
                        <button type="button" className="btn btn-md btn-primary mb-0" style={{marginRight: "20px"}}><i class="ri-printer-line"></i> Print</button>
                        <button type="button" className="btn btn-md btn-warning text-white"><i class="ri-mail-send-line"></i> Mail</button>
                    </div>
                </Col> */}
            </Row>
            <Row>
                <Col>
                    <Card>
                        <Card.Body>
                            <Row className='mt-3'>
                                <Col lg={12}>
                                    <h6 className="card-title mb-3 ml-3">{currentIntegration.orderNo}</h6>
                                </Col>
                                <Col lg={12}>
                                    <div className='d-flex justify-content-between'>
                                        <p className="mb-0"><span style={{fontWeight: "500"}}>Date of Booking :</span> {currentIntegration.order_date}</p>
                                        <p className="mb-0"><span style={{fontWeight: "500"}}>Expected Date of Delivery :</span> {currentIntegration.edd}</p>
                                    </div>
                                </Col>
                                <Col>
                                    <div className='line'></div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={12}>
                                    <div className='d-lg-flex d-sm-block justify-content-between' >
                                        <div className='mb-3'>
                                            <h6>Customer:</h6>
                                            <p className='mb-0'>Name: {currentIntegration.customer_name}</p>
                                            <p className='mb-0'>Mobile: {currentIntegration.customer_mobile}</p>
                                            <p className='mb-0'>Address: {currentIntegration.customer_address}</p>
                                            <p className='mb-0'>Email: {currentIntegration.customer_email}</p>
                                        </div>
                                        <div className='mb-3'>
                                            <h6>Consultant:</h6>
                                            <p className='mb-0'>Cordinator: {currentIntegration.cordinator}</p>
                                            <p className='mb-0'>Reffered by : {currentIntegration.reffered_by}</p>
                                            <p className='mb-0'>Approved by: {currentIntegration.approved_by}</p>
                                        </div>
                                    </div>
                                </Col>
                                <Col>
                                    <div className='line mt-0'></div>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6}>
                                    <Form.Label htmlFor="">Vehicle No:</Form.Label>
                                    <Form.Control type="text" id="" />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className='mt-4'>
                <Col>
                    <Card>
                        <Card.Body>
                            <Row className='mt-3 mb-3'>
                                <Col>
                                    <Accordion defaultActiveKey="0">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>Imported Ready Product</Accordion.Header>
                                            <Accordion.Body>
                                                <Table striped bordered hover>
                                                    <Thead>
                                                        <Tr>
                                                            <Th className='custom-table-header'>#</Th>
                                                            <Th className='custom-table-header'>NO OF PCKT</Th>
                                                            <Th className='custom-table-header'>NAME OF ITEMS</Th>
                                                            <Th className='custom-table-header'>MODEL NO</Th>
                                                            <Th className='custom-table-header'>SIZE</Th>
                                                            <Th className='custom-table-header'>REMARKS</Th>
                                                            <Th className='custom-table-header'>QTY</Th>
                                                            <Th className='custom-table-header'>UNIT</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        {readyProduct && readyProduct.map((dispatch, index) => (
                                                            <Tr key={index}>
                                                                <Td className='custom-table'>{index+1}</Td>
                                                                <Td className='custom-table'><Form.Control type="number" id="package" placeholder="10" style={{width: "60px", margin: "0px"}} /></Td>
                                                                <Td className='custom-table'>SOFA </Td>
                                                                <Td className='custom-table'>SOFA2K37 </Td>
                                                                <Td className='custom-table'>NA</Td>
                                                                <Td className='custom-table'>L Shape sofa</Td>
                                                                <Td className='custom-table'>1</Td>
                                                                <Td className='custom-table'>NOS</Td>
                                                            </Tr>
                                                        ))}
                                                    </Tbody>
                                                </Table>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>Imported Order Product</Accordion.Header>
                                            <Accordion.Body>
                                                <Table striped bordered hover>
                                                    <Thead>
                                                        <Tr>
                                                            <Th className='custom-table-header'>#</Th>
                                                            <Th className='custom-table-header'>NO OF PCKT</Th>
                                                            <Th className='custom-table-header'>NAME OF ITEMS</Th>
                                                            <Th className='custom-table-header'>MODEL NO</Th>
                                                            <Th className='custom-table-header'>SIZE</Th>
                                                            <Th className='custom-table-header'>REMARKS</Th>
                                                            <Th className='custom-table-header'>QTY</Th>
                                                            <Th className='custom-table-header'>UNIT</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        <Tr>
                                                            <Td className='custom-table'>1</Td>
                                                            <Td className='custom-table'><Form.Control type="number" id="package" placeholder="10" style={{width: "60px", margin: "0px"}} /></Td>
                                                            <Td className='custom-table'> </Td>
                                                            <Td className='custom-table'>SOFA2K37 </Td>
                                                            <Td className='custom-table'>NA</Td>
                                                            <Td className='custom-table'>L Shape sofa</Td>
                                                            <Td className='custom-table'>1</Td>
                                                            <Td className='custom-table'>NOS</Td>
                                                        </Tr>
                                                    </Tbody>
                                                </Table>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </Col>
                                <Col lg={12}>
                                    <div className='mt-4' style={{float: "right"}}> 
                                        <Button variant="primary">Submit</Button>
                                    </div>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    </>
  )
}

const mapStateToProps = (state) => {
    return {
       currentIntegration: state.pendingToDispatch.currentIntegration,
       readyProduct: state.pendingToDispatch.currentIntegration.ready,
     }
   }
  
export default connect(mapStateToProps,{getPendingToDispatch})(PendingToDispatch);