import {
   RETRIEVE_PENDING_To_DISPATCH
} from "../PendingToDispatch/actiontype";

import api from "../../../api";



export const getPendingToDispatch= (serial_no) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  let data = {
    serial_no : serial_no,
  };
  try {
    const res = await api.post(`/dispatchSection/dispatchOrderDetailItem`, data);
    dispatch({
      type:RETRIEVE_PENDING_To_DISPATCH,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type:RETRIEVE_PENDING_To_DISPATCH,
      payload: [],
    });
  }
};



