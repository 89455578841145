import * as React from "react";
import {useState, useEffect} from 'react'
import { Row, Col, Container, Badge, Dropdown } from 'react-bootstrap';
import MaterialTable from 'material-table';
import { connect } from "react-redux";
import {getBranchWiseReport} from '../../redux/Report/BranchWise/action';
import SearchBar from "../../components/SearchBar/SearchBar";
import Loader from "../../components/Loader/Loader";

const BranchWise = ({ getBranchWiseReport,currentIntegration,isLoading }) =>  {  useEffect(() => {
    getBranchWiseReport();
  }, []);

const [visible, setVisible] = useState(false);
const handleVisibility = () => {
    setVisible(prev => !prev);
};

const [loading, setLoading] = useState(false);
useEffect(() => {
    if (isLoading) {
        setLoading(true);
    }
  });

const [tableData, setTableData] = useState([]);
    useEffect(() => {
    if(currentIntegration)
    {
        setTableData(currentIntegration); 
    }
    }, [currentIntegration])

const columns = [
{ title: "Order No.", field: "orderNo" },
{ title: "DOO.", field: "doo" },
{ title: "Customer Detail", field: "customerDetail" },
{ title: "Quantity", field: "qty" },
{ title: "Net Total Amount", field: "totalAmount" },
{ title: "Total Discount", field: "totalDiscount" },
{ title: "Grand Total", field: "grandTotal" },
{ title: "Due Amount", field: "dueAmount" },
{ title: "EDO", field: "edo" },
{ title: "Order Status", field: "orderStatus" },
]
 
// Search for Card view start
const [searchValue, setSearchValue] = useState("");

const filterOrders = ({ orderNo }) => {
  return orderNo.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
};
// Search for Card view end

  return (
    <>
        <Container fluid className="main-content">
            <Row>
                <Col lg={"12"} className='d-flex justify-content-between mb-1'>
                    <h3 className="main-title">Branch Wise Report</h3>
                    <div className=" d-block d-sm-none">
                        <div className="magnifierContainer">
                            <button type="button" className="btn btn-md btn-warning text-white roundedcircle mr-3" onClick={handleVisibility}><i className="fa-solid fa-search"></i></button>
                        </div>
                    </div>
                </Col>
                <Col lg={"12"} className='mb-2 d-block d-sm-none'>
                  <div className={visible ? "open" : "close"}>
                    <SearchBar onSearch={setSearchValue} value={searchValue} />
                  </div>
                </Col>
            </Row>
            { loading ? <div>
            <Row className="d-none d-sm-block">
              <Col lg={"12"}>
                <div style={{display: 'table', tableLayout:'fixed', width:'100%'}}>
                <MaterialTable columns={columns} data={tableData}
                    options={{
                    sorting: true, search: true,
                    paging: true, pageSizeOptions: [10, 25, 50, 100], pageSize: 10,
                    exportButton: true,
                    exportAllData: true, exportFileName: "Branch Wise Report", actionsColumnIndex: -1,
                    // headerStyle: { background: "#f44336",color:"#fff"}
                    }}
                    title="Branch Wise Report"
                />
                </div>
              </Col>
            </Row>
            <Row className="d-block d-sm-none">
            {currentIntegration.filter(filterOrders).map((report, index) => (
              <Col xs={"12"} key={index}>
                  <div className="card mb-2">
                      <div className="card-header d-flex justify-content-between pb-0">
                          <h6>{report.orderNo}	<Badge bg="danger" text="light" className="ml-2">{report.qty}</Badge></h6>
                      </div>
                      <div className="card-body pt-1">
                          <p className="card-text mb-0"><span className="quotation-info">Customer Detail:</span> {report.customerDetail}</p>
                          <p className="card-text mb-0"><span className="quotation-info">Net Amount:</span> {report.totalAmount}</p>
                          <p className="card-text mb-0"><span className="quotation-info">Discount:</span> {report.totalDiscount}</p>
                          <p className="card-text mb-0"><span className="quotation-info">Grand Total:</span> {report.grandTotal}</p>
                          <p className="card-text mb-0"><span className="quotation-info">Due Amount:</span> {report.dueAmount}</p>
                          <p className="card-text mb-0"><span className="quotation-info">Order Date:</span> {report.doo}</p>
                          <p className="card-text mb-0"><span className="quotation-info">Delivery Date:</span> {report.edo}</p>
                          <p className="card-text mb-0"><span className="quotation-info">Order Status:</span> {report.orderStatus}</p>
                      </div>
                  </div>
              </Col>
            ))}
            </Row>
            </div>
                : <Loader/> }
        </Container>
    </>

  )
}

const mapStateToProps = (state) => {
  
    return {
       currentIntegration: state.branchWiseReport.currentIntegration,
       isLoading: state.branchWiseReport.isLoading,
     }
   }
  
export default connect(mapStateToProps,{getBranchWiseReport})(BranchWise);