import React from "react";
import { Row, Col, Card } from "react-bootstrap";

function SearchBar(props) {
  return (
    <>
      <Row>
        <Col>
          <Card>
            <Card.Body>
              <input
                type="text"
                placeholder="Search..."
                onChange={(e) => props.onSearch(e.target.value)}
                value={props.value}
                className="form-control is-open mr-3 mb-0 search-input"
              />
            </Card.Body>
          </Card>
        </Col>
    </Row>
    </>
  );
}

export default SearchBar;
