import {
    RETRIEVE_DASHBOARD,
    RECEIVED_PAYMENT,
    TOP_INCENTIVE
   
} from "../DashboardAccounts/actiontype";


const INITIAL_STATE = {
  dashboard: [],
  receivedpayment: [],
  topincentive: [],
 
};

const dashboardSaleReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  
  switch (type) {
    
    case RETRIEVE_DASHBOARD:
      return {
        ...state,
        dashboard: payload,
      };
    case RECEIVED_PAYMENT:
      return {
        ...state,
        receivedpayment: payload,
      };
    case TOP_INCENTIVE:
      return {
        ...state,
        topincentive: payload,
      };
    
    
    default:
      return state;
  }
};
export default dashboardSaleReducer;