import {
  RETRIEVE_COLUMNER_REPORT
} from "../ColumnerReport/actiontype";


const INITIAL_STATE = {
  currentIntegration: [],
  isLoading: false,

};

const  columnerReportReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    
    case RETRIEVE_COLUMNER_REPORT:
      return {
        ...state,
        currentIntegration: payload,
        isLoading: true,
      };
    default:
      return state;
  }
};
export default columnerReportReducer;