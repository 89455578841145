import {
    RETRIEVE_DELIVERED_ORDER,
    DELETE_DELIVERED_ORDER
} from "../DeliveredOrder/actiontype";

import { toast } from 'react-toastify';
import api from "../../../api";

let usertype = JSON.parse(localStorage.getItem('usertype'));
let userid = JSON.parse(localStorage.getItem('userid'));


export const getDeliveredOrder= (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  let data = {
    usertype : usertype,
    userid : userid
  };
  try {
    const res = await api.post(`/order/deliveredOrder`, data);
   
    
    dispatch({
      type: RETRIEVE_DELIVERED_ORDER,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_DELIVERED_ORDER,
      payload: [],
    });
  }
};

export const deleteDeliveredOrder = (id) => async (dispatch) => {
  try {
    const res = await api.get('/order/deleteOrder/' + id);
   
    if (res.status === 200) {
      toast.success(res.data.message);
      dispatch({
        type:  DELETE_DELIVERED_ORDER,
        payload: res.data.data.info,
      });
      
    }
  } catch (error) {
    
    toast.error('Failed to delete');
    dispatch({
      type:  DELETE_DELIVERED_ORDER,
      payload: [],
    });
  }
};

