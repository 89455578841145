import {
    RETRIEVE_DASHBOARD,
    ACTIVE_ORDERS,
    ACTIVE_QUOTATIONS
   
} from "../DashboardSales/actiontype";

import api from "../../api";

let userid = JSON.parse(localStorage.getItem('userid'));


export const getDashboardSale= (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
 
  try {
    const res = await api.get(`/dashboard/cardViewSale/`+userid);
    dispatch({
      type: RETRIEVE_DASHBOARD,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: RETRIEVE_DASHBOARD,
      payload: [],
    });
  }
};

export const getActiveOrder= (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
 
  try {
    const res = await api.get(`/dashboard/activeOrder/`+userid);
    dispatch({
      type: ACTIVE_ORDERS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: ACTIVE_ORDERS,
      payload: [],
    });
  }
};

export const getActiveQuotation= (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
 
  try {
    const res = await api.get(`/dashboard/activeQuotation/`+userid);
    dispatch({
      type: ACTIVE_QUOTATIONS,
      payload: res.data.data,
    });
  } catch (err) {
    dispatch({
      type: ACTIVE_QUOTATIONS,
      payload: [],
    });
  }
};

