import {
    SET_ORDERNAME,
    SET_QUOTATION,
    SET_CORDINATOR,
    SET_REFFERED,
    SET_PRODUCT,
    SET_LOCATION,
    PRODUCT_NAME,
    GET_IMPORTED_READY,
    GET_IMPORTED_ORDER,
    GET_TOTAL_AMOUNT,
    ORDER_CREATED,
    GROSS_TOTAL,
    BALANCE_AMT,
    EDIT_READY_PRODUCT,
    EDIT_PRODUCT_NAME,
    EDIT_NET_AMOUNT,
    READY_NET_AMOUNT,
    ORDER_NET_AMOUNT
} from "../CreateOrder/actionType";

import { toast } from 'react-toastify';
import api from "../../../api";

// get order name from branch id
export const getBrandName = (data) => async (dispatch) => {
   try {
      const res = await api.get(`/common/branchOrderName/`+data);
        dispatch({
          type: SET_ORDERNAME,
          payload: res.data.data,
        });
    } catch (err) { }
  };

  //get all pending quotation
  export const getQuotation = (data) => async (dispatch) => {
    try {
      const res = await api.get(`/common/pendingQuotationOrder`);
      
          dispatch({
            type: SET_QUOTATION,
            payload: res.data.data,
          });
    } catch (err) { }
  };

  //get cordinator name [users]
  export const getCordinator = (data) => async (dispatch) => {
    try {
      const res = await api.get(`/common/cordinator`);
      
          dispatch({
            type: SET_CORDINATOR,
            payload: res.data.data,
          });
    } catch (err) { }
  };
  
  //get vendors name
  export const getReffered = (data) => async (dispatch) => {
    try {
      const res = await api.get(`/common/refferedBy`);
      
          dispatch({
            type: SET_REFFERED,
            payload: res.data.data,
          });
    } catch (err) { }
  };
  
  //get all product with model no + category_name + leather_type_name + colour_code_name
  export const getProduct = (data) => async (dispatch) => {
    try {
      const res = await api.get(`/common/productList`);
      
          dispatch({
            type: SET_PRODUCT,
            payload: res.data.data,
          });
    } catch (err) { }
  };
  
  //get all branch location
  export const getLocation = (data) => async (dispatch) => {
    try {
      const res = await api.get(`/common/locationList`);
          dispatch({
            type: SET_LOCATION,
            payload: res.data.data,
          });
    } catch (err) { }
  };

  //get product name from productid
  export const getProductName = (data) => async (dispatch) => {
    try{
      const res = await api.get(`/common/getProductName/`+data);
      dispatch({
        type: PRODUCT_NAME,
        payload: res.data.data,
      });
    } catch(err) {}
  };
  
  /******************************************************* REady PRoduct******************************* */
  export const onChangeCalcReady = (data)  => async (dispatch) => {    
    let qty = localStorage.getItem('editqty');
    let price = localStorage.getItem('editnetprice');
    let calc = parseFloat(qty) * parseInt(price);
    dispatch({
      type: READY_NET_AMOUNT,
      payload: calc,
    });
  };

  //add imported product
  export const addImportedReady = (data)  => async (dispatch) => {
    var detail= data;
    detail['model'] = localStorage.getItem('product_id');
    detail['location'] = localStorage.getItem('location_id');
    detail['session_id'] = localStorage.getItem('rndno');
    
    const body = JSON.stringify(detail);
    try {
      const res = await api.post('/order/orderProduct', body);
      dispatch({
        type: GET_IMPORTED_READY,
        payload: res.data.data,
      });
      
      const rndno = localStorage.getItem('rndno');
      const res1 = await api.get(`/order/getTotalAmount/`+rndno);
      localStorage.setItem('total_amount', res1.data.data);
      dispatch({
        type: GET_TOTAL_AMOUNT,
        payload: res1.data.data,
      });
    } catch (err) {}
  };
  
  //edit imported Product
  export const getEditReady = (data)  => async (dispatch) => {
    try{
      const res = await api.get(`/order/getEditReady/`+data);
      dispatch({
        type: EDIT_READY_PRODUCT,
        payload: res.data.data[0],
      });

      dispatch({
        type: EDIT_PRODUCT_NAME,
        payload: res.data.data[0].name,
      });
      
      localStorage.setItem('editqty',res.data.data[0].qty);
      localStorage.setItem('editnetprice',res.data.data[0].net_price);

      dispatch({
        type: READY_NET_AMOUNT,
        payload: res.data.data[0].net_amount,
      });
    } catch(err) {}
  };

  //update imported product
  export const updateImportedReady = (data)  => async (dispatch) => {
    var detail= data;
    detail['model'] = localStorage.getItem('product_id');
    detail['location'] = localStorage.getItem('location_id');
    
    const body = JSON.stringify(detail);
    console.log(body);
    try {
      const res = await api.post('/order/updateReadyProduct', body);
      dispatch({
        type: GET_IMPORTED_READY,
        payload: res.data.data,
      });
      
      const rndno = localStorage.getItem('rndno');
      const res1 = await api.get(`/order/getTotalAmount/`+rndno);
      localStorage.setItem('total_amount', res1.data.data);
      dispatch({
        type: GET_TOTAL_AMOUNT,
        payload: res1.data.data,
      });
    } catch (err) {}
  };

  //deleted imported product
  export const deleteImportedReady = (data)  => async (dispatch) => {
    try{
      const res = await api.get(`/order/deleteReadyProduct/`+data);
      dispatch({
        type: GET_IMPORTED_READY,
        payload: res.data.data.name,
      });

      const rndno = localStorage.getItem('rndno');
      const res1 = await api.get(`/order/getTotalAmount/`+rndno);
      localStorage.setItem('total_amount', res1.data.data);
      dispatch({
        type: GET_TOTAL_AMOUNT,
        payload: res1.data.data,
      });
    } catch(err) {}
  }

  export const getEditProductNameReady = (data)  => async (dispatch) => {
    try{
      const res = await api.get(`/common/getProductName/`+data);
      dispatch({
        type: EDIT_PRODUCT_NAME,
        payload: res.data.data.name,
      });
    } catch(err) {}
  };

/***************************************************** Imorted Order Product  **************************/
 //create production name
 export const getProductNameOrder = (data)  => async (dispatch) => {
    try{
      const res = await api.get(`/common/getProductName/`+data);
      dispatch({
        type: PRODUCT_NAME,
        payload: res.data.data,
      });
    } catch(err) {}
  };

  export const onChangeCalcOrder = (data)  => async (dispatch) => {    
    let qty = localStorage.getItem('editqty');
    let price = localStorage.getItem('editnetprice');
    let calc = parseFloat(qty) * parseInt(price);
    console.log(calc);
    dispatch({
      type: ORDER_NET_AMOUNT,
      payload: calc,
    });
  };

  //add imported order product
  export const addImportedOrder = (data)  => async (dispatch) => {
    var detail= data;
    detail['model'] = localStorage.getItem('product_id');
    detail['session_id'] = localStorage.getItem('rndno');

    const body = JSON.stringify(detail);
    try {
      const res = await api.post('/order/orderImportedProductOrder', body);
      dispatch({
        type: GET_IMPORTED_ORDER,
        payload: res.data.data,
      });

      const rndno = localStorage.getItem('rndno');
      const res1 = await api.get(`/order/getTotalAmount/`+rndno);
      localStorage.setItem('total_amount', res1.data.data);
      dispatch({
        type: GET_TOTAL_AMOUNT,
        payload: res1.data.data,
      });
    } catch (err) {}
  };

  //edit imported Product
  // export const getEditOrder = (data)  => async (dispatch) => {
  //   try{
  //     const res = await api.get(`/order/getEditOrder/`+data);
  //     dispatch({
  //       type: EDIT_READY_PRODUCT,
  //       payload: res.data.data[0],
  //     });

  //     dispatch({
  //       type: PRODUCT_NAME,
  //       payload: res.data.data[0].name,
  //     });
      
  //     localStorage.setItem('editqty',res.data.data[0].qty);
  //     localStorage.setItem('editnetprice',res.data.data[0].net_price);

  //     dispatch({
  //       type: READY_NET_AMOUNT,
  //       payload: res.data.data[0].net_amount,
  //     });
  //   } catch(err) {}
  // };

  //update imported product
  export const updateImportedOrder = (data)  => async (dispatch) => {
    var detail= data;
    detail['model'] = localStorage.getItem('product_id');
    
    const body = JSON.stringify(detail);
    console.log(body);
    try {
      const res = await api.post('/order/updateOrderProduct', body);
      dispatch({
        type: GET_IMPORTED_ORDER,
        payload: res.data.data,
      });
      
      const rndno = localStorage.getItem('rndno');
      const res1 = await api.get(`/order/getTotalAmount/`+rndno);
      localStorage.setItem('total_amount', res1.data.data);
      dispatch({
        type: GET_TOTAL_AMOUNT,
        payload: res1.data.data,
      });
    } catch (err) {}
  };

  //deleted imported product
  export const deleteImportedOrder = (data)  => async (dispatch) => {
    try{
      const res = await api.get(`/order/deleteOrderProduct/`+data);
      dispatch({
        type: GET_IMPORTED_ORDER,
        payload: res.data.data.name,
      });

      const rndno = localStorage.getItem('rndno');
      const res1 = await api.get(`/order/getTotalAmount/`+rndno);
      localStorage.setItem('total_amount', res1.data.data);
      dispatch({
        type: GET_TOTAL_AMOUNT,
        payload: res1.data.data,
      });
    } catch(err) {}
  }


  //get total amount
  export const calGrossAmt = (data) => async (dispatch) => {
    const total = localStorage.getItem('total_amount');
    const fitting = localStorage.getItem('fitting');
    const packing = localStorage.getItem('packing');
    const cartage = localStorage.getItem('cartage');
    const other = localStorage.getItem('other');
    const advance = localStorage.getItem('advance');
    
    let totalbill = parseInt(total) + parseInt(fitting) + parseInt(packing) + parseInt(cartage) + parseInt(other);
    dispatch({
      type: GROSS_TOTAL,
      payload: totalbill,
    });

    let balance = totalbill - parseInt(advance);
    dispatch({
      type: BALANCE_AMT,
      payload: balance,
    });

  };
  
  /***************************************** Create Order  *****************************************/

  //create order
  export const createOrder = (data)  => async (dispatch) => {
    var detail= data;
    detail['coordinator'] = localStorage.getItem('coordinator');
    detail['refferedby'] = localStorage.getItem('refferedby');
    detail['rndno'] = localStorage.getItem('rndno');
    detail['branch_id'] = localStorage.getItem('branch_id');
    detail['total_amount'] = localStorage.getItem('total_amount');
    detail['created_by'] = localStorage.getItem('userid');
    
    if(detail['bookingdate'] === '')
    {
      toast.warning('Order Date is Required.');
    }
    else if(detail['coordinator'] === '')
    {
      toast.warning('Coordinator Name is Required.');
    }
    else if(detail['refferedby'] === '')
    {
      toast.warning('Reffered By Name is Required.');
    }
    else if(detail['name'] === '')
    {
      toast.warning('Customer Name is Required.');
    }
    else if(detail['mobile'] === '')
    {
      toast.warning('Mobile Number is Required.');
    }
    else if(detail['deliverydate'] === '')
    {
      toast.warning('Delivery Date is Required.');
    }
    else if(detail['total_amount'] === '')
    {
      toast.warning('Add Product.');
    }
    else
    {
      const body = JSON.stringify(detail);
      console.log(body);
      try {
        const res = await api.post('/order/createOrder', body);
        console.log(res);
        if (res.status === 200) {
          toast.success('Order Created Successfully');
          dispatch({
            type: ORDER_CREATED,
            payload: res.data,
          });
        }
        else if (res.status === 200) {
          toast.success('Order Created Successfully');
          dispatch({
            type: ORDER_CREATED,
            payload: res.data,
          });
        }
      } catch (err) {}
    }

    
  };

 