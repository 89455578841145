import {
    CREATE_DECLINED_QUOTATION,
    RETRIEVE_DECLINED_QUOTATION,
    UPDATE_DECLINED_QUOTATION,
    DELETE_DECLINED_QUOTATION
} from "../DeclinedQuotation/actiontype";

const INITIAL_STATE = {
  currentIntegration: [],
  isLoading: false,
};

const declinedQuotationReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  
  switch (type) {
    case CREATE_DECLINED_QUOTATION:
      return {
        ...state
      };
    case RETRIEVE_DECLINED_QUOTATION:
      return {
        ...state,
        currentIntegration: payload,
        isLoading: true,
      };
    case UPDATE_DECLINED_QUOTATION:
      return state.map((declinedQuotation) => {
        if (declinedQuotation.id === payload.id) {
          return {
            ...declinedQuotation,
            ...payload,
          };
        } else {
          return declinedQuotation;
        }
      });
    case DELETE_DECLINED_QUOTATION:
      return state.filter(({ id }) => id !== payload.id);
    default:
      return state;
  }
};
export default declinedQuotationReducer;