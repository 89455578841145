import * as React from "react";
import {useState, useEffect} from 'react'
import { Row, Col, Container, Button, Modal, Dropdown } from 'react-bootstrap';
import MaterialTable from 'material-table';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import KErrorMessage from "../../../components/ErrorMessage/KErrorMessage";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { customer,getCustomer,deleteCustomer,getSingleCustomer,updateCustomer} from '../../../redux/Users/Customers/action';
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import { id } from "date-fns/locale";
import SearchBar from "../../../components/SearchBar/SearchBar";
import Loader from "../../../components/Loader/Loader";
import { useNavigate } from 'react-router-dom';

const validationSchema = yup.object({
    name: yup.string().required("Customer Name is Required"),
    mobile: yup.string().required("Customer Name is Required"),
    email: yup.string().email('Must be a valid email').max(255).required('Email is Required'),
    address: yup
    .string()
    .min(5, "too small!")
    .max(500, "Too Long String")
    .required("Address is Required"),
  });
  
  const Customer = ({ customer,getCustomer,currentIntegration,deleteCustomer,getSingleCustomer, singleCustomer,updateCustomer,isLoading,isUpdate }) =>  {  useEffect(() => {
    getCustomer();
    
  }, []);

  const [visible, setVisible] = useState(false);
  const handleVisibility = () => {
      setVisible(prev => !prev);
  };
  
  const [loading, setLoading] = useState(false);
  useEffect(() => {
      if (isLoading) {
          setLoading(true);
      }
    });
  
  const [show, setShow] = useState(false);
  const modalAddClose = () => setShow(false);
  const modalAddShow = () => setShow(true);

  const [show2, setShow2] = useState(false);
  const modalEditClose = () => setShow2(false);
  const modalEditShow = () => setShow2(true);

  const navigate = useNavigate();
  useEffect(() => {
    if (isUpdate) {
      //window.location.reload(false);
    }
  });

  const [tableData, setTableData] = useState([]);
    useEffect(() => {
      if(currentIntegration)
      {
        setTableData(currentIntegration); 
      }
    }, [currentIntegration])

  const columns = [
  { title: " Name", field: "name" },
  { title: "Mobile No.", field: "mobile" },
  { title: "Email", field: "email" },
  { title: "Address", field: "address" },
  ]

  const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', type: ''})

  const editCustomer = async (e) => {
      setShow2({
      isOpen: true,
    })
    getSingleCustomer(e);
  }

  const customerDelete = async (e) => {
    setConfirmDialog({
    isOpen: true,
    title: 'Are You Sure To Delete This Customer?',
    type: false,
    onConfirm: () => { 
        deleteCustomer(e);
        setConfirmDialog({ ...confirmDialog, isOpen: false })
    }
    })
  }   

  // Search for Card view start
  const [searchValue, setSearchValue] = useState("");

  const filterCustomer = ({ name }) => {
      return name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
  };
  // Search for Card view end

  return (
    <>
        <Container fluid className="main-content">
            <Row>
                <Col className='d-flex justify-content-between mb-2'>
                    <h3 className="main-title">Customers</h3>
                    <button type="button" className="btn btn-md btn-primary d-none d-sm-block" onClick={modalAddShow}><i className="fa-solid fa-plus"></i> Create</button>
                    <div className="d-flex justify-content-between d-block d-sm-none">
                      <div className="magnifierContainer">
                        <button type="button" className="btn btn-md btn-warning text-white roundedcircle mr-3" onClick={handleVisibility}><i className="fa-solid fa-search"></i></button>
                      </div>
                      <button type="button" className="btn btn-md btn-primary d-block d-sm-none roundedcircle ml-3"  onClick={modalAddShow}><i className="fa-solid fa-plus"></i></button>
                    </div>
                </Col>
                <Col lg={"12"} className='mb-2 d-block d-sm-none'>
                  <div className={visible ? "open" : "close"}>
                    <SearchBar onSearch={setSearchValue} value={searchValue} />
                  </div>
                </Col>
            </Row>
            {loading ? <div>
            <Row className=" d-none d-sm-block mt-2">
            <Col lg={"12"}>
              <div style={{display: 'table', tableLayout:'fixed', width:'100%'}}>
              <MaterialTable columns={columns} data={tableData}
                  actions={[
                    {
                      icon: () => <EditIcon color={'primary'} />,
                      tooltip: 'Edit',
                      onClick: (event, rowData) => editCustomer(rowData.id)
                    },
                    
                    {
                      icon: () => <DeleteIcon color={'error'} />,
                      tooltip: 'Delete',
                      onClick: (event, rowData) => customerDelete(rowData.id)
                    },
                  ]}
                  options={{
                  sorting: true, search: true,
                  paging: true, pageSizeOptions: [10, 25, 50, 100], pageSize: 10,
                  exportButton: true,
                  exportAllData: true, exportFileName: "Customers", actionsColumnIndex: -1,
                  // headerStyle: { background: "#f44336",color:"#fff"}
                  }}
                  title="Customers"
              />
              </div>
            </Col>
          </Row>
          <Row className="d-block d-sm-none">
          {currentIntegration.filter(filterCustomer).map((cust, index) => ( 
             <Col xs={"12"} key={index}>
              <div className="card mb-2">
                <div className="card-header d-flex justify-content-between pb-0">
                    <h6>{cust.name}</h6>
                  <Dropdown>
                    <Dropdown.Toggle variant="light" id="dropdown-basic">
                      <i className="ri-more-2-line"></i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item onClick={() => editCustomer(cust.id)}><i className="ri-edit-box-line text-primary"></i> Edit</Dropdown.Item>
                      <Dropdown.Item onClick={() => customerDelete(cust.id)}><i className="far fa-trash-alt text-danger"></i> Delete</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="card-body pt-1">
                  <p className="card-text mb-0"><span className="user-info">Phone:</span> {cust.mobile}</p>
                  <p className="card-text mb-0"><span className="user-info">Email:</span> {cust.email}</p>
                  <p className="card-text mb-0"><span className="user-info">Address:</span> {cust.address}</p>
                </div>
              </div>
            </Col>
             ))}  
        </Row>
        </div> : <Loader/>}
      </Container>
      <ConfirmDialog
          confirmDialog={confirmDialog}
          setConfirmDialog={setConfirmDialog}
        />
       {/* Add Customer Modal Start */}
        <Modal 
          size="md"
          centered
          show={show} 
          onHide={modalAddClose}>
            <Formik
                validationSchema={validationSchema}
                initialValues={{
                name: "",
                mobile: "",
                email: "",
                address: ""
                }}
                onSubmit={(values) => {
                  customer(values);
                }}
            >
            {({ values }) => (

                <Form>
                <Modal.Header closeButton>
                    <Modal.Title>Add Customers</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label>Customer Name</label>
                    <Field name="name" type="text" className="form-control"  />
                    <KErrorMessage name="name" />
                    <label>Mobile No.</label>
                    <Field name="mobile" type="number" className="form-control"  />
                    <KErrorMessage name="mobile" />
                    <label>Email</label>
                    <Field name="email" type="email" className="form-control"  />
                    <KErrorMessage name="email" />
                    <label>Address</label>
                    <Field name="address" as="textarea" className="form-control" />
                    <KErrorMessage name="address" />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={modalAddClose}>Close</Button>
                    <Button variant="primary" type="submit">Submit</Button>
                </Modal.Footer>
                </Form>
              )}
            </Formik>
        </Modal>
        {/* Add Customer Modal End */}
        {/* Edit Customer Modal Start */}
        <Modal 
          size="md"
          centered
          show={show2} 
          onHide={modalEditClose}>
            <Formik
                enableReinitialize={true}
                validationSchema={validationSchema}
                initialValues={{
                id: singleCustomer.id,
                name: singleCustomer.name,
                mobile: singleCustomer.mobile,
                email: singleCustomer.email,
                address: singleCustomer.address
                }}
                onSubmit={(values) => {
                  console.log(values);
                  updateCustomer(values);
                }}
            >
                {({ values, handleChange }) => (
                <Form>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Customers</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Field name="id" type="hidden" className="form-control" />
                    <label>Customer Name</label>
                    <Field name="name" type="text" className="form-control" />
                    <KErrorMessage name="name" />
                    <label>Mobile No.</label>
                    <Field name="mobile" type="text" className="form-control" />
                    <KErrorMessage name="mobile" />
                    <label>Email</label>
                    <Field name="email" type="email" className="form-control" />
                    <KErrorMessage name="email" />
                    <label>Address</label>
                    <Field name="address" as="textarea" className="form-control" />
                    <KErrorMessage name="address" />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={modalEditClose}>Close</Button>
                    <Button variant="primary" type="submit">Submit</Button>
                </Modal.Footer>
                </Form>
                )}
            </Formik>
        </Modal>
      {/* Edit Customer Modal End */}
    </>

  )
}

const mapStateToProps = (state1) => {
  console.log(state1);
  return {
     currentIntegration: state1.customers.currentIntegration,
     isLoading: state1.customers.isLoading,
     singleCustomer: state1.customers.singleCustomer,
     isUpdate: state1.customers.isUpdate,
   }
  
 }

 export default connect(mapStateToProps,{customer,getCustomer,deleteCustomer,getSingleCustomer,updateCustomer})(Customer);