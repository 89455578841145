import {
    SET_ORDERNAME,
    SET_QUOTATION,
    SET_CORDINATOR,
    SET_CORDINATOR_VALUE,
    SET_CORDINATOR_NAME,
    SET_REFFERED,
    SET_REFFERED_VALUE,
    SET_REFFERED_NAME,
    SET_PRODUCT,
    SET_LOCATION,
    PRODUCT_NAME,
    GET_IMPORTED_READY,
    GET_IMPORTED_ORDER,
    GET_TOTAL_AMOUNT,
    BALANCE_AMT,
    GROSS_TOTAL,
    QUOTATION_CREATED,
    BRANCH_DETAIL,
    EDIT_READY_PRODUCT,
    EDIT_NET_AMOUNT,
    EDIT_ORDER_PRODUCT,
    EDIT_ORDER_NET_AMOUNT,
    EDIT_PRODUCT_NAME,
    EDIT_PRODUCT_NAME_READY,
    EDIT_PRODUCT_NAME_ORDER
} from "../CreateQuotation/actionType";

import { toast } from 'react-toastify';
import api from "../../../api";

// get order name from branch id
export const getBrandName = (data) => async (dispatch) => {
   try {
      const res = await api.get(`/common/branchOrderName/`+data);
      dispatch({
        type: SET_ORDERNAME,
        payload: res.data.data,
      });
      
      const res1 = await api.get(`/setting/getBranch/`+data);
      dispatch({
        type: BRANCH_DETAIL,
        payload: res1.data.data,
      });
    } catch (err) { }
  };

  //get all pending quotation
  export const getQuotation = (data) => async (dispatch) => {
    try {
      const res = await api.get(`/common/pendingQuotationOrder`);
      dispatch({
        type: SET_QUOTATION,
        payload: res.data.data,
      });
    } catch (err) { }
  };

  //get cordinator name [users]
  export const getCordinator = (data) => async (dispatch) => {
    try {
      const res = await api.get(`/common/cordinator`);
      
          dispatch({
            type: SET_CORDINATOR,
            payload: res.data.data,
          });
    } catch (err) { }
  };
  
  //get vendors name
  export const getReffered = (data) => async (dispatch) => {
    try {
      const res = await api.get(`/common/refferedBy`);
      
      dispatch({
        type: SET_REFFERED,
        payload: res.data.data,
          });
        } catch (err) { }
      };
      
  //set Cordinator name and id
  export const setCordinator = (data) => async (dispatch) => {
    let cordinator = JSON.parse(localStorage.getItem('quotation_coordinator'));
    console.log(cordinator);
    if(cordinator != '')
    {
      try {
        const res = await api.get(`/user/getUser/` + cordinator);
        console.log(res.data.data);
        //localStorage.setItem('quotation_coordinator', data);
        dispatch({
          type: SET_CORDINATOR_VALUE,
          payload: res.data.data.id,
        });
        
        dispatch({
          type: SET_CORDINATOR_NAME,
          payload: res.data.data.name,
        });
  
      } catch (err) { }
    }
  };
      
  //set Reffered name and id
  export const setReffered = (data) => async (dispatch) => {
    let reffered = JSON.parse(localStorage.getItem('quotation_coordinator'));
    console.log(reffered);
    if(reffered != '')
    {
      try {
        const res = await api.get(`/vendor/getVendor/` + reffered);
        console.log(res.data);
        dispatch({
          type: SET_REFFERED_VALUE,
          payload: res.data.id,
        });
        
        dispatch({
          type: SET_REFFERED_NAME,
          payload: res.data.name,
        });
  
      } catch (err) { }
    }
  };

  //get all product with model no + category_name + leather_type_name + colour_code_name
  export const getProduct = (data) => async (dispatch) => {
    try {
      const res = await api.get(`/common/productList`);
      
          dispatch({
            type: SET_PRODUCT,
            payload: res.data.data,
          });
    } catch (err) { }
  };
  
  //get all branch location
  export const getLocation = (data) => async (dispatch) => {
    try {
      const res = await api.get(`/common/locationList`);
          dispatch({
            type: SET_LOCATION,
            payload: res.data.data,
          });
    } catch (err) { }
  };

  //get product name from productid
  export const getProductName = (data) => async (dispatch) => {
    try{
      const res = await api.get(`/common/getProductName/`+data);
      dispatch({
        type: PRODUCT_NAME,
        payload: res.data.data,
      });
    } catch(err) {}
  };

  //add imported product
  export const addImportedReady = (data)  => async (dispatch) => {
    const body = JSON.stringify(data);
    try {
      const res = await api.post('/quotation/orderImportedReadyProduct', body);
      dispatch({
        type: GET_IMPORTED_READY,
        payload: res.data.data,
      });

      const rndno = localStorage.getItem('rndno');
      const res1 = await api.get(`/quotation/getTotalAmount/`+rndno);
      localStorage.setItem('total_amount', res1.data.data);
      
      dispatch({
        type: GET_TOTAL_AMOUNT,
        payload: res1.data.data,
      });

      const total = res1.data.data;
      const fitting = localStorage.getItem('fitting');
      const packing = localStorage.getItem('packing');

      let totalamt = parseInt(total) + parseInt(fitting);
      dispatch({
        type: GROSS_TOTAL,
        payload: totalamt,
      });

      let balance = totalamt + parseInt(packing);
      dispatch({
        type: BALANCE_AMT,
        payload: balance,
      });

    } catch (err) {}
  };

  //add imported order product
  export const addImportedOrder = (data)  => async (dispatch) => {
    const body = JSON.stringify(data);
    try {
      const res = await api.post('/quotation/orderImportedProductOrder', body);
      dispatch({
        type: GET_IMPORTED_ORDER,
        payload: res.data.data,
      });

      const rndno = localStorage.getItem('rndno');
      const res1 = await api.get(`/quotation/getTotalAmount/`+rndno);
      localStorage.setItem('total_amount', res1.data.data);
      dispatch({
        type: GET_TOTAL_AMOUNT,
        payload: res1.data.data,
      });

    } catch (err) {}
  };

  //get total amount
  export const calGrossAmt = (data) => async (dispatch) => {
    const total = localStorage.getItem('total_amount');
    const fitting = localStorage.getItem('fitting');
    const packing = localStorage.getItem('packing');
    
    let totalamt = parseInt(total) + parseInt(fitting);
    dispatch({
      type: GROSS_TOTAL,
      payload: totalamt,
    });

    let balance = totalamt + parseInt(packing);
    dispatch({
      type: BALANCE_AMT,
      payload: balance,
    });

  };

  //create order
  export const createQuotation = (data)  => async (dispatch) => {
    var detail= data;
    detail['coordinator'] = localStorage.getItem('quotation_coordinator');
    detail['refferedby'] = localStorage.getItem('quotation_refferedby');
    detail['rndno'] = localStorage.getItem('rndno');
    detail['branch_id'] = localStorage.getItem('branch_id');
    detail['total_amount'] = localStorage.getItem('total_amount');
    detail['created_by'] = localStorage.getItem('userid');
    

    if(detail['deliverydate'] == '')
    {
      toast.warning('Delivery Date is Required.');
    }
    else if(detail['name'] == '')
    {
      toast.warning('Customer Name is Required.');
    }
    else if(detail['mobile'] == '')
    {
      toast.warning('Mobile Number is Required.');
    }
    else if(detail['subtotal'] == '')
    {
      toast.warning('Add Product.');
    }
    else if(detail['grandtotal'] == '')
    {
      toast.warning('Add Product.');
    }
    else
    {
      const body = JSON.stringify(detail);
      console.log(body);
      try {
        const res = await api.post('/quotation/createQuotation', body);
        console.log(res);
        if (res.status === 200) {
          toast.success('Quotation Created Successfully');
          dispatch({
            type: QUOTATION_CREATED,
            payload: res.data,
          });
        }
      } catch (err) {}
    }
  };

  //create production name
  export const getProductNameOrder = (data)  => async (dispatch) => {
    try{
      const res = await api.get(`/common/getProductName/`+data);
      localStorage.setItem('product_name',res.data.data.name);
      dispatch({
        type: PRODUCT_NAME,
        payload: res.data.data,
      });
    } catch(err) {}
  };
  
  //edit ready
  export const getEditReady = (data)  => async (dispatch) => {
    try{
      const res = await api.get(`/quotation/getEditReady/`+data);
      dispatch({
        type: EDIT_READY_PRODUCT,
        payload: res.data.data[0],
      });

      dispatch({
        type: EDIT_PRODUCT_NAME_READY,
        payload: res.data.data[0].name,
      });
      
      localStorage.setItem('editqty',res.data.data[0].qty);
      localStorage.setItem('editnetprice',res.data.data[0].net_price);

      dispatch({
        type: EDIT_NET_AMOUNT,
        payload: res.data.data[0].net_amount,
      });
    } catch(err) {}
  };
  
  //charge calc ready
  export const onChangeCalcReady = (data)  => async (dispatch) => {
    let qty = localStorage.getItem('editqty');
    let price = localStorage.getItem('editnetprice');
    let calc = parseFloat(qty) * parseInt(price);
    dispatch({
      type: EDIT_NET_AMOUNT,
      payload: calc,
    });
  };

  //update ready
  export const updateReady = (data)  => async (dispatch) => {
    var detail= data;
    detail['session_id'] = localStorage.getItem('rndno');
    detail['model'] = localStorage.getItem('product_id');
    detail['location'] = localStorage.getItem('location_id');
    const body = JSON.stringify(detail);
    try {
      const res = await api.post('/quotation/updateEditReady', body);
      dispatch({
        type: GET_IMPORTED_READY,
        payload: res.data.data,
      });

      const rndno = localStorage.getItem('rndno');
      const res1 = await api.get(`/quotation/getTotalAmount/`+rndno);
      localStorage.setItem('total_amount', res1.data.data);
      dispatch({
        type: GET_TOTAL_AMOUNT,
        payload: res1.data.data,
      });
      
      const total = res1.data.data;
      const fitting = localStorage.getItem('fitting');
      const packing = localStorage.getItem('packing');

      let totalamt = parseInt(total) + parseInt(fitting);
      dispatch({
        type: GROSS_TOTAL,
        payload: totalamt,
      });

      let balance = totalamt + parseInt(packing);
      dispatch({
        type: BALANCE_AMT,
        payload: balance,
      });

    } catch (err) {}
  };

  //get Order
  export const getEditOrder = (data)  => async (dispatch) => {
    try{
      const res = await api.get(`/quotation/getEditOrder/`+data);
      console.log(res.data.data[0]);
      console.log(res.data.data[0].net_amount);
      dispatch({
        type: EDIT_ORDER_PRODUCT,
        payload: res.data.data[0],
      });

      dispatch({
        type: EDIT_PRODUCT_NAME_ORDER,
        payload: res.data.data[0].name,
      });

      localStorage.setItem('editqty',res.data.data[0].qty);
      localStorage.setItem('editnetprice',res.data.data[0].net_amount);

      dispatch({
        type: EDIT_ORDER_NET_AMOUNT,
        payload: res.data.data[0].net_amount,
      });

    } catch(err) {}
  };

  //change calc order
  export const onChangeCalcOrder = (data)  => async (dispatch) => {   
    let qty = localStorage.getItem('editqty');
    let price = localStorage.getItem('editnetprice');
    let calc = parseFloat(qty) * parseInt(price);
    dispatch({
      type: EDIT_ORDER_NET_AMOUNT,
      payload: calc,
    });
  };

  export const updateOrder = (data)  => async (dispatch) => {
    var detail= data;
    detail['session_id'] = localStorage.getItem('rndno');
    detail['model'] = localStorage.getItem('product_id');
    const body = JSON.stringify(detail);
    console.log(body);
    try {
      const res = await api.post('/quotation/updateEditOrder', body);
      console.log(res);

      dispatch({
        type: GET_IMPORTED_ORDER,
        payload: res.data.data,
      });

      const rndno = localStorage.getItem('rndno');
      const res1 = await api.get(`/quotation/getTotalAmount/`+rndno);
      localStorage.setItem('total_amount', res1.data.data);
      dispatch({
        type: GET_TOTAL_AMOUNT,
        payload: res1.data.data,
      });

      const total = res1.data.data;
      const fitting = localStorage.getItem('fitting');
      const packing = localStorage.getItem('packing');

      let totalamt = parseInt(total) + parseInt(fitting);
      dispatch({
        type: GROSS_TOTAL,
        payload: totalamt,
      });

      let balance = totalamt + parseInt(packing);
      dispatch({
        type: BALANCE_AMT,
        payload: balance,
      });
      
    } catch (err) {}
  };

  //delete
  //add imported product
  export const deleteReady = (data)  => async (dispatch) => {
    try {
      const res = await api.get('/quotation/deleteReadyProduct/'+data);
      dispatch({
        type: GET_IMPORTED_READY,
        payload: res.data.data,
      });

      const rndno = localStorage.getItem('rndno');
      const res1 = await api.get(`/quotation/getTotalAmount/`+rndno);
      localStorage.setItem('total_amount', res1.data.data);
      dispatch({
        type: GET_TOTAL_AMOUNT,
        payload: res1.data.data,
      });

    } catch (err) {}
  };

  //add imported order product
  export const deleteOrder = (data)  => async (dispatch) => {
    try {
      const res = await api.get('/quotation/deleteOrderProduct/'+data);
      dispatch({
        type: GET_IMPORTED_ORDER,
        payload: res.data.data,
      });
      
      const rndno = localStorage.getItem('rndno');
      const res1 = await api.get(`/quotation/getTotalAmount/`+rndno);
      localStorage.setItem('total_amount', res1.data.data);
      dispatch({
        type: GET_TOTAL_AMOUNT,
        payload: res1.data.data,
      });
      
    } catch (err) {}
  };
  
  export const getEditProductName = (data)  => async (dispatch) => {
    try{
      const res = await api.get(`/common/getProductName/`+data);
      dispatch({
        type: EDIT_PRODUCT_NAME,
        payload: res.data.data,
      });
    } catch(err) {}
  }

  export const getEditProductNameOrder = (data)  => async (dispatch) => {
    try{
      const res = await api.get(`/common/getProductName/`+data);
      dispatch({
        type: EDIT_PRODUCT_NAME_ORDER,
        payload: res.data.data.name,
      });
    } catch(err) {}
  }
  
  export const getEditProductNameReady = (data)  => async (dispatch) => {
    try{
      const res = await api.get(`/common/getProductName/`+data);
      dispatch({
        type: EDIT_PRODUCT_NAME_READY,
        payload: res.data.data.name,
      });
    } catch(err) {}
  }

