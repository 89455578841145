import {
  RETRIEVE_CURRENT_STOCK,
} from "../CurrentStock/actiontype";
import api from "../../../api";

let userid = JSON.parse(localStorage.getItem('userid'));

export const getCurrentStock= (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
 
  try {
    const res = await api.get(`/branch/currentStock/` +userid);
    dispatch({
      type:  RETRIEVE_CURRENT_STOCK,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type:  RETRIEVE_CURRENT_STOCK,
      payload: [],
    });
  }
};

