import * as React from "react";
import {useState, useEffect} from 'react'
import { Row, Col, Container, Button, Modal, Dropdown, Badge } from 'react-bootstrap';
import MaterialTable from 'material-table';
import KErrorMessage from "../../../components/ErrorMessage/KErrorMessage";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import { connect } from "react-redux";
import {getDeclinedQuotation} from '../../../redux/Quotation/DeclinedQuotation/action';
import Loader from "../../../components/Loader/Loader";

const validationSchema = yup.object({
    name: yup.string().required("Customer Name is Required"),
    phone: yup
    .number()
    .min(1000000000, "Not Valid Phone Number")
    .max(9999999999, "Not Valid Phone Number")
    .required("Phone is Required"),
    email: yup.string().email('Must be a valid email').max(255).required('Email is Required'),
    address: yup
    .string()
    .min(5, "too small!")
    .max(500, "Too Long String")
    .required("Address is Required"),
  });
  

const DeclinedQuotation = ({ getDeclinedQuotation,currentIntegration,isLoading}) =>  {  useEffect(() => {
  getDeclinedQuotation();
}, []);
  

const [visible, setVisible] = useState(false);
const handleVisibility = () => {
    setVisible(prev => !prev);
};

const [loading, setLoading] = useState(false);
useEffect(() => {
    if (isLoading) {
        setLoading(true);
    }
  });

const [show, setShow] = useState(false);
const modalAddClose = () => setShow(false);
const modalAddShow = () => setShow(true);

const [show2, setShow2] = useState(false);
const modalEditClose = () => setShow2(false);
const modalEditShow = () => setShow2(true);

const [anchorEl, setAnchorEl] = React.useState(null);
const open = Boolean(anchorEl);
const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
};
const handleClose = () => {
    setAnchorEl(null);
};


const [tableData, setTableData] = useState([]);
  useEffect(() => {
  if(currentIntegration)
  {
      setTableData(currentIntegration); 
  }
  }, [currentIntegration])


const columns = [
{ title: " Quotation No.", field: "quotationNo" },
{ title: "Created By.", field: "createdBy" },
{ title: "Customer Name", field: "customerName" },
{ title: "Customer Mobile", field: "CustomerMobile" },
{ title: "No. Of Items", field: "qty" },
{ title: "Date Of Quotation", field: "quotationDate" },
{ title: "Reason", field: "reason" },
]
    
  return (
    <>
        <Container fluid className="main-content">
            <Row>
                <Col className='d-flex justify-content-between mb-2'>
                    <h3 className="main-title">Declined Quotation</h3>
                    <button type="button" className="btn btn-md btn-primary d-none d-sm-block" onClick={modalAddShow}><i className="fa-solid fa-plus"></i> Create</button>
                    <div className="d-flex justify-content-between d-block d-sm-none">
                      <div className="magnifierContainer">
                        <button type="button" className="btn btn-md btn-warning text-white roundedcircle mr-3" onClick={handleVisibility}><i className="fa-solid fa-search"></i></button>
                      </div>
                      <button type="button" className="btn btn-md btn-primary d-block d-sm-none roundedcircle ml-3"  onClick={modalAddShow}><i className="fa-solid fa-plus"></i></button>
                    </div>
                </Col>
                <Col lg={"12"} className='mb-2 d-block d-sm-none'>
                  <div className={visible ? "open" : "close"}>
                      <input placeholder="Search..." type="text" className="form-control is-open mr-3 mb-0 search-input"/>
                  </div>
                </Col>
            </Row>
            { loading ? <div>
            <Row className="d-none d-sm-block mt-2">
              <Col lg={"12"}>
                <div style={{display: 'table', tableLayout:'fixed', width:'100%'}}>
                <MaterialTable columns={columns} data={tableData}
                    options={{
                    sorting: true, search: true,
                    paging: true, pageSizeOptions: [10, 25, 50, 100], pageSize: 10,
                    exportButton: true,
                    exportAllData: true, exportFileName: "Declined Quotation", actionsColumnIndex: -1,
                    // headerStyle: { background: "#f44336",color:"#fff"}
                    }}
                    title="Declined Quotation"
                />
                </div>
              </Col>
          </Row>
          <Row className="d-block d-sm-none">
              {currentIntegration.map((quotation, index) => ( 
              <Col xs={"12"} key={index}>
                <div className="card mb-2">
                  <div className="card-header d-flex justify-content-between pb-0">
                      <h6>{quotation.quotationNo}	 <Badge bg="danger" text="light" className="ml-2">{quotation.qty}</Badge></h6>
                    <Dropdown>
                      <Dropdown.Toggle variant="light" id="dropdown-basic">
                        <i className="ri-more-2-line"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item href="#/action-1"><i className="ri-edit-box-line text-primary"></i> Edit</Dropdown.Item>
                        <Dropdown.Item href="#/action-2"><i className="far fa-trash-alt text-danger"></i> Delete</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="card-body pt-1">
                    <p className="card-text mb-0"><span className="quotation-info">Customer Name:</span> {quotation.customerName}</p>
                    <p className="card-text mb-0"><span className="quotation-info">Customer Mobile:</span> {quotation.CustomerMobile}</p>
                    <p className="card-text mb-0"><span className="quotation-info">Quotation Date:</span> {quotation.quotationDate}</p>
                    <p className="card-text mb-0"><span className="quotation-info">Created By:</span> {quotation.createdBy}</p>
                    <p className="card-text mb-0"><span className="quotation-info">Reason:</span> {quotation.reason}</p>
                  </div>
                </div>
              </Col>
              ))}
            </Row>
            </div> : <Loader/>}
        </Container>
       {/* Add Customer Modal Start */}
        <Modal 
          size="md"
          centered
          show={show} 
          onHide={modalAddClose}>
            <Formik
                validationSchema={validationSchema}
                initialValues={{
                name: "",
                phone: "",
                email: "",
                address: ""
                }}
                onSubmit={(values) => {
               
                }}
            >
                <Form>
                <Modal.Header closeButton>
                    <Modal.Title>Add Quotation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label>Customer Name</label>
                    <Field name="name" type="text" className="form-control" />
                    <KErrorMessage name="name" />
                    <label>Mobile No.</label>
                    <Field name="phone" type="number" className="form-control" />
                    <KErrorMessage name="phone" />
                    <label>Email</label>
                    <Field name="email" type="email" className="form-control" />
                    <KErrorMessage name="email" />
                    <label>Address</label>
                    <Field name="address" as="textarea" className="form-control" />
                    <KErrorMessage name="address" />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={modalAddClose}>Close</Button>
                    <Button variant="primary" type="submit">Submit</Button>
                </Modal.Footer>
                </Form>
            </Formik>
        </Modal>
        {/* Add Customer Modal End */}
        {/* Edit Customer Modal Start */}
        <Modal 
          size="md"
          centered
          show={show2} 
          onHide={modalEditClose}>
            <Formik
                validationSchema={validationSchema}
                initialValues={{
                name: "",
                phone: "",
                email: "",
                address: ""
                }}
                onSubmit={(values) => {
               
                }}
            >
                <Form>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Quotation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label>Customer Name</label>
                    <Field name="name" type="text" className="form-control" />
                    <KErrorMessage name="name" />
                    <label>Mobile No.</label>
                    <Field name="phone" type="number" className="form-control" />
                    <KErrorMessage name="phone" />
                    <label>Email</label>
                    <Field name="email" type="email" className="form-control" />
                    <KErrorMessage name="email" />
                    <label>Address</label>
                    <Field name="address" as="textarea" className="form-control" />
                    <KErrorMessage name="address" />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={modalEditClose}>Close</Button>
                    <Button variant="primary" type="submit">Submit</Button>
                </Modal.Footer>
                </Form>
            </Formik>
        </Modal>
      {/* Edit Customer Modal End */}
    </>

  )
}

const mapStateToProps = (state) => {

  return {
     currentIntegration: state.declinedQuotation.currentIntegration,
     isLoading: state.declinedQuotation.isLoading,
   }
 }

 export default connect(mapStateToProps,{getDeclinedQuotation})(DeclinedQuotation);