import * as React from "react";
import { useState, useEffect } from 'react'
import { Row, Col, Container, Badge, Dropdown, Modal, Button } from 'react-bootstrap';
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import MaterialTable from 'material-table';
import CheckIcon from '@mui/icons-material/Check';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import { connect } from "react-redux";
import {getVendorIncentive,releaseIncentive,addVendorIncentive} from '../../../redux/Vendor/VendorIncentive/action';
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import { useNavigate } from 'react-router-dom';
import SearchBar from "../../../components/SearchBar/SearchBar";
import Loader from "../../../components/Loader/Loader";

const validationSchema = yup.object({
    amount: yup.string().required("Amount is Required"),
  });


const VendorIncentive = ({ getVendorIncentive,currentIntegration,releaseIncentive,addVendorIncentive,isLoading }) =>  {  useEffect(() => {
    getVendorIncentive();
  }, []);
    

    const [visible, setVisible] = useState(false);
    const handleVisibility = () => {
        setVisible(prev => !prev);
    };

    const [loading, setLoading] = useState(false);
    useEffect(() => {
        if (isLoading) {
            setLoading(true);
        }
    });
    
    const [show, setShow] = useState(false);
    const modalAddClose = () => setShow(false);
    const modalAddShow = () => setShow(true);

    const [tableData, setTableData] = useState([]);
    useEffect(() => {
    if(currentIntegration)
    {
        setTableData(currentIntegration); 
    }
    }, [currentIntegration])

    const columns = [
        { title: "Order No.", field: "orderNo" },
        { title: "Vendor Name", field: "vendorName" },
        { title: "Created By", field: "createdBy" },
        { title: "Approved By", field: "approvedBy" },
        { title: "No. Of Items", field: "qty" },
        { title: "Net Total Amount", field: "totalAmount" },
        { title: "Total Discount", field: "discount" },
        { title: "Grand Total", field: "grandTotal" },
        { title: "Date Of Order", field: "orderDate" },
        { title: "Expected Delivery Date", field: "expectedDate" },
    ]

    const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', type: ''})
    const navigate = useNavigate();

    const viewIncentive = async (e) => {
      
        navigate('/detail-View-order/1');
    }
  
    const incentiverealease = async (e) => {
      
      setConfirmDialog({
      isOpen: true,
      title: 'Are You Sure To Relase The Incentive?',
      type: true,
      onConfirm: () => { 
          releaseIncentive(e);
          setConfirmDialog({ ...confirmDialog, isOpen: false })
      }
      })
    } 
    const [formData, setFormData] = useState({
        amount: "",
        id: ""
      });
    
      const { amount, id } = formData;
    
      const onChange = (e) =>
      {
        setFormData({ ...formData, [e.target.name]: e.target.value });
        
      }
    const addIncentive = async (e) => {
        setFormData({ ...formData, 'id': e });
        setShow({
            isOpen: true,
            
            })
        
      }

    const onSubmit = async (e) => {
        
        e.preventDefault();
        const data = {
         id: formData.id,
         amount: formData.amount,
       };
       
       addVendorIncentive(data); // action name
      };

     // Search for Card view start
    const [searchValue, setSearchValue] = useState("");

    const filterVendor = ({ orderNo }) => {
        return orderNo.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
    };
    // Search for Card view end

    return (
        <>
            <Container fluid className="main-content">
                <Row>
                    <Col className='d-flex justify-content-between mb-2'>
                        <h3 className="main-title">Vendor Incentive</h3>
                        <div className="d-flex justify-content-between d-block d-sm-none">
                            <div className="magnifierContainer">
                                <button type="button" className="btn btn-md btn-warning text-white roundedcircle mr-3" onClick={handleVisibility}><i className="fa-solid fa-search"></i></button>
                            </div>
                        </div>
                    </Col>
                    <Col lg={"12"} className='mb-2 d-block d-sm-none'>
                        <div className={visible ? "open" : "close"}>
                             <SearchBar onSearch={setSearchValue} value={searchValue} /> 
                        </div>
                    </Col>
                </Row>
                {loading ? <div>
                <Row className="d-none d-sm-block mt-2">
                    <Col lg={"12"}>
                        <div style={{ display: 'table', tableLayout: 'fixed', width: '100%' }}>
                            <MaterialTable columns={columns} data={tableData}
                                actions={[
                                    {
                                      icon: () => <VisibilityIcon color={'primary'} />,
                                      tooltip: 'View Incentive',
                                      onClick: (event, rowData) => viewIncentive(rowData.id)
                                    },
                                    
                                    {
                                      icon: () => <CheckIcon color={'success'} />,
                                      tooltip: 'Release Incentive',
                                      onClick: (event, rowData) => incentiverealease(rowData.id)
                                    },
                                    {
                                      icon: () => <EditIcon color={'warning'} />,
                                      tooltip: 'Edit Incentive',
                                      onClick: (event, rowData) => addIncentive(rowData.id)
                                    }
                                  ]}
                                options={{
                                    sorting: true, search: true,
                                    paging: true, pageSizeOptions: [10, 25, 50, 100], pageSize: 10,
                                    exportButton: true,
                                    exportAllData: true, exportFileName: "Vendor Incentive", actionsColumnIndex: -1,
                                    // headerStyle: { background: "#f44336",color:"#fff"}
                                }}
                                title="Incentive"
                            />
                        </div>
                    </Col>
                </Row>
                <Row className="d-block d-sm-none">
                {currentIntegration.filter(filterVendor).map((incentive, index) => ( 
                    <Col xs={"12"} key={index}>
                        <div className="card mb-2">
                        <div className="card-header d-flex justify-content-between pb-0">
                            <h6>{incentive.orderNo}	 <Badge bg="danger" text="light" className="ml-2">{incentive.qty}</Badge></h6>
                            <Dropdown>
                            <Dropdown.Toggle variant="light" id="dropdown-basic">
                                <i className="ri-more-2-line"></i>
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={() => viewIncentive(incentive.id)}><VisibilityIcon color={'primary'} /> View</Dropdown.Item>
                                <Dropdown.Item onClick={() => incentiverealease(incentive.id)}><CheckIcon color={'success'} /> Release</Dropdown.Item>
                                <Dropdown.Item onClick={() => addIncentive(incentive.id)}><EditIcon color={'warning'} /> Edit</Dropdown.Item>
                            </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className="card-body pt-1">
                            <p className="card-text mb-0"><span className="quotation-info">Vendor Name:</span> {incentive.vendorName}</p>
                            <p className="card-text mb-0"><span className="quotation-info">Created By:</span> {incentive.createdBy}</p>
                            <p className="card-text mb-0"><span className="quotation-info">Approved By:</span> {incentive.approvedBy}</p>
                            <p className="card-text mb-0"><span className="quotation-info">Net Amount:</span> {incentive.totalAmount}</p>
                            <p className="card-text mb-0"><span className="quotation-info">Discount:</span> {incentive.discount}</p>
                            <p className="card-text mb-0"><span className="quotation-info">Grand Total:</span> {incentive.grandTotal}</p>
                            <p className="card-text mb-0"><span className="quotation-info">Order Date:</span> {incentive.orderDate}</p>
                            <p className="card-text mb-0"><span className="quotation-info">Delivery Date:</span> {incentive.expectedDate}</p>
                        </div>
                        </div>
                    </Col>
                ))}  
                </Row>
                </div> : <Loader/>}
            </Container>
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
             {/* Add Users Modal Start */}
            <Modal 
            size="md"
            centered
            show={show} 
            onHide={modalAddClose}>
                <Formik
                    validationSchema={validationSchema}
                    initialValues={{
                    amount: "",
                    }}
                >
                {({values, setFieldValue}) => (
                    <Form onSubmit={(e) => onSubmit(e)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Vendor Incentive</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <label>Enter Amount</label>
                        <Field name="amount" type="text" className="form-control" value={amount} onChange={(e) => onChange(e)} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={modalAddClose}>Close</Button>
                        <Button variant="primary" type="submit">Submit</Button>
                    </Modal.Footer>
                    </Form>
                )}
                </Formik>
            </Modal>
        {/* Add Incentive Modal End */}
         </>

    )
}

const mapStateToProps = (state) => {
  
    return {
       currentIntegration: state.vendorIncentive.currentIntegration,
       isLoading: state.vendorIncentive.isLoading,
     }
   }
  
   export default connect(mapStateToProps,{getVendorIncentive,releaseIncentive,addVendorIncentive})(VendorIncentive);