import React, { useState, useEffect } from 'react';
import { Row, Col, Container, Card } from 'react-bootstrap';
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import KErrorMessage from "../../../components/ErrorMessage/KErrorMessage";
import MaterialTable from 'material-table';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { unit, getUnit, updateUnit, deleteUnit } from '../../../redux/Product/Unit/action';
import Loader from '../../../components/Loader/Loader';


const validationSchema = yup.object({
  name: yup.string().required("Unit Name is Required!")
});

const Unit = ({ unit,getUnit,currentIntegration,updateUnit,deleteUnit,isLoading, isUpdate}) =>  {  useEffect(() => {
  getUnit();
}, []);

const [loading, setLoading] = useState(false);
useEffect(() => {
    if (isLoading) {
        setLoading(true);
    }
  });

const updateRow = async (e) => {
  
  const data = {
    name:e.unit
  }
  
  updateUnit(e.id,data);
}

const deleteRow = (e) => {
  //
  deleteUnit(e);
};

  const [tableData, setTableData] = useState([]);
    useEffect(() => {
      if(currentIntegration)
      {
        setTableData(currentIntegration); 
      }
    }, [currentIntegration])
   
  const columns = [
    { title: "Unit", field: "unit" },
  ]

  useEffect(() => {
    if(isUpdate)
    {
      window.location.reload(false);
    }
  }, [isUpdate])

  return (
    <>
      <Container fluid className="main-content">
        <Row>
        <Col lg={"6"} className="mb-3">
            <Card>
              <Card.Body>
                <Card.Title>Add Unit</Card.Title>
                <Formik
                  validationSchema={validationSchema}
                  initialValues={{
                    name: ""
                  }}
                  onSubmit={(values) => {
                    unit(values);
                  }}
                >
                {({ values }) => (
                  <Form>
                    <label>Unit Name</label>
                    <Field name="name" type="text" className="form-control" />
                    <KErrorMessage name="name" />
                    <div className="mt-3 float-right">
                      <button className="btn btn-primary btn-md" type="submit">Submit</button>
                    </div>
                  </Form>
                )}
                </Formik>
              </Card.Body>
            </Card>
          </Col>
          <Col lg={"6"}>
            <div style={{display: 'table', tableLayout:'fixed', width:'100%'}}>
              {loading ?
            <MaterialTable columns={columns} data={tableData}
             editable={{

              onRowUpdate: (newRow, oldRow) => new Promise((resolve, reject) => {
                updateRow(newRow);
                setTimeout(() => resolve(), 500)
              }),
              onRowDelete: (selectedRow) => new Promise((resolve, reject) => {
                deleteRow(selectedRow.id)
                setTimeout(() => resolve(), 1000)
              })
            }}

              options={{
                sorting: true, search: true,
                paging: true, pageSizeOptions: [5, 10, 25, 50, 100], pageSize: 5,
                exportButton: true,
                exportAllData: true, exportFileName: "Unit", actionsColumnIndex: -1,
                // headerStyle: { background: "#f44336",color:"#fff"}
              }}
              title="Unit"
            />
            : <Loader/>}
            </div>
          </Col>
        </Row>
      </Container>

    </>
  )
}

const mapStateToProps = (state) => {
  return {
     currentIntegration: state.unit.currentIntegration,
     isLoading: state.unit.isLoading,
     isUpdate: state.unit.isUpdate,
   }
 }

 export default connect(mapStateToProps,{unit,getUnit,updateUnit,deleteUnit})(Unit);