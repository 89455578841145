import {
    CREATE_FABRICS,
    RETRIEVE_FABRICS,
    DELETE_FABRICS,
    RETRIEVE_FABRIC_IMAGE,
    UPDATE_FABRIC_IMAGE
} from "../Fabric/actiontype";

import { toast } from 'react-toastify';
import api from "../../../api";

export const fabric = ({
  name,
  image,
}) => async (dispatch) => {
  
  try {
    let data = new FormData();
        data.append('name', name);
        data.append('image', image);
        const pic_res = await api.post('/fabric/addFabric', data);
        if (pic_res.status === 200) {
          toast.success('Fabric Added Successfully');
          dispatch({
            type: CREATE_FABRICS,
            payload: pic_res.data.data.info[0],
          });
          
        }
  } catch (error) {
    toast.warn('Invalid inputs! Fabric Not Added');
    // dispatch({
    //   type: UPDATE_PROFILE_ERROR,
    // });
  }
};



export const getFabric = (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  try {
    const res = await api.get(`/fabric/fabric`);
    dispatch({
      type: RETRIEVE_FABRICS,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_FABRICS,
      payload: [],
    });
  }
};

export const deleteFabric = (id) => async (dispatch) => {
  try {
    const res = await api.delete('/fabric/deleteFabric/' + id);
   
    if (res.status === 200) {
      dispatch({
        type: DELETE_FABRICS,
        payload: res.data.data.info,
      });
      toast.success(res.data.message);
    }
  } catch (error) {
    
    toast.error('Failed to delete');
    dispatch({
      type: DELETE_FABRICS,
      payload: [],
    });
  }
};

export const getFabricImage = (id) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  try {
    const res = await api.get(`/fabric/getFabric/` + id);
   
    dispatch({
      type: RETRIEVE_FABRIC_IMAGE,
      payload: res.data.data[0],
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_FABRIC_IMAGE,
      payload: [],
    });
  }
};


export const updateFabricImage = (registerData) => async (dispatch) => {
  const body = JSON.stringify(registerData);
  try {
    const res = await api.post('/fabric/updateFabricImage', body);
   
    if (res.status === 200) {
      toast.success(res.data.message);
        dispatch({
            type: UPDATE_FABRIC_IMAGE,
            payload: res.data.data,
          });
        }
      } catch (err) {
    
    toast.warn('Invalid inputs! Image Not Updated');
    // dispatch({
    //   type: REGISTER_FAIL,
    // });
  }
};