import {
    CREATE_LEATHERTYPE,
    RETRIEVE_LEATHERTYPE,
    DELETE_LEATHERTYPE,
    UPDATE_LEATHERTYPE
} from "../LeatherType/actiontype";

import { toast } from 'react-toastify';
import api from "../../../api";

export const leatherType = (registerData) => async (dispatch) => {
  const body = JSON.stringify(registerData);
  try {
    const res = await api.post('/leatherType/addLeatherType', body);
   
    if (res.status === 200) {
      toast.success('Leather Type Added Successfully');
        dispatch({
            type: CREATE_LEATHERTYPE,
            payload: res.data.data.info[0],
          });
        }
      } catch (err) {
    
    toast.warn('Invalid inputs! Leather Type Not Added');
    // dispatch({
    //   type: REGISTER_FAIL,
    // });
  }
};

export const getLeatherType = (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  try {
    const res = await api.get(`/leatherType/leatherType`);
   
    
    dispatch({
      type: RETRIEVE_LEATHERTYPE,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_LEATHERTYPE,
      payload: [],
    });
  }
};

//export const updateLeatherType
export const updateLeatherType = (appId, formData) => async (dispatch) => {
  formData.id = appId;
  try {
    const res = await api.put('/leatherType/updateLeatherType', formData);
   
    if (res.status === 200) {
      dispatch({
        type: UPDATE_LEATHERTYPE,
        payload: res.data.data.info,
      });
      toast.success(res.data.message);
    }
  } catch (error) {
    
    toast.error('Failed to update data!');
    // dispatch({
    //   type: APP_DATA_ERROR,
    //   payload: 'ERROR' + uuidv1(),
    // });
  }
};


export const deleteLeatherType = (id) => async (dispatch) => {
  try {
    const res = await api.delete('/leatherType/deleteLeatherType/' + id);
   
    if (res.status === 200) {
      dispatch({
        type: DELETE_LEATHERTYPE,
        payload: res.data.data.info,
      });
      toast.success(res.data.message);
    }
  } catch (error) {
    
    toast.error('Failed to delete');
    // dispatch({
    //   type: DELETE_LEATHERTYPE,
    //   payload: [],
    // });
  }
};


