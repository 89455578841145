import {
    RETRIEVE_COMPLETE_DISPATCH
} from "../CompleteDispatch/actiontype";

import { toast } from 'react-toastify';
import api from "../../../api";

export const getCompleteDispatch= (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  
  try {
    const res = await api.get(`/dispatchSection/completeDispatch`);
   
    
    dispatch({
      type: RETRIEVE_COMPLETE_DISPATCH,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_COMPLETE_DISPATCH,
      payload: [],
    });
  }
};



