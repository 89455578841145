import {
    CREATE_POLISH,
    RETRIEVE_POLISH,
    DELETE_POLISH,
    RETRIEVE_POLISH_IMAGE
} from "../Polish/actiontype";

import { toast } from 'react-toastify';
import api from "../../../api";

export const polish = ({
  name,
  image,
}) => async (dispatch) => {
  
  try {
    let data = new FormData();
        data.append('name', name);
        data.append('image', image);
        const pic_res = await api.post('/polish/addPolish', data);
        if (pic_res.status === 200) {
          toast.success('Polish Added Successfully');
          dispatch({
            type: CREATE_POLISH,
            payload: pic_res.data.data.info[0],
          });
          
        }
  } catch (error) {
    // toast.error('Unable to update profile!');
    // dispatch({
    //   type: UPDATE_PROFILE_ERROR,
    // });
  }
};

export const getPolish = (data) => async (dispatch) => {
  try {
    const res = await api.get(`/polish/polish`);
    dispatch({
      type: RETRIEVE_POLISH,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_POLISH,
      payload: [],
    });
  }
};


export const deletePolish = (id) => async (dispatch) => {
  try {
    const res = await api.delete('/polish/deletePolish/' + id);
   
    if (res.status === 200) {
      dispatch({
        type: DELETE_POLISH,
        payload: res.data.data.info,
      });
      toast.success(res.data.message);
    }
  } catch (error) {
    
    toast.error('Failed to delete');
    // dispatch({
    //   type: DELETE_POLISH,,
    //   payload: [],
    // });
  }
};

export const getPolishImage = (id) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  try {
    const res = await api.get(`/polish/getPolish/` + id);
  
    dispatch({
      type: RETRIEVE_POLISH_IMAGE,
      payload: res.data.data[0],
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_POLISH_IMAGE,
      payload: [],
    });
  }
};