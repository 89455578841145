import React from "react";
import { useEffect, useRef, useState } from "react";
import avatar from '../../assets/images/avatar-s-11.jpg'
import { Link } from "react-router-dom";

function Profile() {

    const logout = () => {
        window.localStorage.clear();
        window.location.href = '/';
      }
    
  const ref = useRef();

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (isMenuOpen && ref.current && !ref.current.contains(e.target)) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isMenuOpen]);

  return (
    <div className="dropdown" ref={ref}>
      <img className="round" src={process.env.REACT_APP_IMAGE_URL+'/'+localStorage.getItem('image')} alt={ localStorage.getItem('image')} height="40" width="40"  onClick={() => setIsMenuOpen((oldState) => !oldState)}/>
      {isMenuOpen && (
        <div className='profile dropdown-menu'>
            <div className="header-navheading">
                <h6 className="main-notification-title">{ localStorage.getItem('name')}</h6>
                {/* <p className="main-notification-text mb-0">Admin</p> */}
            </div>
            <hr/>
            <Link className="dropdown-item" to="/change-password"> <i className="ri-lock-unlock-line" style={{verticalAlign: "middle"}}></i> Change Password </Link>
            <a className="dropdown-item" href="#" onClick={logout}> <i className="ri-shut-down-line" style={{verticalAlign: "middle"}}></i> Sign Out </a>
        </div>
      )}
    </div>
  );
}

export default Profile;
