import {
    CREATE_CATEGORY,
    RETRIEVE_CATEGORY,
    UPDATE_CATEGORY,
    DELETE_CATEGORY
} from "../Category/actiontype";

const INITIAL_STATE = {
  currentIntegration: [],
  isLoading: false,
  isUpdate:false,
};

const categoryReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  switch (type) {
    case CREATE_CATEGORY:
      return {
        ...state,
        currentIntegration: [...state.currentIntegration, payload],
      };
    case RETRIEVE_CATEGORY:
      return {
        ...state,
        currentIntegration: payload,
        isLoading: true,
      };
    case UPDATE_CATEGORY:
      return {
        ...state,
        currentIntegration: payload,
        isUpdate: true,
      };
    case DELETE_CATEGORY:
      return {
        ...state,
        currentIntegration: state.currentIntegration.filter((item) => parseInt(item.id) !== parseInt(payload)),
      };
    default:
      return state;
  }
};
export default categoryReducer;
