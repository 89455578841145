import React,{useState,useEffect}  from 'react'

import { Row, Col, Container, Button, Card, Badge} from 'react-bootstrap';
import { Formik, Form, Field } from "formik";
import Select from 'react-select';
import { connect } from "react-redux";
import {getOrderDetail,calGrossAmt} from '../../../redux/Order/ViewOrder/action';
import {getCordinator,getReffered} from '../../../redux/Order/CreateOrder/action';
import { updateIncentive } from '../../../redux/Order/ApproveOrder/action';

  const ApproveOrder = ({ getOrderDetail,currentIntegration,imported_ready_product, imported_order_product,getCordinator,cordinators,getReffered,reffereds,getOrder,order_detail,order_name,imported_ready,imported_order,approveOrder,updateIncentive,gross_total,balance,calGrossAmt }) => { useEffect(() => {
    const order_id = window.location.pathname.split("/").pop();
    getOrderDetail(order_id);
    getCordinator();
    getReffered();
  }, []);

  const [cordinatorList, setCordinatorList] = useState(null);
  const [refferedList, setrefferedList] = useState(null);

  useEffect(() => {
    if(cordinators)
    {
      setCordinatorList(cordinators); 
    }
  }, [cordinators])

  useEffect(() => {
    if(reffereds)
    {
      setrefferedList(reffereds); 
    }
  }, [reffereds])

  function getIncentive(e,f,type)
  {
    const data = {
      type: type,
      incentive: e,
      id:  f
    };
    updateIncentive(data);
  }

  const setForm = (e) => {
    var name = e.target.name;
    
    if(name === 'fitting')
    {
      localStorage.setItem('fitting', e.target.value);
    }
    else if(name === 'packing')
    {
      localStorage.setItem('packing', e.target.value);
    }
    else if(name === 'cartage')
    {
      localStorage.setItem('cartage', e.target.value);
    }
    else if(name === 'other')
    {
      localStorage.setItem('other', e.target.value);
    }
    else if(name === 'advance')
    {
      localStorage.setItem('advance', e.target.value);
    }
    calGrossAmt();
  }

  return (
    <>
      <Container fluid className="main-content">
      < Row>
          <Col className='mb-2'>
              <h3 className="main-title">Edit Order</h3>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <Card.Body>
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                  id:currentIntegration.id,
                  serial_no: currentIntegration.serial_no,
                  coordinator: currentIntegration.coordinator_id,
                  refferedby: currentIntegration.reffered_by_id,
                  advance: currentIntegration.advance,
                  balance: "",
                  cartage: currentIntegration.cartage,
                  orderno: currentIntegration.order_name,
                  bookingdate: currentIntegration.dob,
                  deliverydate: currentIntegration.edd,
                  name: currentIntegration.name,
                  mobile: currentIntegration.mobile_no,
                  email: currentIntegration.email,
                  address: currentIntegration.address,
                  total: currentIntegration.total_amount,
                  packing: currentIntegration.packing,
                  fitting: currentIntegration.fitting,
                  grosstotal: "",
                  other: currentIntegration.other,
                  othercharge: currentIntegration.other_name,
                  }}
                  onSubmit={(values) => {
                    console.log(values);
                    approveOrder(values);
                  }}
                >
                  {({ values , setFieldValue}) => (
                    <Form>
                      <Row>
                        <Col lg={4} sm={12}>
                          <label>Order No.</label>
                          <Field name="orderno" type="text" className="form-control" readOnly/>
                        </Col>
                        
                        <Col lg={4} sm={12}>
                          <label>Date Of Booking</label>
                          <Field name="bookingdate" type="date" className="form-control" />
                        </Col>
                        <Col lg={4} sm={12}>
                          <label>Expected Date of Delivery</label>
                          <Field name="deliverydate" type="date" className="form-control"/>
                        </Col>
                        <Col lg={12}>
                          <div className="line"></div>
                        </Col>
                        <Col lg={12} className='mb-2'>
                            <h5>Consultant:</h5>
                          </Col>
                          <Col lg={4} sm={12}>
                            <label>Cordinator</label>
                            <p >{currentIntegration.coordinator},{currentIntegration.coordinator_id}</p>
                            <Select 
                            defaultValue={{ label: currentIntegration.coordinator, value: currentIntegration.coordinator_id }}
                            options={cordinatorList}
                            name="coordinator" 
                            onChange={newValue => setFieldValue("coordinator", newValue.value)} 
                            className='mb-2' 
                          />
                          </Col>
                          <Col lg={{ span: 4, offset: 4 }} sm={12}>
                            <label>Reffered By</label>
                            <p>{currentIntegration.reffered_by},{currentIntegration.reffered_by_id}</p>
                            <Select 
                            defaultValue={{ label: currentIntegration.reffered_by, value: currentIntegration.reffered_by_id }}
                            options={refferedList}
                            name="refferedby" 
                            onChange={newValue => setFieldValue("refferedby", newValue.value)} 
                            className='mb-2' 
                          />
                          </Col>
                          <Col lg={12} className='mt-2'>
                            <div className="line"></div>
                          </Col>
                          <Col lg={12} sm={12} className='mb-2'>
                              <h5>Customer Detail:</h5>
                          </Col>
                        <Col lg={3} sm={12}>
                          <label>Customer Name</label>
                          <Field name="name" type="text" className="form-control"/>
                        </Col>
                        <Col lg={3} sm={12}>
                          <label>Customer Mobile</label>
                          <Field name="mobile" type="text" className="form-control"/>
                        </Col>
                        <Col lg={3} sm={12}>
                          <label>Customer Email</label>
                          <Field name="email" type="text" className="form-control"/>
                        </Col>
                        <Col lg={3} sm={12}>
                          <label>Address</label>
                          <Field name="address" type="text" className="form-control"/>
                        </Col>
                        <Col lg={12}>
                          <div className="line"></div>
                        </Col>
                      </Row>
                      
                      <Row className='mb-2'>
                        <Col>
                          <div className='d-flex justify-content-between'>
                            <h5>Imported Ready Product</h5>
                          </div>
                        </Col>
                      </Row>
                      <Row className='mt-4'>
                      {imported_ready_product && imported_ready_product.map((ord, index) => (
                        <Col lg={3} sm={"12"} key={index}>
                          <div className="card product mb-2">
                            <div className="card-header d-flex justify-content-between pb-0">
                                <h6 className="mb-0"><span className="card-title">{ord.name}-{ord.model_id}</span> <Badge bg="info" text="light" className="ml-2">{ord.qty}</Badge></h6>
                            </div>
                            <div className="card-body pt-1">
                              <Row>
                                <Col lg={4}>
                                  <div>
                                    <img src={process.env.REACT_APP_IMAGE_URL+'/'+ord.image} alt="user name" style={{width: 50, height:50}} className='product-image' width="100%"/>
                                  </div>
                                </Col>
                                <Col lg={8}>
                                  <p className="card-text mb-0"><span className="product-info">Quoted Price:</span> {ord.quotated_price}</p>
                                  <p className="card-text mb-0"><span className="product-info">Net Price:</span> {ord.net_price}</p>
                                  <p className="card-text mb-0"><span className="product-info">Net Amount:</span> {ord.net_amount}</p>
                                </Col>
                                <Col lg={12} className='mt-1'>
                                  <p className="card-text mb-0"><span className="product-info">Category:</span> {ord.category}</p>
                                  <p className="card-text mb-0"><span className="product-info">Color Code:</span> {ord.colour_code}</p>
                                  <p className="card-text mb-0"><span className="product-info">Leather Type:</span> {ord.leather_type}</p>
                                  <p className="card-text mb-0"><span className="product-info">Product Location:</span> {ord.location}</p>
                                  <p className="card-text mb-0"><span className="product-info">Remarks:</span> {ord.remarks}</p>
                                  <p className="card-text mb-0"><span className="product-info">Expected Incentive:</span> {ord.incentive}</p>
                                  <p className="card-text mb-0"><span className="product-info">Incentive:</span> <input type="number" className="form-control"  style={{height: '25px'}} onChange={ (event) => getIncentive(event.target.value,ord.id,'Ready') }  /></p>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Col>
                        ))}
                       
                        <Col lg={12}>
                          <div className="line"></div>
                        </Col>
                      </Row>
                     
                      <Row className='mb-2'>
                        <Col>
                          <div className='d-flex justify-content-between'>
                            <h5>Imported On Order</h5>
                          </div>
                        </Col>
                      </Row>
                      <Row className='mt-4'>
                      {imported_order_product && imported_order_product.map((quot, index) => (
                        <Col lg={3} sm={"12"} key={index}>
                          <div className="card product mb-2">
                            <div className="card-header d-flex justify-content-between pb-0">
                                <h6 className="mb-0"><span className="card-title">{quot.name}-{quot.model_id}</span> <Badge bg="info" text="light" className="ml-2">{quot.qty}</Badge></h6>
                            </div>
                            <div className="card-body pt-1">
                              <Row>
                                <Col lg={4}>
                                  <div>
                                    <img src={process.env.REACT_APP_IMAGE_URL+'/'+quot.image} alt={quot.image} style={{width: 50, height:50}} className='product-image' width="100%"/>
                                  </div>
                                </Col>
                                <Col lg={8}>
                                  <p className="card-text mb-0"><span className="product-info">Quoted Price:</span>  {quot.quotated_price}</p>
                                  <p className="card-text mb-0"><span className="product-info">Net Price:</span> {quot.net_price}</p>
                                  <p className="card-text mb-0"><span className="product-info">Net Amount:</span> {quot.net_amount}</p>
                                </Col>
                                <Col lg={12} className='mt-1'>
                                  <p className="card-text mb-0"><span className="product-info">Category:</span> {quot.category}</p>
                                  <p className="card-text mb-0"><span className="product-info">Color Code:</span> {quot.colour_code}</p>
                                  <p className="card-text mb-0"><span className="product-info">Leather Type:</span> {quot.leather_type}</p>
                                  <p className="card-text mb-0"><span className="product-info">Remarks:</span> {quot.remarks}</p>
                                  <p className="card-text mb-0"><span className="product-info">Expected Incentive:</span> {quot.incentive}</p>
                                  <p className="card-text mb-0"><span className="product-info">Incentive:</span> <input type="text" className="form-control" value={quot.incentive} style={{height: '25px'}} onChange={ (event) => getIncentive(event.target.value,quot.id,'Order') }  /></p>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Col>
                        ))}
                        <Col lg={12}>
                          <div className="line"></div>
                        </Col>
                      </Row>
                      
                      <Row className='mb-2'>
                        <Col>
                        <h5>Billing</h5>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12}>
                            <Row>
                            <Col lg={{ span: 4, offset: 4 }} sm={3} style={{textAlign: "right"}}>
                              <label>Total</label>
                            </Col>
                            <Col lg={4} sm={9}>
                              <Field name="total" type="text" className="form-control" readOnly/>
                            </Col>
                            <Col lg={{ span: 4, offset: 4 }} sm={3} style={{textAlign: "right"}}>
                              <label>Fitting</label>
                            </Col>
                            <Col lg={4} sm={9}>
                              <Field name="fitting" type="text" className="form-control" onKeyUp={(e) => { setForm(e);}}/>
                            </Col>
                            <Col lg={{ span: 4, offset: 4 }} sm={3} style={{textAlign: "right"}}>
                              <label>Packing</label>
                            </Col>
                            <Col lg={4} sm={9}>
                              <Field name="packing" type="text" className="form-control" onKeyUp={(e) => { setForm(e);}}/>
                            </Col>
                            <Col lg={{ span: 4, offset: 4 }} sm={3} style={{textAlign: "right"}}>
                              <label>Cartage</label>
                            </Col>
                            <Col lg={4} sm={9}>
                              <Field name="cartage" type="text" className="form-control" onKeyUp={(e) => { setForm(e);}}/>
                            </Col>
                            <Col lg={{ span: 4, offset: 4 }} sm={3} style={{textAlign: "right"}}>
                              <div className='d-flex justify-content-end'>
                              <label>Other Charge</label>
                              <Field name="othercharge" type="text" className="form-control w-50 ml-2" />
                              </div>
                            </Col>
                            <Col lg={4} sm={9}>
                              <Field name="other" type="text" className="form-control" onKeyUp={(e) => { setForm(e);}}/>
                            </Col>
                            <Col lg={{ span: 4, offset: 4 }} sm={3} style={{textAlign: "right"}}>
                              <label>Gross Total</label>
                            </Col>
                            <Col lg={4} sm={9}>
                              <Field name="grosstotal" type="text" className="form-control" readOnly value={gross_total}/>
                            </Col>
                            <Col lg={{ span: 4, offset: 4 }} sm={3} style={{textAlign: "right"}}>
                              <label>Advance</label>
                            </Col>
                            <Col lg={4} sm={9}>
                              <Field name="advance" type="text" className="form-control" />
                            </Col>
                            <Col lg={{ span: 4, offset: 4 }} sm={3} style={{textAlign: "right"}}>
                              <label>Balance</label>
                            </Col>
                            <Col lg={4} sm={9}>
                              <Field name="balance" type="text" className="form-control" readOnly value={balance}/>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row className='mt-4'>
                        <Col>
                          <Button variant="primary" type="submit" style={{float: 'right'}}> Submit </Button>
                        </Col>
                      </Row>
                  </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      
    </>
    
  )
}


const mapStateToProps = (state) => {
  console.log(state);
  return {
    currentIntegration: state.orderDetail.currentIntegration,
    imported_ready_product: state.orderDetail.currentIntegration.imported_ready,
    imported_order_product: state.orderDetail.currentIntegration.imported_order,
    payment_history: state.orderDetail.currentIntegration.payment_history,
    cordinators: state.orderReducer.cordinator,
    reffereds: state.orderReducer.reffered,
    gross_total:state.orderDetail.gross_total,
    balance:state.orderDetail.balance,
  }
 }
 
export default connect(mapStateToProps,{getOrderDetail,updateIncentive,getCordinator,getReffered,calGrossAmt})(ApproveOrder);
