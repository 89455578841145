import * as React from "react";
import {useState, useEffect} from 'react'
import { Row, Col, Container, Badge, Dropdown } from 'react-bootstrap';
import MaterialTable from 'material-table';
import { connect } from "react-redux";
import {getExecutiveWiseReport} from '../../redux/Report/ExecutiveWise/action';
import SearchBar from "../../components/SearchBar/SearchBar";
import Loader from "../../components/Loader/Loader";

const ExecutiveWise = ({ getExecutiveWiseReport,currentIntegration,isLoading}) =>  {  useEffect(() => {
    getExecutiveWiseReport();
  }, []);

const [visible, setVisible] = useState(false);
const handleVisibility = () => {
    setVisible(prev => !prev);
};  

const [loading, setLoading] = useState(false);
useEffect(() => {
    if (isLoading) {
        setLoading(true);
    }
  });

const [tableData, setTableData] = useState([]);
    useEffect(() => {
    if(currentIntegration)
    {
        setTableData(currentIntegration); 
    }
    }, [currentIntegration])

const columns = [
{ title: "Created By", field: "created_by" },
{ title: "Order No.", field: "orderNo" },
{ title: "Customer Detail", field: "customerDetail" },
{ title: "Date Of Order", field: "doo" },
{ title: "Quantity", field: "qty" },
{ title: "Total Amount", field: "totalAmount" },
{ title: "Total Discount", field: "totalDiscount" },
{ title: "Grand Total", field: "grandTotal" },
{ title: "Total Due", field: "totalDue" },
{ title: "Status", field: "status" },
{ title: "Incentive Amount", field: "incentiveAmount" },
{ title: "Incentive status", field: "incentiveStatus" },
]

// Search for Card view start
const [searchValue, setSearchValue] = useState("");

const filterOrders = ({ orderNo }) => {
  return orderNo.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
};
// Search for Card view end

  return (
    <>
        <Container fluid className="main-content">
          <Row>
              <Col lg={"12"} className='d-flex justify-content-between mb-2'>
                  <h3 className="main-title">Executive Wise Report</h3>
                  <div className=" d-block d-sm-none">
                      <div className="magnifierContainer">
                          <button type="button" className="btn btn-md btn-warning text-white roundedcircle mr-3" onClick={handleVisibility}><i className="fa-solid fa-search"></i></button>
                      </div>
                  </div>
              </Col>
              <Col lg={"12"} className='mb-2 d-block d-sm-none'>
                <div className={visible ? "open" : "close"}>
                  <SearchBar onSearch={setSearchValue} value={searchValue} />
                </div>
              </Col>
          </Row>
          { loading ? <div>
          <Row className="d-none d-sm-block">
            <Col lg={"12"}>
              <div style={{display: 'table', tableLayout:'fixed', width:'100%'}}>
              <MaterialTable columns={columns} data={tableData}
                  options={{
                  sorting: true, search: true,
                  paging: true, pageSizeOptions: [10, 25, 50, 100], pageSize: 10,
                  exportButton: true,
                  exportAllData: true, exportFileName: "Executive Wise Report", actionsColumnIndex: -1,
                  // headerStyle: { background: "#f44336",color:"#fff"}
                  }}
                  title="Executive Wise Report"
              />
              </div>
            </Col>
          </Row>
          <Row className="d-block d-sm-none">
            {currentIntegration.filter(filterOrders).map((report, index) => (
              <Col xs={"12"} key={index}>
                  <div className="card mb-2">
                      <div className="card-header d-flex justify-content-between pb-0">
                          <h6>{report.orderNo} <Badge bg="danger" text="light" className="ml-2">{report.qty}</Badge></h6>
                          <Dropdown>
                          <Dropdown.Toggle variant="light" id="dropdown-basic">
                              <i className="ri-more-2-line"></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                              <Dropdown.Item href="#/action-1"><i className="ri-edit-box-line text-primary"></i> Edit</Dropdown.Item>
                              <Dropdown.Item href="#/action-2"><i className="far fa-trash-alt text-danger"></i> Delete</Dropdown.Item>
                          </Dropdown.Menu>
                          </Dropdown>
                      </div>
                      <div className="card-body pt-1">
                          <p className="card-text mb-0"><span className="quotation-info">Customer Detail:</span> {report.customerDetail}</p>
                          <p className="card-text mb-0"><span className="quotation-info">Created By:</span> {report.created_by}</p>
                          <p className="card-text mb-0"><span className="quotation-info">Net Amount:</span> {report.totalAmount}</p>
                          <p className="card-text mb-0"><span className="quotation-info">Total Discount:</span> {report.totalDiscount}</p>
                          <p className="card-text mb-0"><span className="quotation-info">Grand Total:</span> {report.grandTotal}</p>
                          <p className="card-text mb-0"><span className="quotation-info">Total Due:</span> {report.totalDue}</p>
                          <p className="card-text mb-0"><span className="quotation-info">Order Date:</span> {report.doo}</p>
                          <p className="card-text mb-0"><span className="quotation-info">Status:</span> {report.status}</p>
                          <p className="card-text mb-0"><span className="quotation-info">Incentive Amount:</span> {report.incentiveAmount}</p>
                          <p className="card-text mb-0"><span className="quotation-info">Incentive Status:</span> {report.incentiveStatus}</p>
                      </div>
                  </div>
              </Col>
              ))}
            </Row>
            </div> : <Loader/> }
        </Container>
    </>

  )
}

const mapStateToProps = (state) => {
    
    return {
       currentIntegration: state.executiveWiseReport.currentIntegration,
       isLoading: state.executiveWiseReport.isLoading,
     }
   }
  
export default connect(mapStateToProps,{getExecutiveWiseReport})(ExecutiveWise);