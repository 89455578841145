import {
    CREATE_PRODUCTWISE_REPORT,
    RETRIEVE_PRODUCTWISE_REPORT,
    UPDATE_PRODUCTWISE_REPORT,
    DELETE_PRODUCTWISE_REPORT
} from "../ProductWise/actiontype";

const INITIAL_STATE = {
  currentIntegration: [],
  isLoading: false,
};

const  productWiseReportReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case CREATE_PRODUCTWISE_REPORT:
      return {
        ...state
      };
    case RETRIEVE_PRODUCTWISE_REPORT:
      return {
        ...state,
        currentIntegration: payload,
        isLoading: true,
      };
    case UPDATE_PRODUCTWISE_REPORT:
      return state.map((productWiseReport) => {
        if (productWiseReport.id === payload.id) {
          return {
            ...productWiseReport,
            ...payload,
          };
        } else {
          return productWiseReport;
        }
      });
    case DELETE_PRODUCTWISE_REPORT:
      return state.filter(({ id }) => id !== payload.id);
    default:
      return state;
  }
};
export default productWiseReportReducer;