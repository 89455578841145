import {
  CREATE_STOCKIN
} from "../StockIn/actiontype";

const INITIAL_STATE = {
  currentIntegration: [],
};
const stockInReducer = (state = INITIAL_STATE, action) => {

  const { type, payload } = action;

  switch (type) {
    case CREATE_STOCKIN:
      return {
        ...state,
        currentIntegration: [...state.currentIntegration, payload],
      };
    
    default:
      return state;
  }
};
export default stockInReducer;