import {
    CREATE_VENDOR_INCENTIVE,
    RETRIEVE_VENDOR_INCENTIVE,
    UPDATE_VENDOR_INCENTIVE,
    DELETE_VENDOR_INCENTIVE,
    REALEASE_INCENTIVE
} from "../VendorIncentive/actiontype";

const INITIAL_STATE = {
  currentIntegration: [],
  isLoading: false,
};

const vendorIncentiveReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  
  switch (type) {
    case CREATE_VENDOR_INCENTIVE:
      return {
        ...state
      };
    case RETRIEVE_VENDOR_INCENTIVE:
      return {
        ...state,
        currentIntegration: payload,
        isLoading: true,
      };
    case UPDATE_VENDOR_INCENTIVE:
      return state.map((vendorIncentive) => {
        if (vendorIncentive.id === payload.id) {
          return {
            ...vendorIncentive,
            ...payload,
          };
        } else {
          return vendorIncentive;
        }
      });
      case REALEASE_INCENTIVE:
      return {
        ...state,
        currentIntegration: state.currentIntegration.filter((item) => parseInt(item.id) !== parseInt(payload)),
      };
    case DELETE_VENDOR_INCENTIVE:
      return state.filter(({ id }) => id !== payload.id);
    default:
      return state;
  }
};
export default vendorIncentiveReducer;