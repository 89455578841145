export const CREATE_IMPORTEDPRODUCT = "CREATE_IMPORTEDPRODUCT";
export const RETRIEVE_IMPORTEDPRODUCT = "RETRIEVE_IMPORTEDPRODUCT";
export const UPDATE_IMPORTEDPRODUCT = "UPDATE_IMPORTEDPRODUCT";
export const DELETE_IMPORTEDPRODUCT = "DELETE_IMPORTEDPRODUCT";
export const SET_BRANDS = "SET_BRANDS";
export const SET_PRODUCT_CATEGORY = "SET_PRODUCT_CATEGORY";
export const SET_LEATHER_CATEGORY = "SET_LEATHER_CATEGORY";
export const SET_LEATHER_TYPE = "SET_LEATHER_TYPE";
export const SET_LEATHER_COLOUR = "SET_LEATHER_COLOUR";
export const SET_COLOUR_CODE = "SET_COLOUR_CODE";
export const RETRIEVE_SINGLE_IMPORTEDPRODUCT = "RETRIEVE_SINGLE_IMPORTEDPRODUCT";
export const PRINT_QRCODE = "PRINT_QRCODE";
