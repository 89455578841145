import {
    RETRIEVE_DUE_INCENTIVE,
    APPROVE_DUE_INCENTIVE
} from "../DueIncentive/actiontype";

import { toast } from 'react-toastify';
import api from "../../../api";

export const getDueIncentive= (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  try {
    const res = await api.get(`/accountSection/dueIncentive`);
   
    
    dispatch({
      type: RETRIEVE_DUE_INCENTIVE,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_DUE_INCENTIVE,
      payload: [],
    });
  }
};

export const approveDueIncentive= (id) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  
  try {
    const res = await api.get(`/accountSection/approveDueIncentive/`+id);
    if (res.status === 200) {
      toast.success(res.data.message);
    
    dispatch({
      type:  APPROVE_DUE_INCENTIVE,
      payload: res.data.data,
    });
  }
  } catch (err) {
    
    dispatch({
      type:  APPROVE_DUE_INCENTIVE,
      payload: [],
    });
  }
};



