import { FlareSharp } from "@mui/icons-material";
import {
    CREATE_PENDING_QUOTATION,
    RETRIEVE_PENDING_QUOTATION,
    UPDATE_PENDING_QUOTATION,
    DELETE_PENDING_QUOTATION,
    DECLINE_PENDING_QUOTATION,
    APPROVE_PENDING_QUOTATION
} from "../PendingQuotation/actiontype";

const INITIAL_STATE = {
  currentIntegration: [],
  isLoading: false,
};

const pendingQuotationReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  
  switch (type) {
    case CREATE_PENDING_QUOTATION:
      return {
        ...state
      };
    case RETRIEVE_PENDING_QUOTATION:
      return {
        ...state,
        currentIntegration: payload,
        isLoading: true,
      };
    case UPDATE_PENDING_QUOTATION:
      return state.map((pendingQuotation) => {
        if (pendingQuotation.id === payload.id) {
          return {
            ...pendingQuotation,
            ...payload,
          };
        } else {
          return pendingQuotation;
        }
      });
    case APPROVE_PENDING_QUOTATION:
      return {
        ...state,
        currentIntegration: state.currentIntegration.filter((item) => parseInt(item.id) !== parseInt(payload)),
      };
    case DECLINE_PENDING_QUOTATION:
      return {
        ...state,
        currentIntegration: state.currentIntegration.filter((item) => parseInt(item.id) !== parseInt(payload)),
      };
    case DELETE_PENDING_QUOTATION:
      return {
        ...state,
        currentIntegration: state.currentIntegration.filter((item) => parseInt(item.id) !== parseInt(payload)),
      };
    default:
      return state;
  }
};
export default pendingQuotationReducer;