import {
  RETRIEVE_ALLOTED_ORDER,
  ADD_PRODUCT_TO_CART
} from "../AllotedOrder/actiontype";

const INITIAL_STATE = {
  currentIntegration: [],
  isLoading: false,
  cart_qty:0
};

const cpAllotedOrderReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
 
  switch (type) {
    case  RETRIEVE_ALLOTED_ORDER:
      return {
        ...state,
        currentIntegration: payload,
        isLoading: true,
      };
      case ADD_PRODUCT_TO_CART:
      return {
        ...state,
        cart_qty: payload,
      };
      default:
        return state;
  }
};
export default cpAllotedOrderReducer;