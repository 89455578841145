import React, { useRef, useEffect } from "react";
// import { Button } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";
import {Container, Row, Col, Card} from 'react-bootstrap';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import { connect } from "react-redux";
import {getOrderDetail} from '../../../redux/Order/ViewOrder/action';

const PrintOrder = ({ getOrderDetail,currentIntegration,imported_ready_product, imported_order_product,payment_history }) =>  {  useEffect(() => {
    const order_id = window.location.pathname.split("/").pop();
    getOrderDetail(order_id);
}, []);
  
const componentRef = useRef();
const handlePrint = useReactToPrint({
content: () => componentRef.current,
});

  return (
    <>
    <Container fluid className="main-content">
      <Row className="mb-3">
        <Col>
          <h3 className="main-title mb-0">Order - {currentIntegration.order_name}</h3>
        </Col>
        <Col>
          <button type="button" onClick={handlePrint} className="btn btn-md btn-warning text-white" style={{float: "right"}}><i class="ri-printer-line"></i> Print</button>
        </Col>
      </Row>
      <div ref={componentRef}>
          <Row>
              <Col>
                  <Card>
                      <Card.Body>
                          <Row className='mt-3'>
                              <Col lg={12}>
                                  <h5 className="card-title mb-3 ml-3">AAKRITI#ORD_1003</h5>
                              </Col>
                              <Col lg={12}>
                                  <div className='d-flex justify-content-between'>
                                      <p className="mb-0"><span style={{fontWeight: "500"}}>DOB :</span> {currentIntegration.dob}</p>
                                      <p className="mb-0"><span style={{fontWeight: "500"}}>EDD:</span> {currentIntegration.edd}</p>
                                  </div>
                              </Col>
                              <Col>
                                  <div className='line'></div>
                              </Col>
                          </Row>
                          <Row>
                              <Col lg={12}>
                                  <div className='d-flex justify-content-between' >
                                      <div className='mb-3'>
                                          <h6>Customer Name:</h6>
                                          <p className='mb-0'>Name: {currentIntegration.name}</p>
                                          <p className='mb-0'>Mobile: {currentIntegration.mobile_no}</p>
                                          <p className='mb-0'>Address: {currentIntegration.address}</p>
                                          <p className='mb-0'>Email: {currentIntegration.email}</p>
                                      </div>
                                      <div className='mb-3'>
                                          <h6>Consultant:</h6>
                                          <p className='mb-0'>Cordinator: {currentIntegration.coordinator}</p>
                                          <p className='mb-0'>Reffered by : {currentIntegration.reffered_by}</p>
                                          <p className='mb-0'>Approved by: {currentIntegration.approved_by}</p>
                                      </div>
                                  </div>
                              </Col>
                              <Col>
                                  <div className='line mt-0'></div>
                              </Col>
                          </Row>
                          <Row>
                              <Col lg={12}>
                                  <h6 className='text-danger mb-4'>Imported Ready Product</h6>
                              </Col>
                              <Col lg={12}>
                                  <Table striped bordered hover>
                                 
                                      <Thead>
                                          <Tr>
                                          <Th className='custom-table-header'>#</Th>
                                          <Th className='custom-table-header'>MODEL NO</Th>
                                          <Th className='custom-table-header'>DESCRIPTION</Th>
                                          <Th className='custom-table-header'>REMARKS</Th>
                                          <Th className='custom-table-header'>UNIT</Th>
                                          <Th className='custom-table-header'>QTY</Th>
                                          </Tr>
                                      </Thead>
                                      <Tbody>
                                        {imported_ready_product && imported_ready_product.map((order, index) => (
                                          <Tr key={index}>
                                          <Td className='custom-table'>{index+1}</Td>
                                          <Td className='custom-table'>{order.model_no}</Td>
                                           <Td className='custom-table'>
                                                <ul class="description-list">
                                                    <li>Product Photo: {order.name}</li>
                                                    <li>Product Category: {order.product_category} </li>
                                                    <li>Color Code: {order.colour_code}</li>
                                                    <li>Qty: {order.qty}</li>
                                                </ul>
                                           </Td>
                                          <Td className='custom-table'>{order.remark}</Td>
                                          <Td className='custom-table'>{order.unit}</Td>
                                          <Td className='custom-table'>{order.qty}</Td>
                                          </Tr>
                                        ))}
                                      </Tbody>
                                  </Table>
                              </Col>
                              <Col lg={12}>
                                  <h6 className='text-danger mb-4 mt-4'>Imported on Order</h6>
                              </Col>
                              <Col lg={12}>
                                  <Table striped bordered hover>
                                      <Thead>
                                          <Tr>
                                          <Th className='custom-table-header'>#</Th>
                                          <Th className='custom-table-header'>MODEL NO</Th>
                                          <Th className='custom-table-header'>DESCRIPTION</Th>
                                          <Th className='custom-table-header'>LEATHER TYPE</Th>
                                          <Th className='custom-table-header'>QTY</Th>
                                          </Tr>
                                      </Thead>
                                      <Tbody>
                                      {imported_order_product && imported_order_product.map((order, index) => (
                                          <Tr key={index}>
                                            <Td className='custom-table'>{index+1}</Td>
                                            <Td className='custom-table'>{order.model_no}</Td>
                                            <Td className='custom-table'>
                                                <ul class="description-list">
                                                    <li>Product Photo: {order.name}</li>
                                                    <li>Product Category: {order.product_category} </li>
                                                    <li>Color Code: {order.colour_code}</li>
                                                    <li>Qty: {order.qty}</li>
                                                </ul>
                                            </Td>
                                            <Td className='custom-table'>{order.leather_type}</Td>
                                            <Td className='custom-table'>{order.qty}</Td>
                                          </Tr>
                                      ))}
                                      </Tbody>
                                  </Table>
                              </Col>
                              <Col lg={12}>
                                  <div className='line'></div>
                              </Col>
                              </Row>
                              <Row>
                              <Col lg={{ span: 6, offset: 6 }} sm={12}>
                                  <div class="table-responsive">
                                      <table class="table table-invoice table-bordered">
                                          <thead> </thead>
                                          <tbody>
                                              <tr>
                                                  <td class="tx-right tx-11">Fitting</td>
                                                  <td class="tx-right tx-12" colspan="2">₹ {currentIntegration.fitting} </td>
                                              </tr>
                                              <tr>
                                                  <td class="tx-right tx-11">Total</td>
                                                  <td class="tx-right tx-12" colspan="2">₹ {currentIntegration.total_amount}</td>
                                              </tr>
                                          </tbody>
                                      </table>
                                  </div>
                              </Col>
                          </Row>
                          
                      </Card.Body>
                  </Card>
              </Col>
          </Row>
      </div>
      </Container>
  </>

    )
}
   
const mapStateToProps = (state) => {
        return {
           currentIntegration: state.orderDetail.currentIntegration,
           imported_ready_product: state.orderDetail.currentIntegration.imported_ready,
           imported_order_product: state.orderDetail.currentIntegration.imported_order,
           payment_history: state.orderDetail.currentIntegration.payment_history,
         }
       }
      
export default connect(mapStateToProps,{getOrderDetail})(PrintOrder);
