import {
    CREATE_CUSTOMERS,
    RETRIEVE_CUSTOMERS,
    UPDATE_CUSTOMERS,
    DELETE_CUSTOMERS,
    RETRIEVE_SINGLE_CUSTOMERS
} from "../Customers/actiontype";

const INITIAL_STATE = {
  currentIntegration: [],
  singleCustomer: [],
  isLoading: false,
  isUpdate: false,
};
const customerReducer = (state1 = INITIAL_STATE, action) => {

  const { type, payload } = action;

  switch (type) {
    case CREATE_CUSTOMERS:
      return {
        ...state1,
        currentIntegration: [...state1.currentIntegration, payload],
      };
    case RETRIEVE_CUSTOMERS:
      return {
        ...state1,
        currentIntegration: payload,
        isLoading: true,
      };
    case RETRIEVE_SINGLE_CUSTOMERS:
      return {
        ...state1,
        singleCustomer: payload,
      };
    case UPDATE_CUSTOMERS:
      return {
        ...state1,
        isUpdate: true,
      };
    case DELETE_CUSTOMERS:
      return {
        ...state1,
        currentIntegration: state1.currentIntegration.filter((item) => parseInt(item.id) !== parseInt(payload)),
      };
    default:
      return state1;
  }
};
export default customerReducer;