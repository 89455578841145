import * as React from "react";
import { useState, useEffect } from 'react'
import { Row, Col, Container, Dropdown, Badge } from 'react-bootstrap';
import MaterialTable from 'material-table';
import CheckIcon from '@mui/icons-material/Check';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PrintIcon from '@mui/icons-material/Print';
import { connect } from "react-redux";
import {getProductionOrder} from '../../../redux/Production/ProductionOrder/action';
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import { useNavigate } from 'react-router-dom';

const ProductionOrder = ({ getProductionOrder,currentIntegration }) =>  {  useEffect(() => {
    getProductionOrder();
}, []);

const [visible, setVisible] = useState(false);
const handleVisibility = () => {
    setVisible(prev => !prev);
};

const [tableData, setTableData] = useState([]);
useEffect(() => {
if(currentIntegration)
{
    setTableData(currentIntegration); 
}
}, [currentIntegration])

const columns = [
    { title: "Order No.", field: "orderNo" },
    { title: "Order Date", field: "date" },
    { title: "Expected Delivery Date", field: "edd" },
    { title: "Quantity", field: "qty" },
    { title: "Created By", field: "name" },
]
const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', type: ''})
const navigate = useNavigate();
// const dispatchOrder = async (e) => {
//     navigate('/pending-to-dispatch/'+e);
// }
const printOrder = async (e) => {
    navigate('/dispatch-order-print');
}
    return (
        <>
            <Container fluid className="main-content">
                <Row>
                    <Col className='d-flex justify-content-between mb-2'>
                        <h3 className="main-title">View Production Order</h3>
                        <div className="d-flex justify-content-between d-block d-sm-none">
                            <div className="magnifierContainer">
                                <button type="button" className="btn btn-md btn-warning text-white roundedcircle mr-3" onClick={handleVisibility}><i className="fa-solid fa-search"></i></button>
                            </div>
                        </div>
                    </Col>
                    <Col lg={"12"} className='mb-2 d-block d-sm-none'>
                        <div className={visible ? "open" : "close"}>
                            <input placeholder="Search..." type="text" className="form-control is-open mr-3 mb-0 search-input"/>
                        </div>
                    </Col>
                </Row>
                <Row className="d-none d-sm-block mt-2">
                    <Col lg={"12"}>
                        <div style={{ display: 'table', tableLayout: 'fixed', width: '100%' }}>
                            <MaterialTable columns={columns} data={tableData}
                               actions={[
                                {
                                  icon: () => <VisibilityIcon color={'primary'} />,
                                  tooltip: 'View',
                                //   onClick: (event, rowData) => dispatchOrder(rowData.serial_no)
                                },
                                {
                                  icon: () => <CheckIcon color={'success'} />,
                                  tooltip: 'Mark As Under Process',
                                //   onClick: (event, rowData) => dispatchOrder(rowData.serial_no)
                                },
                                {
                                  icon: () => <PrintIcon color={'warning'} />,
                                  tooltip: 'Print',
                                  onClick: (event, rowData) => printOrder(rowData.id)
                                }
                            ]}
                                options={{
                                    sorting: true, search: true,
                                    paging: true, pageSizeOptions: [10, 25, 50, 100], pageSize: 10,
                                    exportButton: true,
                                    exportAllData: true, exportFileName: "Production Order", actionsColumnIndex: -1,
                                    // headerStyle: { background: "#f44336",color:"#fff"}
                                }}
                                title="Production Order"
                            />
                        </div>
                    </Col>
                </Row>
                <Row className="d-block d-sm-none">
                    {currentIntegration.map((production, index) => (
                    <Col xs={"12"} key={index}>
                        <div className="card mb-2">
                            <div className="card-header d-flex justify-content-between pb-0">
                                <h6>{production.orderNo}  <Badge bg="danger" text="light" className="ml-2">{production.qty}</Badge></h6>
                                <Dropdown>
                                <Dropdown.Toggle variant="light" id="dropdown-basic">
                                    <i className="ri-more-2-line"></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1"><i className="ri-edit-box-line text-primary"></i> Edit</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2"><i className="far fa-trash-alt text-danger"></i> Delete</Dropdown.Item>
                                </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className="card-body pt-1">
                                <p className="card-text mb-0"><span className="quotation-info">Created By:</span> {production.name}</p>
                                <p className="card-text mb-0"><span className="quotation-info">Order Date:</span> {production.date}</p>
                                <p className="card-text mb-0"><span className="quotation-info">Delivery Date:</span> {production.edd}</p>
                            </div>
                        </div>
                    </Col>
                    ))}
                </Row>
            </Container>
            <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
            />
        </>

    )
}

const mapStateToProps = (state) => {

  return {
     currentIntegration: state.productionOrder.currentIntegration,
   }
 }

 export default connect(mapStateToProps,{getProductionOrder})(ProductionOrder);