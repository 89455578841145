import {
    CREATE_COLOURCODE,
    RETRIEVE_COLOURCODE,
    DELETE_COLOURCODE,
    UPDATE_COLOURCODE
} from "../ColourCode/actiontype";

import { toast } from 'react-toastify';
import api from "../../../api";

export const colourCode = (registerData) => async (dispatch) => {
  const body = JSON.stringify(registerData);
  try {
    const res = await api.post('/colourcode/addColourCode', body);
   
    if (res.status === 200) {
      toast.success('Colour Code Added Successfully');
        dispatch({
            type: CREATE_COLOURCODE,
            payload: res.data.data.info[0],
          });
        }
      } catch (err) {
    
    toast.warn('Invalid inputs! Colour Code Not Added');
    // dispatch({
    //   type: REGISTER_FAIL,
    // });
  }
};

export const getColourCode = (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  try {
    const res = await api.get(`/colourcode/colourcode`);
   
    
    dispatch({
      type: RETRIEVE_COLOURCODE,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_COLOURCODE,
      payload: [],
    });
  }
};


//export const updateColourCode
export const updateColourCode = (appId, formData) => async (dispatch) => {
  formData.id = appId;
  try {
    const res = await api.put('/colourcode/updateColourCode', formData);
   
    if (res.status === 200) {
      dispatch({
        type: UPDATE_COLOURCODE,
        payload: res.data.data.info,
      });
      toast.success(res.data.message);
    }
  } catch (error) {
    
    toast.error('Failed to update data!');
    // dispatch({
    //   type: APP_DATA_ERROR,
    //   payload: 'ERROR' + uuidv1(),
    // });
  }
};


export const deleteColourCode = (id) => async (dispatch) => {
  try {
    const res = await api.delete('/colourcode/deleteColourCode/' + id);
   
    if (res.status === 200) {
      dispatch({
        type: DELETE_COLOURCODE,
        payload: res.data.data.info,
      });
      toast.success(res.data.message);
    }
  } catch (error) {
    
    toast.error('Failed to delete');
    // dispatch({
    //   type: DELETE_COLOURCODE,
    //   payload: [],
    // });
  }
};