import React from 'react'
import { Row, Col, Container, Button, Form} from 'react-bootstrap';

export const StepFour = ({ formData, setForm, navigation }) => {
  const { total, fitting, packing, cartage, othercharge, grosstotal, advance, balance, other  } = formData;
  const { go } = navigation;
  return (
      <>
       <Container fluid>
          <Row className='mb-2'>
            <Col>
            <h5>Billing</h5>
            </Col>
          </Row>
          <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
            <Form.Label column sm={3}>Total</Form.Label>
            <Col sm={9}>
              <Form.Control name="total" value={total} type="text" onChange={setForm} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
            <Form.Label column sm={3}>Fitting</Form.Label>
            <Col sm={9}>
              <Form.Control name="fitting" value={fitting} type="text" onChange={setForm} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
            <Form.Label column sm={3}>Packing</Form.Label>
            <Col sm={9}>
              <Form.Control name="packing" value={packing} type="text" onChange={setForm} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
            <Form.Label column sm={3}>Cartage</Form.Label>
            <Col sm={9}>
              <Form.Control name="cartage" value={cartage} type="text" onChange={setForm} />
            </Col>
          </Form.Group>
          <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
            <Form.Label column sm={1}>Other charges</Form.Label>
            <Col sm={2}>
              <Form.Control name="other" value={other} type="text" onChange={setForm} />
            </Col>
            <Col sm={9}>
              <Form.Control name="othercharge" value={othercharge} type="text" onChange={setForm} />
            </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
              <Form.Label column sm={3}>Gross Total</Form.Label>
              <Col sm={9}>
                <Form.Control name="grosstotal" value={grosstotal} type="text" onChange={setForm} />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
              <Form.Label column sm={3}>Advance</Form.Label>
              <Col sm={9}>
                <Form.Control name="advance" value={advance} type="text" onChange={setForm} />
              </Col>
            </Form.Group>
            <Form.Group as={Row} className="mb-3" controlId="formHorizontalEmail">
              <Form.Label column sm={3}>Balance</Form.Label>
              <Col sm={9}>
                <Form.Control name="balance" value={balance} type="text" onChange={setForm} />
              </Col>
            </Form.Group>
            <Row className='mt-4'>
              <Col>
                <Button variant="primary" onClick={() => navigation.previous()} > Back </Button>
              </Col>
              <Col>
                <Button variant="primary" style={{float: 'right'}} onClick={() => go('submit')} > Submit </Button>
              </Col>
            </Row>
       </Container>
      </>
  )
}
