import * as React from "react";
import {useState, useEffect} from 'react'
import { Row, Col, Container, Card, Button, Modal, Badge, Dropdown } from 'react-bootstrap';
import MaterialTable from 'material-table';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import KErrorMessage from "../../../components/ErrorMessage/KErrorMessage";
import { Formik, Form, Field } from "formik";
import * as yup from "yup";
import { connect } from "react-redux";
import {getApprovedQuotation, deleteQuotation, declineQuotation} from '../../../redux/Quotation/ApprovedQuotation/action';
import {Link } from "react-router-dom";
import Select from 'react-select';
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import { useNavigate } from 'react-router-dom';
import Loader from "../../../components/Loader/Loader";

const validationSchema = yup.object({
    name: yup.string().required("Customer Name is Required"),
    phone: yup
    .number()
    .min(1000000000, "Not Valid Phone Number")
    .max(9999999999, "Not Valid Phone Number")
    .required("Phone is Required"),
    email: yup.string().email('Must be a valid email').max(255).required('Email is Required'),
    address: yup
    .string()
    .min(5, "too small!")
    .max(500, "Too Long String")
    .required("Address is Required"),
  });
  


const ApprovedQuotation = ({ getApprovedQuotation,currentIntegration,deleteQuotation,declineQuotation,isLoading}) =>  {  useEffect(() => {
  getApprovedQuotation();
}, []);

const [visible, setVisible] = useState(false);
const handleVisibility = () => {
    setVisible(prev => !prev);
};

const [loading, setLoading] = useState(false);
useEffect(() => {
    if (isLoading) {
        setLoading(true);
    }
  });

const [show, setShow] = useState(false);
const modalClose = () => setShow(false);
const modalShow = () => setShow(true);

const [show2, setShow2] = useState(false);
const modalAddClose = () => setShow2(false);
const modalAddShow = () => setShow2(true);



const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', subTitle: '' })


const [tableData, setTableData] = useState([]);
    useEffect(() => {
    if(currentIntegration)
    {
        setTableData(currentIntegration); 
    }
    }, [currentIntegration])


const columns = [
{ title: " Quotation No.", field: "quotationNo" },
{ title: "Created By.", field: "createdBy" },
{ title: "Customer Name", field: "customerName" },
{ title: "Customer Mobile", field: "CustomerMobile" },
{ title: "No. Of Items", field: "qty" },
{ title: "Date Of Quotation", field: "quotationDate" },
]

const quotation = [
  { value: '1', label: 'INDESIGN#QT_1002' },
  { value: '2', label: 'INDESIGN#QT_1003' },
  { value: '3', label: 'INDESIGN#QT_10024' }
]  

const [formData, setFormData] = useState({
  reason: "",
  id: ""
});

const { reason, id } = formData;

const onChange = (e) =>
{
  setFormData({ ...formData, [e.target.name]: e.target.value });
  
}
const quotationDecline = async (e) => {
  setFormData({ ...formData, 'id': e });
  setShow({
      isOpen: true,
      })
  
}

const onSubmit = async (e) => {
  e.preventDefault();
  const data = {
   id: formData.id,
   reason: formData.reason,
 };
 
 declineQuotation(data); // action name
};

const navigate = useNavigate();

const quotationView = async (e) => {
    navigate('/view-quotation/'+e);
}

const quotationEdit = async (e) => {
    navigate('/edit-quotation/'+e);
}

const deleteApprovedQuotation = async (e) => {
  
  setConfirmDialog({
    isOpen: true,
    title: 'Are you sure to delete this record?',
    onConfirm: () => { 
      deleteQuotation(e);
      setConfirmDialog({ ...confirmDialog, isOpen: false })
     }
})
}

  return (
    <>
        <Container fluid className="main-content">
            <Row>
                <Col className='d-flex justify-content-between mb-2'>
                    <h3 className="main-title">Approved Quotation</h3>
                    {/* <button type="button" className="btn btn-md btn-primary d-none d-sm-block" onClick={modalAddShow}><i className="fa-solid fa-plus"></i> Create</button> */}
                    <div className="d-flex justify-content-between d-block d-sm-none">
                      <div className="magnifierContainer">
                        <button type="button" className="btn btn-md btn-warning text-white roundedcircle mr-3" onClick={handleVisibility}><i className="fa-solid fa-search"></i></button>
                      </div>
                      <button type="button" className="btn btn-md btn-primary d-block d-sm-none roundedcircle ml-3"  onClick={modalAddShow}><i className="fa-solid fa-plus"></i></button>
                    </div>
                </Col>
                <Col lg={"12"} className='mb-2 d-block d-sm-none'>
                  <div className={visible ? "open" : "close"}>
                      <input placeholder="Search..." type="text" className="form-control is-open mr-3 mb-0 search-input"/>
                  </div>
                </Col>
            </Row>
            {loading ? <div>
            <Row className="d-none d-sm-block mt-2">
              <Col lg={"12"}>
                <div style={{display: 'table', tableLayout:'fixed', width:'100%'}}>
                <MaterialTable columns={columns} data={tableData}
                    
                    actions={[
                      {
                        icon: () => <VisibilityIcon color={'primary'} />,
                        tooltip: 'View',
                        onClick: (event, rowData) => quotationView(rowData.id)
                      },
                      {
                        icon: () => <EditIcon color={'secondary'} />,
                        tooltip: 'Edit',
                        onClick: (event, rowData) => quotationEdit(rowData.id)
                      },
                      
                      {
                        icon: () => <CloseIcon color={'warning'} />,
                        tooltip: 'Decline',
                        onClick: (event, rowData) => quotationDecline(rowData.id)
                      },
                      {
                        icon: () => <DeleteIcon color={'error'} />,
                        tooltip: 'Delete',
                        onClick: (event, rowData) => deleteApprovedQuotation(rowData.id)
                      }
                    ]}
                    options={{
                    sorting: true, search: true,
                    paging: true, pageSizeOptions: [10, 25, 50, 100], pageSize: 10,
                    exportButton: true,
                    exportAllData: true, exportFileName: "Approved Quotation", actionsColumnIndex: -1,
                    // headerStyle: { background: "#f44336",color:"#fff"}
                    }}
                    title="Approved Quotation"
                />
                
                </div>
              </Col>
            </Row>
            <Row className="d-block d-sm-none">
              {currentIntegration.map((quotation, index) => ( 
              <Col xs={"12"} key={index}>
                <div className="card mb-2">
                  <div className="card-header d-flex justify-content-between pb-0">
                      <h6>{quotation.quotationNo}	 <Badge bg="danger" text="light" className="ml-2">{quotation.qty}</Badge></h6>
                    <Dropdown>
                      <Dropdown.Toggle variant="light" id="dropdown-basic">
                        <i className="ri-more-2-line"></i>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                          <Dropdown.Item onClick={() => quotationView(quotation.id)}><VisibilityIcon color={'primary'} /> View</Dropdown.Item>
                          <Dropdown.Item onClick={() => quotationEdit(quotation.id)}><EditIcon color={'secondary'} /> Edit</Dropdown.Item>
                          <Dropdown.Item onClick={() => quotationDecline(quotation.id)}><CloseIcon color={'warning'} /> Decline</Dropdown.Item>
                          <Dropdown.Item onClick={() => deleteApprovedQuotation(quotation.id)}><DeleteIcon color={'error'} /> Delete</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div className="card-body pt-1">
                    <p className="card-text mb-0"><span className="quotation-info">Customer Name:</span> {quotation.customerName}</p>
                    <p className="card-text mb-0"><span className="quotation-info">Customer Mobile:</span> {quotation.CustomerMobile}</p>
                    <p className="card-text mb-0"><span className="quotation-info">Quotation Date:</span> {quotation.quotationDate}</p>
                    <p className="card-text mb-0"><span className="quotation-info">Created By:</span> {quotation.createdBy}</p>
                  </div>
                </div>
              </Col>
              ))}
            </Row>
            </div> : <Loader/>}
          </Container>
       {/* Add Customer Modal Start */}
       <Modal 
            size="md"
            centered
            show={show} 
            onHide={modalClose}>
                <Formik
                    validationSchema={validationSchema}
                    initialValues={{
                    reason: "",
                    }}
                >
                {({values, setFieldValue}) => (
                    <Form onSubmit={(e) => onSubmit(e)}>
                    <Modal.Header closeButton>
                        <Modal.Title>Decline Quotation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <label>Decline Reason</label>
                        <Field name="reason" as="textarea" className="form-control" value={reason} onChange={(e) => onChange(e)} />
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={modalClose}>Close</Button>
                        <Button variant="primary" type="submit">Submit</Button>
                    </Modal.Footer>
                    </Form>
                )}
                </Formik>
            </Modal>
        {/* Add Customer Modal End */}
        {/* Quotation to order Modal Start */}
        <Modal 
          size="md"
          centered
          show={show2} 
          onHide={modalAddClose}>
            <Formik
                validationSchema={validationSchema}
                initialValues={{
                  quotation: ""
                }}
                onSubmit={(values) => {
               
                }}
            >
                <Form>
                <Modal.Header closeButton>
                    <Modal.Title>Create Order</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <label>Quotation</label>
                    <Select options={quotation} name="quotation"/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={modalAddClose}>Close</Button>
                    <Link to="/quotation-to-order/"><Button variant="primary" type="submit">Submit</Button></Link>
                </Modal.Footer>
                </Form>
            </Formik>
        </Modal>
        {/* Quotation to order modal end */}
        <ConfirmDialog
            confirmDialog={confirmDialog}
            setConfirmDialog={setConfirmDialog}
        />
    </>

  )
}

const mapStateToProps = (state) => {

  return {
     currentIntegration: state.approvedQuotation.currentIntegration,
     isLoading: state.approvedQuotation.isLoading,
   }
 }

 export default connect(mapStateToProps,{getApprovedQuotation,deleteQuotation,declineQuotation})(ApprovedQuotation);