export const SET_ORDERNAME = "SET_ORDERNAME";
export const SET_QUOTATION = "SET_QUOTATION";
export const SET_CORDINATOR = "SET_CORDINATOR";
export const SET_CORDINATOR_VALUE = "SET_CORDINATOR_VALUE";
export const SET_CORDINATOR_NAME = "SET_CORDINATOR_NAME";
export const SET_REFFERED = "SET_REFFERED";
export const SET_REFFERED_VALUE = "SET_REFFERED_VALUE";
export const SET_REFFERED_NAME = "SET_REFFERED_NAME";
export const SET_PRODUCT = "SET_PRODUCT";
export const SET_LOCATION = "SET_LOCATION";
export const PRODUCT_NAME = "PRODUCT_NAME";
export const GET_IMPORTED_READY = "GET_IMPORTED_READY";
export const GET_IMPORTED_ORDER = "GET_IMPORTED_ORDER";
export const GET_TOTAL_AMOUNT = "GET_TOTAL_AMOUNT";
export const GROSS_TOTAL = "GROSS_TOTAL";
export const BALANCE_AMT = "BALANCE_AMT";
export const QUOTATION_CREATED = "QUOTATION_CREATED";
export const BRANCH_DETAIL = "BRANCH_DETAIL";
export const EDIT_READY_PRODUCT = "EDIT_READY_PRODUCT";
export const EDIT_NET_AMOUNT = "EDIT_NET_AMOUNT";
export const EDIT_ORDER_PRODUCT = "EDIT_ORDER_PRODUCT";
export const EDIT_ORDER_NET_AMOUNT = "EDIT_ORDER_NET_AMOUNT";
export const EDIT_PRODUCT_NAME = "EDIT_PRODUCT_NAME";
export const EDIT_PRODUCT_NAME_READY = "EDIT_PRODUCT_NAME_READY";
export const EDIT_PRODUCT_NAME_ORDER = "EDIT_PRODUCT_NAME_ORDER";