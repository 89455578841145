import {
  RETRIEVE_ORDER_DETAIL,
  RETRIEVE_IMPORTED_READY,
  RETRIEVE_IMPORTED_ORDER,
  GROSS_TOTAL,
  BALANCE
} from "../ViewOrder/actiontype";

const INITIAL_STATE = {
  currentIntegration: [],
  importedReady: [],
  importedOrder: [],
  isLoading: false,
  gross_total:0,
  balance:0
};

const orderDetail = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case RETRIEVE_ORDER_DETAIL:
      return {
        ...state,
        currentIntegration: payload,
        isLoading: true,
      };
    case RETRIEVE_IMPORTED_READY:
      return {
        ...state,
        importedReady: payload,
        isLoading: true,
      };
    case RETRIEVE_IMPORTED_ORDER:
      return {
        ...state,
        importedOrder: payload,
        isLoading: true,
      };
    case GROSS_TOTAL:
      return {
        ...state,
        gross_total: payload,
      };
    case BALANCE:
      return {
        ...state,
        balance: payload,
      };
    default:
      return state;
  }
};
export default orderDetail;