export const ORDER_DETAIL = "ORDER_DETAIL";
export const UPDATE_ORDER = "UPDATE_ORDER";
export const GET_IMPORTED_READY = "GET_IMPORTED_READY";
export const GET_IMPORTED_ORDER = "GET_IMPORTED_ORDER";
export const GET_TOTAL_AMOUNT = "GET_TOTAL_AMOUNT";
export const GET_ORDER_NAME = "GET_ORDER_NAME";
export const READY_NET_AMOUNT = "READY_NET_AMOUNT";
export const GROSS_TOTAL = "GROSS_TOTAL";
export const BALANCE_AMT = "BALANCE_AMT";
export const EDIT_PRODUCT = "EDIT_PRODUCT";
export const EDIT_PRODUCT_NAME = "EDIT_PRODUCT_NAME";