import {
    RETRIEVE_ORDER_DETAIL
} from "../DetailViewOrder/actiontype";
import api from "../../../api";

export const getCpOrderDetail= (id) => async (dispatch) => {
  
  try {
    const res = await api.get(`/order/viewCPOrder/` + id);
   
    dispatch({
      type: RETRIEVE_ORDER_DETAIL,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_ORDER_DETAIL,
      payload: [],
    });
  }
};



