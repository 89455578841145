import {
  RETRIEVE_ORDER_DETAIL
} from "../DetailViewOrder/actiontype";

const INITIAL_STATE = {
  currentIntegration: [],
  isLoading: false,
};

const CPorderDetail = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case RETRIEVE_ORDER_DETAIL:
      return {
        ...state,
        currentIntegration: payload,
        isLoading: true,
      };
    default:
      return state;
  }
};
export default CPorderDetail;