import {
  RETRIEVE_ASSIGN_PRODUCT,
  SET_CP_USER,
  ASSIGN_CP_PRODUCT
} from "../AssignProduct/actiontype";

const INITIAL_STATE = {
  currentIntegration: [],
  cpUser: [],
  isLoading: false,
  isUpdate: false,
};

const assignProductReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case  RETRIEVE_ASSIGN_PRODUCT:
      return {
        ...state,
        currentIntegration: payload,
        isLoading: true,
      };
      case ASSIGN_CP_PRODUCT:
      return {
        ...state,
        currentIntegration: state.currentIntegration.filter((item) => parseInt(item.id) !== parseInt(payload)),
        isUpdate: true,
      };
      case SET_CP_USER:
      return {
        ...state,
        cpUser: payload,
      };
      
    default:
      return state;
  }
};


export default assignProductReducer;