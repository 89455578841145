import {
    RETRIEVE_OUTFOR_DELIVERY,
    PAYDELACE_OUTFOR_DELIVERY
} from "../OutForDelivery/actiontype";

import { toast } from 'react-toastify';
import api from "../../../api";

let usertype = JSON.parse(localStorage.getItem('usertype'));
let userid = JSON.parse(localStorage.getItem('userid'));

export const getOutForDelivery= (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  let data = {
    usertype : usertype,
    userid : userid
  };
  try {
    const res = await api.post(`/order/outForDelivery`, data);
   
    dispatch({
      type: RETRIEVE_OUTFOR_DELIVERY,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_OUTFOR_DELIVERY,
      payload: [],
    });
  }
};

export const paydelace= (id) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  let data = {
    id : id,
    user_id : userid
  };
  try {
    const res = await api.post(`/order/paydelace`, data);
   
    if (res.status === 200) {
      toast.success(res.data.message);
        dispatch({
            type: PAYDELACE_OUTFOR_DELIVERY,
            payload: res.data.data.info,
          });
         }
      } catch (err) {
    
    toast.warn('Invalid inputs! Order Not Decline');
    // dispatch({
    //   type: REGISTER_FAIL,
    // });
  }
};



