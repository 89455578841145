import React from 'react';
import { Row, Col, Container, Button, Card, Form} from 'react-bootstrap';
import Select from 'react-select';
import Zoom from 'react-medium-image-zoom'
import { connect } from "react-redux";
import { getAssignProduct,getCpUserList,assignCpProduct} from '../../../redux/Product/AssignProduct/action';
import { useEffect, useState } from 'react';
import Loader from "../../../components/Loader/Loader";

const AssignProduct = ({ getAssignProduct, currentIntegration, assignProduct, getCpUserList, cpuserlist, assignCpProduct, isLoading, isUpdate }) =>  {  useEffect(() => {
    getAssignProduct();
    getCpUserList();
}, []);

const [loading, setLoading] = useState(false);
useEffect(() => {
    if (isLoading) {
        setLoading(true);
    }
  });

const [formData, setFormData] = useState({
    cpUser: "",
    
  });

const [cpUserList, setCpUserList] = useState(null);
useEffect(() => {
if(cpuserlist)
{
  setCpUserList(cpuserlist); 
}
}, [cpuserlist])

const onSelectChangeCpUser = (e) =>
{
  setFormData({ ...formData, 'cpUser': e.value });
  
}
const [userinfo, setUserInfo] = useState({
    product: [],
    
  });
const { product } = userinfo;
const handleChange = (e) => {
    // Destructuring
    const { value, checked } = e.target; 
    // Case 1 : The user checks the box
    if (checked) {
      setUserInfo({
        product: [...product, value],
      });
    }
    // Case 2  : The user unchecks the box
    else {
        setUserInfo({
          product: product.filter((e) => e !== value),
        });
      }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const data = {
        cpUser: formData.cpUser,
        product : product
     };
    
    assignCpProduct(data);
  };

  useEffect(() => {
    if(isUpdate)
    {
      window.location.reload(false);
    }
  }, [isUpdate])

  return (
    <>
         <Container fluid className="main-content">
            <Row>
                <Col lg={'8'} sm={12}>
                    <h3 className="main-title">Assign Product</h3>
                </Col>
                <Col lg={'3'} xs={9}>
                    <Select options={cpUserList} name="cpUser" onChange={(e) => onSelectChangeCpUser(e)}/>
                </Col>
                <Col lg={'1'} xs={3}>
                    <Button variant="primary" onClick={onSubmit}>Submit</Button>
                </Col>
            </Row>
            {loading ? <div>
            <Row className='mt-4 mb-4'>
            {currentIntegration && currentIntegration.map((assign, index) => ( 
                <Col lg="2" key={index} className='mb-4'>
                    <Card>
                    <Zoom zoomMargin={20}><Card.Img variant="top" src={process.env.REACT_APP_IMAGE_URL+'/'+assign.image} alt={assign.image} width="100%" height="120px"/></Zoom>
                    <Card.Body>
                        <Card.Title className='d-flex justify-content-between' style={{fontSize: '14px'}}>
                            {assign.name}
                            <Form>
                                <Form.Check className='mt-0'
                                    type="switch"
                                    id="assignproduct"
                                    name="product[]"
                                    value= {assign.id}
                                    onChange={handleChange}
                                />
                            </Form>
                        </Card.Title>
                        <p className="card-text mb-0"><span className="quotation-info">Product Category:</span> {assign.category}</p>
                        <p className="card-text mb-0"><span className="quotation-info">Leather Category:</span> {assign.leather_category}</p>
                        <p className="card-text mb-0"><span className="quotation-info">Leather Type:</span> {assign.leather_type}</p>
                        <p className="card-text mb-0"><span className="quotation-info">Colour Code:</span> {assign.colour_code}</p>
                        <p className="card-text mb-0"><span className="quotation-info">Item Colour:</span> {assign.leather_colour}</p>
                        <p className="card-text mb-0"><span className="quotation-info">RDP:</span> {assign.rdp}</p>
                    </Card.Body>
                    </Card>
                </Col>
            ))}
            </Row>
            </div> : <Loader/>}
         </Container>
    </>
  )
}

const mapStateToProps = (state) => {
//
return {
    currentIntegration: state.assignProduct.currentIntegration,
    isLoading: state.assignProduct.isLoading,
    isUpdate: state.assignProduct.isUpdate,
    cpuserlist: state.assignProduct.cpUser,
    }
}

export default connect(mapStateToProps,{getAssignProduct,getCpUserList,assignCpProduct})(AssignProduct);