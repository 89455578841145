
import * as React from "react";
import {useState, useEffect} from 'react'
import { Row, Col, Container, Badge, Dropdown, Tab, Tabs} from 'react-bootstrap';
import MaterialTable from 'material-table';
import Zoom from 'react-medium-image-zoom'
import { connect } from "react-redux";
import {getStockOutSummary} from '../../redux/Branch/BranchDashboard/StockOut/action';
import {getStockInSummary} from '../../redux/Branch/BranchDashboard/StockIn/action';
import {getStockReturnSummary} from '../../redux/Branch/BranchDashboard/StockReturn/action';
import Loader from '../../components/Loader/Loader';
import SearchBar from "../../components/SearchBar/SearchBar";

  const BranchDesktop = ({getStockOutSummary,stockInSummary,getStockInSummary,stockOutSummary,getStockReturnSummary,stockReturnSummary,isLoading,isLoading2, isLoading3}) =>  {  useEffect(() => {
    getStockOutSummary();
    getStockInSummary();
    getStockReturnSummary();
  }, []);
  
  const [loading, setLoading] = useState(false);
  useEffect(() => {
      if (isLoading) {
          setLoading(true);
      }
    });

  const [loading2, setLoading2] = useState(false);
  useEffect(() => {
      if (isLoading2) {
          setLoading2(true);
      }
    });

  const [loading3, setLoading3] = useState(false);
  useEffect(() => {
      if (isLoading3) {
          setLoading3(true);
      }
    });

const [visible, setVisible] = useState(false);
const handleVisibility = () => {
    setVisible(prev => !prev);
};

const [tableData, setTableData] = useState([]);
useEffect(() => {
  if(stockInSummary)
  {
    setTableData(stockInSummary); 
  }
}, [stockInSummary])

const columns = [
{ title: 'Product Image', field: 'image', render: rowData => <Zoom zoomMargin={20}><img src={process.env.REACT_APP_IMAGE_URL+'/'+rowData.image} alt={rowData.image} style={{width: 50, height:50}}/></Zoom> },
{ title: "Model No. ", field: "model" },
{ title: "Branch ", field: "branch_name" },
{ title: "Qty", field: "qty" },
{ title: "Product Category", field: "category" },

]

const [tableData2, setTableData2] = useState([]);
useEffect(() => {
  if(stockOutSummary)
  {
    setTableData2(stockOutSummary); 
  }
}, [stockOutSummary])

const columns2 = [
{ title: 'Product Image', field: 'image', render: rowData => <Zoom zoomMargin={20}><img src={process.env.REACT_APP_IMAGE_URL+'/'+rowData.image} alt={rowData.image} style={{width: 50, height:50}}/></Zoom> },
{ title: "Model No. ", field: "model" },
{ title: "Branch ", field: "branch_name" },
{ title: "Qty", field: "qty" },
{ title: "Product Category", field: "category" },

]

const [tableData3, setTableData3] = useState([]);
useEffect(() => {
  if(stockReturnSummary)
  {
    setTableData2(stockReturnSummary); 
  }
}, [stockReturnSummary])

const columns3 = [
{ title: 'Product Image', field: 'image', render: rowData => <Zoom zoomMargin={20}><img src={process.env.REACT_APP_IMAGE_URL+'/'+rowData.image} alt={rowData.image} style={{width: 50, height:50}}/></Zoom> },
{ title: "Model No. ", field: "model" },
{ title: "Branch ", field: "branch_name" },
{ title: "Qty", field: "qty" },
{ title: "Product Category", field: "category" },
{ title: "Remark", field: "remark" },

]
    
  return (
    <>
        <Container fluid className="main-content">
          <Row>
              <Col>
                  <h3 className="main-title">Dashboard</h3>
              </Col> 
              <Col>
                  <div style={{float: "right"}} className="d-flex">
                    <div className="d-flex justify-content-between d-flex d-sm-none">
                      <div className="magnifierContainer">
                        <button type="button" className="btn btn-md btn-warning text-white roundedcircle mr-3" onClick={handleVisibility}><i className="fa-solid fa-search"></i></button>
                      </div>
                    </div>
                  </div>
              </Col>
              <Col lg={"12"}>
                <div className='d-block d-sm-none mt-2'>
                  <div className={visible ? "open" : "close"}>
                      <input placeholder="Search..." type="text" className="form-control is-open mr-3 mb-0 search-input"/>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
                <Col>
                    <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3 mt-3 nav-fill">
                        <Tab eventKey="home" title="StockIn Summary">
                        {loading ? <div>
                            <Row className="d-none d-sm-block mt-2">
                                <Col lg={"12"} className="mt-3">
                                    <div style={{display: 'table', tableLayout:'fixed', width:'100%'}}>
                                    <MaterialTable columns={columns} data={tableData}
                                        options={{
                                        sorting: true, search: true,
                                        paging: true, pageSizeOptions: [10, 25, 50, 100], pageSize: 10,
                                        exportButton: true,
                                        exportAllData: true, exportFileName: "StockIn Summary", actionsColumnIndex: -1,
                                        // headerStyle: { background: "#f44336",color:"#fff"}
                                        }}
                                        title="StockIn Summary"
                                    />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="d-block d-sm-none mt-2">
                            {stockInSummary.map((stock, index) => (
                              <Col xs={"12"} key={index}>
                                  <div className="card mb-2">
                                      <div className="card-header d-flex justify-content-between pb-0">
                                          <h6>{stock.model}  <Badge bg="danger" text="light" className="ml-2">{stock.qty}</Badge></h6>
                                      </div>
                                      <div className="card-body pt-1">
                                          <p className="card-text mb-0"><span className="quotation-info">Branch Name:</span> {stock.branch_name}</p>
                                          <p className="card-text mb-0"><span className="quotation-info">Product Category:</span> {stock.category}</p>
                                         
                                      </div>
                                  </div>
                              </Col>
                            ))}
                          </Row>
                          </div> : <Loader/>}
                        </Tab>
                        <Tab eventKey="profile" title="StockOut Summary">
                          {loading2 ? <div>
                            <Row className="d-none d-sm-block mt-2">
                                <Col lg={"12"} className="mt-3">
                                    <div style={{display: 'table', tableLayout:'fixed', width:'100%'}}>
                                    <MaterialTable columns={columns2} data={tableData2}
                                        options={{
                                        sorting: true, search: true,
                                        paging: true, pageSizeOptions: [10, 25, 50, 100], pageSize: 10,
                                        exportButton: true,
                                        exportAllData: true, exportFileName: "StockOut Summary", actionsColumnIndex: -1,
                                        // headerStyle: { background: "#f44336",color:"#fff"}
                                        }}
                                        title="StockOut Summary"
                                    />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="d-block d-sm-none mt-2">
                            {stockOutSummary.map((stock, index) => (
                              <Col xs={"12"} key={index}>
                                  <div className="card mb-2">
                                      <div className="card-header d-flex justify-content-between pb-0">
                                          <h6>{stock.model}  <Badge bg="danger" text="light" className="ml-2">{stock.qty}</Badge></h6>
                                      </div>
                                      <div className="card-body pt-1">
                                          <p className="card-text mb-0"><span className="quotation-info">Branch Name:</span> {stock.branch_name}</p>
                                          <p className="card-text mb-0"><span className="quotation-info">Product Category:</span> {stock.category}</p>
                                         
                                      </div>
                                  </div>
                              </Col>
                            ))}
                          </Row>
                          </div> : <Loader/>}
                        </Tab>
                        <Tab eventKey="contact" title="Return Summary">
                        {loading3 ? <div>
                            <Row className="d-none d-sm-block mt-2">
                                <Col lg={"12"} className="mt-3">
                                    <div style={{display: 'table', tableLayout:'fixed', width:'100%'}}>
                                    <MaterialTable columns={columns3} data={tableData3}
                                        options={{
                                        sorting: true, search: true,
                                        paging: true, pageSizeOptions: [10, 25, 50, 100], pageSize: 10,
                                        exportButton: true,
                                        exportAllData: true, exportFileName: "Return Summary", actionsColumnIndex: -1,
                                        // headerStyle: { background: "#f44336",color:"#fff"}
                                        }}
                                        title="Return Summary"
                                    />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="d-block d-sm-none mt-2">
                            {stockReturnSummary.map((stock, index) => (
                              <Col xs={"12"} key={index}>
                                  <div className="card mb-2">
                                      <div className="card-header d-flex justify-content-between pb-0">
                                          <h6>{stock.model}  <Badge bg="danger" text="light" className="ml-2">{stock.qty}</Badge></h6>
                                      </div>
                                      <div className="card-body pt-1">
                                          <p className="card-text mb-0"><span className="quotation-info">Branch Name:</span> {stock.branch_name}</p>
                                          <p className="card-text mb-0"><span className="quotation-info">Product Category:</span> {stock.category}</p>
                                         
                                      </div>
                                  </div>
                              </Col>
                            ))}
                          </Row>
                          </div> : <Loader/>}
                        </Tab>
                    </Tabs>
                </Col>
            </Row>
        </Container>
    </>

  )
}

const mapStateToProps = (state) => {
    
      return {
         stockOutSummary: state.branchStockOut.stockOutSummary,
         stockInSummary: state.branchStockIn.stockInSummary,
         stockReturnSummary: state.branchStockReturn.stockReturnSummary,
         isLoading2: state.branchStockOut.isLoading,
         isLoading3: state.branchStockReturn.isLoading,
         isLoading: state.branchStockIn.isLoading,
       }
     }
    
     export default connect(mapStateToProps,{getStockOutSummary,getStockInSummary,getStockReturnSummary})(BranchDesktop);