import {
    CART_DETAIL,
    PLACE_ORDER
  } from "../PlaceOrder/actiontype";
  
  const INITIAL_STATE = {
    currentIntegration: [],
    isLoading: false,
    cart_qty:0
  };
  
  const cpPlaceOrderReducer = (state = INITIAL_STATE, action) => {
    const { type, payload } = action;
   
    switch (type) {
      case  CART_DETAIL:
        return {
          ...state,
          currentIntegration: payload,
        };
        case PLACE_ORDER:
        return {
          ...state,
          cart_qty: payload,
        };
        default:
          return state;
    }
  };
  export default cpPlaceOrderReducer;