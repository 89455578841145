import * as React from "react";
import {useState, useEffect} from 'react'
import { Row, Col, Container} from 'react-bootstrap';
import MaterialTable from 'material-table';
import { connect } from "react-redux";
import {getPaymentDetail} from '../../../redux/Order/PaymentDetail/action';
import Loader from "../../../components/Loader/Loader";
import parse from 'html-react-parser';

const PaymentDetail = ({ getPaymentDetail,currentIntegration, isLoading }) =>  {  useEffect(() => {
    getPaymentDetail();
  }, []);
  
const parse = require('html-react-parser');

const [loading, setLoading] = useState(false);
useEffect(() => {
    if (isLoading) {
        setLoading(true);
    }
  });

const [tableData, setTableData] = useState([]);
useEffect(() => {
if(currentIntegration)
{
    setTableData(currentIntegration); 
}
}, [currentIntegration])

const columns = [
{ title: "Order No.", field: "orderNo" },
{ title: "Created By ", field: "createdBy" },
{ title: "Approved By ", field: "approvedBy" },
{ title: "Grand Total", field: "grandTotal" },
{ title: "Total Paid", field: "totalPaid" },
{ title: "Due Amount", field: "dueAmount" },
{ title: "Date Of Order", field: "orderDate" },
{ title: 'Payment Detail', field: "paymentDetail", render: rowData => parse(rowData.paymentDetail)},
]
    
  return (
    <>
        <Container fluid className="main-content">
            <Row>
                <Col className='d-flex justify-content-between'>
                    <h3 className="main-title">Order Payment Detail</h3>
                </Col>
            </Row>
            {loading ? 
            <Row>
              <Col lg={"12"} className="mt-3">
                <div style={{display: 'table', tableLayout:'fixed', width:'100%'}}>
                <MaterialTable columns={columns} data={tableData}
                    options={{
                    sorting: true, search: true,
                    paging: true, pageSizeOptions: [10, 25, 50, 100], pageSize: 10,
                    exportButton: true,
                    exportAllData: true, exportFileName: "Order Payment Detail", actionsColumnIndex: -1,
                    }}
                    title="Order Payment Detail"
                />
                </div>
              </Col>
          </Row>
          : <Loader/> }
        </Container>
    </>

  )
}

const mapStateToProps = (state) => {

  return {
     currentIntegration: state.paymentDetail.currentIntegration,
     isLoading: state.paymentDetail.isLoading,
   }
 }

 export default connect(mapStateToProps,{getPaymentDetail})(PaymentDetail);