import {
    RETRIEVE_ORDER_DETAIL,
    RETRIEVE_IMPORTED_READY,
    RETRIEVE_IMPORTED_ORDER,
    GROSS_TOTAL,
    BALANCE
} from "../ViewOrder/actiontype";
import api from "../../../api";

export const getOrderDetail= (id) => async (dispatch) => {
  
  try {
    const res = await api.get(`/order/orderDetail/` + id);

    localStorage.setItem('total_amount',res.data.data.total_amount);
    localStorage.setItem('fitting',res.data.data.fitting);
    localStorage.setItem('packing',res.data.data.packing);
    localStorage.setItem('cartage',res.data.data.cartage);
    localStorage.setItem('other',res.data.data.other);
    localStorage.setItem('advance',res.data.data.advance);

    dispatch({
      type: RETRIEVE_ORDER_DETAIL,
      payload: res.data.data,
    });
    dispatch({
      type: RETRIEVE_IMPORTED_READY,
      payload: res.data.data.imported_ready,
    });
    dispatch({
      type: RETRIEVE_IMPORTED_ORDER,
      payload: res.data.data.imported_order,
    });
    dispatch({
      type: GROSS_TOTAL,
      payload: res.data.data.gross_total,
    });
    dispatch({
      type: BALANCE,
      payload: res.data.data.balance,
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_ORDER_DETAIL,
      payload: [],
    });
  }
};

//get total amount
export const calGrossAmt = (data) => async (dispatch) => {
  const total = localStorage.getItem('total_amount');
  const fitting = localStorage.getItem('fitting');
  const packing = localStorage.getItem('packing');
  const cartage = localStorage.getItem('cartage');
  const other = localStorage.getItem('other');
  const advance = localStorage.getItem('advance');
  
  let totalbill = parseInt(total) + parseInt(fitting) + parseInt(packing) + parseInt(cartage) + parseInt(other);
  dispatch({
    type: GROSS_TOTAL,
    payload: totalbill,
  });

  let balance = totalbill - parseInt(advance);
  dispatch({
    type: BALANCE,
    payload: balance,
  });
};

