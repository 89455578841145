import {
    CREATE_CONFIRM_BOOKING,
    RETRIEVE_CONFIRM_BOOKING,
} from "../ConfirmBooking/actiontype";

const INITIAL_STATE = {
  currentIntegration: [],
};

const confirmBookingReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
 
  switch (type) {
    case CREATE_CONFIRM_BOOKING:
      return {
        ...state
      };
    case RETRIEVE_CONFIRM_BOOKING:
      return {
        ...state,
        currentIntegration: payload,
      };
      default:
        return state;
  }
};
export default confirmBookingReducer;