import {
    CREATE_DELIVERED_ORDER,
    RETRIEVE_DELIVERED_ORDER,
    UPDATE_DELIVERED_ORDER,
    DELETE_DELIVERED_ORDER
} from "../DeliveredOrder/actiontype";

const INITIAL_STATE = {
  currentIntegration: [],
  isLoading: false,
};

const deliveredOrderReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case CREATE_DELIVERED_ORDER:
      return {
        ...state
      };
    case RETRIEVE_DELIVERED_ORDER:
      return {
        ...state,
        currentIntegration: payload,
        isLoading: true,
      };
    case UPDATE_DELIVERED_ORDER:
      return state.map((deliveredOrder) => {
        if (deliveredOrder.id === payload.id) {
          return {
            ...deliveredOrder,
            ...payload,
          };
        } else {
          return deliveredOrder;
        }
      });
    case DELETE_DELIVERED_ORDER:
      return {
        ...state,
        currentIntegration: state.currentIntegration.filter((item) => parseInt(item.id) !== parseInt(payload)),
      };
    default:
      return state;
  }
};
export default deliveredOrderReducer;