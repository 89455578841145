import {
    RETRIEVE_APPROVED_QUOTATION,
    DELETE_APPROVED_QUOTATION,
    DECLINE_APPROVED_QUOTATION
} from "../ApprovedQuotation/actiontype";

import { toast } from 'react-toastify';
import api from "../../../api";
import id from "date-fns/locale/id";

let usertype = JSON.parse(localStorage.getItem('usertype'));
let userid = JSON.parse(localStorage.getItem('userid'));

export const getApprovedQuotation= (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  let data = {
    usertype : usertype,
    userid : userid
  };

  try {
    const res = await api.post(`/quotation/approvedQuotation`, data);
   
    
    dispatch({
      type: RETRIEVE_APPROVED_QUOTATION,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_APPROVED_QUOTATION,
      payload: [],
    });
  }
};

export const declineQuotation = (data) => async (dispatch) => {
  //const body = JSON.stringify(data);
  
  try {
    let body = {
      id: data.id,
     reason : data.reason
   };
    const res = await api.post('/quotation/declineQuotation', body);
    
    if (res.status === 200) {
      toast.success(res.data.message);
        dispatch({
            type: DECLINE_APPROVED_QUOTATION,
            payload: res.data.data.info,
          });
         }
      } catch (err) {
    
    toast.warn('Invalid inputs! Quotation Not Decline');
    // dispatch({
    //   type: REGISTER_FAIL,
    // });
  }
};


export const deleteQuotation = (id) => async (dispatch) => {
  try {
    const res = await api.get('/quotation/deleteQuotation/' + id);
   
    if (res.status === 200) {
      toast.success(res.data.message);
      dispatch({
        type:  DELETE_APPROVED_QUOTATION,
        payload: res.data.data.info,
      });
      
    }
  } catch (error) {
    
    toast.error('Failed to delete');
    dispatch({
      type:  DELETE_APPROVED_QUOTATION,
      payload: [],
    });
  }
};

