import * as React from "react";
import {useState, useEffect} from 'react'
import { Row, Col, Container,Badge, Card } from 'react-bootstrap';
import MaterialTable from 'material-table';
import { connect } from "react-redux";
import {getOrders} from '../../../redux/Order/Orders/action';
import Zoom from 'react-medium-image-zoom'
import Loader from "../../../components/Loader/Loader";

const Order = ({ getOrders,currentIntegration,isLoading}) =>  {  useEffect(() => {
    getOrders();
}, []);

    
const [visible, setVisible] = useState(false);
const handleVisibility = () => {
    setVisible(prev => !prev);
};

const [loading, setLoading] = useState(false);
useEffect(() => {
    if (isLoading) {
        setLoading(true);
    }
  });

const [tableData, setTableData] = useState([]);
  useEffect(() => {
    if(currentIntegration)
    {
      setTableData(currentIntegration); 
    }
  }, [currentIntegration])

const columns = [
{ title: 'Image', field: 'image', render: rowData => <img src={process.env.REACT_APP_IMAGE_URL+'/'+rowData.image} alt={rowData.image} style={{width: 75}}/> },   
{ title: "Order No.", field: "orderNo" },
{ title: "Model No.", field: "model_no" },
{ title: "Product Name", field: "product_name" },
{ title: "Quantity", field: "qty" },
{ title: "Category", field: "category" },
{ title: "Colour Code", field: "colour_code" },
{ title: "Leather Type", field: "leather_type" },
{ title: "Remarks", field: "remark" },

]
 
  return (
    <>
        <Container fluid className="main-content">
            <Row>
                <Col className='d-flex justify-content-between'>
                    <h3 className="main-title">Orders</h3>
                </Col>
                <Col>
                    <div style={{float: "right"}} className="d-flex">
                        <div className="d-flex justify-content-between d-flex d-sm-none">
                        <div className="magnifierContainer">
                            <button type="button" className="btn btn-md btn-warning text-white roundedcircle mr-3" onClick={handleVisibility}><i className="fa-solid fa-search"></i></button>
                        </div>
                        </div>
                    </div>
                </Col>
                <Col lg={"12"}>
                    <div className='d-block d-sm-none mt-2'>
                    <div className={visible ? "open" : "close"}>
                        <input placeholder="Search..." type="text" className="form-control is-open mr-3 mb-0 search-input"/>
                    </div>
                    </div>
                </Col>
            </Row>
            {loading ? <div>
            <Row className="d-none d-sm-block mt-2">
                <Col lg={"12"} className="mt-3">
                    <div style={{display: 'table', tableLayout:'fixed', width:'100%'}}>
                        <MaterialTable columns={columns} data={tableData}
                            options={{
                            sorting: true, search: true,
                            paging: true, pageSizeOptions: [10, 25, 50, 100], pageSize: 10,
                            exportButton: true,
                            exportAllData: true, exportFileName: "Imported Order", actionsColumnIndex: -1,
                            }}
                            title="Imported Order"
                        />
                    </div>
                </Col>
            </Row>
            <Row className="d-block d-sm-none mt-2">
                {currentIntegration.map((order, index) => (
                <Col xs={"12"} key={index}>
                    <div className="card mb-4">
                    <Zoom><Card.Img  src={process.env.REACT_APP_IMAGE_URL+'/'+order.image} alt={order.image} width="100%" height="150px"/></Zoom>
                        <div className="card-header d-flex justify-content-between pb-0">
                            <h6>{order.orderNo}	 <Badge bg="danger" text="light" className="ml-2">{order.qty}</Badge></h6>
                        </div>
                        <div className="card-body pt-1">
                            <p className="card-text mb-0"><span className="quotation-info">Model No:</span> {order.model_no}</p>
                            <p className="card-text mb-0"><span className="quotation-info">Product Name:</span> {order.product_name}</p>
                            <p className="card-text mb-0"><span className="quotation-info">Category:</span> {order.category}</p>
                            <p className="card-text mb-0"><span className="quotation-info">Colour Code:</span> {order.colour_code}</p>
                            <p className="card-text mb-0"><span className="quotation-info">Leather Type:</span> {order.leather_type}</p>
                            <p className="card-text mb-0"><span className="quotation-info">Remark:</span> {order.remark}</p>
                        </div>
                    </div>
                </Col>
                ))}
            </Row>
            </div> : <Loader/>}
        </Container>
    </>

  )
}

const mapStateToProps = (state) => {
return {
    currentIntegration: state.orders.currentIntegration,
    isLoading: state.orders.isLoading,
    }
}
      
export default connect(mapStateToProps,{getOrders})(Order);