import {
    SET_ORDERNAME,
    SET_QUOTATION,
    SET_CORDINATOR,
    SET_REFFERED,
    SET_PRODUCT,
    SET_LOCATION,
    PRODUCT_NAME,
    GET_IMPORTED_READY,
    GET_IMPORTED_ORDER,
    GET_TOTAL_AMOUNT,
} from "../QuotationToOrder/actionType";

import { toast } from 'react-toastify';
import api from "../../../api";

// get order name from branch id
export const getBrandName = (data) => async (dispatch) => {
   try {
      const res = await api.get(`/common/branchOrderName/`+data);
        dispatch({
          type: SET_ORDERNAME,
          payload: res.data.data,
        });
    } catch (err) { }
  };

  //get all pending quotation
  export const getQuotation = (data) => async (dispatch) => {
    try {
      const res = await api.get(`/common/pendingQuotationOrder`);
      
          dispatch({
            type: SET_QUOTATION,
            payload: res.data.data,
          });
    } catch (err) { }
  };

  //get cordinator name [users]
  export const getCordinator = (data) => async (dispatch) => {
    try {
      const res = await api.get(`/common/cordinator`);
      
          dispatch({
            type: SET_CORDINATOR,
            payload: res.data.data,
          });
    } catch (err) { }
  };
  
  //get vendors name
  export const getReffered = (data) => async (dispatch) => {
    try {
      const res = await api.get(`/common/refferedBy`);
      
          dispatch({
            type: SET_REFFERED,
            payload: res.data.data,
          });
    } catch (err) { }
  };
  
  //get all product with model no + category_name + leather_type_name + colour_code_name
  export const getProduct = (data) => async (dispatch) => {
    try {
      const res = await api.get(`/common/productList`);
      
          dispatch({
            type: SET_PRODUCT,
            payload: res.data.data,
          });
    } catch (err) { }
  };
  
  //get all branch location
  export const getLocation = (data) => async (dispatch) => {
    try {
      const res = await api.get(`/common/locationList`);
          dispatch({
            type: SET_LOCATION,
            payload: res.data.data,
          });
    } catch (err) { }
  };

  //get product name from productid
  export const getProductName = (data) => async (dispatch) => {
    try{
      const res = await api.get(`/common/getProductName/`+data);
      dispatch({
        type: PRODUCT_NAME,
        payload: res.data.data,
      });
    } catch(err) {}
  };

  //add imported product
  export const addImportedReady = (data)  => async (dispatch) => {
    const body = JSON.stringify(data);
    try {
      const res = await api.post('/order/orderProduct', body);
      dispatch({
        type: GET_IMPORTED_READY,
        payload: res.data.data,
      });
    } catch (err) {}
  };

  //add imported order product
  export const addImportedOrder = (data)  => async (dispatch) => {
    const body = JSON.stringify(data);
    try {
      const res = await api.post('/order/orderImportedProductOrder', body);
      dispatch({
        type: GET_IMPORTED_ORDER,
        payload: res.data.data,
      });
    } catch (err) {}
  };

  //get total amount
  export const getTotal = (data) => async (dispatch) => {
    const rndno = localStorage.getItem('rndno');
    try{
      const res = await api.get(`/order/getTotalAmount/`+rndno);
      
      dispatch({
        type: GET_TOTAL_AMOUNT,
        payload: res.data.data,
      });
    } catch(err) {}
  };

  //create order
  export const createOrder = (data)  => async (dispatch) => {
    const body = JSON.stringify(data);
    
    try {
      const res = await api.post('/order/createOrder', body);
      
      if (res.status === 200) {
        toast.success('Order Created Successfully');
      }
    } catch (err) {}
  };