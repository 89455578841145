import {
 RETRIEVE_BRANCH_ENTRIES
} from "../BranchEntries/actiontype";

import api from "../../../api";

let userid = JSON.parse(localStorage.getItem('userid'));

export const getBranchEntries= (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
 
  try {
    const res = await api.get(`/branch/branchEntries/` +userid);
    dispatch({
      type: RETRIEVE_BRANCH_ENTRIES,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_BRANCH_ENTRIES,
      payload: [],
    });
  }
};






