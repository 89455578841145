import {
    CREATE_SAP_BOOKED,
    RETRIEVE_SAP_BOOKED,
} from "../BookedOrder/actiontype";

const INITIAL_STATE = {
  currentIntegration: [],
};

const sapBookedOrderReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
 
  switch (type) {
    case CREATE_SAP_BOOKED:
      return {
        ...state
      };
    case RETRIEVE_SAP_BOOKED:
      return {
        ...state,
        currentIntegration: payload,
      };
      default:
        return state;
  }
};
export default sapBookedOrderReducer;