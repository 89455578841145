import {
    CREATE_VENDORS,
    RETRIEVE_VENDORS,
    UPDATE_VENDORS,
    DELETE_VENDORS,
    RETRIEVE_SINGLE_VENDORS
} from "../Vendor/actiontype";

const INITIAL_STATE = {
  currentIntegration: [],
  singleVendor: [],
  isLoading: false,
  isUpdate: false,
};

const vendorReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
  
  switch (type) {
    case CREATE_VENDORS:
      return {
        ...state
      };
    case RETRIEVE_VENDORS:
      return {
        ...state,
        currentIntegration: payload,
        isLoading: true,
      };
    case RETRIEVE_SINGLE_VENDORS:
      return {
        ...state,
        singleVendor: payload,
      };
    case UPDATE_VENDORS:
      return {
        ...state,
        currentIntegration: payload,
        isUpdate: true,
      };
      case  DELETE_VENDORS:
        return {
          ...state,
          currentIntegration: state.currentIntegration.filter((item) => parseInt(item.id) !== parseInt(payload)),
        };
    default:
      return state;
  }
};
export default vendorReducer;