import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Dropdown from './Dropdown';
import OrderDropdown from './OrderDropdown';
import UserDropdown from './UserDropdown';
import VendorDropdown from './VendorDropdown';
import SettingDropdown from './SettingDropdown';
import IncentiveDropdown from './IncentiveDropdown';
import QuotationDropdown from './QuotationDropdown';
//import logo from '../../assets/images/logo.png'
import logo from '../../assets/images/logo.gif'
import avatar from '../../assets/images/avatar-s-11.jpg'
import { Navbar } from 'react-bootstrap';
import ReportDropdown from './ReportDropdown';
import PaymentDropdown from './PaymentDropdown';
import AccountIncentiveDropdown from './AccountIncentiveDropdown';
import { useLocation, useNavigate} from 'react-router-dom';
import ManagementOrderDropdown from './ManagementOrderDropdown';
import Profile from '../ProfileDropdown/Profile';
import { MenuItems, User, Order, Quotation, Vendor, Incentive, Setting, Report, Payment, AccountIncentive, ManagementOrder } from './MenuItems';

function NavbarMenu() {
    
    const { pathname } = useLocation();

    const [click, setClick] = useState(false);
    const handleClick = () => setClick(!click);

    const [product, setProduct] = useState(false);
    const showProduct = () => setProduct(!product);
    
    const [user, setUser] = useState(false);
    const showUser = () => setUser(!user);
    
    const [order, setOrder] = useState(false);
    const showOrder = () => setOrder(!order);
    
    const [quotation, setQuotation] = useState(false);
    const showQuotation = () => setQuotation(!quotation);

    const [vendor, setVendor] = useState(false);
    const showVendor = () => setVendor(!vendor);

    const [incentive, setIncentive] = useState(false);
    const showIncentive = () => setIncentive(!incentive);

    const [setting, setSetting] = useState(false);
    const showSetting = () => setSetting(!setting);

    const [report, setReport] = useState(false);
    const showReport = () => setReport(!report);

    const [payment, setPayment] = useState(false);
    const showPayment = () => setPayment(!payment);

    const [accountIncentive, setAccountIncentive] = useState(false);
    const showAccountIncentive = () => setAccountIncentive(!accountIncentive);

    const [managementOrder, setManagementOrder] = useState(false);
    const showManagementOrder = () => setManagementOrder(!managementOrder);

    const [dropdown, setDropdown] = useState(false);
    const onMouseEnter = name => {
        if (window.innerWidth < 960) {
            setDropdown(state => ({ ...state, [name]: true }));
            } else {
                setDropdown(state => ({ ...state, [name]: true }));
            }
        };
    
    const onMouseLeave = name => {
    if (window.innerWidth < 960) {
        setDropdown(state => ({ ...state, [name]: false }));
        } else {
            setDropdown(state => ({ ...state, [name]: false }));
        }
    };
    
      
    let usertype = JSON.parse(localStorage.getItem('usertype'));
    const navigate = useNavigate();
     if(usertype === null) { navigate('/'); }
    
    return (
            <>
            <header className="main-header">
                <div className="container-fluid">
                    <div className="header">
                        <div className="widget-left">
                            <div className="d-flex">
                                <div className="image-wrap d-block d-sm-none">
                                    <img className="img-fluid" src={logo} alt="logo" width="80px" style={{paddingTop: "7px", marginRight: "10px"}}/>
                                </div>
                                <div className="menu-icon d-block d-sm-none" onClick={handleClick}>
                                <i className="ri-menu-2-line"></i>
                                    {/* <span className="material-icons" >menu</span> */}
                                </div>
                            </div>
                            <div className="menu-icon d-none d-sm-block" onClick={handleClick}>
                                <i className="ri-menu-2-line"></i>
                                    {/* <span className="material-icons" >menu</span> */}
                            </div>
                        </div>
                        <div className="menu-widget">
                            <div className="d-md-flex align-items-center d-none">
                                <div className="image-wrap">
                                    <img className="img-fluid" src={logo} alt="logo" width="80px"/>
                                </div>
                                <h2 className="mb-0 logo-text text-white">Order Processing</h2>
                            </div>
                        </div>
                        <div className="widget-right">
                            <div className="screen-resizer">
                                <a href="javascript:void(0)">
                                    <span className="material-icons text-white stroke-primary fullscreen-button" >
                                        fullscreen
                                    </span>
                                </a>
                            </div>
                            <div className="widget-item">
                                <a className="prof-item" href="javascript:void(0)">
                                    <div className="user-nav d-lg-flex d-none flex-column">
                                        <span className="user-name">Hello { localStorage.getItem('name')}</span>
                                    </div>
                                    <span> <Profile/></span>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <Navbar sticky="top" className= { click ? 'menu-horizontal' : 'menu-horizontal d-none d-lg-block'}>
                <div className="container-fluid mobile-nav">
                    <div className="mob-logo">
                        <div className="d-flex align-items-center">
                            <div className="image-wrap">
                                <img className="img-fluid" src={logo} alt="logo" width="120px" />
                            </div>
                            {/* <h2 className="mb-0 logo-text primary-text">SOHO</h2> */}
                        </div>
                        <div className="close-btn primary-text">
                            <span className="material-icons" onClick={handleClick}>
                                close
                            </span>
                        </div>
                    </div>
                    <ul className={click ? 'nav' : 'nav'}>
                        {/* Admin section start */}
                        <li  className={[1,2].includes(usertype) ? "nav-item d-block" : "nav-item d-none" }>
                            <NavLink exact className='nav-link' to="/dashboard" onClick={handleClick}>
                                <span className="material-icons">dashboard</span>
                                <span>Dashboard</span>
                            </NavLink>
                        </li>
                        <li  className={[3].includes(usertype) ? "nav-item d-block" : "nav-item d-none" }>
                            <NavLink exact className='nav-link' to="/dashboard-sales" onClick={handleClick}>
                                <span className="material-icons">dashboard</span>
                                <span>Dashboard</span>
                            </NavLink>
                        </li>
                        <li  className={[4].includes(usertype) ? "nav-item d-block" : "nav-item d-none" }>
                            <NavLink exact className='nav-link' to="/dashboard-accounts" onClick={handleClick}>
                                <span className="material-icons">dashboard</span>
                                <span>Dashboard</span>
                            </NavLink>
                        </li>
                        {/* product dropdown start */}
                        <li
                            className={[1].includes(usertype) ? "nav-item visible d-none d-sm-block" : "nav-item visible d-none" }
                            onMouseEnter={onMouseEnter.bind(this, 'product')}
                            onMouseLeave={onMouseLeave.bind(this, 'product')}
                        >
                            <NavLink exact={true} to="/#" onClick={e => e.preventDefault()} className={['/brand', '/polish', '/fabric', '/unit',
                        '/category', '/colourcode', '/leathercategory', '/leathertype', '/leathercolour', '/product-imported'].includes(pathname)  ? "nav-link active" : "nav-link" }>
                                <span className="material-icons">inventory_2</span>
                                <span>Product</span>
                                <span className="material-icons expand-row">expand_more</span>
                            </NavLink>
                            {dropdown.product && <Dropdown />}
                        </li>
                        <li
                            className={[1].includes(usertype) ? "nav-item visible d-block d-sm-none" : "nav-item visible d-none" }
                            onClick={showProduct}
                        >
                            <NavLink exact={true} to="/#" onClick={e => e.preventDefault()} className={['/brand', '/polish', '/fabric', '/unit',
                        '/category', '/colourcode', '/leathercategory', '/leathertype', '/leathercolour', '/product-imported'].includes(pathname)  ? "nav-link active" : "nav-link" }>
                                <span className="material-icons">inventory_2</span>
                                <span>Product</span>
                                <span className="material-icons expand-row">expand_more</span>
                            </NavLink>
                            <ul
                                className={product ? 'cus-dropdown visible' : 'cus-dropdown d-none'}
                            >
                                {MenuItems.map((item, index) => {
                                return (
                                    <li key={index}
                                    className='menu-list'
                                    >
                                    <NavLink
                                        activeclassName="active"
                                        className={item.cName}
                                        to={item.path}
                                        onClick={handleClick}
                                    >
                                        <span className="material-icons">arrow_right_alt</span> {item.title}
                                    </NavLink>
                                    </li>
                                );
                                })}
                            </ul>
                        </li>
                        {/* product dropdown end */}
                        {/* user dropdown start */}
                        <li 
                            className={[1].includes(usertype) ? "nav-item visible d-none d-sm-block" : "nav-item visible d-none" }
                            onMouseEnter={onMouseEnter.bind(this, 'users')}
                            onMouseLeave={onMouseLeave.bind(this, 'users')}
                        >
                            <NavLink to="" onClick={e => e.preventDefault()} className={['/users', '/customers'].includes(pathname) ? "nav-link active" : "nav-link" }>
                                <span className="material-icons">people_alt</span>
                                <span>Users</span>
                                <span className="material-icons expand-row">expand_more</span>
                            </NavLink>
                            {dropdown.users && <UserDropdown />}
                        </li>
                        <li 
                            className={[1].includes(usertype) ? "nav-item visible d-block d-sm-none" : "nav-item visible d-none" }
                            onClick={showUser}
                        >
                            <NavLink to="" onClick={e => e.preventDefault()} className={['/users', '/customers'].includes(pathname) ? "nav-link active" : "nav-link" }>
                                <span className="material-icons">people_alt</span>
                                <span>Users</span>
                                <span className="material-icons expand-row">expand_more</span>
                            </NavLink>
                            <ul
                                className={user ? 'cus-dropdown visible' : 'cus-dropdown d-none'}
                            >
                                {User.map((item, index) => {
                                return (
                                    <li key={index}
                                    className='menu-list'
                                    >
                                    <NavLink
                                        activeclassName="active"
                                        className={item.cName}
                                        to={item.path}
                                        onClick={handleClick}
                                    >
                                        <span className="material-icons">arrow_right_alt</span> {item.title}
                                    </NavLink>
                                    </li>
                                );
                                })}
                            </ul>
                        </li>
                        <li className={[2].includes(usertype) ? "nav-item visible d-block" : "nav-item visible d-none" }>
                            <NavLink exact className='nav-link' to="/users">
                                <span className="material-icons">people_alt</span>
                                <span>Users</span>
                            </NavLink>
                        </li>
                        {/* user dropdown end */}
                        {/* order dropdown start */}
                        <li 
                            className={[1,2].includes(usertype) ? "nav-item visible d-none d-sm-block" : "nav-item visible d-none" }
                            onMouseEnter={onMouseEnter.bind(this, 'order')}
                            onMouseLeave={onMouseLeave.bind(this, 'order')}
                        >
                            <NavLink  to="" className={['/order', '/approved-order', '/delivery-due-order', '/expected-delivery-date', '/out-for-delivery', '/payment', '/make-order', '/make-imported'].includes(pathname) ? "nav-link active" : "nav-link" } onClick={e => e.preventDefault()}>
                                <span className="material-icons">list_alt</span>
                                <span>Order</span>
                                <span className="material-icons expand-row">expand_more</span>
                            </NavLink>
                            {dropdown.order && <OrderDropdown />}
                        </li>
                        <li 
                            className={[1,2].includes(usertype) ? "nav-item visible d-block d-sm-none" : "nav-item visible d-none" }
                            onClick={showOrder}
                        >
                            <NavLink  to="" className={['/order', '/approved-order', '/delivery-due-order', '/expected-delivery-date', '/out-for-delivery', '/payment', '/make-order', '/make-imported'].includes(pathname) ? "nav-link active" : "nav-link" } onClick={e => e.preventDefault()}>
                                <span className="material-icons">list_alt</span>
                                <span>Order</span>
                                <span className="material-icons expand-row">expand_more</span>
                            </NavLink>
                            <ul
                                className={order ? 'cus-dropdown visible' : 'cus-dropdown d-none'}
                            >
                                {Order.map((item, index) => {
                                return (
                                    <li key={index}
                                    className='menu-list'
                                    >
                                    <NavLink
                                        activeclassName="active"
                                        className={item.cName}
                                        to={item.path}
                                        onClick={handleClick}
                                    >
                                        <span className="material-icons">arrow_right_alt</span> {item.title}
                                    </NavLink>
                                    </li>
                                );
                                })}
                            </ul>
                        </li>
                        {/* order dropdown end */}
                        {/* order management dropdown start */}
                        <li 
                            className={[3].includes(usertype) ? "nav-item visible d-none d-sm-block" : "nav-item visible d-none" }
                            onMouseEnter={onMouseEnter.bind(this, 'managementorder')}
                            onMouseLeave={onMouseLeave.bind(this, 'managementorder')}
                        >
                            <NavLink  to="" className={['/delivered-order', '/declined-order'].includes(pathname) ? "nav-link active" : "nav-link" } onClick={e => e.preventDefault()}>
                                <span className="material-icons">list_alt</span>
                                <span>Order</span>
                                <span className="material-icons expand-row">expand_more</span>
                            </NavLink>
                            {dropdown.managementorder && <ManagementOrderDropdown/>}
                        </li>
                        <li 
                            className={[3].includes(usertype) ? "nav-item visible d-sm-none d-block" : "nav-item visible d-none" }
                            onClick={showManagementOrder}
                        >
                            <NavLink  to="" className={['/delivered-order', '/declined-order'].includes(pathname) ? "nav-link active" : "nav-link" } onClick={e => e.preventDefault()}>
                                <span className="material-icons">list_alt</span>
                                <span>Order</span>
                                <span className="material-icons expand-row">expand_more</span>
                            </NavLink>
                            <ul
                                className={managementOrder ? 'cus-dropdown visible' : 'cus-dropdown d-none'}
                            >
                                {ManagementOrder.map((item, index) => {
                                return (
                                    <li key={index}
                                    className='menu-list'
                                    >
                                    <NavLink
                                        activeclassName="active"
                                        className={item.cName}
                                        to={item.path}
                                        onClick={handleClick}
                                    >
                                        <span className="material-icons">arrow_right_alt</span> {item.title}
                                    </NavLink>
                                    </li>
                                );
                                })}
                            </ul>
                        </li>
                         {/* order management dropdown end */}
                          {/* Quotation dropdown start */}
                        <li 
                            className={[1,2,3].includes(usertype) ? "nav-item visible d-none d-sm-block" : "nav-item visible d-none" }
                            onMouseEnter={onMouseEnter.bind(this, 'quotation')}
                            onMouseLeave={onMouseLeave.bind(this, 'quotation')}
                        >
                            <NavLink  to="" className={['/quotation', '/approved-quotation', '/declined-quotation'].includes(pathname) ? "nav-link active" : "nav-link" } onClick={e => e.preventDefault()}>
                                <span className="material-icons">file_open</span>
                                <span>Quotation</span>
                                <span className="material-icons expand-row">expand_more</span>
                            </NavLink>
                            {dropdown.quotation && <QuotationDropdown/>}
                        </li>
                        <li 
                            className={[1,2,3].includes(usertype) ? "nav-item visible d-block d-sm-none" : "nav-item visible d-none" }
                            onClick={showQuotation}
                        >
                            <NavLink  to="" className={['/quotation', '/approved-quotation', '/declined-quotation'].includes(pathname) ? "nav-link active" : "nav-link" } onClick={e => e.preventDefault()}>
                                <span className="material-icons">file_open</span>
                                <span>Quotation</span>
                                <span className="material-icons expand-row">expand_more</span>
                            </NavLink>
                            <ul
                                className={quotation ? 'cus-dropdown visible' : 'cus-dropdown d-none'}
                            >
                                {Quotation.map((item, index) => {
                                return (
                                    <li key={index}
                                    className='menu-list'
                                    >
                                    <NavLink
                                        activeclassName="active"
                                        className={item.cName}
                                        to={item.path}
                                        onClick={handleClick}
                                    >
                                        <span className="material-icons">arrow_right_alt</span> {item.title}
                                    </NavLink>
                                    </li>
                                );
                                })}
                            </ul>
                        </li>
                         {/* quotation dropdown end */}
                         {/* vendor dropdown start */}
                        <li 
                            className={[1,2].includes(usertype) ? "nav-item visible d-none d-sm-block" : "nav-item visible d-none" }
                            onMouseEnter={onMouseEnter.bind(this, 'vendor')}
                            onMouseLeave={onMouseLeave.bind(this, 'vendor')}
                        >
                            <NavLink  to="" className={['/vendor', '/vendor-incentive', '/vendor-paid-incentive'].includes(pathname) ? "nav-link active" : "nav-link" } onClick={e => e.preventDefault()}>
                                <span className="material-icons">store</span>
                                <span>Vendor</span>
                                <span className="material-icons expand-row">expand_more</span>
                            </NavLink>
                            {dropdown.vendor && <VendorDropdown />}
                        </li>
                        <li 
                            className={[1,2].includes(usertype) ? "nav-item visible d-sm-none d-block" : "nav-item visible d-none" }
                            onClick={showVendor}
                        >
                            <NavLink  to="" className={['/vendor', '/vendor-incentive', '/vendor-paid-incentive'].includes(pathname) ? "nav-link active" : "nav-link" } onClick={e => e.preventDefault()}>
                                <span className="material-icons">store</span>
                                <span>Vendor</span>
                                <span className="material-icons expand-row">expand_more</span>
                            </NavLink>
                            <ul
                                className={vendor ? 'cus-dropdown visible' : 'cus-dropdown d-none'}
                            >
                                {Vendor.map((item, index) => {
                                return (
                                    <li key={index}
                                    className='menu-list'
                                    >
                                    <NavLink
                                        activeclassName="active"
                                        className={item.cName}
                                        to={item.path}
                                        onClick={handleClick}
                                    >
                                        <span className="material-icons">arrow_right_alt</span> {item.title}
                                    </NavLink>
                                    </li>
                                );
                                })}
                            </ul>
                        </li>
                        {/* vendor dropdown end */}
                        {/* incentive dropdown start */}
                        <li 
                            className={[1,2].includes(usertype) ? "nav-item visible d-none d-sm-block" : "nav-item visible d-none" }
                            onMouseEnter={onMouseEnter.bind(this, 'incentive')}
                            onMouseLeave={onMouseLeave.bind(this, 'incentive')}
                        >
                            <NavLink  to="" className={['/incentive', '/paid-incentive'].includes(pathname) ? "nav-link active" : "nav-link" } onClick={e => e.preventDefault()}>
                                <span className="material-icons">receipt</span>
                                <span>Incentive Sales</span>
                                <span className="material-icons expand-row">expand_more</span>
                            </NavLink>
                            {dropdown.incentive && <IncentiveDropdown/>}
                        </li>
                        <li 
                            className={[1,2].includes(usertype) ? "nav-item visible d-sm-none d-block" : "nav-item visible d-none" }
                           onClick={showIncentive}
                        >
                            <NavLink  to="" className={['/incentive', '/paid-incentive'].includes(pathname) ? "nav-link active" : "nav-link" } onClick={e => e.preventDefault()}>
                                <span className="material-icons">receipt</span>
                                <span>Incentive Sales</span>
                                <span className="material-icons expand-row">expand_more</span>
                            </NavLink>
                            <ul
                                className={incentive ? 'cus-dropdown visible' : 'cus-dropdown d-none'}
                            >
                                {Incentive.map((item, index) => {
                                return (
                                    <li key={index}
                                    className='menu-list'
                                    >
                                    <NavLink
                                        activeclassName="active"
                                        className={item.cName}
                                        to={item.path}
                                        onClick={handleClick}
                                    >
                                        <span className="material-icons">arrow_right_alt</span> {item.title}
                                    </NavLink>
                                    </li>
                                );
                                })}
                            </ul>
                        </li>
                        {/* incentive dropdown end */}
                        {/* setting dropdown start */}
                        <li 
                            className={[1].includes(usertype) ? "nav-item visible d-none d-sm-block" : "nav-item visible d-none" }
                            onMouseEnter={onMouseEnter.bind(this, 'setting')}
                            onMouseLeave={onMouseLeave.bind(this, 'setting')}
                        >
                            <NavLink  to="" className={['/usertype', '/branch'].includes(pathname) ? "nav-link active" : "nav-link" } onClick={e => e.preventDefault()}>
                                <span className="material-icons">settings</span>
                                <span>Setting</span>
                                <span className="material-icons expand-row">expand_more</span>
                            </NavLink>
                            {dropdown.setting && <SettingDropdown />}
                        </li>
                        <li 
                            className={[1].includes(usertype) ? "nav-item visible d-sm-none d-block" : "nav-item visible d-none" }
                            onClick={showSetting}
                        >
                            <NavLink  to="" className={['/usertype', '/branch'].includes(pathname) ? "nav-link active" : "nav-link" } onClick={e => e.preventDefault()}>
                                <span className="material-icons">settings</span>
                                <span>Setting</span>
                                <span className="material-icons expand-row">expand_more</span>
                            </NavLink>
                            <ul
                                className={setting ? 'cus-dropdown visible' : 'cus-dropdown d-none'}
                            >
                                {Setting.map((item, index) => {
                                return (
                                    <li key={index}
                                    className='menu-list'
                                    >
                                    <NavLink
                                        activeclassName="active"
                                        className={item.cName}
                                        to={item.path}
                                        onClick={handleClick}
                                    >
                                        <span className="material-icons">arrow_right_alt</span> {item.title}
                                    </NavLink>
                                    </li>
                                );
                                })}
                            </ul>
                        </li>
                         {/* setting dropdown end */}
                          {/* Report dropdown start */}
                        <li 
                            className={[1,2].includes(usertype) ? "nav-item visible d-none d-sm-block" : "nav-item visible d-none" }
                            onMouseEnter={onMouseEnter.bind(this, 'report')}
                            onMouseLeave={onMouseLeave.bind(this, 'report')}
                        >
                            <NavLink  to="" className={['/branch-wise', '/executive-wise', '/consolidated-report', '/product-wise-report', '/stock-report'].includes(pathname) ? "nav-link active" : "nav-link" } onClick={e => e.preventDefault()}>
                                <span className="material-icons">summarize</span>
                                <span>Report</span>
                                <span className="material-icons expand-row">expand_more</span>
                            </NavLink>
                            {dropdown.report && <ReportDropdown/>}
                        </li>
                        <li 
                            className={[1,2].includes(usertype) ? "nav-item visible d-sm-none d-block" : "nav-item visible d-none" }
                            onClick={showReport}
                        >
                            <NavLink  to="" className={['/branch-wise', '/executive-wise', '/consolidated-report', '/product-wise-report', '/stock-report'].includes(pathname) ? "nav-link active" : "nav-link" } onClick={e => e.preventDefault()}>
                                <span className="material-icons">summarize</span>
                                <span>Report</span>
                                <span className="material-icons expand-row">expand_more</span>
                            </NavLink>
                            <ul
                                className={report ? 'cus-dropdown visible' : 'cus-dropdown d-none'}
                            >
                                {Report.map((item, index) => {
                                return (
                                    <li key={index}
                                    className='menu-list'
                                    >
                                    <NavLink
                                        activeclassName="active"
                                        className={item.cName}
                                        to={item.path}
                                        onClick={handleClick}
                                    >
                                        <span className="material-icons">arrow_right_alt</span> {item.title}
                                    </NavLink>
                                    </li>
                                );
                                })}
                            </ul>
                        </li>
                        {/* Report dropdown end */}
                        {/* Admin section end */}
                        {/* Account section start */}
                        <li 
                            className={[4].includes(usertype) ? "nav-item visible d-none d-sm-block" : "nav-item visible d-none" }
                            onMouseEnter={onMouseEnter.bind(this, 'payment')}
                            onMouseLeave={onMouseLeave.bind(this, 'payment')}
                        >
                            <NavLink  to="" className={['/receive-payment', '/approved-payment'].includes(pathname) ? "nav-link active" : "nav-link" } onClick={e => e.preventDefault()}>
                                <span className="material-icons">payment</span>
                                <span>Payment</span>
                                <span className="material-icons expand-row">expand_more</span>
                            </NavLink>
                            {dropdown.payment && <PaymentDropdown/>}
                        </li>
                        <li 
                            className={[4].includes(usertype) ? "nav-item visible d-sm-none d-block" : "nav-item visible d-none" }
                            onClick={showPayment}
                        >
                            <NavLink  to="" className={['/receive-payment', '/approved-payment'].includes(pathname) ? "nav-link active" : "nav-link" } onClick={e => e.preventDefault()}>
                                <span className="material-icons">payment</span>
                                <span>Payment</span>
                                <span className="material-icons expand-row">expand_more</span>
                            </NavLink>
                            <ul
                                className={payment ? 'cus-dropdown visible' : 'cus-dropdown d-none'}
                            >
                                {Payment.map((item, index) => {
                                return (
                                    <li key={index}
                                    className='menu-list'
                                    >
                                    <NavLink
                                        activeclassName="active"
                                        className={item.cName}
                                        to={item.path}
                                        onClick={handleClick}
                                    >
                                        <span className="material-icons">arrow_right_alt</span> {item.title}
                                    </NavLink>
                                    </li>
                                );
                                })}
                            </ul>
                        </li>
                        <li 
                            className={[4].includes(usertype) ? "nav-item visible d-none d-sm-block" : "nav-item visible d-none" }
                            onMouseEnter={onMouseEnter.bind(this, 'accountincentive')}
                            onMouseLeave={onMouseLeave.bind(this, 'accountincentive')}
                        >
                            <NavLink  to="" className={['/due-incentive', '/account-paid-incentive'].includes(pathname) ? "nav-link active" : "nav-link" } onClick={e => e.preventDefault()}>
                                <span className="material-icons"><span className="material-icons-outlined">account_balance_wallet</span></span>
                                <span>Incentive</span>
                                <span className="material-icons expand-row">expand_more</span>
                            </NavLink>
                            {dropdown.accountincentive && <AccountIncentiveDropdown/>}
                        </li>
                        <li 
                            className={[4].includes(usertype) ? "nav-item visible d-sm-none d-block" : "nav-item visible d-none" }
                            onClick={showAccountIncentive}
                        >
                            <NavLink  to="" className={['/due-incentive', '/account-paid-incentive'].includes(pathname) ? "nav-link active" : "nav-link" } onClick={e => e.preventDefault()}>
                                <span className="material-icons"><span className="material-icons-outlined">account_balance_wallet</span></span>
                                <span>Incentive</span>
                                <span className="material-icons expand-row">expand_more</span>
                            </NavLink>
                            <ul
                                className={accountIncentive ? 'cus-dropdown visible' : 'cus-dropdown d-none'}
                            >
                                {AccountIncentive.map((item, index) => {
                                return (
                                    <li key={index}
                                    className='menu-list'
                                    >
                                    <NavLink
                                        activeclassName="active"
                                        className={item.cName}
                                        to={item.path}
                                        onClick={handleClick}
                                    >
                                        <span className="material-icons">arrow_right_alt</span> {item.title}
                                    </NavLink>
                                    </li>
                                );
                                })}
                            </ul>
                        </li>
                       {/* Account section end */}
                       {/* Book section start */}
                        <li className={[9].includes(usertype) ? "nav-item visible d-block" : "nav-item visible d-none" } onClick={handleClick}>
                            <NavLink    to="/view-order" className="nav-link">
                                <span className="material-icons">receipt_long</span>
                                <span>View Order</span>
                            </NavLink>
                        </li>
                        <li className={[9].includes(usertype) ? "nav-item visible d-block" : "nav-item visible d-none" } onClick={handleClick}>
                            <NavLink    to="/booked-order" className="nav-link">
                                <span className="material-icons">receipt_long</span>
                                <span>Booked Order</span>
                            </NavLink>
                        </li>
                       {/* Book section end */}
                        {/* Design section start */}
                        <li className={[8].includes(usertype) ? "nav-item visible d-block" : "nav-item visible d-none" } onClick={handleClick}>
                            <NavLink    to="/view-pending-design" className="nav-link">
                                <span className="material-icons">draw</span>
                                <span>Pending Design</span>
                            </NavLink>
                        </li>
                        <li className={[8].includes(usertype) ? "nav-item visible d-block" : "nav-item visible d-none" } onClick={handleClick}>
                            <NavLink    to="/completed-design" className="nav-link">
                                <span className="material-icons">draw</span>
                                <span>Completed Design</span>
                            </NavLink>
                        </li>
                        {/* Design section end */}
                        {/* Dispatch section start */}
                        <li className={[6].includes(usertype) ? "nav-item visible d-block" : "nav-item visible d-none" } onClick={handleClick}>
                            <NavLink    to="/dispatch-Order" className="nav-link">
                                <span className="material-icons">local_shipping</span>
                                <span>Pending Dispatch</span>
                            </NavLink>
                        </li>
                        <li className={[6].includes(usertype) ? "nav-item visible d-block" : "nav-item visible d-none" } onClick={handleClick}>
                            <NavLink    to="/dispatched-order" className="nav-link">
                                <span className="material-icons">local_shipping</span>
                                <span>Dispatched Order</span>
                            </NavLink>
                        </li>
                        {/* Dispatch section end */}
                        {/* Production section end */}
                        <li className={[5].includes(usertype) ? "nav-item visible d-block" : "nav-item visible d-none" } onClick={handleClick}>
                            <NavLink    to="/production-view" className="nav-link">
                                <span className="material-icons">receipt_long</span>
                                <span>View Order</span>
                            </NavLink>
                        </li>
                        <li className={[5].includes(usertype) ? "nav-item visible d-block" : "nav-item visible d-none" } onClick={handleClick}>
                            <NavLink    to="/production-under-processing" className="nav-link">
                                <span className="material-icons">receipt_long</span>
                                <span>Under Processing</span>
                            </NavLink>
                        </li>
                        <li className={[5].includes(usertype) ? "nav-item visible d-block" : "nav-item visible d-none" } onClick={handleClick}>
                            <NavLink    to="/production-completed" className="nav-link">
                                <span className="material-icons">receipt_long</span>
                                <span>Completed Order</span>
                            </NavLink>
                        </li>
                        {/* Production section end */}
                        {/* SAP section start */}
                        <li className={[7].includes(usertype) ? "nav-item visible d-block" : "nav-item visible d-none" } onClick={handleClick}>
                            <NavLink    to="/sap-booking" className="nav-link">
                                <span className="material-icons">receipt_long</span>
                                <span>Confirm Booking</span>
                            </NavLink>
                        </li>
                        <li className={[7].includes(usertype) ? "nav-item visible d-block" : "nav-item visible d-none" } onClick={handleClick}>
                            <NavLink    to="/sap-booked" className="nav-link">
                                <span className="material-icons">receipt_long</span>
                                <span>Booked</span>
                            </NavLink>
                        </li>
                        <li className={[7].includes(usertype) ? "nav-item visible d-block" : "nav-item visible d-none" } onClick={handleClick}>
                            <NavLink    to="/sap-stockout" className="nav-link">
                                <span className="material-icons">receipt_long</span>
                                <span>Stock Out</span>
                            </NavLink>
                        </li>
                        {/* SAP section end */}
                        {/* Peddler section start */}
                        <li className={[11].includes(usertype) ? "nav-item visible d-block" : "nav-item visible d-none" } onClick={handleClick}>
                            <NavLink    to="/peddler-stock-query" className="nav-link">
                                <span className="material-icons">receipt_long</span>
                                <span>Stock Query</span>
                            </NavLink>
                        </li>
                        {/* Peddler section end */}
                        {/* Branch section start */}
                        <li className={[12].includes(usertype) ? "nav-item visible d-block" : "nav-item visible d-none" } onClick={handleClick}>
                            <NavLink    to="/branch-dashboard" className="nav-link">
                                <span className="material-icons">receipt_long</span>
                                <span>Dashboard</span>
                            </NavLink>
                        </li>
                        <li className={[12].includes(usertype) ? "nav-item visible d-block" : "nav-item visible d-none" } onClick={handleClick}>
                            <NavLink    to="/stock-in" className="nav-link">
                                <span className="material-icons">receipt_long</span>
                                <span>Stock In</span>
                            </NavLink>
                        </li>
                        <li className={[12].includes(usertype) ? "nav-item visible d-block" : "nav-item visible d-none" } onClick={handleClick}>
                            <NavLink    to="/stock-out" className="nav-link">
                                <span className="material-icons">receipt_long</span>
                                <span>Stock Out</span>
                            </NavLink>
                        </li>
                        <li className={[12].includes(usertype) ? "nav-item visible d-block" : "nav-item visible d-none" }onClick={handleClick} >
                            <NavLink    to="/stock-query" className="nav-link">
                                <span className="material-icons">receipt_long</span>
                                <span>Stock Query</span>
                            </NavLink>
                        </li>
                        <li className={[12].includes(usertype) ? "nav-item visible d-block" : "nav-item visible d-none" } onClick={handleClick}>
                            <NavLink    to="/current-stock" className="nav-link">
                                <span className="material-icons">receipt_long</span>
                                <span>Current Stock</span>
                            </NavLink>
                        </li>
                        <li className={[12].includes(usertype) ? "nav-item visible d-block" : "nav-item visible d-none" } onClick={handleClick}>
                            <NavLink    to="/branch-entries" className="nav-link">
                                <span className="material-icons">receipt_long</span>
                                <span>Branch Entries</span>
                            </NavLink>
                        </li>
                        {/* Branch section end */}
                        {/* CP section start */}
                        <li className={[13].includes(usertype) ? "nav-item visible d-block" : "nav-item visible d-none" } onClick={handleClick}>
                            <NavLink    to="/create-cp-order" className="nav-link">
                                <span className="material-icons">receipt_long</span>
                                <span>Create Order</span>
                            </NavLink>
                        </li>
                        <li className={[13].includes(usertype) ? "nav-item visible d-block" : "nav-item visible d-none" } onClick={handleClick}>
                            <NavLink    to="/view-cp-order" className="nav-link">
                                <span className="material-icons">receipt_long</span>
                                <span>View Order</span>
                            </NavLink>
                        </li>
                        <li className={[13].includes(usertype) ? "nav-item visible d-block" : "nav-item visible d-none" } onClick={handleClick}>
                            <NavLink    to="/cp-product" className="nav-link">
                                <span className="material-icons">receipt_long</span>
                                <span>Product</span>
                            </NavLink>
                        </li>
                        {/* CP section end */}
                        <li className={[1,2,3,4,5,6,7,8,9].includes(usertype) ? "nav-item visible d-block" : "nav-item visible d-none" }>
                            <NavLink  to="/prfm" className={['/prfm'].includes(pathname)  ? "nav-link active" : "nav-link" } onClick={handleClick}>
                                <span className="material-icons">question_answer</span>
                                <span>PRFM</span>
                                {/* <span className="material-icons expand-row">expand_more</span> */}
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </Navbar>
        </>
  )
}
export default NavbarMenu