import {
    RETRIEVE_PENDING_DISPATCH
} from "../PendingDispatch/actiontype";

import { toast } from 'react-toastify';
import api from "../../../api";
let usertype = JSON.parse(localStorage.getItem('usertype'));
let userid = JSON.parse(localStorage.getItem('userid'));

export const getPendingDispatch= (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  let data = {
    usertype : usertype,
    userid : userid
  };
  
  try {
    const res = await api.post(`/dispatchSection/pendingDispatchOrder`, data);
   
    
    dispatch({
      type: RETRIEVE_PENDING_DISPATCH,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_PENDING_DISPATCH,
      payload: [],
    });
  }
};



