import {
    RETRIEVE_BOOK_ORDER
} from "../BookedOrder/actiontype";

import { toast } from 'react-toastify';
import api from "../../../api";

let usertype = JSON.parse(localStorage.getItem('usertype'));
let userid = JSON.parse(localStorage.getItem('userid'));

export const getBookedOrder= (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  let data = {
    usertype : usertype,
    userid : userid
  };

  try {
    const res = await api.post(`/bookSection/bookedOrder`, data);
   
    
    dispatch({
      type: RETRIEVE_BOOK_ORDER,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_BOOK_ORDER,
      payload: [],
    });
  }
};



