import {
   RETRIEVE_COLUMNER_REPORT
} from "../ColumnerReport/actiontype";

import api from "../../../api";

export const getColumnerReport= (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  try {
    const res = await api.get(`/report/columnerReport`);
    console.log(res);
    dispatch({
      type:RETRIEVE_COLUMNER_REPORT,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type:RETRIEVE_COLUMNER_REPORT,
      payload: [],
    });
  }
};



