export const QUOTATION_DETAIL = "QUOTATION_DETAIL";
export const UPDATE_QUOTATION = "UPDATE_QUOTATION";
export const GET_IMPORTED_READY = "GET_IMPORTED_READY";
export const GET_IMPORTED_ORDER = "GET_IMPORTED_ORDER";
export const GET_TOTAL_AMOUNT = "GET_TOTAL_AMOUNT";
export const GET_QUOTATION_NAME = "GET_QUOTATION_NAME";
export const READY_NET_AMOUNT = "READY_NET_AMOUNT";
export const ORDER_NET_AMOUNT = "ORDER_NET_AMOUNT";
export const EDIT_READY_PRODUCT = "EDIT_READY_PRODUCT";
export const EDIT_NET_AMOUNT = "EDIT_NET_AMOUNT";
export const EDIT_ORDER_PRODUCT = "EDIT_ORDER_PRODUCT";
export const EDIT_ORDER_NET_AMOUNT = "EDIT_ORDER_NET_AMOUNT";
export const BRANCH_DETAIL = " BRANCH_DETAIL";
export const TOTAL_AMOUNT = " TOTAL_AMOUNT";
export const FITTING_AMOUNT = " FITTING_AMOUNT";
export const SUB_TOTAL_AMOUNT = " SUB_TOTAL_AMOUNT";
export const PACKING_AMOUNT = " PACKING_AMOUNT";
export const GRAND_TOTAL = " GRAND_TOTAL";
export const GROSS_TOTAL = "GROSS_TOTAL";
export const BALANCE_AMT = "BALANCE_AMT";