import * as React from "react";
import { useState, useEffect } from 'react'
import { Row, Col, Container, Dropdown, Badge } from 'react-bootstrap';
import MaterialTable from 'material-table';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CheckIcon from '@mui/icons-material/Check';
import { connect } from "react-redux";
import {getPendingIncentive,approveIncentive} from '../../../redux/Incentive/PendingIncentive/action';
import ConfirmDialog from "../../../components/ConfirmDialog/ConfirmDialog";
import { useNavigate } from 'react-router-dom';
import SearchBar from "../../../components/SearchBar/SearchBar";
import Loader from "../../../components/Loader/Loader";

const Incentive = ({ getPendingIncentive,currentIntegration,approveIncentive,isLoading }) =>  {  useEffect(() => {
    getPendingIncentive();
  }, []);

const [visible, setVisible] = useState(false);
const handleVisibility = () => {
    setVisible(prev => !prev);
};

const [loading, setLoading] = useState(false);
useEffect(() => {
    if (isLoading) {
        setLoading(true);
    }
  });

const [tableData, setTableData] = useState([]);
useEffect(() => {
if(currentIntegration)
{
    setTableData(currentIntegration); 
}
}, [currentIntegration])

const columns = [
    { title: "Order No.", field: "orderNo" },
    { title: "Customer Detail", field: "customerDetail" },
    { title: "Created By", field: "createdBy" },
    { title: "Approved By", field: "approvedBy" },
    { title: "No. Of Items", field: "qty" },
    { title: "Net Total Amt.", field: "totalAmount" },
    { title: "Total Disc.", field: "discount" },
    { title: "Grand Total", field: "grandTotal" },
    { title: "DOO", field: "orderDate" },
    { title: "Delivery Date", field: "deliveryDate" },
    { title: "Total Incent.", field: "totalIncentive" },
]

const [confirmDialog, setConfirmDialog] = useState({ isOpen: false, title: '', type: ''})
const navigate = useNavigate();

const viewIncentive = async (e) => {
    
    navigate('/detail-View-order/1');
}

const incentiveapprove = async (e,f) => {
    
    setConfirmDialog({
    isOpen: true,
    title: 'Are You Sure To Approve This Incentive ?',
    type: true,
    onConfirm: () => { 
        const data = {
            id: e,
            totalIncentive:  f,
          };
        approveIncentive(data);
        setConfirmDialog({ ...confirmDialog, isOpen: false })
    }
    })
} 
   
// Search for Card view start
const [searchValue, setSearchValue] = useState("");

const filterOrders = ({ orderNo }) => {
  return orderNo.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
};
// Search for Card view end

return (
        <>
            <Container fluid className="main-content">
                <Row>
                    <Col className='d-flex justify-content-between mb-2'>
                        <h3 className="main-title">Incentive</h3>
                        <div className="d-flex justify-content-between d-block d-sm-none">
                            <div className="magnifierContainer">
                                <button type="button" className="btn btn-md btn-warning text-white roundedcircle mr-3" onClick={handleVisibility}><i className="fa-solid fa-search"></i></button>
                            </div>
                        </div>
                    </Col>
                    <Col lg={"12"} className='mb-2 d-block d-sm-none'>
                        <div className={visible ? "open" : "close"}>
                            <SearchBar onSearch={setSearchValue} value={searchValue} />
                        </div>
                    </Col>
                </Row>
                {loading ? <div>
                <Row className="d-none d-sm-block mt-2">
                    <Col lg={"12"}>
                        <div style={{ display: 'table', tableLayout: 'fixed', width: '100%' }}>
                            <MaterialTable columns={columns} data={tableData}
                               actions={[
                                {
                                  icon: () => <VisibilityIcon color={'primary'} />,
                                  tooltip: 'View Incentive',
                                  onClick: (event, rowData) => viewIncentive(rowData.id)
                                },
                                
                                {
                                  icon: () => <CheckIcon color={'success'} />,
                                  tooltip: 'Approve Incentive',
                                  onClick: (event, rowData) => incentiveapprove(rowData.id, rowData.totalIncentive)
                                }
                              ]}
                                options={{
                                    sorting: true, search: true,
                                    paging: true, pageSizeOptions: [10, 25, 50, 100], pageSize: 10,
                                    exportButton: true,
                                    exportAllData: true, exportFileName: "Incentive", actionsColumnIndex: -1,
                                    // headerStyle: { background: "#f44336",color:"#fff"}
                                }}
                                title="Incentive"
                            />
                        </div>
                    </Col>
                </Row>
                <Row className="d-block d-sm-none">
                    {currentIntegration.filter(filterOrders).map((incentive, index) => ( 
                    <Col xs={"12"} key={index}>
                        <div className="card mb-2">
                            <div className="card-header d-flex justify-content-between pb-0">
                                <h6>{incentive.orderNo} <Badge bg="danger" text="light" className="ml-2">{incentive.qty}</Badge></h6>
                                <Dropdown>
                                <Dropdown.Toggle variant="light" id="dropdown-basic">
                                    <i className="ri-more-2-line"></i>
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item onClick={() => viewIncentive(incentive.id)}><VisibilityIcon color={'primary'} /> View</Dropdown.Item>
                                    <Dropdown.Item onClick={() => incentiveapprove(incentive.id, incentive.totalIncentive)}><CheckIcon color={'success'} /> Approve</Dropdown.Item>
                                </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className="card-body pt-1">
                                <p className="card-text mb-0"><span className="quotation-info">Customer Detail:</span> {incentive.customerDetail}</p>
                                <p className="card-text mb-0"><span className="quotation-info">Created By:</span> {incentive.createdBy}</p>
                                <p className="card-text mb-0"><span className="quotation-info">Approved By:</span> {incentive.approvedBy}</p>
                                <p className="card-text mb-0"><span className="quotation-info">Net Amount:</span> {incentive.totalAmount}</p>
                                <p className="card-text mb-0"><span className="quotation-info">Discount:</span> {incentive.discount}</p>
                                <p className="card-text mb-0"><span className="quotation-info">Grand Total:</span> {incentive.grandTotal}</p>
                                <p className="card-text mb-0"><span className="quotation-info">Total Incentive:</span> {incentive.totalIncentive}</p>
                                <p className="card-text mb-0"><span className="quotation-info">Order Date:</span> {incentive.orderDate}</p>
                                <p className="card-text mb-0"><span className="quotation-info">Delivery Date:</span> {incentive.deliveryDate}</p>
                            </div>
                        </div>
                    </Col>
                    ))}
                </Row>
                </div> : <Loader/>}
            </Container>
            <ConfirmDialog
                confirmDialog={confirmDialog}
                setConfirmDialog={setConfirmDialog}
            />
        </>

    )
}

const mapStateToProps = (state) => {

  return {
     currentIntegration: state.pendingIncentive.currentIntegration,
     isLoading: state.pendingIncentive.isLoading,
   }
 }

 export default connect(mapStateToProps,{getPendingIncentive,approveIncentive})(Incentive);