import {
    CREATE_IMPORTEDPRODUCT,
    RETRIEVE_IMPORTEDPRODUCT,
    SET_BRANDS,
    SET_PRODUCT_CATEGORY,
    SET_LEATHER_CATEGORY,
    SET_LEATHER_TYPE,
    SET_LEATHER_COLOUR,
    SET_COLOUR_CODE,
    DELETE_IMPORTEDPRODUCT,
    RETRIEVE_SINGLE_IMPORTEDPRODUCT,
    PRINT_QRCODE
} from "../ImportedProduct/actiontype";

import { toast } from 'react-toastify';
import api from "../../../api";

//  Get Brand Name
export const getBrandList = (data) => async (dispatch) => {
  //console.log('test');
 try {
   const res = await api.get(`/common/brandList`);
   console.log(res);
       dispatch({
         type: SET_BRANDS,
         payload: res.data.data,
       });
 } catch (err) { }
};

//  Get Product Category
export const getProductCategory = (data) => async (dispatch) => {
 try {
   const res = await api.get(`/common/categoryList`);
   //console.log(res);
       dispatch({
         type: SET_PRODUCT_CATEGORY,
         payload: res.data.data,
       });
 } catch (err) { }
};

//  Get Leather Category
export const getLeatherCategory = (data) => async (dispatch) => {
 try {
   const res = await api.get(`/common/leatherCategoryList`);
   //console.log(res);
       dispatch({
         type: SET_LEATHER_CATEGORY,
         payload: res.data.data,
       });
 } catch (err) { }
};

//  Get Leather Type
export const getLeatherType = (data) => async (dispatch) => {
 try {
   const res = await api.get(`/common/leatherTypeList`);
   //console.log(res);
       dispatch({
         type: SET_LEATHER_TYPE,
         payload: res.data.data,
       });
 } catch (err) { }
};

//  Get Leather Colour
export const getLeatherColour = (data) => async (dispatch) => {
 try {
   const res = await api.get(`/common/leatherColourList`);
  // console.log(res);
       dispatch({
         type: SET_LEATHER_COLOUR,
         payload: res.data.data,
       });
 } catch (err) { }
};

//  Get Colour code
export const getColourCode = (data) => async (dispatch) => {
 try {
   const res = await api.get(`/common/colourCodeList`);
  // console.log(res);
       dispatch({
         type: SET_COLOUR_CODE,
         payload: res.data.data,
       });
 } catch (err) { }
};

export const importedProduct = ({
  brand,
  productname,
  model,
  category,
  leathercategory,
  leathertype,
  leathercolour,
  colourcode,
  specification,
  size,
  rdp,
  amount,
  files
}) => async (dispatch) => {
  
  try {
    let data = new FormData();
        data.append('brand_id', brand);
        data.append('name', productname);
        data.append('model_no', model);
        data.append('category_id', category);
        data.append('leather_category_id', leathercategory);
        data.append('leather_type_id', leathertype);
        data.append('leather_colour_id', leathercolour);
        data.append('colour_code_id', colourcode);
        data.append('spec', specification);
        data.append('size', size);
        data.append('rdp', rdp);
        data.append('amount', amount);
        data.append('files', files);
        const res = await api.post('/productImported/addProductImported', data);
        if (res.status === 200) {
          toast.success('Imported Product Added Successfully');
          dispatch({
            type: CREATE_IMPORTEDPRODUCT,
            payload: res.data.data.info[0],
          });
        }
  } catch (error) {
    // toast.error('Unable to update profile!');
    // dispatch({
    //   type: CREATE_IMPORTEDPRODUCT,
    // });
  }
};

export const getImportedProduct = (data) => async (dispatch) => {
  
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  try {
    const res = await api.get(`/productImported/productImported`);
   
    dispatch({
      type: RETRIEVE_IMPORTEDPRODUCT,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_IMPORTEDPRODUCT,
      payload: [],
    });
  }
};

export const getSingleImportedProduct = (id) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  try {
    const res = await api.get(`/productImported/getProductImported/` + id);
    console.log(res);
    dispatch({
      type: RETRIEVE_SINGLE_IMPORTEDPRODUCT,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_SINGLE_IMPORTEDPRODUCT,
      payload: [],
    });
  }
};

export const deleteImportedProduct = (id) => async (dispatch) => {
  try {
    const res = await api.delete('/productImported/deleteProductImported/' + id);
    console.log(res);
    if (res.status === 200) {
      toast.success(res.data.message);
      dispatch({
        type:  DELETE_IMPORTEDPRODUCT,
        payload: res.data.data.info,
      });
      
    }
  } catch (error) {
    console.log(error);
    toast.error('Failed to delete');
    dispatch({
      type:  DELETE_IMPORTEDPRODUCT,
      payload: [],
    });
  }
};

export const getQrCodeData = (id) => async (dispatch) => {
  try {
    const res = await api.get('/productImported/qrCodeData/' + id);
    console.log(res);
    if (res.status === 200) {
      //toast.success(res.data.message);
      dispatch({
        type:  PRINT_QRCODE,
        payload: res.data.data,
      });
      
    }
  } catch (error) {
    //toast.error('Failed to delete');
    dispatch({
      type:  PRINT_QRCODE,
      payload: [],
    });
  }
};