import {
    RETRIEVE_PAID_INCENTIVE
} from "../PaidIncentive/actiontype";

import { toast } from 'react-toastify';
import api from "../../../api";


export const getPaidIncentive= (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  try {
    const res = await api.get(`/incentive/paidIncentive`);
   
    
    dispatch({
      type: RETRIEVE_PAID_INCENTIVE,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_PAID_INCENTIVE,
      payload: [],
    });
  }
};



