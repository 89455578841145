import * as React from "react";
import {useState, useEffect} from 'react'
import { Row, Col, Container, Badge, Dropdown } from 'react-bootstrap';
import MaterialTable from 'material-table';
import { connect } from "react-redux";
import {getExpectedDeliveryDate} from '../../../redux/Order/ExpectedDeliveryDate/action';
import Loader from "../../../components/Loader/Loader";

const ExpectedDeliveryDate = ({ getExpectedDeliveryDate,currentIntegration,isLoading }) =>  {  useEffect(() => {
    getExpectedDeliveryDate();
  }, []);
    
const [visible, setVisible] = useState(false);
const handleVisibility = () => {
    setVisible(prev => !prev);
};

const [loading, setLoading] = useState(false);
useEffect(() => {
    if (isLoading) {
        setLoading(true);
    }
  });

const [tableData, setTableData] = useState([]);
useEffect(() => {
if(currentIntegration)
{
    setTableData(currentIntegration); 
}
}, [currentIntegration])

const columns = [
{ title: "EDD", field: "expectedDate" },
{ title: "Due For/Due In", field: "days" },
{ title: "Order No. | Created By", field: "orderNo" },
// { title: "Created By ", field: "createdBy" },
{ title: "Customer Detail", field: "customerDetail" },
// { title: "Customer Mobile", field: "customerMobile" },
{ title: "Qty", field: "qty" },
{ title: "Net Total Amount", field: "totalAmount" },
{ title: "Total Discount", field: "totalDiscount" },
{ title: "Grand Total", field: "grandTotal" },
{ title: "Advance Paid", field: "advancePaid" },
{ title: "Total Paid", field: "totalPaid" },
{ title: "Status", field: "status" },

]
    
  return (
    <>
        <Container fluid className="main-content">
            <Row>
                <Col>
                    <h3 className="main-title">Expected Delivery Date</h3>
                </Col> 
                <Col>
                    <div style={{float: "right"}} className="d-flex">
                        <div className="d-flex justify-content-between d-flex d-sm-none">
                        <div className="magnifierContainer">
                            <button type="button" className="btn btn-md btn-warning text-white roundedcircle mr-3" onClick={handleVisibility}><i className="fa-solid fa-search"></i></button>
                        </div>
                        </div>
                    </div>
                </Col>
                <Col lg={"12"}>
                    <div className='d-block d-sm-none mt-2'>
                    <div className={visible ? "open" : "close"}>
                        <input placeholder="Search..." type="text" className="form-control is-open mr-3 mb-0 search-input"/>
                    </div>
                    </div>
                </Col>
            </Row>
            {loading ? <div>
            <Row className="d-none d-sm-block mt-2">
                <Col lg={"12"} className="mt-3">
                    <div style={{display: 'table', tableLayout:'fixed', width:'100%'}}>
                    <MaterialTable columns={columns} data={tableData}
                        options={{
                        sorting: true, search: true,
                        paging: true, pageSizeOptions: [10, 25, 50, 100], pageSize: 10,
                        exportButton: true,
                        exportAllData: true, exportFileName: "Expected Delivery Date", actionsColumnIndex: -1,
                        // headerStyle: { background: "#f44336",color:"#fff"}
                        }}
                        title="Expected Delivery Date"
                    />
                    </div>
                </Col>
            </Row>
            <Row className="d-block d-sm-none mt-2">
                {currentIntegration.map((order, index) => (
                <Col xs={"12"} key={index}>
                    <div className="card mb-2">
                        <div className="card-header d-flex justify-content-between pb-0">
                            <h6>{order.orderNo}	 <Badge bg="danger" text="light" className="ml-2">{order.qty}</Badge></h6>
                        </div>
                        <div className="card-body pt-1">
                            <p className="card-text mb-0"><span className="quotation-info">Customer Detail:</span> {order.customerDetail}</p>
                            <p className="card-text mb-0"><span className="quotation-info">Net Total Amount:</span> {order.totalAmount}</p>
                            <p className="card-text mb-0"><span className="quotation-info">Total Discount:</span> {order.totalDiscount}</p>
                            <p className="card-text mb-0"><span className="quotation-info">Grand Total:</span> {order.grandTotal}</p>
                            <p className="card-text mb-0"><span className="quotation-info">Advance Paid:</span> {order.advancePaid}</p>
                            <p className="card-text mb-0"><span className="quotation-info">Total Paid:</span> {order.totalPaid}</p>
                            <p className="card-text mb-0"><span className="quotation-info">Delivery Date:</span> {order.expectedDate}</p>
                            <p className="card-text mb-0"><span className="quotation-info">Due For/Due In:</span> {order.days}</p>
                            <p className="card-text mb-0"><span className="quotation-info">Status:</span> {order.status}</p>
                        </div>
                    </div>
                </Col>
                ))}
            </Row>
            </div> : <Loader/>}
        </Container>
    </>

  )
}

const mapStateToProps = (state) => {
  
    return {
       currentIntegration: state.expectedDeliveryDate.currentIntegration,
       isLoading: state.expectedDeliveryDate.isLoading,
     }
   }
  
   export default connect(mapStateToProps,{getExpectedDeliveryDate})(ExpectedDeliveryDate);
