import {
    SET_ORDERNAME,
    SET_QUOTATION,
    SET_CORDINATOR,
    SET_REFFERED,
    SET_PRODUCT,
    SET_LOCATION,
    PRODUCT_NAME,
    GET_IMPORTED_READY,
    GET_IMPORTED_ORDER,
    GET_TOTAL_AMOUNT,
} from "../QuotationToOrder/actionType";

const INITIAL_STATE = {
  orderName :'',
  currentIntegration: [],
  quotations:[],
  cordinator:[],
  reffered:[],
  products:[],
  locations:[],
  product_name :'',
  imported_ready: [],
  imported_order: [],
  total_amount:''
};

const orderReducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;
 
  switch (type) {
    case SET_ORDERNAME:
      return {
        ...state,
        orderName: payload,
      };
    case SET_QUOTATION:
      return {
        ...state,
        quotations: payload,
      };
    case SET_CORDINATOR:
      return {
        ...state,
        cordinator: payload,
      };
    case SET_REFFERED:
      return {
        ...state,
        reffered: payload,
      };
    case SET_PRODUCT:
      return {
        ...state,
        products: payload,
      };
    case SET_LOCATION:
      return {
        ...state,
        locations: payload,
      };
    case PRODUCT_NAME:
      return {
        ...state,
        product_name: payload,
      };
    case GET_IMPORTED_READY:
      return {
        ...state,
        imported_ready: payload,
      };
    case GET_IMPORTED_ORDER:
      return {
        ...state,
        imported_order: payload,
      };
    case GET_TOTAL_AMOUNT:
      return {
        ...state,
        total_amount: payload,
      };
    default:
      return state;
  }
};


export default orderReducer;