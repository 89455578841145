import {
    RETRIEVE_APPROVE_PAYMENT
} from "../ApprovePayment/actiontype";

import { toast } from 'react-toastify';
import api from "../../../api";

export const getApprovePayment= (data) => async (dispatch) => {
  //dispatch({ type: REQUEST_INTEGRATION_LOADER });
  try {
    const res = await api.get(`/accountSection/approvedPayment`);
   
    
    dispatch({
      type: RETRIEVE_APPROVE_PAYMENT,
      payload: res.data.data,
    });
  } catch (err) {
    
    dispatch({
      type: RETRIEVE_APPROVE_PAYMENT,
      payload: [],
    });
  }
};



