import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';

function Footer() {
  
  return (
        <>
            <footer className="footer-area"> 
              <Container fluid>
                <Row>
                  <Col>
                    <div className="d-md-flex justify-content-between pt-2 pb-2 d-block">
                      <p className="mb-0">Copyright 2022, Order Processing </p>
                      <p className="mb-0">Powered By <a href="#">Techie Squad</a></p>
                  </div>
                  </Col>
                </Row>
              </Container>
               
            </footer>
        </>
  )
}
export default Footer