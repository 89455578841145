import * as React from "react";
import {useState, useEffect} from 'react'
import { Row, Col, Container, Badge, Dropdown} from 'react-bootstrap';
import MaterialTable from 'material-table';
import EditIcon from '@mui/icons-material/Edit';
import { connect } from "react-redux";
import {getCpOrder} from '../../redux/CP/ViewOrder/action';
import Loader from '../../components/Loader/Loader';
import SearchBar from "../../components/SearchBar/SearchBar";
import { useNavigate } from 'react-router-dom';

const CpViewOrder = ({ getCpOrder,currentIntegration,isLoading }) =>  {  useEffect(() => {
  getCpOrder();
}, []);

const [loading, setLoading] = useState(false);
useEffect(() => {
    if (isLoading) {
        setLoading(true);
    }
  });

const [visible, setVisible] = useState(false);
const handleVisibility = () => {
    setVisible(prev => !prev);
};

const [tableData, setTableData] = useState([]);
  useEffect(() => {
    if(currentIntegration)
    {
      setTableData(currentIntegration); 
    }
  }, [currentIntegration])

const columns = [
{ title: "Order No.", field: "order_id" },
{ title: "Date", field: "date" },
{ title: "Total Quantity", field: "qty" },
{ title: "Status", field: "status" },

]
const navigate = useNavigate();
const editCpOrder = async (e) => {
  navigate('/edit-order/'+e);
}
// Search for Card view start
const [searchValue, setSearchValue] = useState("");

const filterOrder = ({ order_id }) => {
  return order_id.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
};
// Search for Card view end

  return (
    <>
        <Container fluid className="main-content">
          <Row>
              <Col>
                  <h3 className="main-title">Order Summary</h3>
              </Col> 
              <Col>
                  <div style={{float: "right"}} className="d-flex">
                    <div className="d-flex justify-content-between d-flex d-sm-none">
                      <div className="magnifierContainer">
                        <button type="button" className="btn btn-md btn-warning text-white roundedcircle mr-3" onClick={handleVisibility}><i className="fa-solid fa-search"></i></button>
                      </div>
                    </div>
                  </div>
              </Col>
              <Col lg={"12"}>
                <div className='d-block d-sm-none mt-2'>
                  <div className={visible ? "open" : "close"}>
                    <SearchBar onSearch={setSearchValue} value={searchValue}/>
                  </div>
                </div>
              </Col>
            </Row>
            {loading ? <div>
            <Row className="d-none d-sm-block mt-2">
              <Col lg={"12"} className="mt-3">
                <div style={{display: 'table', tableLayout:'fixed', width:'100%'}}>
                <MaterialTable columns={columns} data={tableData}
                    options={{
                    sorting: true, search: true,
                    paging: true, pageSizeOptions: [10, 25, 50, 100], pageSize: 10,
                    exportButton: true,
                    exportAllData: true, exportFileName: "Order Summary", actionsColumnIndex: -1,
                    // headerStyle: { background: "#f44336",color:"#fff"}
                    }}
                    title="Order Summary"
                    actions={[
                        {
                          icon: () => <EditIcon color={'primary'} />,
                          tooltip: 'Edit',
                          onClick: (event, rowData) => editCpOrder(rowData.id)
                        }
                    ]}
                />
                </div>
              </Col>
            </Row>
            <Row className="d-block d-sm-none mt-2">
             {currentIntegration.filter(filterOrder).map((cporder, index) => ( 
            <Col xs={"12"}>
                <div className="card mb-2">
                    <div className="card-header d-flex justify-content-between pb-0">
                        <h6>{cporder.order_id}</h6>
                        <Dropdown>
                          <Dropdown.Toggle variant="light" id="dropdown-basic">
                              <i className="ri-more-2-line"></i>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                              <Dropdown.Item onClick={() => editCpOrder(cporder.id)}><i className="ri-edit-box-line text-primary"></i> Edit</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div className="card-body pt-1">
                        <p className="card-text mb-0"><span className="quotation-info">Date:</span> {cporder.date}</p>
                        <p className="card-text mb-0"><span className="quotation-info">Quantity:</span> {cporder.qty}</p>
                        <p className="card-text mb-0"><span className="quotation-info">Status:</span> {cporder.status}</p>
                    </div>
                </div>
            </Col>
             ))} 
        </Row>
        </div> : <Loader/> }
      </Container>
    </>

  )
}

const mapStateToProps = (state) => {
  //
    return {
       currentIntegration: state.cpOrder.currentIntegration,
       isLoading: state.cpOrder.isLoading,
     }
   }
  
export default connect(mapStateToProps,{getCpOrder})(CpViewOrder);